
import {
    Box, Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell
} from '@mui/material';

export function PackagingTotalTable({ packagingMaterialsDataTotals }) {

    return (
        <Box>
            <TableContainer component={Paper} sx={{ height: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                Month
                            </TableCell>
                            <TableCell align="left">
                                Glass (kg)
                            </TableCell>
                            <TableCell align="left">
                                Cardboard (kg)
                            </TableCell>
                            <TableCell align="left">
                                Labels (kg)
                            </TableCell>
                            <TableCell align="left">
                                Corks (kg)
                            </TableCell>
                            <TableCell align="left">
                                Plastic (kg)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {packagingMaterialsDataTotals?.map((data, i) => {

                            if (!Object.keys(data).includes('grand_total_glass')) {
                                return (
                                    <TableRow key={i}>
                                        <TableCell align="left">
                                            {data.month}
                                        </TableCell>
                                        <TableCell align="left">
                                            {data.glassQuantity}
                                        </TableCell>
                                        <TableCell align="left">
                                            {data.cardboardQuantity}
                                        </TableCell>
                                        <TableCell align="left">
                                            {data.labelsQuantity}
                                        </TableCell>
                                        <TableCell align="left">
                                            {data.corksQuantity}
                                        </TableCell>
                                        <TableCell align="left">
                                            {data.plasticQuantity}
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        })}
                        <TableRow sx={{ border: '2px solid black' }}>
                            <TableCell align="left"><b>Grand Total</b></TableCell>
                            <TableCell align="left"><b>{packagingMaterialsDataTotals[12].grand_total_glass} kg</b></TableCell>
                            <TableCell align="left"><b>{packagingMaterialsDataTotals[12].grand_total_cardboard} kg</b></TableCell>
                            <TableCell align="left"><b>{packagingMaterialsDataTotals[12].grand_total_labels} kg</b></TableCell>
                            <TableCell align="left"><b>{packagingMaterialsDataTotals[12].grand_total_corks} kg</b></TableCell>
                            <TableCell align="left"><b>{packagingMaterialsDataTotals[12].grand_total_plastic} kg</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
