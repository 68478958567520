import { Box, CardMedia, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import webAppInfographicSrc from '../../../assets/images/WebAppInfographic.png';
import mobileAppInfographicSrc from '../../../assets/images/CHARRmobileappinfographic.png';

const Infographic = () => {
  return (
    <Box sx={{ p: 3 }}>
        <Typography variant="h4" component="h4" sx={{ mb: 3 }}>
        Getting started with the Biochar Programme
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5" component="div">How to use the web app</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CardMedia component="img" image={webAppInfographicSrc} title="Web app Infographic" />
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ mt: 3 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant="h5" component="div">How to use the CHARR mobile app</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CardMedia component="img" image={mobileAppInfographicSrc} title="Mobile app Infographic" />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Infographic;
