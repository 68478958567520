import { setAlert } from '../alert';
import {
    API_GET_BATCH_VOLUMES,
    API_GET_BATCH_BULK_DENSITY,
    API_GET_VOL_AND_BD_AVERAGE,
    API_GET_BIOCHAR_MASS,
    API_GET_BATCH_CARBON_CONTENT,
    API_GET_BIOCHAR_STORED_CARBON,
    API_POST_VOL_AND_BD_AVERAGE,
    API_POST_BATCH_CARBON_CONTENT,
    API_POST_BIOCHAR_STORED_CARBON,
    API_GET_CB_BATCH_DATA,
    API_GET_CB_ADDITIONAL_BATCH_DATA
} from './constants/conservation-burn-constants';
import {
    GET_BATCH_VOLUMES_SUCCESS,
    GET_BATCH_VOLUMES_FAIL,
    GET_BATCH_BULK_DENSITY_SUCCESS,
    GET_BATCH_BULK_DENSITY_FAIL,
    GET_VOL_AND_BD_AVERAGE_SUCCESS,
    GET_VOL_AND_BD_AVERAGE_FAIL,
    GET_BIOCHAR_MASS_SUCCESS,
    GET_BIOCHAR_MASS_FAIL,
    GET_BATCH_CARBON_CONTENT_SUCCESS,
    GET_BATCH_CARBON_CONTENT_FAIL,
    GET_BIOCHAR_STORED_CARBON_SUCCESS,
    GET_BIOCHAR_STORED_CARBON_FAIL,
    POST_VOL_AND_BD_AVERAGE_SUCCESS,
    POST_VOL_AND_BD_AVERAGE_FAIL,
    POST_BATCH_CARBON_CONTENT_SUCCESS,
    POST_BATCH_CARBON_CONTENT_FAIL,
    POST_BIOCHAR_STORED_CARBON_SUCCESS,
    POST_BIOCHAR_STORED_CARBON_FAIL,
    GET_CB_BATCH_DATA_SUCCESS,
    GET_CB_BATCH_DATA_FAIL,
    GET_CB_ADDITIONAL_BATCH_DATA_SUCCESS,
    GET_CB_ADDITIONAL_BATCH_DATA_FAIL
} from './types/conservation-burn-types';

import axios from 'axios';
const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    },
    // withCredentials: true,
};

// GET Actions
export const getBatchVolumes = (project) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_BATCH_VOLUMES + `/${project}`,
            config
        );
        dispatch({
            type: GET_BATCH_VOLUMES_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_BATCH_VOLUMES_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getBatchBulkDensity = (project) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_BATCH_BULK_DENSITY + `/${project}`,
            config
        );
        dispatch({
            type: GET_BATCH_BULK_DENSITY_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_BATCH_BULK_DENSITY_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getVolAndBdAverage = (project) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_VOL_AND_BD_AVERAGE + `/${project}`,
            config
        );
        dispatch({
            type: GET_VOL_AND_BD_AVERAGE_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_VOL_AND_BD_AVERAGE_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getBiocharMass = (project) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_BIOCHAR_MASS + `/${project}`,
            config
        );
        dispatch({
            type: GET_BIOCHAR_MASS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_BIOCHAR_MASS_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getBatchCarbonContent = (project) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_BATCH_CARBON_CONTENT + `/${project}`,
            config
        );
        dispatch({
            type: GET_BATCH_CARBON_CONTENT_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_BATCH_CARBON_CONTENT_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getBiocharStoredCarbon = (project) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_BIOCHAR_STORED_CARBON + `/${project}`,
            config
        );
        dispatch({
            type: GET_BIOCHAR_STORED_CARBON_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_BIOCHAR_STORED_CARBON_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getCbBatchData = (project) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_CB_BATCH_DATA + `/${project}`,
            config
        );
        dispatch({
            type: GET_CB_BATCH_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_CB_BATCH_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getCbAdditionalBatchData = (project) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_CB_ADDITIONAL_BATCH_DATA + `/${project}`,
            config
        );
        dispatch({
            type: GET_CB_ADDITIONAL_BATCH_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_CB_ADDITIONAL_BATCH_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

// POST Actions
export const postVolAndBdAverage = (project, formData) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_VOL_AND_BD_AVERAGE + `/${project}`,
            formData,
            config
        );
        dispatch({
            type: POST_VOL_AND_BD_AVERAGE_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_VOL_AND_BD_AVERAGE_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const postBatchCarbonContent = (project, formData) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_BATCH_CARBON_CONTENT + `/${project}`,
            formData,
            config
        );
        dispatch({
            type: POST_BATCH_CARBON_CONTENT_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_BATCH_CARBON_CONTENT_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const postBiocharStoredCarbon = (formData) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_BIOCHAR_STORED_CARBON,
            formData,
            config
        );
        dispatch({
            type: POST_BIOCHAR_STORED_CARBON_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_BIOCHAR_STORED_CARBON_FAIL,
            payload: err.response.data.detail,
        });
    }
};