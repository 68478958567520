export const APP_NAME = 'CHARR';
export const UPLOADED_DOCS =
  'https://ikhala-static-content1.s3.eu-central-1.amazonaws.com/uploaded_docs/';

// API
export const API_REGISTER = 'api/user/register/';
export const API_LOGIN = 'api/user/login/';
export const API_LOGOUT = 'api/user/logout/';
export const API_FAQS = 'api/faqs';
export const API_USER_PROFILE = 'api/user/profile/';
export const API_USER_DETAILS = 'api/user/details/';
export const API_FORM_DATA = 'api/form-data';
export const API_FIREBREAKS = 'api/firebreaks';
export const API_UPLOAD_FILES = 'api/upload-files';
export const API_USER_PROGRAMMES = 'api/user/programmes/';
export const API_PROJECTS = 'api/projects';
export const API_UPDATE_PROJECT_NICKNAME = API_PROJECTS + '/update-nickname';
export const API_IRT_REGISTRIES = 'api/irt-registries';
export const API_IRT_PROJECTS = 'api/irt-projects';
export const API_PLOTLIST = 'api/cf/plot-list/';
export const API_CREATE_GENERAL_INFORMATION = 'api/cf/general-information';
export const API_GET_GENERAL_INFORMATION = 'api/cf/general-information/';
export const API_CREATE_CULTIVATION_GENERAL_INFORMATION =
  'api/cf/cultivation-general-information';
export const API_GET_CULTIVATION_GENERAL_INFORMATION =
  'api/cf/cultivation-general-information/';
export const API_CF_USER_DETAILS = 'api/user/cf/details/';
export const API_FETCH_NOTES = 'api/fetch-notes';
// fertilizers
export const API_CF_GET_OR_CREATE_FERTILIZERS = 'api/cf/fertilizers/';
export const API_CF_UPDATE_FERTILIZER_ACTIVE_STATE =
  'api/cf/active-fertilizers/';
export const API_CF_GET_FERTILIZER_PLOTLIST_DATA =
  'api/cf/fertilizer-plotlist/';
export const API_CF_SUBMIT_FERTILIZER_PLOTLIST_DATA =
  'api/cf/submit-fertilizer-plotlist-data/';
// agro chemicals
export const API_CF_GET_OR_CREATE_AGROCHEMICAL = 'api/cf/agrochemicals/';
export const API_CF_UPDATE_AGROCHEMICAL_ACTIVE_STATE =
  'api/cf/active-agrochemicals/';
export const API_CF_GET_AGROCHEMICAL_PLOTLIST_DATA =
  'api/cf/agrochemical-plotlist/';
export const API_CF_SUBMIT_AGROCHEMICAL_PLOTLIST_DATA =
  'api/cf/submit-agrochemical-plotlist-data/'; // NEW !!!
// compost
export const CREATE_ON_FARM_COMPOST = 'api/cf/on-farm-compost/';
export const CREATE_PURCHASED_COMPOST = 'api/cf/purchased-compost/';
export const GET_ON_FARM_COMPOST = 'api/cf/get-on-farm-compost/';
export const GET_PURCHASED_COMPOST = 'api/cf/get-purchased-compost/';
export const API_CF_GET_COMPOST_PLOTLIST_DATA = 'api/cf/compost-plotlist/';
export const API_CF_SUBMIT_COMPOST_PLOTLIST_DATA =
  'api/cf/submit-compost-plotlist-data/'; // NEW !!!
// biochar
export const GET_BATCH_REPORTS = 'api/fetch-batch-reports/';
export const GET_PROJECT_REPORTS = 'api/fetch-project-reports/';
export const GET_BCR_REPORTS = 'api/fetch-bcr-reports/';
export const GET_KILN_DIMENSIONS = 'api/fetch-kiln-dimensions/';
// mobile
export const API_GET_BATCH_MEDIA = 'api/fetch-batch-media/';
export const API_GET_BATCH_DATA = 'api/fetch-batch-volumes/';
export const API_GET_ADDITIONAL_BATCH_DATA = 'api/fetch-additional-batch-data/';
//
export const API_FETCH_CHARGING_DATA = 'api/fetch-biochar-charging/';
export const API_FETCH_APPLICATION_DATA = 'api/fetch-biochar-application/';
// verification
export const API_FETCH_FEEDSTOCK_VERIFICATION_DATA =
  'api/fetch-feedstock-moisture-data-for-verification/';
export const API_FETCH_FLAMING_COMBUSTION_VERIFICATION_DATA =
  'api/fetch-flaming-combustion-data-for-verification/';
export const API_FETCH_BIOCHAR_MEASUREMENT_VERIFICATION_DATA =
  'api/fetch-biochar-measurement-data-for-verification/';
export const API_FETCH_BULK_DENSITY_VERIFICATION_DATA =
  'api/fetch-bulk-density-data-for-verification/';
export const API_FETCH_UNBURNT_VOLUME_VERIFICATION_DATA =
  'api/fetch-unburnt-volume-data-for-verification/';
export const API_FETCH_BIOCHAR_CHARGING_VERIFICATION_DATA =
  'api/fetch-biochar-charging-data-for-verification/';
export const API_FETCH_BIOCHAR_APPLICATION_VERIFICATION_DATA =
  'api/fetch-biochar-application-data-for-verification/';
export const API_VERIFY_BIOCHAR_DATA = 'api/verify-biochar-data/';
export const API_POST_ADD_BIOCHAR_ASSET = 'api/cf/post-add-biochar-asset';
export const API_GET_BIOCHAR_ASSETS = 'api/cf/get-biochar-assets';
/* DIESEL URLS */
export const API_POST_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA =
  'api/cf/post-stationary-or-mobile-biochar-diesel-data';
export const API_GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA =
  'api/cf/get-stationary-or-mobile-biochar-diesel-data';
export const API_GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_TOTALS =
  'api/cf/get-stationary-or-mobile-biochar-diesel-data-totals';
/* PETROL URLS */
export const API_POST_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA =
  'api/cf/post-stationary-or-mobile-biochar-petrol-data';
export const API_GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA =
  'api/cf/get-stationary-or-mobile-biochar-petrol-data';
export const API_GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_TOTALS =
  'api/cf/get-stationary-or-mobile-biochar-petrol-data-totals';
/* LPG URLS */
export const API_POST_BIOCHAR_LPG_DATA = 'api/cf/post-biochar-lpg-data';
export const API_GET_BIOCHAR_LPG_DATA = 'api/cf/get-biochar-lpg-data';
export const API_GET_BIOCHAR_LPG_DATA_TOTALS =
  'api/cf/get-biochar-lpg-data-totals';
/* HIGHEST ID */
export const API_GET_HIGHEST_BIOCHAR_ID = 'api/cf/get-highest-biochar-id/';
/* FILE UPLOAD */
export const API_BIOCHAR_UPLOAD_FILE = 'api/cf/biochar-upload-file'
export const SET_MEASUREMENT_TYPE = 'api/set-measurement-type'
export const API_DATA_TABLES_DATA = 'api/data-tables-data'
export const API_POST_BATCH_CCIK = 'api/post-batch-ccik'
export const API_POST_LAB_REPORT_FILE_UPLOAD = 'api/post-lab-report-file-upload'
export const API_POST_BIOCHAR_STORED_CARBON = 'api/post-biochar-stored-carbon'
export const API_POST_BATCH_VOLUMES_AND_MEDIA = 'api/fetch-batch-volumes-and-media-pdf'

