import {
    GET_PETROL_CARD_HOLDERS_SUCCESS,
    GET_PETROL_CARD_HOLDERS_FAIL,
    POST_PETROL_CARD_HOLDER_SUCCESS,
    POST_PETROL_CARD_HOLDER_FAIL,
    GET_PETROL_CARDS_DATA_SUCCESS,
    GET_PETROL_CARDS_DATA_FAIL,
    POST_PETROL_CARDS_DATA_SUCCESS,
    POST_PETROL_CARDS_DATA_FAIL,
    GET_PETROL_CARDS_DATA_TOTALS_SUCCESS,
    GET_PETROL_CARDS_DATA_TOTALS_FAIL
} from '../../../actions/carbonfootprint-rework/types/03-indirect-ghg-emissions-from-transportation';

const initialState = {
    loading: true,
    petrolCardHolders: [],
    petrolCardData: [],
    petrolCardDataTotals: []
};

// CAR HIRE REDUCERS
export default function petrolCardHolderReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_PETROL_CARD_HOLDERS_SUCCESS:
            return {
                ...state,
                petrolCardHolders: payload,
                loading: false,
            };
        case GET_PETROL_CARD_HOLDERS_FAIL:
            return {
                ...state,
                loading: false
            }
        case POST_PETROL_CARD_HOLDER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_PETROL_CARD_HOLDER_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_PETROL_CARDS_DATA_SUCCESS:
            return {
                ...state,
                petrolCardData: payload,
                loading: false
            };
        case GET_PETROL_CARDS_DATA_FAIL:
            return {
                ...state,
                loading: false
            };
        case POST_PETROL_CARDS_DATA_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case POST_PETROL_CARDS_DATA_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_PETROL_CARDS_DATA_TOTALS_SUCCESS:
            return {
                ...state,
                petrolCardDataTotals: payload,
                loading: false
            };
        case GET_PETROL_CARDS_DATA_TOTALS_FAIL:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}