// GET routes
export const API_GET_BATCH_VOLUMES = 'api/conservation-burns/batch-volumes'
export const API_GET_BATCH_BULK_DENSITY = 'api/conservation-burns/bulk-densities'
export const API_GET_VOL_AND_BD_AVERAGE = 'api/conservation-burns/vol-and-bd-average'
export const API_GET_BIOCHAR_MASS = 'api/conservation-burns/biochar-mass'
export const API_GET_BATCH_CARBON_CONTENT = 'api/conservation-burns/batch-carbon-content'
export const API_GET_BIOCHAR_STORED_CARBON = 'api/conservation-burns/biochar-stored-carbon'
// DATA MONITORING ROUTES
// GET routes

export const API_GET_CB_BATCH_DATA = 'api/conservation-burns/batch-data'
export const API_GET_CB_ADDITIONAL_BATCH_DATA = 'api/conservation-burns/additional-batch-data'

// POST routes
export const API_POST_VOL_AND_BD_AVERAGE = 'api/conservation-burns/post-vol-and-bd-average'
export const API_POST_BATCH_CARBON_CONTENT = 'api/conservation-burns/post-batch-carbon-content'
export const API_POST_BIOCHAR_STORED_CARBON = 'api/conservation-burns/post-biochar-stored-carbon'