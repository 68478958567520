import {
    GET_SUPPLIERS_SUCCESS,
    GET_SUPPLIERS_FAIL,
    ADD_SUPPLIER_SUCCESS,
    ADD_SUPPLIER_FAIL,
} from '../../../actions/carbonfootprint-rework/types/04-indirect-ghg-emissions-from-products-used-by-an-organisation';

const initialState = {
    loading: true,
    suppliers: []
};

export default function indirectGhgEmissionsFromProductsSuppliers(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ADD_SUPPLIER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case ADD_SUPPLIER_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_SUPPLIERS_SUCCESS:
            return {
                ...state,
                suppliers: payload,
                loading: false,
            };
        case GET_SUPPLIERS_FAIL:
            return {
                ...state,
                suppliers: payload,
                loading: false
            }
        default:
            return state;
    }
}