import { Divider } from '@mui/material';
import Resources from '../sections/home/Resources';
// import Apply from '../sections/home/Apply';
import IkhalaCalculators from '../sections/home/IkhalaCalculators';
import Infographic from '../sections/home/Infographic';

const ResourcesPage = () => {
    return (
        <>
            {/* <Welcome /> */}
            <IkhalaCalculators />
            <Divider />
            <Infographic />
            <Divider />
            {/*<Apply />*/}
            {/*<Divider />*/}
            {/* <Updates /> */}

            {/* <CarbonCalculator /> */}
            {/* <Divider /> */}
            {/* <HempSupplyComponent /> */}
            {/* <Divider /> */}
            {/* <HempDemandComponent /> */}
            {/* <Divider /> */}
            <Resources />
            {/* <Divider /> */}
            {/* <BiocharTraderForms /> */}
            <Divider />

        </>
    );
};

export default ResourcesPage;
