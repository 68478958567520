import React from 'react'
import { TableContainer, Table, TableHead, TableCell, TableRow, Paper, TableBody } from '@mui/material';

const EmissionFactors = () => {
    return (
        <>
            <h2>Emission Factors</h2>
            <TableContainer key={0} component={Paper} sx={{ height: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                Emission Factor Description
                            </TableCell>
                            <TableCell align="left">
                                Emission Factor
                            </TableCell>
                            <TableCell align="left">
                                Value (Conversion Factor)
                            </TableCell>
                            <TableCell align="left">
                                Unit
                            </TableCell>
                            <TableCell align="left">
                                Source
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">Emissions factor for thermochemical conversion process TC used to produce biochar</TableCell>
                            <TableCell align="left">EF_TC</TableCell>
                            <TableCell align="left">0.00033</TableCell>
                            <TableCell align="left">MTCH4/MT biomass</TableCell>
                            <TableCell align="left">CHARR Boss LCA Space Keeper</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default EmissionFactors