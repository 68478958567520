import {
  POST_STATIONARY_OR_MOBILE_DIESEL_DATA_SUCCESS,
  POST_STATIONARY_OR_MOBILE_DIESEL_DATA_FAIL,
  GET_STATIONARY_OR_MOBILE_DIESEL_DATA_SUCCESS,
  GET_STATIONARY_OR_MOBILE_DIESEL_DATA_FAIL,
  GET_STATIONARY_OR_MOBILE_DIESEL_DATA_TOTALS_SUCCESS,
  GET_STATIONARY_OR_MOBILE_DIESEL_DATA_TOTALS_FAIL,
  GET_HIGHEST_ID_SUCCESS,
  GET_HIGHEST_ID_FAIL
} from '../../../actions/carbonfootprint-rework/types/01-direct-ghg-emissions-and-removals-types';


const initialState = {
  loading: true,
  dieselData: [],
  dieselDataTotals: [],
  highestId: -1,
};

// DIESEL REDUCERS
export default function directGhgEmissionsAndRemovalsForDiesel(state = initialState, action) {

  const { type, payload } = action;

  switch (type) {
    // STATIONARY DIESEL
    case POST_STATIONARY_OR_MOBILE_DIESEL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case POST_STATIONARY_OR_MOBILE_DIESEL_DATA_FAIL:
      return {
        ...state,
        loading: false
      }
    case GET_STATIONARY_OR_MOBILE_DIESEL_DATA_SUCCESS:
      return {
        ...state,
        dieselData: payload,
        loading: false,
      };
    case GET_STATIONARY_OR_MOBILE_DIESEL_DATA_FAIL:
      return {
        ...state,
        dieselData: payload,
        loading: false
      }
    case GET_STATIONARY_OR_MOBILE_DIESEL_DATA_TOTALS_SUCCESS:
      return {
        ...state,
        dieselDataTotals: payload,
        loading: false,
      };
    case GET_STATIONARY_OR_MOBILE_DIESEL_DATA_TOTALS_FAIL:
      return {
        ...state,
        dieselDataTotals: payload,
        loading: false
      }
    case GET_HIGHEST_ID_SUCCESS:
      return {
        ...state,
        highestId: payload,
        loading: false,
      };
    case GET_HIGHEST_ID_FAIL:
      return {
        ...state,
        highestId: payload,
        loading: false
      }
    default:
      return state
  }
}
