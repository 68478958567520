
import {
    Box, Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell
} from '@mui/material';

export function CapitalGAndSTotalTable({ capitalGoodsAndServicesDataTotals }) {

    return (
        <Box>
            <TableContainer component={Paper} sx={{ height: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                Month
                            </TableCell>
                            {/* <TableCell align="left">
                                Item Value
                            </TableCell> */}
                            <TableCell align="left">
                                Total Items
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {capitalGoodsAndServicesDataTotals?.map((data, i) => {

                            if (!Object.keys(data).includes('grand_total')) {
                                return (
                                    <TableRow key={i}>
                                        <TableCell align="left">
                                            {data.month}
                                        </TableCell>
                                        {/* <TableCell align="left">
                                            {data.itemsQuantity}
                                        </TableCell> */}
                                        <TableCell align="left">
                                            {data.total}
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        })}
                        <TableRow sx={{ border: '2px solid black' }}>
                            {/* <TableCell align="left"></TableCell> */}
                            <TableCell align="left"><b>Grand Total</b></TableCell>
                            <TableCell align="left"><b>{capitalGoodsAndServicesDataTotals[12].grand_total}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
