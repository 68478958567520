import React, { useEffect } from 'react'
import { TableContainer, Table, TableHead, TableCell, TableRow, Paper, TableBody } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getBiocharVolumes } from '../../../../actions/tigercat-burns/tigercat-actions';

const BiocharData = ({ projectId }) => {
    const dispatch = useDispatch()

    const biocharVolumes = useSelector(state => state.tigercatBurnsReducer.biocharVolumes.data)

    useEffect(() => {
        dispatch(getBiocharVolumes(projectId));
    }, [dispatch, projectId])

    return (<>
        <h2>Biochar Data</h2>
        <TableContainer key={0} component={Paper} sx={{ height: 'auto' }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">
                            User
                        </TableCell>
                        <TableCell align="left">
                            Date
                        </TableCell>
                        <TableCell align="left">
                            Bin Used
                        </TableCell>
                        <TableCell align="left">
                            Measurement (inch)
                        </TableCell>
                        <TableCell align="left">
                            Measurement Photo
                        </TableCell>
                        <TableCell align="left">
                            Equipment Used
                        </TableCell>
                        <TableCell align="left">
                            Volume Biochar (m3)
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {biocharVolumes?.map((data, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell align="left">{data.user_name}</TableCell>
                                <TableCell align="left">{data.created_at.split('T')[0]}</TableCell>
                                <TableCell align="left">{data.bin_used_name}</TableCell>
                                <TableCell align="left">{data.depth_measurement}</TableCell>
                                <TableCell align="left"><a href={data.measurement_photo} about="_blank">{data.measurement_photo.split('/')[data.measurement_photo.split('/').length - 1]}</a></TableCell>
                                <TableCell align="left">{data.equipment_used_name}</TableCell>
                                <TableCell align="left">{data.biochar_volume}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    </>

    )
}

export default BiocharData