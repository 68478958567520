import React from "react";
import Step1Form from "./stepForms/Step1Form";
import Step2Form from "./stepForms/Step2Form";
import Step3Form from "./stepForms/Step3Form";
import Step4Form from "./stepForms/Step4Form";

// const StepForm = ({ activeStep }) => {
//   const [currency, setCurrency] = useState("R-ZAR");
//   const [measurementSystem, setMeasurementSystem] = useState("Metric");
//   const [kilnVolume, setKilnVolume] = useState("");
//   const [biocharYieldLevel, setBiocharYieldLevel] = useState("");
//   const [numberOfKilns, setNumberOfKilns] = useState("1");
//   const [laborCosts, setLaborCosts] = useState("0");
//   const [burnSupervisorCosts, setBurnSupervisorCosts] = useState("0");
//   const [otherCosts, setOtherCosts] = useState("0");
//   const [capitalCosts, setCapitalCosts] = useState("");
//   const [bulkDensity, setBulkDensity] = useState("");

//   switch (activeStep) {
//     case 0:
//       return (
//         <Step1Form
//           currency={currency}
//           setCurrency={setCurrency}
//           measurementSystem={measurementSystem}
//           setMeasurementSystem={setMeasurementSystem}
//         />
//       );
//     case 1:
//       return (
//         <Step2Form
//           kilnVolume={kilnVolume}
//           setKilnVolume={setKilnVolume}
//           biocharYieldLevel={biocharYieldLevel}
//           setBiocharYieldLevel={setBiocharYieldLevel}
//           numberOfKilns={numberOfKilns}
//           setNumberOfKilns={setNumberOfKilns}
//           measurementSystem={measurementSystem}
//         />
//       );
//     case 2:
//       return (
//         <Step3Form
//           currency={currency}
//           laborCosts={laborCosts}
//           setLaborCosts={setLaborCosts}
//           burnSupervisorCosts={burnSupervisorCosts}
//           setBurnSupervisorCosts={setBurnSupervisorCosts}
//           otherCosts={otherCosts}
//           setOtherCosts={setOtherCosts}
//         />
//       );
//     case 3:
//       return (
//         <Step4Form
//           kilnVolume={kilnVolume}
//           biocharYieldLevel={biocharYieldLevel}
//           numberOfKilns={numberOfKilns}
//           laborCosts={laborCosts}
//           burnSupervisorCosts={burnSupervisorCosts}
//           otherCosts={otherCosts}
//           measurementSystem={measurementSystem}
//         />
//       );
//     default:
//       throw new Error("Unknown step");
//   }
// };


const Steps = ({
  activeStep,
  currency,
  kilnVolume,
  biocharYieldLevel,
  numberOfKilns,
  laborCosts,
  burnSupervisorCosts,
  otherCosts,
  setCurrency,
  measurementSystem,
  setMeasurementSystem,
  setKilnVolume,
  setBiocharYieldLevel,
  setNumberOfKilns,
  setLaborCosts,
  setBurnSupervisorCosts,
  setOtherCosts,
  capitalCosts,
  setCapitalCosts,
  bulkDensity,
  setBulkDensity,
}) => {
  switch (activeStep) {
    case 0:
      return (
        <Step1Form
          currency={currency}
          setCurrency={setCurrency}
          measurementSystem={measurementSystem}
          setMeasurementSystem={setMeasurementSystem}
        />
      );
    case 1:
      return (
        <Step2Form
          kilnVolume={kilnVolume}
          setKilnVolume={setKilnVolume}
          biocharYieldLevel={biocharYieldLevel}
          setBiocharYieldLevel={setBiocharYieldLevel}
          numberOfKilns={numberOfKilns}
          setNumberOfKilns={setNumberOfKilns}
          measurementSystem={measurementSystem}
          bulkDensity={bulkDensity}
          setBulkDensity={setBulkDensity}
        />
      );
    case 2:
      return (
        <Step3Form
          currency={currency}
          laborCosts={laborCosts}
          setLaborCosts={setLaborCosts}
          burnSupervisorCosts={burnSupervisorCosts}
          setBurnSupervisorCosts={setBurnSupervisorCosts}
          otherCosts={otherCosts}
          setOtherCosts={setOtherCosts}
          capitalCosts={capitalCosts}
          setCapitalCosts={setCapitalCosts}
        />
      );
    case 3:
      return (
        <Step4Form
          kilnVolume={kilnVolume}
          biocharYieldLevel={biocharYieldLevel}
          numberOfKilns={numberOfKilns}
          laborCosts={laborCosts}
          burnSupervisorCosts={burnSupervisorCosts}
          otherCosts={otherCosts}
          currency={currency}
          measurementSystem={measurementSystem}
          bulkDensity={bulkDensity}
          capitalCosts={capitalCosts}
        />
      );
    default:
      throw new Error("Unknown step");
  }
};

export default Steps;
