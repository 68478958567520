import { useEffect } from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

export default function F017ComplianceChecklist({ data, setData, setFormId }) {
  useEffect(() => {
    setFormId('F017');
  }, [setFormId]);

  const { name, contract } = data;

  const items = [
    {
      id: 0,
      text: "Are you aware of the landwoner's responsibilities under the National Veld and Forest Fire Act?",
      name: 'awareOfLandownerRespo',
    },
    {
      id: 1,
      text: 'Are you in possession of a valid FPA membership certificate?',
      name: 'validFPA',
    },
    {
      id: 2,
      text: 'Do you know who your local FMU/ward leadership is and have their contact details?',
      name: 'localLeadership',
    },
    {
      id: 3,
      name: 'name',
      value: name,
      textField: true,
    },
    {
      id: 4,
      name: 'contract',
      value: contract,
      textField: true,
    },
    {
      id: 5,
      text: 'In your absence, do you have a responsible person on or near the property who will take steps to extinguish the fire, has gate keys/codes and emergency contact numbers?',
      name: 'responsiblePerson',
    },
    {
      id: 6,
      text: 'Are emergency contact numbers visible for all?',
      name: 'emergencyContactVisible',
    },
    {
      id: 7,
      text: 'Have you discussed safe behaviour and clothing when near a wildfire with all residents and staff on your property?',
      name: 'safeBehavior',
    },
    {
      id: 8,
      text: 'Have you discussed possible evacuation plans with all residents? Made provisions for any animals?',
      name: 'possibleEvacuation',
    },
    {
      id: 9,
      text: 'Have you discussed fire risk insurance and fire spread insurance with your broker?',
      name: 'fireRiskInsurance',
    },
    {
      id: 10,
      text: 'Do you know the past frequency of veldfires in your area?',
      name: 'pastFrequency',
    },
    {
      id: 11,
      text: 'Have you identified the possible causes or direction of approach of a veldfire?',
      name: 'possibleCause',
    },
    {
      id: 12,
      text: 'Have you identified the possible social impacts of a veldfire? Injuries and evacuations?',
      name: 'socialImpacts',
    },
    {
      id: 13,
      text: 'Have you identified the possible economic impacts of a veldfire? Financial and livelihoods?',
      name: 'economicImpacts',
    },
    {
      id: 14,
      text: 'Have you identified the possible environmental impacts of a veldfire? Minimal to total loss?',
      name: 'environmentalImpacts',
    },
    {
      id: 15,
      text: 'Have you isolated any hazard area that is present or likely to develop?',
      name: 'hazardArea',
    },
    {
      id: 16,
      text: 'Is there easy access for firefighters to property and high risk areas? Roads, signage, gate keys/codes, disabling of electric fences and gates, turning circles for fire trucks?',
      name: 'easyAccess',
    },
    {
      id: 17,
      text: 'Are you undertaking efforts to to clear the invasive alien plants on your property?',
      name: 'undertakingEfforts',
    },
    {
      id: 18,
      text: 'Do you have stacks of dry logs, branches and leaves in your property?',
      name: 'dryLogs',
    },
    {
      id: 19,
      text: 'Do you have designated areas and rules for domestic/social fires by farm, contractors or visitors?',
      name: 'designatedAreas',
    },
    {
      id: 20,
      text: 'Do you have designated areas and policies about smoking on your property? Are all residents, employees, contractors and visitors made aware of these?',
      name: 'smoking',
    },
    {
      id: 21,
      text: 'Do you have rules for any possible fire causing work (eg power tools) with steps to prevent runaway veldfires? Are all relevant employees and contractors made aware of these?',
      name: 'rulesForFireCausingWork',
    },
    {
      id: 22,
      text: 'Installed thatch roof sprayers? Are they in good working order?',
      name: 'thatchRoof',
    },
    {
      id: 23,
      text: 'Cleared a defendable space of 30m around buildings? Cut grass, isolated pruned shrubs and trees, fire proof plants?',
      name: 'defendableSpace',
    },
    {
      id: 24,
      text: 'Cleared all fire hazards 10m around buildings? Including cleaned gutters, under wooden decks, against alls, overhanging branches?',
      name: 'clearedFireHazards',
    },
    {
      id: 25,
      text: 'A signed agreement is in place for communal fire belts',
      name: 'signedAgreement',
    },
    {
      id: 26,
      text: 'Strategic fire belts are in place and minimum 20m',
      name: 'strategicFirebelts',
    },
    {
      id: 27,
      text: 'Property boundary/tactical fire belts are minimum 10m (5m each side)',
      name: 'propertyBoundry',
    },
    {
      id: 28,
      text: 'Fire belts do not cause soil erosion',
      name: 'soilErosion',
    },
    {
      id: 29,
      text: 'Cut brush spread broad at least 3m from break edge',
      name: 'cutBrush',
    },
    {
      id: 30,
      text: 'A burn permit was obtained through FPA for all burning of rubbish pits, slash heaps, agricultural alnds and veld blocks',
      name: 'burnPermit',
    },
    {
      id: 31,
      text: 'All rules on the burn permit are adhered to',
      name: 'rulesBurnPermit',
    },
    {
      id: 32,
      text: 'Are you familiar with the Fire Danger Rating, what it means for you and where to obtain a forecast?',
      name: 'FDR',
    },
    {
      id: 33,
      text: 'Do you comply with the FPA guidelines for minimum equipment and personnel?',
      name: 'complyFPA',
    },
    {
      id: 34,
      text: 'Is all your equipment serviceable and in good working order?',
      name: 'equipmentServiceable',
    },
    {
      id: 35,
      text: 'Have you identified the nearest location of fire hydrants and refilling points for firefighting units?',
      name: 'fireHydrantsLocation',
    },
    {
      id: 36,
      text: 'Are you connected with the FMU/ward communication system for fires?',
      name: 'FMU',
    },
    {
      id: 37,
      text: 'Are you aware of the FPA rules on mutual aid between landowners?',
      name: 'FPARules',
    },
    {
      id: 38,
      text: 'Are you aware of the firefighting command structure?',
      name: 'commandStructure',
    },
  ];

  return (
    <>
      <Typography variant='h5'>Compliance Checklist</Typography>
      {items.map(item => {
        return (
          <Box key={item.id}>
            {item.textField ? (
              <TextField
                sx={{ mb: 2 }}
                name={item.name}
                label='If yes, add name'
                value={item.value}
                onChange={setData}
                fullWidth
              />
            ) : (
              <>
                <Typography variant='h6' component='p' sx={{ mt: 2 }}>
                  {item.text}
                </Typography>
                <RadioGroup row name={item.name} onChange={setData}>
                  <FormControlLabel
                    value='yes'
                    control={<Radio />}
                    label='Yes'
                    name={item.name}
                  />
                  <FormControlLabel
                    value='no'
                    control={<Radio />}
                    label='No'
                    name={item.name}
                  />
                </RadioGroup>
              </>
            )}
          </Box>
        );
      })}
    </>
  );
}
