import { Paper, Typography } from "@mui/material"
import FireBreakProofContract from "../sections/contracts/FireBreakProofContract"
import HedgeContract from "../sections/contracts/HedgeContract"
import CBRContract from "../sections/contracts/CBRContract"

export default function Contracts() {
  return (
    <Paper sx={{p:2}}>
      <Typography variant="h2" component="h2">Active Contracts</Typography>
      <FireBreakProofContract />
      <HedgeContract />
      <CBRContract />
    </Paper>
  )
}
