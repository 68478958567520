import React, { useRef } from 'react'
import { Alert, Typography, TextField, Button, Select, MenuItem, Dialog, Divider, DialogContent, DialogActions, DialogTitle } from '@mui/material';


const AddBinDialog = ({
    openBinModal,
    setOpenBinModal,
    binName,
    setBinName,
    binType,
    setBinType,
    rectDimensions,
    trapezoidalDimensions,
    handleRectDimensions,
    handleTrapDimensions,
    handleAddBin,
    handleCancel,
    clearInputs,
    clearError,
    formErrors
}) => {

    const rectRefs = useRef([]);
    const trapRefs = useRef([]);

    const renderInputs = () => {
        if (binType === 'Rectangular') {
            return rectDimensions.map((input, index) => (
                <React.Fragment key={input.id}>
                    <Typography>{input.label}</Typography>
                    <TextField
                        name={input.name}
                        value={input.value}
                        onChange={handleRectDimensions}
                        fullWidth
                        sx={{ mb: 2 }}
                        type="text"
                        inputRef={el => rectRefs.current[index] = el}
                    />
                </React.Fragment>
            ));
        } else if (binType === 'Trapezoidal') {
            return trapezoidalDimensions.map((input, index) => (
                <React.Fragment key={input.id}>
                    <Typography>{input.label}</Typography>
                    <TextField
                        name={input.name}
                        value={input.value}
                        onChange={handleTrapDimensions}
                        fullWidth
                        sx={{ mb: 2 }}
                        type="text"
                        inputRef={el => trapRefs.current[index] = el}
                    />
                </React.Fragment>
            ));
        }
    };

    const binTypes = [
        { id: 0, name: 'Rectangular' },
        { id: 1, name: 'Trapezoidal' },
    ];

    return (
        <Dialog open={openBinModal} onClose={() => setOpenBinModal(false)} >
            <DialogTitle>Add Bin</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
                <Divider sx={{ my: 1 }} />
                <Typography>Name</Typography>
                <TextField
                    name='Name'
                    value={binName}
                    onChange={(e) => { setBinName(e.target.value); clearError(e.target.name) }}
                    fullWidth
                    sx={{ mb: 2 }}
                />
                <Typography>Select Type</Typography>
                <Select sx={{ mb: 2 }} name='Type' value={binType} fullWidth onChange={(e) => { setBinType(e.target.value); clearInputs(); clearError(e.target.name) }}>
                    {binTypes.map(bin => {
                        return (
                            <MenuItem key={bin.id} value={bin.name}>
                                {bin.name}
                            </MenuItem>
                        );
                    })}
                </Select>

                {renderInputs()}

                {formErrors.map((error, index) => {
                    return (
                        <Alert sx={{ mb: 1 }} key={index} severity="error">{error}</Alert>
                    )
                })}
            </DialogContent>
            <DialogActions sx={{ mr: 'auto', mb: 3, ml: 2 }}>
                <Button variant='contained' onClick={handleAddBin}>
                    Add Bin
                </Button>
                <Button variant='outlined' onClick={handleCancel}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddBinDialog