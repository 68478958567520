import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import biocharcoalition from '../../../assets/images/partners/biocharcoalition.jpeg';
import sarahbaartman from '../../../assets/images/partners/sarahbaartman.jpeg';
import wilsonbiochar from '../../../assets/images/partners/wilsonbiochar.webp';
import napachar from '../../../assets/images/partners/napachar.png';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function Partners() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const partners = [
        {
            image: biocharcoalition,
            // name: 'Biochar Coalition',
            height: 150,
            width: 150
        },
        {
            image: wilsonbiochar,
            name: 'Wilson Biochar',
            height: 300,
            width: 300
        },
        {
            image: sarahbaartman,
            height: 185,
            width: 185
        },
        {
            name: 'Napachar',
            image: napachar,
            height: 250,
            width: 250
        }
    ]

    const settings = {
        dots: false,
        infinite: true,
        speed: 1500, // Adjust speed for slow glide
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        cssEase: 'linear',
    };

    return (
        <Grid container id='partners' >
            <Grid
                lg={12}
                item
                sx={{ width: '100%' }}
            >
                <Typography
                    sx={{
                        fontSize: '36px',
                        fontWeight: '600',
                        fontStyle: 'normal',
                        lineHeight: '44px',
                        letterSpacing: '-0.72px',
                        // color: '#DA9DE3',
                        mt: '10px',
                        mb: '50px'
                    }}
                    id="partners__heading"
                >
                    Partners
                </Typography>


                {isSmallScreen ?
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '1000px' }}>
                        {partners.map((p, i) => {
                            return (
                                <Box key={i} sx={{ mb: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                                    <img
                                        src={p.image}
                                        alt='member'
                                        style={{ maxHeight: p.width, maxWidth: p.height, margin: 'auto', marginTop: p.name === 'Napachar' ? '30px' : null }}
                                    />
                                    {p.name ? <Typography
                                        sx={{
                                            fontSize: '18px',
                                            fontWeight: '600',
                                            fontStyle: 'normal',
                                            lineHeight: '44px',
                                            letterSpacing: '-0.72px',
                                            textAlign: 'center',
                                            marginTop: '-10px',
                                            font: 'sans-serif',
                                        }}
                                    >
                                        {p.name === 'Biochar Coalition' ? p.name : null}
                                    </Typography> : null}
                                </Box>

                            )
                        })}
                    </Box> :
                    <Box sx={{ width: '100%', height: '400px', overflow: 'hidden' }}>
                        <Slider {...settings}>
                            {partners.map((p, i) => {
                                return (
                                    <Box key={i} sx={{ mb: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                                        <img
                                            src={p.image}
                                            alt='member'
                                            style={{ maxHeight: p.width, maxWidth: p.height, margin: 'auto', marginTop: p.name === 'Napachar' ? '30px' : null }}
                                        />
                                        {p.name ? <Typography
                                            sx={{
                                                fontSize: '18px',
                                                fontWeight: '600',
                                                fontStyle: 'normal',
                                                lineHeight: '44px',
                                                letterSpacing: '-0.72px',
                                                textAlign: 'center',
                                                marginTop: '-10px',
                                                font: 'sans-serif',
                                            }}
                                        >
                                            {p.name === 'Biochar Coalition' ? p.name : null}
                                        </Typography> : null}
                                    </Box>

                                )
                            })}
                        </Slider>
                    </Box>
                }

            </Grid>
        </Grid>
    );
}
