import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography, TextField, Alert } from '@mui/material';

const AddCallerDialog = ({
    openCallerModal,
    setOpenCallerModal,
    entity,
    setEntity,
    setContact,
    contact,
    handleAddNewCaller,
    formErrors,
    handleClearFormErrors
}) => {

    return (
        <Dialog open={openCallerModal} onClose={() => setOpenCallerModal(false)}>
            <DialogTitle>Add New Caller</DialogTitle>
            <DialogContent sx={{ minWidth: 400 }}>
                <Divider sx={{ my: 1 }} />
                <Typography>Entity</Typography>
                <TextField
                    name='entity'
                    value={entity}
                    onChange={(e) => { setEntity(e.target.value); handleClearFormErrors('Please specify Entity') }}
                    fullWidth
                    sx={{ mb: 2 }}
                />
                <Typography>Number</Typography>
                <TextField
                    name='number'
                    value={contact}
                    onChange={(e) => { setContact(e.target.value); handleClearFormErrors('Please specify Contact') }}
                    fullWidth
                    sx={{
                        mb: 2,
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                            display: "none",
                        },
                        "& input[type=number]": {
                            MozAppearance: "textfield",
                        }
                    }}
                    type='number'
                />
                {formErrors.map((error, index) => {
                    return (
                        <Alert sx={{ mb: 1 }} key={index} severity="error">{error}</Alert>
                    )
                })}
            </DialogContent>
            <DialogActions sx={{ mr: 'auto', mb: 3, ml: 2 }}>
                <Button variant='contained' onClick={handleAddNewCaller}>
                    Add
                </Button>
                <Button variant='outlined' onClick={() => setOpenCallerModal(false)}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddCallerDialog