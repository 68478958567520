import React, { useEffect } from 'react';
import {
  Alert,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import tooltips from '../../../../../../store/tooltips';
import InfoIcon from '@mui/icons-material/Info';

export default function BF002UsualFeedstock({
  data,
  setData,
  setFormId,
  feedstockSourceTypes,
}) {
  useEffect(() => {
    setFormId('BF002');
  }, [setFormId]);
  const { feedstockType, feedstockUse, proof_doc } = data;

  const feedstockUses = [
    {
      id: 0,
      text: 'It would be burnt',
    },
    {
      id: 1,
      text: 'It would be sold as firewood',
    },
    {
      id: 2,
      text: 'It would be chipped',
    },
    {
      id: 3,
      text: 'It would be left to decay',
    },
  ];

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Typography variant='h5' sx={{ mb: 2 }}>
          Business as a usual for feedstock
        </Typography>
        <Tooltip
          title={
            <h2 style={{ color: 'lightgreen' }}>
              {tooltips.businessAsUsualQ1AndQ2}
            </h2>
          }
        >
          {<InfoIcon sx={{ width: 15 }} />}
        </Tooltip>
      </div>
      <Alert severity='info'>
        If you have more than one type of feedstock available or are planning to
        mix two types of feedstock for your burns, please submit this form once
        for each type of feedstock.
      </Alert>
      <FormControl
        sx={{
          mt: 1,
        }}
        fullWidth
      >
        <InputLabel sx={{ background: 'white', pl: 1, pr: 1 }}>Feedstock Type</InputLabel>
        <Select name='feedstockType' value={feedstockType} onChange={setData}>
          {feedstockSourceTypes.map(item => {
            return (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl
        sx={{
          mt: 1,
        }}
        fullWidth
      >
        <InputLabel sx={{ background: 'white', pl: 1, pr: 1 }}>
          What would normally be done with this feedstock?
        </InputLabel>
        <Select name='feedstockUse' value={feedstockUse} onChange={setData}>
          {feedstockUses.map(item => {
            return (
              <MenuItem key={item.id} value={item.text}>
                {item.text}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Can you provide evidence of the alternative fate for this feedstock?
        <Tooltip
          title={
            <h2 style={{ color: 'lightgreen' }}>
              {tooltips.businessAsUsualQ3}
            </h2>
          }
        >
          {<InfoIcon sx={{ width: 15 }} />}
        </Tooltip>
      </Typography>

      <RadioGroup row name='useProof' onChange={setData}>
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='useProof'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='useProof'
        />
      </RadioGroup>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        If yes, please upload evidence for the alternative fate of feedstock
        <Tooltip
          title={
            <h2 style={{ color: 'lightgreen' }}>
              {tooltips.businessAsUsualQ4}
            </h2>
          }
        >
          {<InfoIcon sx={{ width: 15 }} />}
        </Tooltip>
      </Typography>
      <TextField
        name='proof_doc'
        type='file'
        value={proof_doc}
        onChange={setData}
        fullWidth
      />
    </>
  );
}
