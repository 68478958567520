const tooltips = {
    baseline: 'The baseline phase refers to the business as usual scenario. i.e. What would otherwise have happened to the waste biomass in absence of the project. Information relating to this is submitted here.',
    planning: 'The planning phase takes place pre-burn. The data relating to the planning phase is recorded by the landowner or farm manager through the web and mobile application, and mostly concerns information about the feedstock that will be used for biochar production during a project.',
    businessAsUsualQ1AndQ2: 'Feedstock type: What type of biomass is this? You can use invasive alien trees, waste from vineyards or orchards such as clippings, and other types of agricultural waste. If you have more than one type of feedstock available or are planning to mix two types of feedstock for your burns, please submit this form once for each type of feedstock.' +
        /'\n'/ + /'\n'/ +
        ' What would normally be done with this feedstock?: Please select what would normally happen to this feedstock on the farm: would you burn it or leave it to decompose?',
    businessAsUsualQ3: 'Would you be able to submit any document or picture that proves that you normally burn or leave this type of feedstock to decay on your farm? For example burn permits from the local fire protection association.',
    businessAsUsualQ4: 'If you replied yes to the previous question, please upload the picture or document here.',
    equipmentForIABQ1: 'Will you hire a contractor to oversee the burns for this project, or will your farm team perform the burns? If you are hiring a contractor, you don’t have to answer any other questions on this form.',
    equipmentForIABQ2: 'Do you have the proper Personal Protection Equipment available on your farm to ensure your team is protected on the day of the burn? This generally includes good  work boots, leather gloves and non-synthetic clothing.',
    equipmentForIABQ3: 'Do you have a Ring of Fire or a Kon Tiki kiln available on your farm to burn the feedstock in?',
    equipmentForIABQ4: 'Y/No, planning to use a pit kiln',
    logKilnQ1: 'Please use this form to log the kilns you have available to perform the burns. You should submit the form once per kiln.',
    logKilnQ2: 'Please indicate the height and diameter of the kiln in mm',
    logKilnQ3: 'Please assign this kiln an ID, which is a name that you can use to identify it. This name can be to your liking, and it should be something easy to remember, such as Old Kon-Tiki.',
    logKilnQ4: 'Please upload a picture where you can clearly see this kiln.',
    planningQ1: 'What type of biomass is going to be used as feedstock for this project? You can use invasive alien trees, waste from vineyards or orchards such as clippings, and other types of agricultural waste.',
    planningQ2: 'Please give more information about the type of feedstock you selected in the previous question, such as the species of invasive tree, the type of orchard, etc.',
    planningQ3: 'Select the location on your property from where this feedstock originated.',
    planningQ4: 'How much feedstock will be available for this project? You can select the number of hectares, if the feedstock has not been cleared yet, or the estimated tonnes, if clearing has already happened by the time you fill out this form.',
    burnSupervisorQ1: 'If a trained contractor will be responsible for the burns, there is no need to answer other questions on this form. If not, please provide more information in the questions below. Please make sure that this person has been added as a collaborator through the Add Collaborator button.',
    burnSupervisorQ2: 'Please write down the name and surname of the person who will be supervising the burns on the farm.',
    burnSupervisorQ3: 'Please specify the role this person normally has on the farm and any information about previous experience with biochar (supervised other burns, completed training, etc)',
    proofProjectQ1: 'Are there other people involved in this project other than the teams on the ground, the burn supervisor and your farm manager? These people could be valuable to confirm or verify the project in case it is needed.',
    proofProjectQ2: 'Please write down the person’s name and surname',
    proofProjectQ3: 'Please state what their role in your farm/this project is (e.g: office administration, soil specialist, etc.)',
    proofProjectQ4: 'Please provide contact information for this person, preferably an email or phone contact.',
    proofProjectQ5: 'Please submit any documents or images that can verify this person’s involvement in the project. This can be a printscreen of an email or an invoice for example.',
    biocharCharacteristicsQ1: 'If you have sent biochar samples from batches in this project to a lab for testing, you can submit results through this form. If not, use this form confirm the feedstock used for each batch. Please select yes or no and see the information for each specific case.',
    biocharCharacteristicsQ1Yes: 'What is the name of the laboratory that performed the analysis on these biochar samples?',
    biocharCharacteristicsQ2Yes: 'Laboratories will generally give each lab test a unique ID that can be found on the documents sent to clients. Please track down this ID in the document with your lab results and write it down in this field.',
    biocharCharacteristicsQ3Yes: 'Please upload the file containing the lab results for samples from this project’s batches.',
    biocharCharacteristicsQ4Yes: 'You should submit one value of carbon content and bulk density for each batch in the project. Start by identifying the batch number, and then type the values for that sample in the lab results You can add more batches and the respective values using the (+) sign below. When values for all batches are listed, please submit the form.',
    biocharCharacteristicsQ5Yes: 'Carbon content',
    biocharCharacteristicsQ1No: 'You should verify the feedstock used for each batch in the project. Start by identifying the batch number, and then select the feedstock from the dropdown list. The C-content and Bulk density fields will be automatically filled based on stored values. You can add more batches and the respective values using the (+) sign below. When values for all batches are listed, please submit the form.',
    burnTeamPaymentsQ1: 'Please make sure that the team working on your burn is being paid fairly, and at least a minimum wage. If a contractor is being hired, please confirm this information with them.',
    burnTeamPaymentsQ2: 'What was the total number of people involved in the burns for this project?',
    burnTeamPaymentsQ3: 'How many members of the ground team were women? If none, please type 0.',
    burnTeamPaymentsQ4: 'How many days in total did the team spend in the field to complete the burns and bag all the biochar?',
    burnTeamPaymentsQ5: 'What was the full amount paid to the team responsible for the burns and bagging the biochar?',
    biocharTraderForms: 'The Biochar Trader Forms are used when biochar is sold or moved to another property and “Proof of Biochar Application to Soil” is required. This function will be linked to the biochar tracked in your “Biochar Inventory” and “Charged Biochar”. Please contact admin@ikhala.tech if you require this functionality.',
    resources: 'This resource section aims to provide you with valuable resources and information that relate to programmes on Ikhala. If there are any additional resources you would like to be added here for quick access, please email us at admin@ikhala.tech',
    measurementUnits: 'Select the measurement units used for this project - either metric or imperial.'
};

export default tooltips;
