import {
    POST_ELECTRICITY_PURCHASED_DATA_SUCCESS,
    POST_ELECTRICITY_PURCHASED_DATA_FAIL,
    GET_ELECTRICITY_PURCHASED_DATA_SUCCESS,
    GET_ELECTRICITY_PURCHASED_DATA_FAIL,
    GET_ELECTRICITY_PURCHASED_DATA_TOTALS_SUCCESS,
    GET_ELECTRICITY_PURCHASED_DATA_TOTALS_FAIL,
} from '../../../actions/carbonfootprint-rework/types/02-indirect-ghg-emissions-from-imported-energy-types';


const initialState = {
    loading: true,
    electricityPurchasedData: [],
    electricityPurchasedDataTotals: []
};

// PETROL REDUCERS
export default function
    directGhgEmissionsAndRemovalsForElectricityPurchased(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        // STATIONARY PETROL
        case POST_ELECTRICITY_PURCHASED_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_ELECTRICITY_PURCHASED_DATA_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_ELECTRICITY_PURCHASED_DATA_SUCCESS:
            return {
                ...state,
                electricityPurchasedData: payload,
                loading: false,
            };
        case GET_ELECTRICITY_PURCHASED_DATA_FAIL:
            return {
                ...state,
                electricityPurchasedData: payload,
                loading: false
            }
        case GET_ELECTRICITY_PURCHASED_DATA_TOTALS_SUCCESS:
            return {
                ...state,
                electricityPurchasedDataTotals: payload,
                loading: false,
            };
        case GET_ELECTRICITY_PURCHASED_DATA_TOTALS_FAIL:
            return {
                ...state,
                electricityPurchasedDataTotals: payload,
                loading: false
            }

        default:
            return state;
    }
}


