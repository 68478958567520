import { useState } from 'react';
import { Grid, Typography, Paper, Button, Divider, Alert } from '@mui/material';
import EnvironmentalDescriptors from '../sections/ecosystem/EnvironmentalDescriptors';
import EcologicalAttributes from '../sections/ecosystem/EcologicalAttributes';

export default function EcosystemAccount() {
  const [tab, setTab] = useState(null);

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Paper sx={{ background: '#eee', p: 5 }}>
            <Typography>Diversity of Ecosystems and Habitats</Typography>
            <Button
              sx={{ mt: 5 }}
              variant='outlined'
              value='diversity'
              onClick={e => setTab(e.target.value)}
            >
              Open
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper sx={{ background: '#eee', p: 5 }}>
            <Typography>Environmental Descriptors</Typography>
            <Button
              sx={{ mt: 5 }}
              variant='outlined'
              value='descriptors'
              onClick={e => setTab(e.target.value)}
            >
              Open
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper sx={{ background: '#eee', p: 5 }}>
            <Typography>Ecological Attributes</Typography>
            <Button
              sx={{ mt: 5 }}
              variant='outlined'
              value='attributes'
              onClick={e => setTab(e.target.value)}
            >
              Open
            </Button>
          </Paper>
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      {tab === 'diversity' ? (
        <Alert severity='error'>
          Content for tab "Diversity of Ecosystems and Habitats" is not
          available
        </Alert>
      ) : tab === 'descriptors' ? (
        <EnvironmentalDescriptors />
      ) : tab === 'attributes' ? (
        <EcologicalAttributes />
      ) : (
        <Alert severity='info'>Please open one of the tabs</Alert>
      )}
    </Paper>
  );
}
