import React, { useEffect } from 'react';
import AddItem from '../../../../carbonfootprinting/ItemAdd';
import { getAssets, addAsset } from '../../../../../../actions/carbonfootprint-rework/01-direct-ghg-emissions-and-removals/01-add-asset'
import {
    Box,
    Table, TableBody, TableContainer, TableRow, TableHead, TableCell, Paper
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

export default function AssetList() {
    // { farmId }
    const dispatch = useDispatch()
    const { assetData } = useSelector(state => state.directGhgEmissionsAndRemovalsForAssets)
    const year = localStorage.getItem('year')
    const group = localStorage.getItem('group')
    const company = localStorage.getItem('company')

    useEffect(() => {
        dispatch(getAssets(group))
    }, [dispatch, group])

    const formFields = [
        { name: 'asset', label: 'Asset', type: 'text' },
        {
            name: 'assetType',
            label: 'Asset Type',
            type: 'select',
            options: ['Vehicles', 'Farm Equipment', 'Tractor', 'Generator'],
        },
        { name: 'assetModel', label: 'Asset Model', type: 'text' },
        {
            name: 'datePurchased',
            label: 'Date Purchased',
            type: 'date',
        },
        {
            name: 'dateSold',
            label: 'Date Sold',
            type: 'date',
        },
        {
            name: 'description',
            label: 'Description',
            type: 'text',
        }
    ];

    const initialState = formFields.reduce((obj, field) => {
        obj[field.name] = '';
        return obj;
    }, {});


    const handleFormSubmit = data => {
        dispatch(addAsset(data, year, company, group)).then(() => {
            dispatch(getAssets(group))
        })
    };

    const columns = [
        {
            field: 'asset', headerName: 'Asset',
            flex: 1,

        },
        {
            field: 'asset_type',
            headerName: 'Asset Type',
            flex: 1,
        },
        {
            field: 'asset_model',
            headerName: 'Asset Model',
            flex: 1,
        },
        {
            field: 'date_purchased',
            headerName: 'Date Purchased',
            flex: 1,
        },
        {
            field: 'date_sold',
            headerName: 'Date Sold',
            flex: 1,
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
        }
    ]

    return (
        <Box sx={{ width: '100%' }} mt={5}>
            <Box sx={{ height: '100%' }}>
                <AddItem
                    formFields={formFields}
                    initialState={initialState}
                    onFormSubmit={handleFormSubmit}
                    items={[]}
                    addTitle='Add New Asset'
                />
                <TableContainer component={Paper} sx={{ height: 'auto' }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {columns.map((c, i) => {
                                    return (

                                        <TableCell key={i} align="left">
                                            {c.headerName}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assetData?.data?.map((d, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell align="left">
                                            {d.asset}
                                        </TableCell>
                                        <TableCell align="left">
                                            {d.asset_type}
                                        </TableCell>
                                        <TableCell align="left">
                                            {d.asset_model}
                                        </TableCell>
                                        <TableCell align="left">
                                            {d.date_purchased}
                                        </TableCell>
                                        <TableCell align="left">
                                            {d.date_sold}
                                        </TableCell>
                                        <TableCell align="left">
                                            {d.description}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
}
