import { Alert, Box, Button, Divider, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import OverviewTable from '../OverviewTable';
export default function CatOneSelection({ setSelection, setCatOne }) {

    let catOne = ['Diesel', 'Petrol', 'Liquid petroleum gas (LPG)', 'Refrigerant gas', 'Dry ice', 'Fertilisers Applied', 'Agrochemicals Applied']

    // console.log(localStorage.getItem('company'))
    if (localStorage.getItem('group') === '1') {
        catOne = catOne.splice(0, 5)
    }

    const facility = localStorage.getItem('facility')

    const isReynekeOrTweeJonge = facility === '1' || facility === '3'

    const catOneOverview = [
        { category: 'Diesel', unit: 'Litres', quantity: null, override: null },
        { category: 'Diesel (stationary)', unit: 'Litres', quantity: null, override: null },
        { category: 'Diesel (mobile)', unit: 'Litres', quantity: null, override: null },
        { category: 'Petrol', unit: 'Litres', quantity: null, override: null },
        { category: 'Liquid petroleum gas (LPG)', unit: 'Litres', quantity: null, override: null },
        { category: 'Refrigerant gas', unit: 'Kilograms', quantity: null, override: null },
        { category: 'Dry ice', unit: 'Kilograms', quantity: null, override: null },
        { category: 'Compost applied', unit: 'Kilograms', quantity: null, override: null },
        { category: 'Fertiliser applied', unit: 'Kilograms', quantity: null, override: null },
        { category: 'Agrochemicals applied', unit: 'Kilograms', quantity: null, override: null }
    ]
    const [currentTab] = useState(0)
    // const handleTabChange = (event, newValue) => {
    //     setCurrentTab(newValue);
    // };

    const renderComponent = () => {
        switch (currentTab) {
            case 0: {
                return (
                    <Box marginTop={2} sx={{ clear: 'left' }}>
                        <Typography variant='h5'>Category</Typography>
                        <Divider />
                        <Alert severity='info'>Please select a category</Alert>
                        {catOne.map((c, i) => {
                            return (
                                <Button
                                    key={i}
                                    variant='contained'
                                    size='large'
                                    sx={{ width: '35%', mb: 2.5, display: c === 'Dry ice' && !isReynekeOrTweeJonge ? 'none': 'block' }}
                                    onClick={() => {
                                        setCatOne(c)
                                        setSelection({ id: 5, title: c })
                                        localStorage.setItem('catOne', c)
                                    }}
                                >
                                    {c}
                                </Button>
                            )
                        })
                        }
                    </Box>
                )
            }
            case 1: {
                return (
                    <Box marginTop={2} sx={{ clear: 'left', display: 'flex', flexDirection: 'column', width: '200px' }}>
                        <Button variant="contained" sx={{ mt: 2 }} onClick={() => null}>Asset Register</Button>
                        <Button variant="contained" sx={{ mt: 2 }} onClick={() => null}>Plotlist</Button>
                    </Box>
                )
            }
            case 2: {
                return (
                    <Box marginTop={2} sx={{ clear: 'left' }}>
                        <OverviewTable category="Direct GHG emissions and removals" data={catOneOverview} />
                    </Box>
                )
            }
            default:
                return null
        }
    }

    return (
        <Grid item xs={12}>
            {/* <Tabs
                value={currentTab}
                onChange={handleTabChange}
                style={{ marginBottom: 10 }}
                textColor='inherit'
                indicatorColor='secondary'
                className='theTabs'
                sx={{
                    float: 'left',
                }}
            >
                <Tab label='Data Capturing' />
                <Tab label='General Info' />
            </Tabs> */}
            {renderComponent()}
        </Grid>
    );
}
