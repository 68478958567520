import {
    POST_FLIGHTS_AND_ACCOMMODATION_DATA_SUCCESS,
    POST_FLIGHTS_AND_ACCOMMODATION_DATA_FAIL,
    GET_FLIGHTS_AND_ACCOMMODATION_DATA_SUCCESS,
    GET_FLIGHTS_AND_ACCOMMODATION_DATA_FAIL,
    GET_FLIGHTS_AND_ACCOMMODATION_DATA_TOTALS_SUCCESS,
    GET_FLIGHTS_AND_ACCOMMODATION_DATA_TOTALS_FAIL,
} from '../../../actions/carbonfootprint-rework/types/03-indirect-ghg-emissions-from-transportation';

const initialState = {
    loading: true,
    flightsAndAccommodationData: [],
    flightsAndAccommodationDataTotals: []
};

// flights & accommodation REDUCERS
export default function flightsAndAccommodationReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case POST_FLIGHTS_AND_ACCOMMODATION_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_FLIGHTS_AND_ACCOMMODATION_DATA_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_FLIGHTS_AND_ACCOMMODATION_DATA_SUCCESS:
            return {
                ...state,
                flightsAndAccommodationData: payload,
                loading: false,
            };
        case GET_FLIGHTS_AND_ACCOMMODATION_DATA_FAIL:
            return {
                ...state,
                flightsAndAccommodationData: payload,
                loading: false
            }
        case GET_FLIGHTS_AND_ACCOMMODATION_DATA_TOTALS_SUCCESS:
            return {
                ...state,
                flightsAndAccommodationDataTotals: payload,
                loading: false,
            };
        case GET_FLIGHTS_AND_ACCOMMODATION_DATA_TOTALS_FAIL:
            return {
                ...state,
                flightsAndAccommodationDataTotals: payload,
                loading: false
            }

        default:
            return state;
    }
}