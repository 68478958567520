import { useEffect } from 'react';
import F017ComplianceChecklist from './types/F017ComplianceChecklist';

export default function ComplianceChecklist({
  dataF017,
  setData,
  setFormId,
  totalItems,
}) {
  useEffect(() => {
    totalItems(1);
  }, [totalItems]);
  return (
    <F017ComplianceChecklist
      data={dataF017}
      setData={setData}
      setFormId={setFormId}
    />
  );
}
