import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  // getFormData,
  postFormData,
  postFormDataFiles,
  updateProjectNickname,
} from '../../../../actions/forms';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  Tooltip,
  TextField,
  // Paper,
  // CircularProgress,
  // Alert,
} from '@mui/material';
import Baseline from './forms/Baseline';
// import Planning from './forms/Planning';
// import Implementation from './forms/Implementation';
// import MRV from './forms/MRV';
// import CA from './forms/CA';
import tooltips from '../../../../store/tooltips';
// import { getKilnDimensions } from '../../../../actions/forms';
import 'moment';
import { DataMonitoring } from './forms/DataMonitoring';
import { Reports } from './forms/Reports';
import { DataTables } from './forms/DataTables';
import { getLogKilns, postLogKiln } from '../../../../actions/rof-burns/rof-actions';

const CBR = ({ postFormData, postFormDataFiles, projectId, nickname, projectKey, measureType }) => {
  const initStateBF000 = {
    description: '',
    projectLocation: '',
    projectStartTime: '',
  };
  const initStateBF002 = {
    feedstockType: '',
    feedstockUse: '',
    useProof: '',
    proof_doc: '',
  };
  const initStateBF003 = {
    productionMethod: '',
    ppeAvailable: '',
    kilnsAvailable: '',
    buyKiln: '',
  };
  const initStateBF003a = {
    units: '',
    kilnType: '',
    diameter: '',
    heightOfInnerPanels: '',
    numberOfPanels: '',
    heatShieldPanelWidth: '',
    heatShieldPanelLength: '',
    innerWidth: '',
    innerLength: '',
    innerHeight: '',
    height: '',
    kilnId: '',
    kiln_picture: '',
  };
  const initStateBF004 = {
    labTestSubmit: '',
    labName: '',
    labTestId: '',
    lab_results: '',

    batchNumber: '',
    cContent: '',
    density: '',
    feedstockType: '',
  };
  const initStateBF005 = {
    batchNumber: '',
    cContent: '',
    density: '',
    feedstockType: '',
  };
  const initStateBF007a = {
    feedstockType: '',
    feedstockDetails: '',
    location: '',
    feedstockAmount: '',
    feedstockAmountUnit: '',
    iapClearing: '',
  };
  const initStateBF019 = {
    minWage: '',
    teamSize: '',
    teamSizeWomen: '',
    prodDays: '',
    valuePaid: '',
  };
  const initStateBF020 = {
    trainedContractor: '',
    supervisorName: '',
    supervisorInfo: '',
  };
  const initStateBF022 = {
    stakeholders: '',
    sName: '',
    sRole: '',
    sContact: '',
    stakeholder_doc: '',
  };
  const initStateBF025 = {
    didTransport: '',
    transported: '',
    startPoint: '',
    endPoint: '',
    distance: '',
    tripCount: '',
    fuelBurned: '',
    fuelUnit: '',
  };
  const initStateBF026 = {
    orgName: '',
    orgLogo: '',
    orgAddress: '',
    orgFiscalInfo: '',
    clientName: '',
    clientLogo: '',
    clientAddress: '',
    clientFiscalInfo: '',
  };

  const dispatch = useDispatch();
  // const { loading, formdata } = useSelector(state => state.postFormData);

  const [formDataBF000, setFormDataBF000] = useState(initStateBF000);
  const [formDataBF002, setFormDataBF002] = useState(initStateBF002);
  const [formDataBF003, setFormDataBF003] = useState(initStateBF003);
  const [formDataBF003a, setFormDataBF003a] = useState(initStateBF003a);
  const [formDataBF004, setFormDataBF004] = useState(initStateBF004);
  const [formDataBF005, setFormDataBF005] = useState(initStateBF005);
  const [formDataBF007a, setFormDataBF007a] = useState(initStateBF007a);
  const [formDataBF019, setFormDataBF019] = useState(initStateBF019);
  const [formDataBF020, setFormDataBF020] = useState(initStateBF020);
  const [formDataBF022, setFormDataBF022] = useState(initStateBF022);
  const [formDataBF025, setFormDataBF025] = useState(initStateBF025);
  const [formDataBF026, setFormDataBF026] = useState(initStateBF026);
  const [loopData, setLoopData] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [activeForm, setActiveForm] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [formId, setFormId] = useState('');
  const [nicknameEdit, setNicknameEdit] = useState(false);
  const [newNickname, setNewNickname] = useState('');
  // const [lastSubmission, setLastSubmission] = useState({});
  const [formErrors, setFormErrors] = useState([])

  const items = [
    // {
    //   id: 1,
    //   name: 'Planning',
    // },
    // {
    //   id: 2,
    //   name: 'Production Data',
    // },
    // {
    //   id: 3,
    //   name: 'C&A',
    // },
    // {
    //   id: 4,
    //   name: 'MRV',
    // },
    {
      id: 0,
      name: 'Data Monitoring',
    },
    {
      id: 1,
      name: 'Data Tables',
    },
    {
      id: 2,
      name: 'Reports',
    },
    {
      id: 3,
      name: 'Project Info',
    },
  ];

  const feedstockSourceTypes = [
    {
      id: 0,
      name: 'Invasive Alien Plants',
    },
    {
      id: 1,
      name: 'Vegetation or Fuel Load Management',
    },
    {
      id: 2,
      name: 'Vineyard clippings',
    },
    {
      id: 3,
      name: 'Other Agricultural Waste',
    },
  ];

  const handleReset = () => {
    setFormDataBF000(initStateBF000);
    setFormDataBF002(initStateBF002);
    setFormDataBF003(initStateBF003);
    setFormDataBF003a(initStateBF003a);
    setFormDataBF004(initStateBF004);
    setFormDataBF005(initStateBF005);
    setFormDataBF007a(initStateBF007a);
    setFormDataBF019(initStateBF019);
    setFormDataBF020(initStateBF020);
    setFormDataBF022(initStateBF022);
    setFormDataBF025(initStateBF025);
    setFormDataBF026(initStateBF026);
    setLoopData([]);
    setUploadedFiles([]);
  };

  const handleNext = active => {
    setActiveForm(active);
  };

  const handleChange = e => {
    // console.log(e.target.value)
    setFormDataBF000({ ...formDataBF000, [e.target.name]: e.target.value });
    setFormDataBF002({ ...formDataBF002, [e.target.name]: e.target.value });
    setFormDataBF003({ ...formDataBF003, [e.target.name]: e.target.value });
    setFormDataBF003a({ ...formDataBF003a, [e.target.name]: e.target.value });
    setFormDataBF004({ ...formDataBF004, [e.target.name]: e.target.value });
    setFormDataBF005({ ...formDataBF005, [e.target.name]: e.target.value });
    setFormDataBF007a({ ...formDataBF007a, [e.target.name]: e.target.value });
    setFormDataBF020({ ...formDataBF020, [e.target.name]: e.target.value });
    setFormDataBF019({ ...formDataBF019, [e.target.name]: e.target.value });
    setFormDataBF022({ ...formDataBF022, [e.target.name]: e.target.value });
    setFormDataBF025({ ...formDataBF025, [e.target.name]: e.target.value });
    setFormDataBF026({ ...formDataBF026, [e.target.name]: e.target.value });
    if (e.target.files) {
      const chosenFiles = Array.prototype.slice.call(e.target.files);
      handleUploadFiles(chosenFiles);
    }
  };

  const handleUploadFiles = files => {
    const uploaded = [...uploadedFiles];
    // eslint-disable-next-line array-callback-return
    files.some(file => {
      if (uploaded.findIndex(f => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });
    setUploadedFiles(uploaded);
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormErrors([])
    if (formId === 'BF003a') {
      if (formDataBF003a.kilnType === 'Ring of Fire') {
        delete formDataBF003a['innerWidth']
        delete formDataBF003a['innerLength']
        delete formDataBF003a['innerHeight']
        delete formDataBF003a['height']
        delete formDataBF003a['kiln_picture']
      } else if (formDataBF003a.kilnType === 'Conservation Burn - Hand Pile' || formDataBF003a.kilnType === 'Conservation Burn - Machine Pile') {
        delete formDataBF003a['innerWidth']
        delete formDataBF003a['innerLength']
        delete formDataBF003a['innerHeight']
        delete formDataBF003a['kiln_picture']
        delete formDataBF003a['heightOfInnerPanels']
        delete formDataBF003a['numberOfPanels']
        delete formDataBF003a['heatShieldPanelWidth']
        delete formDataBF003a['heatShieldPanelLength']
      } else {
        delete formDataBF003a['diameter']
        delete formDataBF003a['heightOfInnerPanels']
        delete formDataBF003a['numberOfPanels']
        delete formDataBF003a['heatShieldPanelWidth']
        delete formDataBF003a['heatShieldPanelLength']
        delete formDataBF003a['kiln_picture']
      }
      for (let key in formDataBF003a) {
        if (formDataBF003a[key] === '') {
          setFormErrors([...formErrors, 'Please complete all form fields when submitting a new Kiln'])
          return
        }
      }
    }
    if (formId === 'BF003a') {
      dispatch(postLogKiln(formDataBF003a, projectKey))
        .then(() => {
          dispatch(getLogKilns(projectKey))
          handleReset();
        })
    } else {
      await postFormData({
        formId: formId,
        projectId: projectId,
        formData: JSON.stringify(
          formId === 'BF000'
            ? formDataBF000
            : formId === 'BF002'
              ? formDataBF002
              : formId === 'BF003'
                ? formDataBF003
                // : formId === 'BF003a'
                //   ? formDataBF003a
                : formId === 'BF004'
                  ? loopData
                  : formId === 'BF005'
                    ? formDataBF005
                    : formId === 'BF007a'
                      ? formDataBF007a
                      : formId === 'BF019'
                        ? formDataBF019
                        : formId === 'BF020'
                          ? formDataBF020
                          : formId === 'BF022'
                            ? formDataBF022
                            : formId === 'BF025'
                              ? formDataBF025
                              : formId === 'BF026'
                                ? formDataBF026
                                : null
        ),
      });
      setFormDataBF000(initStateBF000);
      setFormDataBF002(initStateBF002);
      setFormDataBF003(initStateBF003);
      // setFormDataBF003a(initStateBF003a);
      setFormDataBF004(initStateBF004);
      setFormDataBF005(initStateBF005);
      setFormDataBF007a(initStateBF007a);
      setFormDataBF019(initStateBF019);
      setFormDataBF020(initStateBF020);
      setFormDataBF022(initStateBF022);
      setFormDataBF025(initStateBF025);
      setFormDataBF026(initStateBF026);

      handleReset();
    }

  };

  const handleFileSubmit = e => {
    e.preventDefault()
    if (uploadedFiles.length > 0) {
      const data = new FormData();
      data.append('formId', formId);
      data.append('projectId', projectId);

      // eslint-disable-next-line array-callback-return
      uploadedFiles.map(file => {
        data.append('files', file);
      });
      postFormDataFiles(data);
    }
  };

  const handleNicknameUpdate = () => {
    setNicknameEdit(false);
    dispatch(updateProjectNickname({ nickname: newNickname, projectId }));
  };

  // useEffect(() => {
  //   dispatch(getFormData(projectKey));
  // }, [dispatch, projectKey]);

  // useEffect(() => {
  //   const filteredItems = formdata?.filter(data => data.formId === formId);
  //   setLastSubmission(filteredItems);
  // }, [formId, formdata, setLastSubmission]);

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Divider sx={{ my: 2 }} />
      <Grid container sx={{ justifyContent: 'space-between' }}>
        <Grid item xs={6}>
          <Typography variant='h5' component='h5' sx={{ mb: 3 }}>
            <strong>Selected Project:</strong> {projectId}
          </Typography>
          <Typography variant='h6' component='h5' sx={{ mb: 3 }}>
            <strong>Project Nickname:</strong>{' '}
            {nickname ? (
              nickname
            ) : !nicknameEdit ? (
              <Button variant='outlined' onClick={() => setNicknameEdit(true)}>
                Set Nickname
              </Button>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    name='nickname'
                    value={newNickname}
                    placeholder='Type nickname here'
                    onChange={e => setNewNickname(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    onClick={handleNicknameUpdate}
                    size='large'
                    fullWidth
                  >
                    Update
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant='outlined'
                    onClick={() => setNicknameEdit(false)}
                    size='large'
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            )}
          </Typography>
        </Grid>
        {/* <Grid item xs={4} sx={{ textAlign: 'right' }}>
          <Button variant='outlined'>
            Add Collaborator
            <AddCircleIcon sx={{ ml: 2 }} />
          </Button>
        </Grid> */}
      </Grid>
      <Divider sx={{ mb: 2 }} />
      <Grid container sx={{ justifyContent: 'space-between', mb: 2 }}>
        {items.map((item, i) => (
          (item.id === 0 || item.id === 1) ? (
            <Tooltip
              key={i}
              title={<h2 style={{ color: 'lightgreen' }}>
                {item.id === 0 ? tooltips.baseline : tooltips.planning}
              </h2>}
            >
              <Grid item sx={{ display: 'flex' }}>
                <Button
                  variant={activeStep === item.id ? 'contained' : 'outlined'}
                  onClick={() => {
                    setActiveStep(item.id);
                    handleNext(0);
                  }}
                >
                  {item.name}
                </Button>
              </Grid>
            </Tooltip>
          ) : (
            <Grid item key={i} sx={{ display: 'flex' }}>
              <Button
                variant={activeStep === item.id ? 'contained' : 'outlined'}
                onClick={() => {
                  setActiveStep(item.id);
                  handleNext(0);
                }}
              >
                {item.name}
              </Button>
            </Grid>
          )
        ))}
      </Grid>
      {activeStep === 3 ? (
        <Grid container sx={{ mb: 2 }}>
          <Baseline
            dataBF000={formDataBF000}
            dataBF002={formDataBF002}
            dataBF003={formDataBF003}
            dataBF004={formDataBF004}
            dataBF003a={formDataBF003a}
            dataBF007a={formDataBF007a}
            dataBF020={formDataBF020}
            dataBF022={formDataBF022}
            dataBF019={formDataBF019}
            dataBF026={formDataBF026}
            setLoopData={val => setLoopData(val)}
            setData={handleChange}
            setFormId={setFormId}
            feedstockSourceTypes={feedstockSourceTypes}
            active={activeForm}
            next={handleNext}
            totalItems={setTotalItems}
            projectKey={projectKey}
            measureType={measureType}
            formErrors={formErrors}
          />

        </Grid>
      )

        // : activeStep === 4 ? (
        //   <>
        //     <Divider sx={{ mb: 2 }} />
        //     <MRV
        //       dataBF004={formDataBF004}
        //       dataBF005={formDataBF005}
        //       dataBF019={formDataBF019}
        //       dataBF025={formDataBF025}
        //       dataBF026={formDataBF026}
        //       setData={handleChange}
        //       setFormId={setFormId}
        //       feedstockSourceTypes={feedstockSourceTypes}
        //       setLoopData={val => setLoopData(val)}
        //       active={activeForm}
        //       next={handleNext}
        //       totalItems={setTotalItems}
        //       submitForm={handleSubmit}
        //       projectId={projectId}
        //       projectKey={projectKey}
        //     />
        //   </>
        // ) 
        : activeStep === 0 ? (

          <>
            <Divider />
            <DataMonitoring
              active={activeForm}
              next={handleNext}
              totalItems={setTotalItems}
              projectKey={projectKey}
              measureType={measureType}
            />

          </>
        )
          : activeStep === 1 ? (
            <>
              <Divider />
              <DataTables
                dataBF005={formDataBF005}
                setData={handleChange}
                setFormId={setFormId}
                active={activeForm}
                next={handleNext}
                totalItems={setTotalItems}
                projectId={projectId}
                projectKey={projectKey}
                measureType={measureType}
              />

            </>)
            : activeStep === 2 ? (
              <>
                <Divider />
                <Reports
                  next={handleNext}
                  totalItems={setTotalItems}
                  projectId={projectId}
                  active={activeForm}
                  projectKey={projectKey}
                />

              </>) :
              null}
      {activeStep === 3 && <Box sx={{ textAlign: 'right', mt: 2 }}>
        {activeForm + 1 !== totalItems && (
          <Button
            sx={{ mr: 2 }}
            variant='outlined'
            onClick={() => handleNext(activeForm + 1, totalItems)}
          >
            Next Form
          </Button>
        )}
        <Button
          variant='contained'
          onClick={(e) => {
            handleSubmit(e);
            handleFileSubmit(e);
          }}
        >
          Submit
        </Button>
      </Box>}
      {/* {formId === 'BF003a' ? null :
        <Paper sx={{ p: 2, mt: 3 }} elevation={5}>
          <Typography variant='h4'>Last Submission</Typography>
          {loading ? (
            <CircularProgress />
          ) : (
            <Typography>
              {lastSubmission?.length > 0 ? (
                JSON.stringify(lastSubmission[0])
              ) : (
                <Alert severity='warning'>
                  'No data has been submitted using this form yet'
                </Alert>
              )}
            </Typography>
          )}
        </Paper>} */}

    </Box>
  );
};

CBR.propTypes = {
  postFormData: PropTypes.func.isRequired,
  postFormDataFiles: PropTypes.func.isRequired,
};

export default connect(null, { postFormData, postFormDataFiles })(CBR);
