import { useEffect } from 'react';
import {
  Alert,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import tooltips from '../../../../../../store/tooltips';
import InfoIcon from '@mui/icons-material/Info';

export default function BF007aFeedstockSource({
  data,
  setData,
  setFormId,
  feedstockSourceTypes,
}) {
  useEffect(() => {
    setFormId('BF007a');
  }, [setFormId]);

  const {
    feedstockType,
    feedstockDetails,
    location,
    feedstockAmount,
    feedstockAmountUnit,
  } = data;

  return (
    <>
      <Typography variant='h5' sx={{ mb: 2 }}>
        Feedstock Source
        <Tooltip
          title={<h2 style={{ color: 'lightgreen' }}>{tooltips.planningQ1}</h2>}
        >
          {<InfoIcon sx={{ width: 15 }} />}
        </Tooltip>
      </Typography>
      <Alert severity='info'>
        If you have more than one type of feedstock available or are planning to
        mix two types of feedstock for your burns, please submit this form once
        for each type of feedstock.
      </Alert>
      <FormControl
        sx={{
          mt: 1,
        }}
        fullWidth
      >
        <InputLabel sx={{ background: 'white', pl: 1, pr: 1 }}>Feedstock Type</InputLabel>
        <Select name='feedstockType' value={feedstockType} onChange={setData}>
          {feedstockSourceTypes.map(item => {
            return (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Please define type/species
        <Tooltip
          title={<h2 style={{ color: 'lightgreen' }}>{tooltips.planningQ2}</h2>}
        >
          {<InfoIcon sx={{ width: 15 }} />}
        </Tooltip>
      </Typography>
      <TextField
        name='feedstockDetails'
        value={feedstockDetails}
        label='Please define type/species'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Location
        <Tooltip
          title={<h2 style={{ color: 'lightgreen' }}>{tooltips.planningQ3}</h2>}
        >
          {<InfoIcon sx={{ width: 15 }} />}
        </Tooltip>
      </Typography>
      <Alert severity='warning'>
        <Link href='https://what3words.com/' target={'_blank'}>
          Open what3words
        </Link>
        . Please copy and paste the 3words from a block corresponding to the
        location where you can find the feedstock on the farm.
      </Alert>
      <TextField
        name='location'
        value={location}
        label='Location'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        How much is available?
        <Tooltip
          title={<h2 style={{ color: 'lightgreen' }}>{tooltips.planningQ4}</h2>}
        >
          {<InfoIcon sx={{ width: 15 }} />}
        </Tooltip>
      </Typography>
      <TextField
        name='feedstockAmount'
        value={feedstockAmount}
        label='Amount'
        sx={{ width: 1 / 2 }}
        onChange={setData}
      />
      <FormControl sx={{ width: 1 / 2 }}>
        <InputLabel sx={{ background: 'white', pl: 1, pr: 1 }}>Unit</InputLabel>
        <Select
          name='feedstockAmountUnit'
          value={feedstockAmountUnit}
          onChange={setData}
        >
          <MenuItem value='ha'>ha</MenuItem>
          <MenuItem value='tonnes'>tonnes</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
