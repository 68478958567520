import React from 'react'
import { TableContainer, Table, TableHead, TableCell, TableRow, Paper, TableBody } from '@mui/material';

const Transport = () => {
    return (
        <>
            <h2>Transport</h2>
            <TableContainer key={0} component={Paper} sx={{ height: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                Description
                            </TableCell>
                            <TableCell align="left">
                                Transport Type (tr)
                            </TableCell>
                            <TableCell align="left">
                                Emission Factor for Transport Type (tr)
                            </TableCell>
                            <TableCell align="left">
                                Unit
                            </TableCell>
                            <TableCell align="left">
                                Source
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">Medium-and-Heavy-Duty Truck</TableCell>
                            <TableCell align="left">tr_MHDT_1</TableCell>
                            <TableCell align="left">0.000117329</TableCell>
                            <TableCell align="left">tCO2e/tonne.km</TableCell>
                            <TableCell align="left">U.S. EPA 2024</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default Transport