import {
  FORM_DATA_SUCCESS,
  FORM_DATA_FAIL,
  FIREBREAKS_SUCCESS,
  FIREBREAKS_FAIL,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAIL,
  PROJECTS_SUCCESS,
  PROJECTS_FAIL,
  UPDATE_PROJECT_NICKNAME_SUCCESS,
  UPDATE_PROJECT_NICKNAME_FAIL,
  FETCH_BATCH_MEDIA_SUCCESS,
  FETCH_BATCH_MEDIA_FAIL,
  FETCH_KILN_DIMENSIONS_SUCCESS,
  FETCH_KILN_DIMENSIONS_FAIL,
  FETCH_BATCH_DATA_SUCCESS,
  FETCH_BATCH_DATA_FAIL,
  FETCH_ADDITIONAL_BATCH_DATA_SUCCESS,
  FETCH_ADDITIONAL_BATCH_DATA_FAIL,
  CREATE_FORM_DATA_SUCCESS,
  CREATE_FORM_DATA_FAIL,
  FETCH_CHARGING_DATA_SUCCESS,
  FETCH_CHARGING_DATA_FAIL,
  FETCH_APPLICATION_DATA_SUCCESS,
  FETCH_APPLICATION_DATA_FAIL,
  API_FETCH_FEEDSTOCK_VERIFICATION_DATA_SUCCESS,
  API_FETCH_FEEDSTOCK_VERIFICATION_DATA_FAIL,
  API_VERIFY_BIOCHAR_DATA_SUCCESS,
  API_VERIFY_BIOCHAR_DATA_FAIL,
  API_FETCH_FLAMING_COMBUSTION_DATA_SUCCESS,
  API_FETCH_FLAMING_COMBUSTION_DATA_FAIL,
  API_FETCH_BIOCHAR_MEASUREMENT_DATA_FAIL,
  API_FETCH_BIOCHAR_MEASUREMENT_DATA_SUCCESS,
  API_FETCH_BULK_DENSITY_DATA_SUCCESS,
  API_FETCH_BULK_DENSITY_DATA_FAIL,
  API_FETCH_UNBURNT_VOLUME_SUCCESS,
  API_FETCH_UNBURNT_VOLUME_FAIL,
  GET_BIOCHAR_ASSETS_SUCCESS,
  GET_BIOCHAR_ASSETS_FAIL,
  ADD_BIOCHAR_ASSET_SUCCESS,
  ADD_BIOCHAR_ASSET_FAIL,
  GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_SUCCESS,
  GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_FAIL,
  POST_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_SUCCESS,
  POST_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_FAIL,
  GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_TOTALS_SUCCESS,
  GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_TOTALS_FAIL,
  GET_HIGHEST_BIOCHAR_ID_SUCCESS,
  GET_HIGHEST_BIOCHAR_ID_FAIL,
  POST_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_SUCCESS,
  POST_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_FAIL,
  GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_SUCCESS,
  GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_FAIL,
  GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_TOTALS_SUCCESS,
  GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_TOTALS_FAIL,
  POST_LPG_BIOCHAR_DATA_SUCCESS,
  POST_LPG_BIOCHAR_DATA_FAIL,
  GET_LPG_BIOCHAR_DATA_SUCCESS,
  GET_LPG_BIOCHAR_DATA_FAIL,
  GET_LPG_BIOCHAR_DATA_TOTALS_SUCCESS,
  GET_LPG_BIOCHAR_DATA_TOTALS_FAIL,
  BIOCHAR_FILE_UPLOAD_SUCCESS,
  BIOCHAR_FILE_UPLOAD_FAIL,
  SET_MEASUREMENT_TYPE_SUCCESS,
  SET_MEASUREMENT_TYPE_FAIL,
  FETCH_DATA_TABLES_DATA_SUCCESS,
  FETCH_DATA_TABLES_DATA_FAIL,
  POST_BATCH_CCIK_SUCCESS,
  POST_BATCH_CCIK_FAIL,
  POST_LAB_REPORT_FILE_UPLOAD_SUCCESS,
  POST_LAB_REPORT_FILE_UPLOAD_FAIL,
  POST_BIOCHAR_STORED_CARBON_SUCCESS,
  POST_BIOCHAR_STORED_CARBON_FAIL,
  POST_BATCH_VOLUMES_AND_MEDIA_PDF_SUCCESS,
  POST_BATCH_VOLUMES_AND_MEDIA_PDF_FAIL,
  FETCH_NOTES_SUCCESS,
  FETCH_NOTES_FAIL,
} from './types';
import {
  API_FIREBREAKS,
  API_FORM_DATA,
  API_PROJECTS,
  API_UPDATE_PROJECT_NICKNAME,
  API_UPLOAD_FILES,
  API_GET_BATCH_MEDIA,
  GET_KILN_DIMENSIONS,
  API_GET_BATCH_DATA,
  API_GET_ADDITIONAL_BATCH_DATA,
  API_FETCH_CHARGING_DATA,
  API_FETCH_APPLICATION_DATA,
  API_FETCH_FEEDSTOCK_VERIFICATION_DATA,
  API_FETCH_FLAMING_COMBUSTION_VERIFICATION_DATA,
  API_VERIFY_BIOCHAR_DATA,
  API_FETCH_BIOCHAR_MEASUREMENT_VERIFICATION_DATA,
  API_FETCH_BULK_DENSITY_VERIFICATION_DATA,
  API_FETCH_UNBURNT_VOLUME_VERIFICATION_DATA,
  API_POST_ADD_BIOCHAR_ASSET,
  API_GET_BIOCHAR_ASSETS,
  API_GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA,
  API_POST_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA,
  API_GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_TOTALS,
  API_GET_HIGHEST_BIOCHAR_ID,
  API_POST_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA,
  API_GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA,
  API_GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_TOTALS,
  API_POST_BIOCHAR_LPG_DATA,
  API_GET_BIOCHAR_LPG_DATA,
  API_GET_BIOCHAR_LPG_DATA_TOTALS,
  API_BIOCHAR_UPLOAD_FILE,
  SET_MEASUREMENT_TYPE,
  API_DATA_TABLES_DATA,
  API_POST_BATCH_CCIK,
  API_POST_LAB_REPORT_FILE_UPLOAD,
  API_POST_BIOCHAR_STORED_CARBON,
  API_POST_BATCH_VOLUMES_AND_MEDIA,
  API_FETCH_NOTES,
} from './constants';
import { setAlert } from './alert';
import api from '../util/api';
import axios from 'axios';
const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
  },
};

const configBlob = {
  method: "POST",
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  },
  // withCredentials: true,
  responseType: 'blob',
};

// { responseType: 'blob' }

const configFiles = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  // withCredentials: true,
};

// Submit Form Data
export const postFormData = data => async dispatch => {
  try {
    const res = await api().post(API_FORM_DATA, data, config);
    dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: CREATE_FORM_DATA_SUCCESS,
      payload: res.data.formdata,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CREATE_FORM_DATA_FAIL,
    });
  }
};

// Upload Files
export const postFormDataFiles = data => async dispatch => {
  try {
    const res = await api().post(API_UPLOAD_FILES, data, configFiles);
    dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: FILE_UPLOAD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: FILE_UPLOAD_FAIL,
    });
  }
};

// Fetch Form Data
export const getFormData = projectKey => async dispatch => {
  try {
    const res = await api().get(API_FORM_DATA + `/${projectKey}`, config);
    dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: FORM_DATA_SUCCESS,
      payload: res.data.formdata,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: FORM_DATA_FAIL,
    });
  }
};

export const getKilnDimensions = projectKey => async dispatch => {
  try {
    const res = await api().get(GET_KILN_DIMENSIONS + `${projectKey}`, config);
    dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: FETCH_KILN_DIMENSIONS_SUCCESS,
      payload: res.data.formdata,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: FETCH_KILN_DIMENSIONS_FAIL,
    });
  }
};

// Get Batch Media
export const getBatchMedia = projectId => async dispatch => {
  try {
    const res = await api().get(API_GET_BATCH_MEDIA + projectId, config);
    dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: FETCH_BATCH_MEDIA_SUCCESS,
      payload: res.data.batchMedia,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: FETCH_BATCH_MEDIA_FAIL,
    });
  }
};

// Get Batch Data
export const getBatchData = projectId => async dispatch => {
  try {
    const res = await api().get(API_GET_BATCH_DATA + projectId, config);
    dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: FETCH_BATCH_DATA_SUCCESS,
      payload: res.data.batchdata,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: FETCH_BATCH_DATA_FAIL,
    });
  }
};

export const getChargingData = projectId => async dispatch => {
  try {
    const res = await api().get(API_FETCH_CHARGING_DATA + projectId, config);
    dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: FETCH_CHARGING_DATA_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: FETCH_CHARGING_DATA_FAIL,
    });
  }
};

export const getApplicationData = projectId => async dispatch => {
  try {
    const res = await api().get(API_FETCH_APPLICATION_DATA + projectId, config);
    dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: FETCH_APPLICATION_DATA_SUCCESS,
      payload: res.data.biochar_application,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: FETCH_APPLICATION_DATA_FAIL,
    });
  }
};

export const getAdditionalBatchData = projectId => async dispatch => {
  try {
    const res = await api().get(
      API_GET_ADDITIONAL_BATCH_DATA + projectId,
      config
    );
    dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: FETCH_ADDITIONAL_BATCH_DATA_SUCCESS,
      payload: res.data.additional_batch_data,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: FETCH_ADDITIONAL_BATCH_DATA_FAIL,
    });
  }
};

// Fetch Firebreaks
export const getFirebreaks = () => async dispatch => {
  try {
    const res = await api().get(API_FIREBREAKS, config);
    dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: FIREBREAKS_SUCCESS,
      payload: res.data.firebreaks,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: FIREBREAKS_FAIL,
    });
  }
};

// Create Project
export const createProject = data => async dispatch => {
  try {
    const res = await api().post(API_PROJECTS, data, config);
    dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: PROJECTS_SUCCESS,
      payload: res.data.projects,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: PROJECTS_FAIL,
    });
  }
};

// Update Project Nickname
export const updateProjectNickname = data => async dispatch => {
  try {
    const res = await api().post(API_UPDATE_PROJECT_NICKNAME, data, config);
    dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: UPDATE_PROJECT_NICKNAME_SUCCESS,
      payload: res.data.projects,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: UPDATE_PROJECT_NICKNAME_FAIL,
    });
  }
};

// Fetch User Projects
export const getProjects = () => async dispatch => {
  try {
    const res = await api().get(API_PROJECTS, config);
    dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: PROJECTS_SUCCESS,
      payload: res.data.projects,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: PROJECTS_FAIL,
    });
  }
};

export const getFeedstockVerificationData = projectId => async dispatch => {
  try {
    const res = await api().get(
      API_FETCH_FEEDSTOCK_VERIFICATION_DATA + projectId,
      config
    );
    dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: API_FETCH_FEEDSTOCK_VERIFICATION_DATA_SUCCESS,
      payload: res.data.feedStockData,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: API_FETCH_FEEDSTOCK_VERIFICATION_DATA_FAIL,
    });
  }
};

export const getFlamingCombustionData = projectId => async dispatch => {
  try {
    const res = await api().get(
      API_FETCH_FLAMING_COMBUSTION_VERIFICATION_DATA + projectId,
      config
    );
    dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: API_FETCH_FLAMING_COMBUSTION_DATA_SUCCESS,
      payload: res.data.flamingCombustionData,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: API_FETCH_FLAMING_COMBUSTION_DATA_FAIL,
    });
  }
};

export const getBiocharMeasurementData = projectId => async dispatch => {
  try {
    const res = await api().get(
      API_FETCH_BIOCHAR_MEASUREMENT_VERIFICATION_DATA + projectId,
      config
    );
    dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: API_FETCH_BIOCHAR_MEASUREMENT_DATA_SUCCESS,
      payload: res.data.biocharMeasurementData,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: API_FETCH_BIOCHAR_MEASUREMENT_DATA_FAIL,
    });
  }
};

export const getBulkDensityData = projectId => async dispatch => {
  try {
    const res = await api().get(
      API_FETCH_BULK_DENSITY_VERIFICATION_DATA + projectId,
      config
    );
    dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: API_FETCH_BULK_DENSITY_DATA_SUCCESS,
      payload: res.data.bulkDensityData,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: API_FETCH_BULK_DENSITY_DATA_FAIL,
    });
  }
};

export const getUnburntVolume = projectId => async dispatch => {
  try {
    const res = await api().get(
      API_FETCH_UNBURNT_VOLUME_VERIFICATION_DATA + projectId,
      config
    );
    dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: API_FETCH_UNBURNT_VOLUME_SUCCESS,
      payload: res.data.unburntVolumeData,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: API_FETCH_UNBURNT_VOLUME_FAIL,
    });
  }
};

export const verifyBiocharData =
  (projectId, dataType, dataToVerify) => async dispatch => {
    try {
      const res = await api().post(
        API_VERIFY_BIOCHAR_DATA + projectId + `/${dataType}`,
        dataToVerify,
        config
      );
      dispatch(setAlert(res.data.detail, 'success'));
      dispatch({
        type: API_VERIFY_BIOCHAR_DATA_SUCCESS,
      });
    } catch (err) {
      dispatch(setAlert(err.response.data.detail, 'error'));
      dispatch({
        type: API_VERIFY_BIOCHAR_DATA_FAIL,
      });
    }
  };

export const addBiocharAsset =
  (formData, year, farm, project) => async dispatch => {
    try {
      const { data } = await api().post(
        process.env.REACT_APP_API_URL +
        API_POST_ADD_BIOCHAR_ASSET +
        `/${year}/${farm}/${project}`,
        formData,
        config
      );
      dispatch({
        type: ADD_BIOCHAR_ASSET_SUCCESS,
      });
      dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
      dispatch(setAlert(err.response.data.detail, 'error'));
      dispatch({
        type: ADD_BIOCHAR_ASSET_FAIL,
        payload: err.response.data.detail,
      });
    }
  };

export const getBiocharAssets = project => async dispatch => {
  try {
    const { data } = await api().get(
      process.env.REACT_APP_API_URL + API_GET_BIOCHAR_ASSETS + `/${project}`,
      config
    );
    dispatch({
      type: GET_BIOCHAR_ASSETS_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: GET_BIOCHAR_ASSETS_FAIL,
      payload: err.response.data.detail,
    });
  }
};

// Diesel

export const postStationaryBiocharDieselData =
  (formData, type, year, farm, project) => async dispatch => {
    try {
      const { data } = await api().post(
        process.env.REACT_APP_API_URL +
        API_POST_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA +
        `/${type}/${year}/${farm}/${project}`,
        formData,
        config
      );
      dispatch({
        type: POST_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_SUCCESS,
        payload: data,
      });
      dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
      dispatch(setAlert(err.response.data.detail, 'error'));
      dispatch({
        type: POST_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_FAIL,
        payload: err.response.data.detail,
      });
    }
  };

export const getStationaryBiocharDieselData =
  (type, year, project) => async dispatch => {
    try {
      const { data } = await api().get(
        process.env.REACT_APP_API_URL +
        API_GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA +
        `/${type}/${year}/${project}`,
        config
      );
      dispatch({
        type: GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_SUCCESS,
        payload: data,
      });
      dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
      dispatch(setAlert(err.response.data.detail, 'error'));
      dispatch({
        type: GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_FAIL,
        payload: err.response.data.detail,
      });
    }
  };

export const getStationaryBiocharDieselDataTotals =
  (type, year, project) => async dispatch => {
    try {
      const { data } = await api().get(
        process.env.REACT_APP_API_URL +
        API_GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_TOTALS +
        `/${type}/${year}/${project}`,
        config
      );
      dispatch({
        type: GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_TOTALS_SUCCESS,
        payload: data,
      });
      dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
      dispatch(setAlert(err.response.data.detail, 'error'));
      dispatch({
        type: GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_TOTALS_FAIL,
        payload: err.response.data.detail,
      });
    }
  };

export const geBiocharHighestId = type => async dispatch => {
  try {
    const { data } = await api().get(
      process.env.REACT_APP_API_URL + API_GET_HIGHEST_BIOCHAR_ID + type,
      config
    );
    dispatch({
      type: GET_HIGHEST_BIOCHAR_ID_SUCCESS,
      payload: data,
    });
    // dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: GET_HIGHEST_BIOCHAR_ID_FAIL,
      payload: err.response.data.detail,
    });
  }
};

/* PETROL */

export const postStationaryOrMobileBiocharPetrolData =
  (formData, type, year, farm, project) => async dispatch => {
    try {
      const { data } = await api().post(
        process.env.REACT_APP_API_URL +
        API_POST_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA +
        `/${type}/${year}/${farm}/${project}`,
        formData,
        config
      );
      dispatch({
        type: POST_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_SUCCESS,
        payload: data,
      });
      dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
      dispatch(setAlert(err.response.data.detail, 'error'));
      dispatch({
        type: POST_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_FAIL,
        payload: err.response.data.detail,
      });
    }
  };

export const getStationaryOrMobileBiocharPetrolData =
  (type, year, project) => async dispatch => {
    try {
      const { data } = await api().get(
        process.env.REACT_APP_API_URL +
        API_GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA +
        `/${type}/${year}/${project}`,
        config
      );
      dispatch({
        type: GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_SUCCESS,
        payload: data,
      });
      dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
      dispatch(setAlert(err.response.data.detail, 'error'));
      dispatch({
        type: GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_FAIL,
        payload: err.response.data.detail,
      });
    }
  };

export const getStationaryOrMobileBiocharPetrolDataTotals =
  (type, year, project) => async dispatch => {
    try {
      const { data } = await api().get(
        process.env.REACT_APP_API_URL +
        API_GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_TOTALS +
        `/${type}/${year}/${project}`,
        config
      );
      dispatch({
        type: GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_TOTALS_SUCCESS,
        payload: data,
      });
      dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
      dispatch(setAlert(err.response.data.detail, 'error'));
      dispatch({
        type: GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_TOTALS_FAIL,
        payload: err.response.data.detail,
      });
    }
  };

export const postLpgBiocharData =
  (formData, year, farm, project) => async dispatch => {
    try {
      const { data } = await api().post(
        process.env.REACT_APP_API_URL +
        API_POST_BIOCHAR_LPG_DATA +
        `/${year}/${farm}/${project}`,
        formData,
        config
      );
      dispatch({
        type: POST_LPG_BIOCHAR_DATA_SUCCESS,
        payload: data,
      });
      dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
      dispatch(setAlert(err.response.data.detail, 'error'));
      dispatch({
        type: POST_LPG_BIOCHAR_DATA_FAIL,
        payload: err.response.data.detail,
      });
    }
  };

export const getLpgBiocharData = (year, project) => async dispatch => {
  try {
    const { data } = await api().get(
      process.env.REACT_APP_API_URL +
      API_GET_BIOCHAR_LPG_DATA +
      `/${year}/${project}`,
      config
    );
    dispatch({
      type: GET_LPG_BIOCHAR_DATA_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: GET_LPG_BIOCHAR_DATA_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const getLpgBiocharDataTotals = (year, project) => async dispatch => {
  try {
    const { data } = await api().get(
      process.env.REACT_APP_API_URL +
      API_GET_BIOCHAR_LPG_DATA_TOTALS +
      `/${year}/${project}`,
      config
    );
    dispatch({
      type: GET_LPG_BIOCHAR_DATA_TOTALS_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: GET_LPG_BIOCHAR_DATA_TOTALS_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const biocharFileUpload = (formData, type) => async dispatch => {
  try {
    const { data } = await api().post(
      process.env.REACT_APP_API_URL + API_BIOCHAR_UPLOAD_FILE + `/${type}`,
      formData,
      config
    );
    dispatch({
      type: BIOCHAR_FILE_UPLOAD_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: BIOCHAR_FILE_UPLOAD_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const postMeasurementType = (formData, project) => async dispatch => {
  try {
    const { data } = await api().post(
      process.env.REACT_APP_API_URL + SET_MEASUREMENT_TYPE + `/${project}`,
      formData,
      config
    );
    dispatch({
      type: SET_MEASUREMENT_TYPE_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: SET_MEASUREMENT_TYPE_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const postBatchCcik = formData => async dispatch => {
  try {
    const { data } = await api().post(
      process.env.REACT_APP_API_URL + API_POST_BATCH_CCIK,
      formData,
      config
    );
    dispatch({
      type: POST_BATCH_CCIK_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: POST_BATCH_CCIK_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const postBiocharStoredCarbonContent = formData => async dispatch => {
  try {
    const { data } = await api().post(
      process.env.REACT_APP_API_URL + API_POST_BIOCHAR_STORED_CARBON,
      formData,
      config
    );
    dispatch({
      type: POST_BIOCHAR_STORED_CARBON_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: POST_BIOCHAR_STORED_CARBON_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const labReportFileUpload =
  (formData, year, farm, project) => async dispatch => {
    try {
      const { data } = await api().post(
        process.env.REACT_APP_API_URL +
        API_POST_LAB_REPORT_FILE_UPLOAD +
        `/${year}/${farm}/${project}`,
        formData,
        configFiles
      );
      dispatch({
        type: POST_LAB_REPORT_FILE_UPLOAD_SUCCESS,
        payload: data,
      });
      dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
      dispatch(setAlert(err.response.data.detail, 'error'));
      dispatch({
        type: POST_LAB_REPORT_FILE_UPLOAD_FAIL,
        payload: err.response.data.detail,
      });
    }
  };

export const getDataTablesData = projectId => async dispatch => {
  try {
    const res = await api().get(API_DATA_TABLES_DATA + `/${projectId}`, config);
    dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: FETCH_DATA_TABLES_DATA_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: FETCH_DATA_TABLES_DATA_FAIL,
    });
  }
};

// fetch-batch-volumes-and-media-pdf/<projectId>/<batchId>

export const postBatchVolumesAndMediaPdf = (formData, projectId, batchId) => async dispatch => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}${API_POST_BATCH_VOLUMES_AND_MEDIA}/${projectId}/${batchId}`,
      formData,
      configBlob,
      // Specify that we expect a binary response
    );

    // Create a URL for the PDF blob
    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    // Create a link to download it
    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download', 'batch_volumes.pdf'); // Set the file name
    document.body.appendChild(link);
    link.click();
    dispatch({
      type: POST_BATCH_VOLUMES_AND_MEDIA_PDF_SUCCESS,
      payload: 'PDF downloaded successfully',
    });
    dispatch(setAlert('PDF downloaded successfully', 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: POST_BATCH_VOLUMES_AND_MEDIA_PDF_FAIL,
      payload: err.response.data.detail,
    });
  }
};
// Fetch Notes
export const getNotes = projectId => async dispatch => {
  try {
    const { data } = await api().get(
      process.env.REACT_APP_API_URL + API_FETCH_NOTES + '/' + projectId,
      config
    );
    dispatch({
      type: FETCH_NOTES_SUCCESS,
      payload: data.notes,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: FETCH_NOTES_FAIL,
      payload: err.response.data.detail,
    });
  }
};
