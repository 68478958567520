import React, { useState, useEffect } from 'react';
import AddItem from '../../../../carbonfootprinting/ItemAdd';
// import { listedAgroChemicals } from '../../dataFiles/listedAgroChemicals';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Paper
} from '@mui/material';
import { createAgroChemical, getAgroChemicals, updateAgrochemicalActiveState } from '../../../../../../actions/carbonfootprint';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function CultivationAgroChemicals() {
  const dispatch = useDispatch()

  const year = localStorage.getItem('year')
  const group = localStorage.getItem('group')

  const { agrochemicals } = useSelector(state => state.carbonfootprint)

  useEffect(() => {
    dispatch(getAgroChemicals(group, year))
  }, [group, year, dispatch])

  const formFields = [
    { name: 'productName', label: 'Product Name', type: 'text' },
    {
      name: 'purpose',
      label: 'Select Category',
      type: 'select',
      options: ['Pesticide', 'Herbicide', 'Insecticide'],
    },
    { name: 'supplier', label: 'Supplier', type: 'text' },
    {
      name: 'linkToProduct',
      label: 'Link to product on website',
      type: 'text',
    },
    {
      name: 'activeIngredientOneName',
      label: 'Active ingredient 1: Name',
      type: 'text',
    },
    {
      name: 'activeIngredientOnePercentage',
      label: 'Active ingredient 1: Percentage',
      type: 'number',
    },
    {
      name: 'activeIngredientTwoName',
      label: 'Active ingredient 2: Name',
      type: 'text',
    },
    {
      name: 'activeIngredientTwoPercentage',
      label: 'Active ingredient 2: Percentage',
      type: 'number',
    },
    {
      name: 'activeIngredientThreeName',
      label: 'Active ingredient 3: Name',
      type: 'text',
    },
    {
      name: 'activeIngredientThreePercentage',
      label: 'Active ingredient 3: Percentage',
      type: 'number',
    },
    {
      name: "applicationRate",
      label: "How is the application rate defined",
      type: "select",
      options: ['kg/ha', 'L/ha']
    },
  ];

  const initialState = formFields.reduce((obj, field) => {
    obj[field.name] = '';
    return obj;
  }, {});
  const [selectedListedAgrochemical, setSelectedListedAgrochemical] = useState('')


  const handleFormSubmit = data => {
    dispatch(createAgroChemical(data, group, year))
  };

  const handleAddAgrochemicalToList = () => {
    dispatch(updateAgrochemicalActiveState(group, selectedListedAgrochemical)).then(() => {
      dispatch(getAgroChemicals(group, year))
    })
  }

  const [curTab, setCurTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setCurTab(newValue);
  };

  const handleListedAgrochemicalChange = (event) => {
    setSelectedListedAgrochemical(event.target.value)
  }

  const columns = [
    {
      field: 'product_name', headerName: 'Product Name',
      flex: 1,

    },
    {
      field: 'supplier',
      headerName: 'Supplier',
      flex: 1,
    },
    {
      field: 'link_to_product_on_site',
      headerName: 'Link to product',
      flex: 1,
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
    },
    {
      field: 'application_rate_unit',
      headerName: 'Application rate',
      flex: 1,
    }
  ]

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={curTab}
        onChange={handleTabChange}
        style={{ marginBottom: 10 }}
      >
        <Tab label='Add Product' />
      </Tabs>
      <Box sx={{ height: '100%' }}>
        <AddItem
          formFields={formFields}
          initialState={initialState}
          onFormSubmit={handleFormSubmit}
          items={[]}
          addTitle='Add New Agro Chemical'
        />
        <FormControl fullWidth>
          <InputLabel>Listed Agro Chemicals</InputLabel>
          <Select
            name='listedAgroChemical'
            value={selectedListedAgrochemical}
            onChange={handleListedAgrochemicalChange}
            label={'Listed Agro Chemicals'}
          >
            {agrochemicals?.map(item => {
              return (
                <MenuItem key={item._id} value={item._id}>
                  {item.category}: {item.product_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button sx={{ m: '1rem 0' }} variant='contained' color={agrochemicals?.filter((f) => f.is_active).map((f) => f._id).includes(selectedListedAgrochemical) ? 'warning' : 'primary'} onClick={handleAddAgrochemicalToList}>
          {agrochemicals?.filter((f) => f.is_active).map((f) => f._id).includes(selectedListedAgrochemical) ? 'Remove Agrochemical from list' : 'Add Agrochemical to list'}
        </Button>
        {/* <DataGrid
            rows={agrochemicals?.filter((f) => f.is_active) || []}
            getRowId={row => row._id}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            components={{ Toolbar: GridToolbar }}
          /> */}
        <TableContainer component={Paper} sx={{ height: 'auto' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.field} align="center">
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {agrochemicals && agrochemicals?.length > 0 ? agrochemicals?.filter((f) => f.is_active).map((row) => (
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {columns.map((column) => (
                    <TableCell key={column.field} align="center">
                      {row[column.field]}
                    </TableCell>
                  ))}
                </TableRow>
              )) : null}

            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
