import {
    POST_STATIONARY_OR_MOBILE_PETROL_DATA_SUCCESS,
    POST_STATIONARY_OR_MOBILE_PETROL_DATA_FAIL,
    GET_STATIONARY_OR_MOBILE_PETROL_DATA_SUCCESS,
    GET_STATIONARY_OR_MOBILE_PETROL_DATA_FAIL,
    GET_STATIONARY_OR_MOBILE_PETROL_DATA_TOTALS_SUCCESS,
    GET_STATIONARY_OR_MOBILE_PETROL_DATA_TOTALS_FAIL
} from '../../../actions/carbonfootprint-rework/types/01-direct-ghg-emissions-and-removals-types';


const initialState = {
    loading: true,
    petrolData: [],
    petrolDataTotals: []
};

// PETROL REDUCERS
export default function
    directGhgEmissionsAndRemovalsForPetrol(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        // STATIONARY PETROL
        case POST_STATIONARY_OR_MOBILE_PETROL_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_STATIONARY_OR_MOBILE_PETROL_DATA_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_STATIONARY_OR_MOBILE_PETROL_DATA_SUCCESS:
            return {
                ...state,
                petrolData: payload,
                loading: false,
            };
        case GET_STATIONARY_OR_MOBILE_PETROL_DATA_FAIL:
            return {
                ...state,
                petrolData: payload,
                loading: false
            }
        case GET_STATIONARY_OR_MOBILE_PETROL_DATA_TOTALS_SUCCESS:
            return {
                ...state,
                petrolDataTotals: payload,
                loading: false,
            };
        case GET_STATIONARY_OR_MOBILE_PETROL_DATA_TOTALS_FAIL:
            return {
                ...state,
                petrolDataTotals: payload,
                loading: false
            }
        default:
            return state;
    }
}


