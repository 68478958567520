import { Box, Button, Grid } from '@mui/material';
import { useState } from 'react'
import DryIceDataCapturing from './DryIceDataCapturing';
// import { DataCapturing } from './DieselDataCapturingStationary';

export default function DryIce() {
    const [selected, setSelected] = useState('Dry ice')
    const dryIceCategories = ['Dry ice']

    return (
        <>
            <Grid item xs={3}>
                <Box marginTop={2}>
                    {dryIceCategories.map((c, i) => {
                        return (
                            <Button
                                key={i}
                                variant={c === selected ? 'contained' : 'outlined'}
                                size='large'
                                sx={{ width: '100%', display: 'block', mb: 2.5 }}
                                onClick={() => {
                                    setSelected(c)
                                    localStorage.setItem('catOne', c)
                                }}
                            >
                                {c}
                            </Button>
                        )
                    })}
                </Box>
            </Grid>
            <Grid item xs={9}>
                <DryIceDataCapturing />
            </Grid>
        </>
    );
}

/*
Select Category 1->
Select Dry Ice->
Select Month (Monthly basis)->
Select unit from drop down list (kilograms only)->
Manually Enter Dry Ice consumption for the selected selected month with the corresponding unit->
Upload corresponding source documentation

Users should be able to see the annual sum table for corresponding line items in the TOTALS tab (i.e. 12 month total).
*/
