import {
    Box,
    Button,
    Divider
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import RofBatchData from '../sections/programmes/myProgrammes/forms/types/RofBatchData';
import RofMedia from '../sections/programmes/myProgrammes/forms/types/RofMedia';

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const ProjectQuickLink = () => {
    let { projectId } = useParams();
    const [searchParams] = useSearchParams();
    const projectIdentifier = searchParams.get("projectIdentifier")
    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate('/programs', { replace: true, state: { projectId: projectIdentifier, projectKey: projectId } })
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <Button sx={{ display: 'flex', cursor: 'pointer', mr: 3 }} variant={'outlined'} onClick={() => navigate('/home')}>
                    <ArrowBack /> Back to Home
                </Button>
                <Button variant={'contained'} onClick={handleNavigate}>
                    Go to Project {projectIdentifier}
                </Button>
            </Box>
            <RofBatchData projectKey={projectId} />
            <Divider sx={{ py: 3 }} />
            <RofMedia projectKey={projectId} />
        </>
    )
}

export default ProjectQuickLink