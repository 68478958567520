import {
    GET_PACKAGING_MATERIALS_DATA_SUCCESS,
    GET_PACKAGING_MATERIALS_DATA_FAIL,
    POST_PACKAGING_MATERIALS_DATA_SUCCESS,
    POST_PACKAGING_MATERIALS_DATA_FAIL,
    GET_PACKAGING_MATERIALS_DATA_TOTALS_SUCCESS,
    GET_PACKAGING_MATERIALS_DATA_TOTALS_FAIL
} from '../../../actions/carbonfootprint-rework/types/04-indirect-ghg-emissions-from-products-used-by-an-organisation';

const initialState = {
    loading: true,
    packagingMaterialsData: [],
    packagingMaterialsDataTotals: []
};

export default function indirectGhgEmissionsFromProductsForPackagingMaterialsReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case POST_PACKAGING_MATERIALS_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_PACKAGING_MATERIALS_DATA_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_PACKAGING_MATERIALS_DATA_SUCCESS:
            return {
                ...state,
                packagingMaterialsData: payload,
                loading: false,
            };
        case GET_PACKAGING_MATERIALS_DATA_FAIL:
            return {
                ...state,
                packagingMaterialsData: payload,
                loading: false
            }
        case GET_PACKAGING_MATERIALS_DATA_TOTALS_SUCCESS:
            return {
                ...state,
                packagingMaterialsDataTotals: payload,
                loading: false,
            };
        case GET_PACKAGING_MATERIALS_DATA_TOTALS_FAIL:
            return {
                ...state,
                packagingMaterialsDataTotals: payload,
                loading: false
            }
        default:
            return state;
    }
}