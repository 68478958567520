import { Box, Button, Grid, } from '@mui/material';
import { useState } from 'react'
import ElectricityPurchasedDataCapturing from './ElectricityPurchasedDataCapturing';

export default function ElectricityPurchased() {
    const [selected, setSelected] = useState('Electricity purchased')
    const electricityPurchasedCategories = ['Electricity purchased']

    return (
        <>
            <Grid item xs={3}>
                <Box marginTop={2}>
                    {electricityPurchasedCategories.map((c, i) => {
                        return (
                            <Button
                                key={i}
                                variant={c === selected ? 'contained' : 'outlined'}
                                size='large'
                                sx={{ width: '100%', display: 'block', mb: 2.5 }}
                                onClick={() => {
                                    setSelected(c)
                                    localStorage.setItem('catTwo', c)
                                }}
                            >
                                {c}
                            </Button>
                        )
                    })
                    }
                </Box>
            </Grid>
            <Grid item xs={9}>
                <ElectricityPurchasedDataCapturing />
            </Grid>
        </>
    );
}
