import {
    FILE_UPLOAD_SUCCESS,
    FILE_UPLOAD_FAIL
} from '../../../actions/carbonfootprint-rework/types/01-direct-ghg-emissions-and-removals-types';

const initialState = {
    loading: true
};

export default function
    directGhgEmissionsAndRemovalsFileUpload(state = initialState, action) {

    const { type, //payload 
    } = action;

    switch (type) {
        case FILE_UPLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case FILE_UPLOAD_FAIL:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }
}


