import React, { useState, useEffect } from 'react';
import AddItem from '../../../../carbonfootprinting/ItemAdd';
// import { listedFertilisers } from '../../dataFiles/listedFertilisers';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Grid
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import { createFertilizer, getFertilizers, updateFertilizerActiveState } from '../../../../../../actions/carbonfootprint';
// import FertilizerPlotListTable from '../tables/FertilizerPlotListTable'

export default function CultivationFertiliser() {
  const dispatch = useDispatch()

  const year = localStorage.getItem('year')
  const group = localStorage.getItem('group')

  const { loading, fertilizers } = useSelector(state => state.carbonfootprint)

  useEffect(() => {
    dispatch(getFertilizers(group, year))
  }, [group, year, dispatch])

  const formFields = [
    { name: 'productName', label: 'Product Name', type: 'text' },
    { name: 'supplier', label: 'Supplier', type: 'text' },
    {
      name: 'linkToProduct',
      label: 'Link to product on website',
      type: 'text',
    },
    {
      name: 'category',
      label: 'Select Category',
      type: 'select',
      options: ['Synthetic', 'Organic'],
    },
    {
      name: 'percentageN',
      label: 'Define % of active ingredient (N)',
      type: 'text',
    },
    {
      name: 'percentageP',
      label: 'Define % of active ingredient (P)',
      type: 'text',
    },
    {
      name: 'percentageK',
      label: 'Define % of active ingredient (K)',
      type: 'text',
    },
    // {
    //   name: 'productPurchased',
    //   label: 'How much was purchased (kg/L)',
    //   type: 'text',
    // },
    {
      name: "applicationRate",
      label: "How is the application rate defined",
      type: "select",
      options: ['kg/ha', 'L/ha']
    },

    { name: 'invoice', label: 'Upload invoice', type: 'file' },
  ];

  const initialState = formFields.reduce((obj, field) => {
    obj[field.name] = '';
    return obj;
  }, {});

  const [items, setItems] = useState([]);
  const handleFormSubmit = data => {
    dispatch(createFertilizer(data, group, year))
    setItems(prevItems => [...prevItems, data]);
  };

  const [curTab, setCurTab] = useState(0);
  const handleTabChange = (newValue) => {
    setCurTab(newValue);
  };

  const [selectedListedFertiliser, setSelectedListedFertiliser] = useState('')

  const handleAddFertilizerToList = () => {
    dispatch(updateFertilizerActiveState(group, selectedListedFertiliser)).then(() => {
      dispatch(getFertilizers(group, year))
    })
  }

  const handleListedFertiliserChange = (event) => {
    setSelectedListedFertiliser(event.target.value)
  }

  const columns = [
    {
      field: 'product_name', headerName: 'Product Name',
      flex: 1,

    },
    {
      field: 'supplier',
      headerName: 'Supplier',
      flex: 1,
    },
    {
      field: 'link_to_product_on_site',
      headerName: 'Link to product',
      flex: 1,
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
    },
    {
      field: 'percentage_active_ingredient_nitrogen',
      headerName: 'Percentage N',
      flex: 1,
    },
    {
      field: 'percentage_active_ingredient_phosphorus',
      headerName: 'Percentage P',
      flex: 1,
    },
    {
      field: 'percentage_active_ingredient_potassium',
      headerName: 'Percentage K',
      flex: 1,
    },
    {
      field: 'application_rate_unit',
      headerName: 'Application rate',
      flex: 1,
    }
  ]

  return (
    <Grid col="12" sx={{ width: '100%' }}>
      <Tabs
        value={curTab}
        onChange={handleTabChange}
        style={{ marginBottom: 10 }}
      >
        <Tab label='Add Fertiliser' />
      </Tabs>

      <Box sx={{ width: '100%', height: '100%' }}>
        <AddItem
          formFields={formFields}
          initialState={initialState}
          onFormSubmit={handleFormSubmit}
          items={items}
          addTitle='Add New Fertiliser'
        />
        <FormControl fullWidth sx={{ mb: '1rem' }}>
          <InputLabel>Listed Fertilisers</InputLabel>
          <Select
            name='listedFertiliser'
            value={selectedListedFertiliser}
            onChange={handleListedFertiliserChange}
            label={'Listed Fertilisers'}
          >
            {loading ? <>loading</> : fertilizers.map((f) => {
              return (
                <MenuItem key={f._id} value={f._id}>
                  {f.product_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button sx={{ m: '1rem 0' }} variant='contained' color={fertilizers?.filter((f) => f.is_active).map((f) => f._id).includes(selectedListedFertiliser) ? 'warning' : 'primary'} onClick={handleAddFertilizerToList}>
          {fertilizers?.filter((f) => f.is_active).map((f) => f._id).includes(selectedListedFertiliser) ? 'Remove Fertilizer from list' : 'Add Fertiliser to list'}
        </Button>
        {loading ? <h1>loading</h1> :
          <Grid col='12' sx={{ height: '100%' }}>
            {/* <DataGrid
                rows={fertilizers && fertilizers?.length > 0 ? fertilizers?.filter((f) => f.is_active) : []}
                getRowId={row => row._id}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{ Toolbar: GridToolbar }}
                sx={{ height: '100%' }}
              /> */}
            <TableContainer component={Paper} sx={{ height: 'auto' }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.field} align="center">
                        {column.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fertilizers && fertilizers?.length > 0 ? fertilizers?.filter((f) => f.is_active).map((row) => (
                    <TableRow
                      key={row._id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {columns.map((column) => (
                        <TableCell key={column.field} align="center">
                          {row[column.field]}
                        </TableCell>
                      ))}
                    </TableRow>
                  )) : null}

                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        }
      </Box>
    </Grid>
  );
}
