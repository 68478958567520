import { useEffect } from 'react';
import { Box, TextField, Typography } from '@mui/material';

export default function BF000ProjectDescription({ data, setData, setFormId }) {
  useEffect(() => {
    setFormId('BF000');
  }, [setFormId]);

  const items = [
    {
      id: 0,
      name: 'description',
      question: 'Please provide a short paragraph description of this project',
      value: data?.description,
      multiline: true,
    },
    {
      id: 1,
      name: 'projectLocation',
      question: 'Where is the project taking place?',
      value: data?.projectLocation,
    },
    {
      id: 2,
      name: 'projectStartTime',
      question:
        'When is the project starting and what is the predicted time frame for the activities?',
      value: data?.projectStartTime,
    },
  ];

  return (
    <>
      <Typography variant='h5' sx={{ mb: 2 }}>
        Project Description
      </Typography>
      {items.map(item => {
        return (
          <Box key={item.id}>
            <Typography variant='h6' component='p' sx={{ mt: 2 }}>
              {item.question}
            </Typography>
            <TextField
              name={item.name}
              value={item.value}
              onChange={setData}
              multiline={item.multiline}
              rows={5}
              fullWidth
            />
          </Box>
        );
      })}
    </>
  );
}
