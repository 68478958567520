import {
  FORM_DATA_SUCCESS,
  FORM_DATA_FAIL,
  FIREBREAKS_SUCCESS,
  FIREBREAKS_FAIL,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAIL,
  PROJECTS_SUCCESS,
  PROJECTS_FAIL,
  UPDATE_PROJECT_NICKNAME_SUCCESS,
  UPDATE_PROJECT_NICKNAME_FAIL,
  FETCH_BATCH_MEDIA_SUCCESS,
  FETCH_BATCH_MEDIA_FAIL,
  FETCH_KILN_DIMENSIONS_SUCCESS,
  FETCH_KILN_DIMENSIONS_FAIL,
  FETCH_BATCH_DATA_SUCCESS,
  FETCH_BATCH_DATA_FAIL,
  CREATE_FORM_DATA_SUCCESS,
  CREATE_FORM_DATA_FAIL,
  FETCH_ADDITIONAL_BATCH_DATA_SUCCESS,
  FETCH_ADDITIONAL_BATCH_DATA_FAIL,
  FETCH_CHARGING_DATA_SUCCESS,
  FETCH_CHARGING_DATA_FAIL,
  FETCH_APPLICATION_DATA_SUCCESS,
  FETCH_APPLICATION_DATA_FAIL,
  API_FETCH_FEEDSTOCK_VERIFICATION_DATA_SUCCESS,
  API_FETCH_FEEDSTOCK_VERIFICATION_DATA_FAIL,
  API_VERIFY_BIOCHAR_DATA_SUCCESS,
  API_VERIFY_BIOCHAR_DATA_FAIL,
  API_FETCH_FLAMING_COMBUSTION_DATA_SUCCESS,
  API_FETCH_FLAMING_COMBUSTION_DATA_FAIL,
  API_FETCH_BIOCHAR_MEASUREMENT_DATA_SUCCESS,
  API_FETCH_BULK_DENSITY_DATA_SUCCESS,
  API_FETCH_BULK_DENSITY_DATA_FAIL,
  API_FETCH_UNBURNT_VOLUME_SUCCESS,
  ADD_BIOCHAR_ASSET_SUCCESS,
  ADD_BIOCHAR_ASSET_FAIL,
  GET_BIOCHAR_ASSETS_SUCCESS,
  GET_BIOCHAR_ASSETS_FAIL,
  POST_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_FAIL,
  GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_FAIL,
  GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_TOTALS_FAIL,
  POST_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_FAIL,
  GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_FAIL,
  GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_TOTALS_FAIL,
  GET_HIGHEST_BIOCHAR_ID_FAIL,
  POST_LPG_BIOCHAR_DATA_FAIL,
  GET_LPG_BIOCHAR_DATA_FAIL,
  GET_LPG_BIOCHAR_DATA_TOTALS_FAIL,
  POST_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_SUCCESS,
  GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_SUCCESS,
  GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_TOTALS_SUCCESS,
  GET_HIGHEST_BIOCHAR_ID_SUCCESS,
  POST_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_SUCCESS,
  GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_SUCCESS,
  GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_TOTALS_SUCCESS,
  POST_LPG_BIOCHAR_DATA_SUCCESS,
  GET_LPG_BIOCHAR_DATA_SUCCESS,
  GET_LPG_BIOCHAR_DATA_TOTALS_SUCCESS,
  BIOCHAR_FILE_UPLOAD_FAIL,
  BIOCHAR_FILE_UPLOAD_SUCCESS,
  SET_MEASUREMENT_TYPE_FAIL,
  SET_MEASUREMENT_TYPE_SUCCESS,
  FETCH_DATA_TABLES_DATA_SUCCESS,
  FETCH_DATA_TABLES_DATA_FAIL,
  POST_BATCH_CCIK_FAIL,
  POST_BATCH_CCIK_SUCCESS,
  POST_LAB_REPORT_FILE_UPLOAD_FAIL,
  POST_LAB_REPORT_FILE_UPLOAD_SUCCESS,
  POST_BIOCHAR_STORED_CARBON_SUCCESS,
  POST_BIOCHAR_STORED_CARBON_FAIL,
  POST_BATCH_VOLUMES_AND_MEDIA_PDF_SUCCESS,
  POST_BATCH_VOLUMES_AND_MEDIA_PDF_FAIL,
  FETCH_NOTES_FAIL,
  FETCH_NOTES_SUCCESS,
} from '../actions/types';

const initialState = {
  loading: true,
  firebreaks: [],
  formdata: [],
  projects: [],
  batchMedia: [],
  batchData: [],
  kilnDimensions: [],
  additionalBatchData: [],
  chargingData: [],
  applicationData: [],
  feedstockVerificationData: [],
  flamingCombustionVerificationData: [],
  biocharMeasurementVerificationData: [],
  bulkDensityVerificationData: [],
  unburntVolumeVerificationData: [],
  biocharAssets: [],
  biocharDieselData: [],
  biocharDieselDataTotals: [],
  biocharPetrolData: [],
  biocharPetrolDataTotals: [],
  biocharLpgData: [],
  biocharLpgDataTotals: [],
  dataTablesData: [],
  biocharHighestId: -1,
  measurementType: '',
  notes: [],
};

export const postFormData = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_KILN_DIMENSIONS_SUCCESS:
      return {
        ...state,
        kilnDimensions: payload,
        loading: false,
      };
    case FETCH_CHARGING_DATA_SUCCESS:
      return {
        ...state,
        chargingData: payload.biochar_charging,
        id: payload.id,
        loading: false,
      };
    case FETCH_APPLICATION_DATA_SUCCESS:
      return {
        ...state,
        applicationData: payload,
        loading: false,
      };
    case FETCH_BATCH_MEDIA_SUCCESS:
      return {
        ...state,
        batchMedia: payload,
        loading: false,
      };
    case FETCH_BATCH_DATA_SUCCESS:
      return {
        ...state,
        batchData: payload,
        loading: false,
      };
    case FETCH_ADDITIONAL_BATCH_DATA_SUCCESS:
      return {
        ...state,
        additionalBatchData: payload,
        loading: false,
      };

    case FORM_DATA_SUCCESS:
      return {
        ...state,
        formdata: payload,
        loading: false,
      };

    case FIREBREAKS_SUCCESS:
      return {
        ...state,
        firebreaks: payload,
        loading: false,
      };

    case PROJECTS_SUCCESS:
    case UPDATE_PROJECT_NICKNAME_SUCCESS:
      return {
        ...state,
        projects: payload,
        loading: false,
      };

    case API_FETCH_FEEDSTOCK_VERIFICATION_DATA_SUCCESS:
      return {
        ...state,
        feedstockVerificationData: payload,
        loading: false,
      };

    case API_FETCH_FLAMING_COMBUSTION_DATA_SUCCESS:
      return {
        ...state,
        flamingCombustionVerificationData: payload,
        loading: false,
      };
    case API_FETCH_BIOCHAR_MEASUREMENT_DATA_SUCCESS:
      return {
        ...state,
        biocharMeasurementVerificationData: payload,
        loading: false,
      };
    case API_FETCH_BULK_DENSITY_DATA_SUCCESS:
      return {
        ...state,
        bulkDensityVerificationData: payload,
        loading: false,
      };
    case API_FETCH_UNBURNT_VOLUME_SUCCESS:
      return {
        ...state,
        unburntVolumeVerificationData: payload,
        loading: false,
      };
    case ADD_BIOCHAR_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_BIOCHAR_ASSETS_SUCCESS:
      return {
        ...state,
        biocharAssets: payload,
        loading: false,
      };
    case POST_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_SUCCESS:
      return {
        ...state,
        biocharDieselData: payload,
        loading: false,
      };
    case GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_TOTALS_SUCCESS:
      return {
        ...state,
        biocharDieselDataTotals: payload,
        loading: false,
      };
    case POST_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_SUCCESS:
      return {
        ...state,
        biocharPetrolData: payload,
        loading: false,
      };
    case GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_TOTALS_SUCCESS:
      return {
        ...state,
        biocharPetrolDataTotals: payload,
        loading: false,
      };
    case GET_HIGHEST_BIOCHAR_ID_SUCCESS:
      return {
        ...state,
        biocharHighestId: payload,
        loading: false,
      };
    //
    case POST_LPG_BIOCHAR_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_LPG_BIOCHAR_DATA_SUCCESS:
      return {
        ...state,
        biocharLpgData: payload,
        loading: false,
      };
    case GET_LPG_BIOCHAR_DATA_TOTALS_SUCCESS:
      return {
        ...state,
        biocharLpgDataTotals: payload,
        loading: false,
      };
    case BIOCHAR_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SET_MEASUREMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        measurementType: payload,
      };
    case FETCH_DATA_TABLES_DATA_SUCCESS:
      return {
        ...state,
        dataTablesData: payload,
        loading: false,
      };
    case FETCH_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        notes: payload,
      };
    case POST_BATCH_CCIK_SUCCESS:
    case POST_LAB_REPORT_FILE_UPLOAD_SUCCESS:
    case POST_BIOCHAR_STORED_CARBON_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case POST_BATCH_VOLUMES_AND_MEDIA_PDF_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case POST_BATCH_VOLUMES_AND_MEDIA_PDF_FAIL:
    case FETCH_NOTES_FAIL:
    case POST_BIOCHAR_STORED_CARBON_FAIL:
    case POST_LAB_REPORT_FILE_UPLOAD_FAIL:
    case POST_BATCH_CCIK_FAIL:
    case FETCH_DATA_TABLES_DATA_FAIL:
    case FETCH_KILN_DIMENSIONS_FAIL:
    case FETCH_CHARGING_DATA_FAIL:
    case FETCH_APPLICATION_DATA_FAIL:
    case FETCH_BATCH_MEDIA_FAIL:
    case FETCH_BATCH_DATA_FAIL:
    case FETCH_ADDITIONAL_BATCH_DATA_FAIL:
    case CREATE_FORM_DATA_SUCCESS:
    case CREATE_FORM_DATA_FAIL:
    case FORM_DATA_FAIL:
    case FILE_UPLOAD_SUCCESS:
    case FILE_UPLOAD_FAIL:
    case FIREBREAKS_FAIL:
    case PROJECTS_FAIL:
    case UPDATE_PROJECT_NICKNAME_FAIL:
    case API_FETCH_FEEDSTOCK_VERIFICATION_DATA_FAIL:
    case API_FETCH_FLAMING_COMBUSTION_DATA_FAIL:
    case API_VERIFY_BIOCHAR_DATA_SUCCESS:
    case API_VERIFY_BIOCHAR_DATA_FAIL:
    case API_FETCH_BULK_DENSITY_DATA_FAIL:
    case ADD_BIOCHAR_ASSET_FAIL:
    case GET_BIOCHAR_ASSETS_FAIL:
    case POST_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_FAIL:
    case GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_FAIL:
    case GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_TOTALS_FAIL:
    case POST_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_FAIL:
    case GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_FAIL:
    case GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_TOTALS_FAIL:
    case GET_HIGHEST_BIOCHAR_ID_FAIL:
    case POST_LPG_BIOCHAR_DATA_FAIL:
    case GET_LPG_BIOCHAR_DATA_FAIL:
    case GET_LPG_BIOCHAR_DATA_TOTALS_FAIL:
    case BIOCHAR_FILE_UPLOAD_FAIL:
    case SET_MEASUREMENT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
