import React, { useState } from 'react'
import { Grid, Button, Tabs, Tab } from '@mui/material';
import { useSelector } from 'react-redux';
import DieselDataCapturingStationary from '../types/data-tables/fuel/DieselDataCapturingStationary';
import DieselDataCapturingMobile from '../types/data-tables/fuel/DieselDataCapturingMobile';
import PetrolDataCapturingStationary from '../types/data-tables/fuel/PetrolDataCapturingStationary';
import { PetrolDataCapturingMobile } from '../types/data-tables/fuel/PetrolDataCapturingMobile';
import LPGDataCapturing from '../types/data-tables/fuel/LPGDataCapturing';

const FuelTables = ({ active, next, projectKey }) => {

    const { userData } = useSelector(state => state.auth);
    const dieselItems = [
        {
            id: 0,
            name: 'Stationary',
        },
        {
            id: 1,
            name: 'Mobile',
        }
    ];

    const petrolItems = [
        {
            id: 0,
            name: 'Stationary',
        },
        {
            id: 1,
            name: 'Mobile',
        }
    ];

    const lpgItems = [
        {
            id: 0,
            name: 'Liquid Petroleum Gas',
        }
    ];

    const [fuelValue, setFuelValue] = useState(0);
    const handleFuelChange = (event, index) => {
        setFuelValue(index);
    };

    return (
        <>
            <Grid item sx={{ mb: 2, display: 'flex', width: '100%' }}>
                <Tabs value={fuelValue} onChange={handleFuelChange} style={{ marginBottom: 10 }}>
                    <Tab label="Diesel" />
                    <Tab label="Petrol" />
                    <Tab label="Liquid Petroleum Gas" />
                </Tabs>
            </Grid>
            <Grid item xs={2} sx={{ borderRight: '1px solid', p: 2 }}>
                {fuelValue === 0 ? dieselItems.map(item => (
                    <Button
                        fullWidth
                        key={item.id}
                        onClick={() => next(item.id)}
                        variant={active === item.id ? 'outlined' : null}
                    >
                        {item.name}
                    </Button>
                )) : fuelValue === 1 ? petrolItems.map(item => (
                    <Button
                        fullWidth
                        key={item.id}
                        onClick={() => next(item.id)}
                        variant={active === item.id ? 'outlined' : null}
                    >
                        {item.name}
                    </Button>)) : lpgItems.map(item => (
                        <Button
                            fullWidth
                            key={item.id}
                            onClick={() => next(item.id)}
                            variant={active === item.id ? 'outlined' : null}
                        >
                            {item.name}
                        </Button>))}
            </Grid>
            <Grid item xs={10} sx={{ p: 2 }}>
                {/* {fuelValue === 0 && active === 0 ? <DieselDataCapturingStationary /> : fuelValue === 0 && active === 1 ? <DieselDataCapturingMobile /> : null} */}
                {fuelValue === 0 && active === 0 ? <DieselDataCapturingStationary farm={userData.farm[0]._id} project={projectKey} /> : fuelValue === 0 && active === 1 ? <DieselDataCapturingMobile farm={userData.farm[0]._id} project={projectKey} /> : null}
                {fuelValue === 1 && active === 0 ? <PetrolDataCapturingStationary farm={userData.farm[0]._id} project={projectKey} /> : fuelValue === 1 && active === 1 ? <PetrolDataCapturingMobile farm={userData.farm[0]._id} project={projectKey} /> : null}
                {fuelValue === 2 && active === 0 ? <LPGDataCapturing farm={userData.farm[0]._id} project={projectKey} /> : null}
            </Grid>
        </>
    )
}

export default FuelTables