import { useContext, useEffect } from 'react';
import TileLayer from 'ol/layer/Tile';
import MapContext from '../../context/MapContext';

const OlTileLayer = ({ source, zIndex, opacity, name }) => {
  const { map } = useContext(MapContext);
  useEffect(() => {
    if (!map) return;

    let tLayer = new TileLayer({
      source,
      zIndex,
      opacity,
      name,
    });
    map.addLayer(tLayer);
    return () => map.removeLayer(tLayer);
  });
  return null;
};

export default OlTileLayer;
