import { useEffect } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import tooltips from '../../../../../../store/tooltips';
import InfoIcon from '@mui/icons-material/Info';

export default function BF003IABEquipment({ data, setData, setFormId }) {
  useEffect(() => {
    setFormId('BF003');
  }, [setFormId]);

  const { productionMethod } = data;

  const roles = [
    {
      id: 0,
      type: 'I am a contractor',
    },
    {
      id: 1,
      type: 'Hiring a contractor',
    },
    {
      id: 2,
      type: 'In-farm production',
    },
  ];

  const items = [
    {
      id: 0,
      name: 'ppeAvailable',
      question: 'Do you have PPE available on site?',
      tooltip: tooltips.equipmentForIABQ2,
    },
    {
      id: 1,
      name: 'kilnsAvailable',
      question: 'Do you have kilns?',
      tooltip: tooltips.equipmentForIABQ3,
    },
    {
      id: 2,
      name: 'buyKiln',
      question: 'If No, Are you planning to buy a kiln?',
      tooltip: tooltips.equipmentForIABQ4,
    },
  ];

  return (
    <>
      <Typography variant='h5' sx={{ mb: 2 }}>
        Equipment for Biochar Burns
        <Tooltip
          title={
            <h2 style={{ color: 'lightgreen' }}>
              {tooltips.equipmentForIABQ1}
            </h2>
          }
        >
          {<InfoIcon sx={{ width: 15 }} />}
        </Tooltip>
      </Typography>
      <FormControl
        sx={{
          mt: 1,
        }}
        fullWidth
      >
        <InputLabel sx={{ background: 'white', pl: 1, pr: 1 }}>
          How will you be producing biochar?
        </InputLabel>
        <Select
          name='productionMethod'
          value={productionMethod}
          onChange={setData}
        >
          {roles.map(role => {
            return (
              <MenuItem key={role.id} value={role.type}>
                {role.type}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {(productionMethod === 'I am a contractor' ||
        productionMethod === 'In-farm production') &&
        items.map(item => {
          return (
            <Box key={item.id}>
              <Typography variant='h6' component='p' sx={{ mt: 2 }}>
                {item.question}
                <Tooltip
                  title={
                    <h2 style={{ color: 'lightgreen' }}>{item.tooltip}</h2>
                  }
                >
                  {<InfoIcon sx={{ width: 15 }} />}
                </Tooltip>
              </Typography>
              <RadioGroup row name={item.name} onChange={setData}>
                <FormControlLabel
                  value='yes'
                  control={<Radio />}
                  label='Yes'
                  name={item.name}
                />
                <FormControlLabel
                  value='no'
                  control={<Radio />}
                  label='No'
                  name={item.name}
                />
              </RadioGroup>
            </Box>
          );
        })}
    </>
  );
}
