import { connect } from 'react-redux';
import { getApplicationData } from '../../../../../../actions/forms';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
    Box, Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell, Grid, Button
} from '@mui/material';
import { UPLOADED_DOCS } from '../../../../../../actions/constants';
const Application = ({ projectKey, next, active, applicationData }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getApplicationData(projectKey));
    }, [dispatch, projectKey]);

    const items = [
        {
            id: 0,
            name: 'Biochar Application',
        },
        {
            id: 1,
            name: 'Project Media',
        }
    ];


    return (
        <Grid container>
            <Grid item xs={3} sx={{ borderRight: '1px solid', p: 2 }}>
                {items.map(item => (
                    <Button
                        fullWidth
                        key={item.id}
                        onClick={() => next(item.id)}
                        variant={active === item.id ? 'outlined' : null}
                    >
                        {item.name}
                    </Button>
                ))}
            </Grid>

            <Grid item xs={9} sx={{ p: 2 }}>
                <h3>Biochar Application Data</h3>
                {applicationData.map((ad, index) => {
                    return (
                        <>

                            <TableContainer component={Paper} sx={{ height: 'auto' }} key={index}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                Description
                                            </TableCell>
                                            <TableCell align="left">
                                                Value
                                            </TableCell>
                                            <TableCell align="left">
                                                Unit
                                            </TableCell>
                                            <TableCell align="left">
                                                Visual Proof
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* <TableRow>
                                        <TableCell align="left">
                                            Batch being applied
                                        </TableCell>
                                        <TableCell align="left">
                                            <b>{ad?.batchId ? <>{ad?.batchId}</> : null}</b>
                                        </TableCell>
                                        <TableCell align="left">
                                            N/A
                                        </TableCell>
                                        <TableCell align="left">
                                            N/A
                                        </TableCell>
                                    </TableRow> */}
                                        <TableRow>
                                            <TableCell align="left">
                                                Location
                                            </TableCell>
                                            <TableCell align="left">
                                                {ad?.volLatitude ? <>{ad?.volLatitude} / {ad?.volLongitude}</> : null}
                                            </TableCell>
                                            <TableCell align="left">
                                                Lat, Long
                                            </TableCell>
                                            <TableCell align="left">
                                                N/A
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left">
                                                Application Type
                                            </TableCell>
                                            <TableCell align="left">
                                                {ad?.applicationType ? <>{ad?.applicationType}</> : null}
                                            </TableCell>
                                            <TableCell align="left">
                                                N/A
                                            </TableCell>
                                            <TableCell align="left">
                                                N/A
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left">
                                                Last place biochar was tracked
                                            </TableCell>
                                            <TableCell align="left">
                                                {ad?.biocharLastPlaceTracked ? <>{ad?.biocharLastPlaceTracked}</> : null}
                                            </TableCell>
                                            <TableCell align="left">
                                                N/A
                                            </TableCell>
                                            <TableCell align="left">
                                                N/A
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left">
                                                Amount applied
                                            </TableCell>
                                            <TableCell align="left">
                                                {ad?.biocharApplied ? <>{ad?.biocharApplied}</> : null}
                                            </TableCell>
                                            <TableCell align="left">
                                                N/A
                                            </TableCell>
                                            <TableCell align="left">
                                                N/A
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', mt: 3 }}>
                                {/* {ad?.volumePhoto ? <h4>Biochar Application Media</h4> : null}
                            {ad?.volumePhoto ? <img height="300" width="300" src={`https://ikhala-static-content1.s3.amazonaws.com/uploaded_docs/${ad?.volumePhoto?.split("/").pop()}`} alt="url" /> : null} */}
                                {ad?.appliedPhotoURL ? <h4>Biochar Application Media</h4> : null}
                                {ad?.appliedPhotoURL ? <img height="300" width="300" src={`${UPLOADED_DOCS}${ad?.appliedPhotoURL?.split("/").pop()}`} alt="url" /> : null}
                            </Box>
                        </>
                    )
                })}
            </Grid>
        </Grid>

    )
}

const mapStateToProps = state => ({
    applicationData: state.postFormData.applicationData,
    loading: state.postFormData.loading,
});

const mapDispatchToProps = dispatch => {
    return {
        getApplicationData: projectKey => dispatch(getApplicationData(projectKey))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Application);