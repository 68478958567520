import { Box, Grid, Typography } from '@mui/material';

const About = () => {
    return (
        <Grid container id='kiln'>
            <Grid
                lg={12}
                item
                display={'flex'}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        flexDirection: {
                            xs: 'column',
                            sm: 'column',
                            md: 'column',
                            lg: 'row',
                            xl: 'row',
                        },
                        height: '100%'
                    }}
                >
                    <Box
                        id='kiln__image'
                        sx={{ width: { xs: '100%', lg: '50%' }, height: '650px', minHeight: '650px' }}
                    >
                    </Box>
                    <Box
                        display={'flex'}
                        sx={{
                            // pl: { xs: '0px', sm: '0px', md: '64px', lg: '64px', xl: '64px' },
                            // pr: '99px',
                            background: 'linear-gradient(to bottom, #212746, #3e4877)',
                            color: 'white',
                            width: { lg: '50%' },
                            height: '100%',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            maxHeight: { lg: '650px' }
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '36px',
                                fontWeight: '600',
                                fontStyle: 'normal',
                                lineHeight: '44px',
                                letterSpacing: '-0.72px',
                                // ml: '80px',
                                // color: '#DA9DE3',
                                display: 'inline-block',
                                pt: '5%',
                                pl: '5%',
                                pr: '5%',
                            }}
                            id="kiln__heading"
                        >
                            About Ikhala
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontWeight: '400',
                                fontStyle: 'normal',
                                lineHeight: '28px',
                                // mt: '40px',
                                // mr: '20%',
                                // ml: '80px',
                                p: '5%'
                            }}
                        >
                            Ikhala provides a comprehensive Digital-MMRV solution for climate change mitigation. Our platform features predefined 'programs' that provide projects with an end-to-end solution to monitor and verify their positive impact. Our Biochar program, streamlines the entire biochar production process and automates carbon removal calculations resulting from this process. This aids farmers, landowners, and contractors in showcasing their contributions towards climate action through biochar production from “vegetation and fuel load management activities”. What sets us apart is our platform's customizability, enabling projects to craft a tailored MRV policy, whether adhering to existing standards or defining their own. In addition, Ikhala simplifies project lifecycle management by offering a centralized data hub for all project information. It facilitates automated reporting and focuses on the collection of the optimal amount of data to verify the impact of a project.
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default About;
