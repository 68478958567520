import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  // Card,
  // CardActions,
  // CardContent,
  // Alert,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  // IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { connect } from 'react-redux';
import { createProject, getProjects } from '../../../../actions/forms';
import CBR from './CBR';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';

const Projects = ({ createProject, getProjects, projects, loading, quickLinks }) => {
  const [open, setOpen] = useState(false);
  const [programmeId, setProgrammeId] = useState(1);
  const [projectId, setProjectId] = useState(null);
  const [projectKey, setProjectKey] = useState(null);
  const [projectMeasurementType, setProjectMeasureType] = useState('')
  const [nickname, setNickname] = useState('');
  const [hide, setHide] = useState(false);

  useEffect(() => {
    getProjects();
    if (quickLinks) {
      // console.log(quickLinks, 'quickLinks')
      setProjectId(quickLinks.projectId)
      setProjectKey(quickLinks.projectKey)
      setNickname('wow')
    }
  }, [getProjects, quickLinks]);

  const handleSubmit = () => {
    setOpen(false);
    createProject({ programmeId, nickname });
  };

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => setHide(!hide)}
        >
          <Typography>{hide ? "Hide" : "Expand"} Projects</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {/* <Alert severity='info'>Select an existing project below or add a new project</Alert> */}
            <Button
              variant='outlined'
              size='medium'
              sx={{ mt: 3, mb: 3, height: '3.4em', width: '15.5rem' }}
              onClick={() => setOpen(true)}
            ><AddIcon></AddIcon>Add New Biochar Project</Button>
            {loading ? (
              <CircularProgress />
            ) : (
              <Grid container>
                <Typography variant='h5' component='h4' sx={{ mb: 3 }}>
                  Biochar Production Projects
                </Typography>
                <Grid xs={12} display="flex" alignContent={'space-between'} flexWrap={'wrap'} mb={'1rem'}>
                  {projects?.map((project, index) => (
                    // <Grid key={project._id} item xs={3}>
                    //   <Card
                    //     variant='outlined'
                    //     sx={{
                    //       textAlign: 'center',
                    //       height: '8.5em',
                    //       bgcolor: projectId === project.projectId && '#eee',
                    //     }}
                    //   >
                    //     <CardContent>
                    //       <Typography variant='h5'>{project.projectId}</Typography>
                    //     </CardContent>
                    //     <CardActions sx={{ justifyContent: 'center' }}>
                    //       <Button
                    //         variant='outlined'
                    //         size='small'
                    //         sx={{ mt: 1 }}
                    //         onClick={() => {
                    //           setProjectId(project.projectId);
                    //           setNickname(project.nickname);
                    //           setProjectKey(project._id)
                    //           setProjectMeasureType(project.measurement_type)
                    //         }}
                    //       >
                    //         Open
                    //       </Button>
                    //     </CardActions>
                    //   </Card>
                    // </Grid>

                    <Box className="biochar-project" key={index} sx={projectId === project.projectId && { background: 'linear-gradient(to bottom right, #535988, #313551)', color: '#FFF', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                      onClick={() => {
                        setProjectId(project.projectId);
                        setNickname(project.nickname);
                        setProjectKey(project._id)
                        setProjectMeasureType(project.measurement_type)
                      }}>
                      <Typography sx={{ fontSize: '18px' }}>{projectId === project.projectId ? <BookmarkOutlinedIcon sx={{ mb: '-7px' }} /> : <BookmarkBorderOutlinedIcon sx={{ mb: '-7px' }} />} {project.projectId}</Typography>
                      <Typography sx={project.nickname ? { fontSize: '14px' } : { visibility: 'hidden' }} mt={3}><strong>Nickname:</strong> {project.nickname}</Typography>
                      <Typography sx={{ fontSize: '14px' }}><strong>Date Created:</strong> {project.created_at.split('T')[0]}</Typography>
                    </Box>

                  ))}
                </Grid>
                <Grid xs={12} >

                  {/* <Card variant='outlined'>
                  <CardContent sx={{ textAlign: 'center' }}>
                    <Typography variant='h5'>Add Project</Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'center' }}>
                    <IconButton onClick={() => setOpen(true)}>
                      <AddIcon fontSize='large' />
                    </IconButton>
                  </CardActions>
                </Card> */}
                </Grid>
              </Grid>
            )}
            <Dialog open={open} onClose={() => setOpen(false)}>
              <DialogTitle>Available Programs</DialogTitle>
              <DialogContent>
                <DialogContentText>Please select Program</DialogContentText>
                <FormControl
                  sx={{
                    mt: 1,
                  }}
                  fullWidth
                >
                  <InputLabel>Program</InputLabel>
                  <Select
                    name='feedstockType'
                    value={programmeId}
                    onChange={e => setProgrammeId(e.target.value)}
                  >
                    <MenuItem value={1}>Place based biochar production, leaving on site</MenuItem>
                    <MenuItem value={2}>Place based biochar production, transport off site for soil use</MenuItem>
                  </Select>
                </FormControl>
                <Divider sx={{ my: 2 }} />
                <Typography>Add Project Nickname</Typography>
                <TextField
                  name='nickname'
                  value={nickname}
                  onChange={e => setNickname(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button variant='outlined' onClick={() => setOpen(false)}>
                  Cancel
                </Button>
                <Button variant='contained' onClick={handleSubmit}>
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </AccordionDetails>
      </Accordion>
      {projectId && <CBR projectKey={projectKey} projectId={projectId} nickname={nickname} measureType={projectMeasurementType} />}
    </>
  );
};

const mapStateToProps = state => ({
  projects: state.postFormData.projects,
  loading: state.postFormData.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    createProject: data => dispatch(createProject(data)),
    getProjects: () => dispatch(getProjects()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
