
import {
    Box, Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell
} from '@mui/material';


export function DieselTotalTable ({ dieselDataTotals } ) {

    return (
        <Box>
            <TableContainer component={Paper} sx={{ height: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                Month
                            </TableCell>
                            <TableCell align="left">
                                Liter Value
                            </TableCell>
                            <TableCell align="left">
                                Rand To Liter Conversion
                            </TableCell>
                            <TableCell align="left">
                                Total Liter
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {months.slice(0, 12).map((m) => { */}
                            {dieselDataTotals?.map((data, i) => {
                                if (!Object.keys(data).includes('grand_total')) {
                                    return (
                                        <TableRow key={i}>
                                        <TableCell align="left">
                                        {data.month === 'Grand Total' ? <b>Grand Total</b> : data.month}
                                        </TableCell>
                                        <TableCell align="left">
                                        {data.litresQuantity}
                                        </TableCell>
                                        <TableCell align="left">
                                        {data.randQuantity}
                                        </TableCell>
                                        <TableCell align="left">
                                        {data.total}
                                        </TableCell>
                                    </TableRow>
                            )
                        }
                        })}
                        <TableRow sx={{border: '2px solid black'}}>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"><b>Grand Total</b></TableCell>
                            <TableCell align="left"><b>{dieselDataTotals[12].grand_total}</b></TableCell>
                        </TableRow>
                        {/* {data.map((d, i) => {
                            return (
                                <TableRow >
                                    <TableCell align="left">
                                        {d.category}
                                    </TableCell>
                                    <TableCell align="left">
                                        {d.unit}
                                    </TableCell>
                                    <TableCell align="left">
                                        {d.quantity}
                                    </TableCell>
                                </TableRow>
                            )
                        })} */}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

// const mapStateToProps = state => ({
//     dieselDataTotals: state.directGhgEmissionsAndRemovalsForDiesel.dieselDataTotals.data,
// });

// const mapDispatchToProps = dispatch => {
//     return {
//         dieselDataTotals: (type) => dispatch(getStationaryDieselDataTotals('stationary'))
//     };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(DieselTotalTable);

// const mapStateToProps = state => ({
//     onFarmCompost: state.carbonfootprint.onFarmCompost,
//     loading: state.carbonfootprint.loading,
//   });
  
//   const mapDispatchToProps = dispatch => {
//     return {
//       getOnFarmCompost: (farmId, year) => dispatch(getOnFarmCompost(farmId, year))
//     };
//   };
  
//   export default connect(mapStateToProps, mapDispatchToProps)(OnFarmCompost);