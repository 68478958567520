import { useState } from 'react';
import {
  Box,
  CssBaseline,
  Divider,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { theme } from './theme';
import { styled } from '@mui/material/styles';
import { DashboardNavbar } from './partials/DashboardNavbar';
import { DashboardSidebar } from './partials/DashboardSidebar';
import { useParams } from 'react-router-dom';
import Dashboard from './screens/Dashboard';
// import FormData from './screens/FormData';
import Account from './screens/Account';
import Settings from './screens/Settings';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280,
  },
}));

export const Screens = ({ setTitle }) => {
  const { screen } = useParams();
  switch (screen) {
    case 'form-data':
      setTitle('Form Data');
      // return <FormData />;
      return <></>
    case 'account':
      setTitle('Account');
      return <Account />;
    case 'settings':
      setTitle('Settings');
      return <Settings />;
    default:
      setTitle('Dashboard');
      return <Dashboard />;
  }
};

export default function DashboardLayout() {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [title, setTitle] = useState('');

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Typography variant='h3' sx={{ p: 1 }}>
            {title}
          </Typography>
          <Divider />
          <Screens setTitle={setTitle} />
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)} />
      <DashboardSidebar
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
      />
    </ThemeProvider>
  );
}
