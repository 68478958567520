import React, { useState, useEffect } from 'react'
import { Alert, Box, TextField, Button, TableContainer, Table, TableHead, TableCell, TableRow, Paper, TableBody, Select, MenuItem, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getFeedstockTransport, postFeedstockTransport } from '../../../../../actions/tigercat-burns/tigercat-actions';

const FeedstockTransport = ({ projectId }) => {
    const [feedstockTransportState, setFeedstockTransportState] = useState([])
    const dispatch = useDispatch()
    const feedstockTransports = useSelector(state => state.tigercatBurnsReducer.feedstockTransports.data)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        dispatch(getFeedstockTransport(projectId));
    }, [dispatch, projectId]);

    useEffect(() => {
        const formDataArray = feedstockTransports?.map((item) => {
            const formData = new FormData();
            formData.append('id', item.id);
            formData.append('feedstockType', item.feedstockType);
            formData.append('feedstockMass', item.feedstockMass);
            formData.append('distanceTravelled', item.distanceTravelled);
            formData.append('numberOfTrips', item.numberOfTrips);
            formData.append('transportType', item.transportType);
            formData.append('deliveredBy', item.deliveredBy);
            formData.append('sourceDocument', item.sourceDocumentUrl);
            formData.append('comments', item.comments);
            formData.append('disabled', true)
            return formData;
        });
        setFeedstockTransportState(formDataArray);
    }, [feedstockTransports]);

    const handleAddItem = () => {
        setFeedstockTransportState((prevState) => {
            const state = [...prevState]
            let id;
            if (state.length === 0) {
                id = null
            } else {
                id = Number(state[state.length - 1].get('id')) + 1
            }
            const formData = new FormData();
            formData.append('id', id);
            formData.append('feedstockType', '');
            formData.append('feedstockMass', '');
            formData.append('distanceTravelled', '');
            formData.append('numberOfTrips', '');
            formData.append('transportType', 'Medium- and Heavy-Duty Truck');
            formData.append('deliveredBy', '');
            formData.append('sourceDocument', null);
            formData.append('comments', '');
            state.push(formData)
            return state
        })
    }

    const handleInputChange = (e, dataId, field) => {
        setFeedstockTransportState((prevState) => {
            return prevState.map((item) => {
                if (item.get('id') === dataId) {
                    item.set(field, e.target.value);
                }
                return item;
            });
        });
    }

    const handleSourceDoc = (e, dataId) => {
        const file = e.target.files[0];
        setFeedstockTransportState((prevState) => {
            return prevState.map((item) => {
                if (item.get('id') === dataId) {
                    item.set('sourceDocument', file);
                }
                return item;
            });
        });
    }

    const transportTypes = [
        { id: 0, name: 'Medium- and Heavy-Duty Truck' }
    ]

    const handleSubmitData = () => {
        setLoading(true)

        dispatch(postFeedstockTransport(projectId, feedstockTransportState)).then(() => {
            dispatch(getFeedstockTransport(projectId))
            setLoading(false)
        })
    }

    return (
        <>
            <h2>Feedstock Transport</h2>
            <TableContainer key={0} component={Paper} >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Feedstock Type</TableCell>
                            <TableCell align="left">Mass of Feedstock (tonne)</TableCell>
                            <TableCell align="left">One-way Distance (km)</TableCell>
                            <TableCell align="left">Number of Trips</TableCell>
                            <TableCell align="left">Transport Type</TableCell>
                            <TableCell align="left">Delivered By</TableCell>
                            <TableCell align="left">Upload Source Document</TableCell>
                            <TableCell align="left">Comments</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {feedstockTransportState?.map((data, index) => {
                            const sourceDocument = data.get('sourceDocument');
                            const isString = typeof sourceDocument === 'string' && sourceDocument !== 'null';
                            return (
                                <TableRow key={index}>
                                    <TableCell align="left">
                                        <TextField
                                            name='feedstockType'
                                            value={data.get('feedstockType')}
                                            onChange={(e) => handleInputChange(e, data.get('id'), 'feedstockType')}
                                            type="text"
                                            disabled={data.get('disabled') ?? false}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            name='feedstockMass'
                                            value={data.get('feedstockMass')}
                                            onChange={(e) => handleInputChange(e, data.get('id'), 'feedstockMass')}
                                            type="number"
                                            disabled={data.get('disabled') ?? false}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            name='distanceTravelled'
                                            value={data.get('distanceTravelled')}
                                            onChange={(e) => handleInputChange(e, data.get('id'), 'distanceTravelled')}
                                            type="number"
                                            disabled={data.get('disabled') ?? false}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            name='numberOfTrips'
                                            value={data.get('numberOfTrips')}
                                            onChange={(e) => handleInputChange(e, data.get('id'), 'numberOfTrips')}
                                            type="number"
                                            disabled={data.get('disabled') ?? false}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        <Select name='transportType' value={data.get('transportType')} disabled={true} fullWidth onChange={(e) => handleInputChange(e, data.get('id'), 'transportType')}>
                                            {transportTypes?.map(type => {
                                                return (
                                                    <MenuItem key={type.id} value={type.name} >
                                                        {type.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            name='deliveredBy'
                                            value={data.get('deliveredBy')}
                                            onChange={(e) => handleInputChange(e, data.get('id'), 'deliveredBy')}
                                            type="text"
                                            disabled={data.get('disabled') ?? false}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        {isString ? (
                                            <a href={sourceDocument} target="_blank" rel="noopener noreferrer">
                                                {sourceDocument}
                                            </a>
                                        ) : (
                                            <TextField
                                                name={'sourceDocument'}
                                                defaultValue={sourceDocument && sourceDocument.name ? sourceDocument.name : ''}
                                                onChange={(e) => handleSourceDoc(e, data.get('id'))}
                                                type="file"
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            name='comments'
                                            value={data.get('comments')}
                                            onChange={(e) => handleInputChange(e, data.get('id'), 'comments')}
                                            type="text"
                                            disabled={data.get('disabled') ?? false}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ position: 'relative', mb: 10 }}>
                <Button variant='contained' sx={{ height: 'auto', mt: 2 }} onClick={handleAddItem}>Add Entry</Button>
                {loading ? <CircularProgress sx={{ position: 'absolute', left: '125px', top: '12px' }}></CircularProgress> : <Button variant='contained' sx={{ height: 'auto', mt: 2, ml: 3 }} onClick={handleSubmitData}>Submit Data</Button>}
                {loading && <Alert severity='info' sx={{ my: 2 }}>Please be patient during data submission as file uploads can take some time to complete</Alert>}
            </Box>
        </>
    )
}

export default FeedstockTransport