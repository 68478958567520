import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import wihanImage from '../../../assets/images/wihan.png';
import jpImage from '../../../assets/images/jp.png';
import lucyImage from '../../../assets/images/lucy.png';
import kelpieImage from '../../../assets/images/Kelpie.png';
import reanaImage from '../../../assets/images/Reana.png';
import cameronImage from '../../../assets/images/cameron.png';
import rossImage from '../../../assets/images/ross.png';
// import profilePlaceholder from '../../../assets/images/profile-placeholder.png';
import waleedImage from '../../../assets/images/waleed.jpeg';
import sarahImage from '../../../assets/images/sarah2.jpg';
import seanHarrisImage from '../../../assets/images/sharris.png';
import { useEffect, useRef } from 'react';

function Team() {
  const coreTeamRowOne = [
    {
      name: 'Wihan Bekker',
      occupation: 'Engineer',
      description: 'D-MRV Policy Design',
      image: wihanImage,
    },
    {
      name: 'JP du Plessis',
      occupation: 'Attorney',
      description: 'Business Development',
      image: jpImage,
    },
    {
      name: 'Lucy Gibbons',
      occupation: 'Engineer',
      description: 'LCA Lead',
      image: lucyImage,
    }
  ];
  const coreTeamRowTwo = [
    {
      name: 'Waleed Ahmad',
      occupation: 'Engineer',
      description: 'Developer',
      image: waleedImage,
    },
    {
      name: 'Sarah Muller',
      occupation: 'MSc.',
      description: 'Research & Reporting',
      // description2: '',
      image: sarahImage,
    },
    {
      name: 'Sean Harris',
      occupation: 'Manager',
      description: 'Biochar Program Manager',
      image: seanHarrisImage,
    },
  ];
  const advisorsTeam = [
    {
      name: 'Kelpie Wilson',
      description: 'Biochar Advisor & Partner',
      image: kelpieImage,
    },
    {
      name: 'Ross Gearing',
      description: 'Strategy & Finance Advisor',
      image: rossImage,
    },
    {
      name: 'Cameron Mckenzie',
      description: 'Strategy & Finance Advisor',
      image: cameronImage,
    },
    {
      name: 'Reana Rossouw',
      description: 'Social Impact Advisor & Partner',
      image: reanaImage,
    },
  ];
  const cardRefs = useRef([]);

  const setCardRef = (el) => {
    if (el && !cardRefs.current.includes(el)) {
      cardRefs.current.push(el);
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // console.log('yesss');
            entry.target.classList.add('visible');
            // Do not unobserve here if you want to re-trigger the animation
          } else {
            entry.target.classList.remove('visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    cardRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      cardRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <Grid id='team' container sx={{ height: { xs: '100%' } }}>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: '36px',
            fontWeight: '600',
            fontStyle: 'normal',
            lineHeight: '44px',
            letterSpacing: '-0.72px',
            // color: '#DA9DE3',
            display: 'inline-block',
          }}
          id="team__heading"
        >
          Core Team
        </Typography>
        <Box
          id='team__profiles-container'
        >
          {coreTeamRowOne.map((member, index) => {
            return (
              <Box ref={setCardRef} id='team__profiles-container__card' sx={{ m: 3 }} key={index} minWidth={200} maxWidth={200}>
                <Card >
                  <Box >
                    <img
                      src={member.image}
                      id='team-image'
                      alt='member'
                      // width={225}
                      style={{ width: '100%', height: 'auto' }}
                    />
                  </Box>
                  <CardContent >
                    <Typography sx={{ fontWeight: 'bold', color: 'charcoal' }}>
                      {member.name}
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', color: 'charcoal' }}>
                      {member.occupation}
                    </Typography>
                    <Typography sx={{ color: 'charcoal', fontSize: '14px' }}>
                      {member.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            );
          })}
          {coreTeamRowTwo.map((member, index) => {
            return (
              <Box sx={{ m: 3 }} key={index} minWidth={200} maxWidth={200} >
                <Card ref={setCardRef} id='team__profiles-container__card'>
                  <Box>
                    <img
                      src={member.image}
                      id='team-image'
                      alt='member'
                      // height={200}
                      style={{ width: '100%', height: '200px' }}
                    />
                  </Box>
                  <CardContent >
                    <Typography sx={{ fontWeight: 'bold', color: 'charcoal' }}>
                      {member.name}
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', color: 'charcoal' }}>
                      {member.occupation}
                    </Typography>
                    <Typography sx={{ color: 'charcoal', fontSize: '14px' }}>
                      {member.description}<br />{member.description2}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            );
          })}
          {/* <Box
            id='team__profiles-container'
            sx={{ display: 'flex', flexWrap: 'wrap' }}
          >

          </Box> */}
        </Box>
        <Typography
          sx={{
            fontSize: '36px',
            fontWeight: '600',
            fontStyle: 'normal',
            lineHeight: '44px',
            letterSpacing: '-0.72px',
            color: '#DA9DE3',
            display: 'inline-block',
          }}
          id="team__heading"

        >
          Advisors
        </Typography>
        <Box
          sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}
          id='team__profiles-container--advisors'
        >
          {advisorsTeam.map((member, index) => {
            return (
              <Box sx={{ m: 3 }} key={index} minWidth={200} maxWidth={200} >
                <Card ref={setCardRef} id='team__profiles-container__card'>
                  <Box>
                    <img
                      src={member.image}
                      id='team-image'
                      alt='member'
                      style={{ width: '100%', height: 'auto' }}
                    />
                  </Box>
                  <CardContent sx={{ minHeight: 65 }}>
                    <Typography sx={{ fontWeight: 'bold', color: 'charcoal' }}>
                      {member.name}
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', color: 'charcoal' }}>
                      {member.occupation}
                    </Typography>
                    <Typography sx={{ color: 'charcoal', fontSize: '14px' }}>
                      {member.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            );
          })}
        </Box>

      </Grid>
    </Grid>
  );
}

export default Team;
