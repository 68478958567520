export function Phone({ width = '21', height = '21' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.6484 11.2812L19.0234 13.1562C19.7266 13.4297 20.1172 14.1719 19.9609 14.9141L19.0234 19.2891C18.8672 19.9922 18.2422 20.5391 17.5 20.5391C17.2266 20.5391 16.9922 20.5 16.7578 20.5C16.6016 20.5 16.4062 20.5 16.25 20.4609C7.14844 19.8359 0 12.2578 0 3C0 2.29688 0.507812 1.63281 1.21094 1.47656L5.58594 0.539062C6.32812 0.382812 7.07031 0.773438 7.34375 1.47656L9.21875 5.85156C9.49219 6.47656 9.33594 7.21875 8.78906 7.64844L7.1875 8.97656C8.24219 10.7734 9.72656 12.2578 11.5234 13.3125L12.8516 11.7109C13.2812 11.1641 14.0234 11.0078 14.6484 11.2812ZM17.4609 19.25C17.6172 19.25 17.7344 19.1719 17.7734 19.0156L18.7109 14.6406C18.75 14.4844 18.6719 14.3672 18.5547 14.2891L14.1797 12.4141C14.0234 12.375 13.9062 12.4141 13.7891 12.4922L12.5 14.0938C12.1094 14.5625 11.4453 14.6797 10.8984 14.3672C8.90625 13.2344 7.26562 11.5938 6.13281 9.60156C5.82031 9.05469 5.9375 8.39062 6.40625 8L8.00781 6.71094C8.08594 6.59375 8.125 6.47656 8.08594 6.32031L6.21094 1.94531C6.13281 1.82812 6.01562 1.75 5.85938 1.78906L1.48438 2.72656C1.32812 2.72656 1.25 2.88281 1.25 3C1.25 11.9844 8.51562 19.25 17.4609 19.25Z'
        fill='#DA9DE3'
      />
    </svg>
  );
}
