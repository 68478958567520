import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NavItem } from '../partials/NavItem';
import PropTypes from 'prop-types';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import PersonIcon from '@mui/icons-material/Person';
import TuneIcon from '@mui/icons-material/Tune';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import InventoryIcon from '@mui/icons-material/Inventory';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

const items = [
  {
    href: '/',
    icon: <DashboardIcon fontSize='small' />,
    title: 'Dashboard',
  },
  {
    href: '/form-data',
    icon: <DocumentScannerIcon fontSize='small' />,
    title: 'Form Data',
  },
  {
    href: '/biochar-inventories',
    icon: <InventoryIcon fontSize='small' />,
    title: 'Biochar Inventory',
  },
  {
    href: '/biochar-charge-logs',
    icon: <TextSnippetIcon fontSize='small' />,
    title: 'Biochar Charge Logs',
  },
  {
    href: '/account',
    icon: <PersonIcon fontSize='small' />,
    title: 'Account',
  },
  {
    href: '/settings',
    icon: <TuneIcon fontSize='small' />,
    title: 'Settings',
  },
];

const adminItems = [
  {
    href: '/farms',
    icon: <AgricultureIcon fontSize='small' />,
    title: 'Farms',
  },
  {
    href: '/projects',
    icon: <AccountTreeIcon fontSize='small' />,
    title: 'Projects',
  },
];

export const DashboardSidebar = props => {
  const { open, onClose } = props;

  const navigate = useNavigate('/');

  const { userData } = useSelector(state => state.auth);

  const lgUp = useMediaQuery(theme => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false,
  });

  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box>
          <Box sx={{ p: 3 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                px: 3,
                py: '11px',
                borderRadius: 1,
              }}
            >
              <Button
                variant='outlined'
                sx={{ color: 'background.paper', fontWeight: 900 }}
                onClick={() => navigate('/')}
              >
                <IconButton variant='outlined'>
                  <ArrowCircleLeftIcon sx={{ fontSize: '2em' }} />
                </IconButton>
                <Typography>Go Back</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
        <Divider
          sx={{
            borderColor: '#2D3748',
            my: 3,
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          {items.map(item => (
            <NavItem
              key={item.title}
              icon={item.icon}
              href={`/dashboard${item.href}`}
              title={item.title}
            />
          ))}
          <Divider
            sx={{
              borderColor: '#2D3748',
              my: 3,
            }}
          />
          {userData?.isAdmin &&
            adminItems.map(item => (
              <NavItem
                key={item.title}
                icon={item.icon}
                href={`/dashboard${item.href}`}
                title={item.title}
              />
            ))}
        </Box>
        <Divider sx={{ borderColor: '#2D3748' }} />
        <Box
          sx={{
            px: 2,
            py: 3,
          }}
        >
          <Typography color='neutral.100' variant='subtitle2'>
            Having trouble with the app?
          </Typography>
          <Typography color='neutral.500' variant='body2'>
            Contact admin@ikhala.tech
          </Typography>
        </Box>
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor='left'
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            color: '#FFFFFF',
            width: 280,
          },
        }}
        variant='permanent'
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor='left'
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280,
        },
      }}
      sx={{ zIndex: theme => theme.zIndex.appBar + 100 }}
      variant='temporary'
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
