import React from 'react'
import { Alert, Typography, Button, Select, MenuItem, Dialog, Divider, DialogContent, DialogActions, DialogTitle } from '@mui/material';


const AddEquipmentToProjectDialog = ({
    openAssignEquipmentToProjectModal,
    setOpenAssignEquipmentToProjectModal,
    equipmentId,
    projectId,
    handleCancel,
    formErrors,
    userProjects,
    compnayEquipment,
    handleSetProjectId,
    handleSetEquipmentId,
    handleAssignEquipmentToProject,
}) => {

    return (
        <Dialog open={openAssignEquipmentToProjectModal} onClose={() => setOpenAssignEquipmentToProjectModal(false)} >
            <DialogTitle>Assign Equipment to CAR Rppb Project</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
                <Divider sx={{ my: 1 }} />
                <Typography>Select Equipment</Typography>
                <Select sx={{ mb: 2 }} name='equipment' value={equipmentId} fullWidth onChange={handleSetEquipmentId}>
                    {compnayEquipment?.map(equip => {
                        return (
                            <MenuItem key={equip.id} value={equip.id}>
                                {equip.name} (serial: {equip.serial})
                            </MenuItem>
                        );
                    })}
                </Select>
                <Typography>Select Project</Typography>
                <Select sx={{ mb: 2 }} name='project' value={projectId} fullWidth onChange={handleSetProjectId}>
                    {userProjects?.data?.map(project => {
                        return (
                            <MenuItem key={project.id} value={project.id}>
                                {project.project_name}
                            </MenuItem>
                        );
                    })}
                </Select>
                {projectId ? <Alert severity="info" sx={{ mb: 1 }}>This project belongs to the {userProjects?.data?.find((pro) => pro.id === projectId).project_car_rppb_entity_name} CAR Rppb</Alert> : null}
                {formErrors.map((error, index) => {
                    return (
                        <Alert sx={{ mb: 1 }} key={index} severity="error">{error}</Alert>
                    )
                })}
            </DialogContent>
            <DialogActions sx={{ mr: 'auto', mb: 3, ml: 2 }}>
                <Button variant='contained' onClick={handleAssignEquipmentToProject}>
                    Add Equipment To Project
                </Button>
                <Button variant='outlined' onClick={handleCancel}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddEquipmentToProjectDialog