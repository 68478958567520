import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Typography, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { getFaqs } from '../../actions/faqs';

const Support = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFaqs());
  }, [dispatch]);

  const { faqs, loading } = useSelector(state => state.faqs);

  return (
    <Box>
      {!loading ? (
        faqs?.map(faq => (
          <Accordion key={faq._id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${faq._id}a-content`}
              id={`panel${faq.id}a-header`}
            >
              <Typography variant='h5'>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                component='div'
                dangerouslySetInnerHTML={{ __html: faq.answer }}
              />
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <CircularProgress />
      )}
      <Button
        variant='contained'
        size='large'
        sx={{ mt: 5 }}
        href='mailto:admin@ikhala.tech'
      >
        Contact Our Support Team
      </Button>
    </Box>
  );
};

export default Support;
