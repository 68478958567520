import {
    GET_CHEMICALS_AND_ADDITIVES_DATA_SUCCESS,
    GET_CHEMICALS_AND_ADDITIVES_DATA_FAIL,
    POST_CHEMICALS_AND_ADDITIVES_DATA_SUCCESS,
    POST_CHEMICALS_AND_ADDITIVES_DATA_FAIL,
    GET_CHEMICALS_AND_ADDITIVES_DATA_TOTALS_SUCCESS,
    GET_CHEMICALS_AND_ADDITIVES_DATA_TOTALS_FAIL
} from '../../../actions/carbonfootprint-rework/types/04-indirect-ghg-emissions-from-products-used-by-an-organisation';

const initialState = {
    loading: true,
    chemicalsAndAdditivesData: [],
    chemicalsAndAdditivesDataTotals: []
};

export default function indirectGhgEmissionsFromProductsForChemicalsAndAdditivesReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case POST_CHEMICALS_AND_ADDITIVES_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_CHEMICALS_AND_ADDITIVES_DATA_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_CHEMICALS_AND_ADDITIVES_DATA_SUCCESS:
            return {
                ...state,
                chemicalsAndAdditivesData: payload,
                loading: false,
            };
        case GET_CHEMICALS_AND_ADDITIVES_DATA_FAIL:
            return {
                ...state,
                chemicalsAndAdditivesData: payload,
                loading: false
            }
        case GET_CHEMICALS_AND_ADDITIVES_DATA_TOTALS_SUCCESS:
            return {
                ...state,
                chemicalsAndAdditivesDataTotals: payload,
                loading: false,
            };
        case GET_CHEMICALS_AND_ADDITIVES_DATA_TOTALS_FAIL:
            return {
                ...state,
                chemicalsAndAdditivesDataTotals: payload,
                loading: false
            }
        default:
            return state;
    }
}