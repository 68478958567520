import React from "react";
import { Grid, TextField, Typography } from "@mui/material";

const fields = [
  { id: 'kiln-volume', labelFunc: (measurementSystem) => `Kiln Volume (${measurementSystem === "Metric" ? "m3" : "yrd3"})`, state: 'kilnVolume', setState: 'setKilnVolume'},
  { id: 'biochar-yield-level', label: 'Biochar Yield Level, the average kiln level at burn end (%)', state: 'biocharYieldLevel', setState: 'setBiocharYieldLevel'},
  { id: 'number-of-kilns', label: 'Number of Kilns', state: 'numberOfKilns', setState: 'setNumberOfKilns'},
  { id: 'bulk-density', labelFunc: (measurementSystem) => `Bulk Density (${measurementSystem === "Metric" ? "kg/m3" : "lb/yrd3"})`, state: 'bulkDensity', setState: 'setBulkDensity'},
];

const Step2Form = (props) => {
  const handleChange = (setter) => ({ target: { value } }) => {
    setter(value);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Daily or Batch Level Biochar Production Information
        </Typography>
      </Grid>
      {fields.map(({id, label, labelFunc, state, setState}) => (
        <Grid item xs={12} sm={6} key={id}>
          <TextField
            required
            fullWidth
            id={id}
            label={labelFunc ? labelFunc(props.measurementSystem) : label}
            variant="outlined"
            value={props[state]}
            onChange={handleChange(props[setState])}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Step2Form;
