export function Envelope() {
  return (
    <svg
      width='20'
      height='15'
      viewBox='0 0 20 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.5 1.25C1.79688 1.25 1.25 1.83594 1.25 2.5V4.0625L8.86719 9.6875C9.53125 10.1562 10.4297 10.1562 11.0938 9.6875L18.75 4.0625V2.5C18.75 1.83594 18.1641 1.25 17.5 1.25H2.5ZM1.25 5.625V12.5C1.25 13.2031 1.79688 13.75 2.5 13.75H17.5C18.1641 13.75 18.75 13.2031 18.75 12.5V5.625L11.8359 10.7031C10.7422 11.4844 9.21875 11.4844 8.125 10.7031L1.25 5.625ZM0 2.5C0 1.13281 1.09375 0 2.5 0H17.5C18.8672 0 20 1.13281 20 2.5V12.5C20 13.9062 18.8672 15 17.5 15H2.5C1.09375 15 0 13.9062 0 12.5V2.5Z'
        fill='#DA9DE3'
      />
    </svg>
  );
}
