import {
  Grid,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import tooltips from '../../../store/tooltips';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import ContractorDiscoveryForm from '../home/contractorService/ContractorDiscoveryForm';

export default function Resources() {
  const resources = [
    {
      id: 1,
      title: 'International Resources',
      items: [
        {
          id: 0,
          name: 'Wilson Biochar',
          pdf: 'https://wilsonbiochar.com/resources',
        },
        {
          id: 1,
          name: 'International Biochar Initiative',
          pdf: 'https://biochar-international.org/',
        },
        {
          id: 1,
          name: 'Piled Fuels Biomass and Emissions Calculator',
          pdf: 'https://depts.washington.edu/nwfire/piles/',
        }
      ],
    },
    {
      id: 0,
      title: 'South African Resources',
      items: [
        {
          id: 5,
          name: 'Calculate runoff losses to Invasive Alien Plants',
          desc: "Use this calculator to determine the water runoff losses from the invasive plants on your land",
          pdf: 'https://gmoncrieff.shinyapps.io/aliens_waterloss/',
        },
      ],
    },
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant='h4' component='h4' sx={{ mb: 3 }}>
        Biochar related resources
        <Tooltip
          title={<h2 style={{ color: 'lightgreen' }}>{tooltips.resources}</h2>}
        >
          {<InfoIcon width={18} height={18} />}
        </Tooltip>
      </Typography>
      {resources.map(resource => (
        <Accordion key={resource.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant='h6' sx={{ borderBottom: '1px solid #eee', my: 2 }}>
              {resource.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={6}>
              {resource.items.map(item => (
                <Grid xs={3} item key={item.id}>
                  <Card variant='outlined'>
                    <CardContent sx={{ minHeight: '5em' }}>
                      <Typography>{item.name}</Typography>
                      <Typography color="text.secondary">{item.desc}</Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant='outlined'
                        size='small'
                        onClick={() => window.open(item.pdf)}
                      >
                        View
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
              {resource.id === 0 && (
                <Grid xs={3} item>
                  <Card variant='outlined'>
                    <CardContent>
                      <Typography variant='h7' component='h7'>
                        Biochar Contractor Cost Estimator for Ring of Fire kilns
                      </Typography>
                      <ContractorDiscoveryForm />
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
