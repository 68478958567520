import { useEffect } from 'react';
import { TextField, Typography } from '@mui/material';

export default function F013FPAMembership({ data, setData, setFormId }) {
  useEffect(() => {
    setFormId('F013');
  }, [setFormId]);

  const { nameOfFPA, membershipExpiryDate, fpa_certificate } = data;

  return (
    <>
      <Typography variant='h5' sx={{ mb: 2 }}>
        FPA Membership
      </Typography>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Name of FPA
      </Typography>
      <TextField
        name='nameOfFPA'
        label='Name of FPA'
        value={nameOfFPA}
        type='text'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Membership Expiry Date
      </Typography>
      <TextField
        name='membershipExpiryDate'
        value={membershipExpiryDate}
        label=''
        type='date'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Upload FPA Membership Certificate
      </Typography>
      <TextField
        name='fpa_certificate'
        value={fpa_certificate}
        type='file'
        onChange={setData}
        fullWidth
      />
    </>
  );
}
