import { Alert, Box, Button, Divider, Grid, Typography } from '@mui/material';
// import { useState } from 'react';

export default function YearSelection({ setSelection, setCompany }) {

    const companies = ['Vinimark Holdings Group']

    return (
        <Grid item xs={12}>
            <Typography variant='h5'>Company</Typography>
            <Divider />
            <Alert severity='info'>Please select a Company</Alert>
            <Box marginTop={2}>
                {companies.map((c, i) => {
                    return (
                        <Button
                            key={i}
                            variant='contained'
                            size='large'
                            sx={{ width: '20%', display: 'block', mb: 2.5 }}
                            onClick={() => {
                                let companyId = ''
                                if (c === 'Vinimark Holdings Group') {
                                    companyId = '1'
                                }
                                localStorage.setItem('company', companyId)
                                setSelection({ id: 2, title: 'group' })
                                setCompany(c)
                            }}
                        >
                            {c}
                        </Button>
                    )
                })
                }
            </Box>
        </Grid>
    );
}
