import { useState } from "react"
import { FormControl, TextField, Typography, Button, Divider } from "@mui/material"

export default function HedgeForm() {

    const initialState = {
        growHedges: '',
        meters: '',
        water: '',
        bioChar: ''
    }

    const [formData, setFormData] = useState(initialState);
    const handleChange = (e) => setFormData({...formData, [e.target.name]: e.target.value});

    const {
        growHedges,
        meters,
        water,
        bioChar
     } = formData


  return (
    <>
        <Typography variant='h4' component='h4' sx={{mb:2}}>
            Hedge
        </Typography>
    
        <FormControl fullWidth sx={{mb:2}}>
            <Typography variant='h6' component='p'>
              Are you looking to grow hedges for timber, carbon financing, biochar feedstock or a mix of these options?
            </Typography>
            <TextField
                name='growHedges'
                label='Your answer here'
                value={growHedges}
                onChange={e => handleChange(e)}
            />
        </FormControl>
        <FormControl fullWidth sx={{mb:2}}>
            <Typography variant='h6' component='p'>
              How many meters of hedge would you like to plant?
            </Typography>
            <TextField
                name='meters'
                label='Your answer here'
                value={meters}
                onChange={e => handleChange(e)}
            />
        </FormControl>
        <FormControl fullWidth sx={{mb:2}}>
            <Typography variant='h6' component='p'>
              Do you have access to water?
            </Typography>
            <TextField
                name='water'
                label='Your answer here'
                value={water}
                onChange={e => handleChange(e)}
            />
        </FormControl>
        <FormControl fullWidth sx={{mb:2}}>
            <Typography variant='h6' component='p'>
              Have you used biochar as a soil amendment?
            </Typography>
            <TextField
                name='bioChar'
                label='Your answer here'
                value={bioChar}
                onChange={e => handleChange(e)}
            />
        </FormControl>
        <Divider sx={{my:2}} />
        <FormControl fullWidth sx={{mb:2}}>
            <Typography variant='p' component='p'>
              Please use the drawing tool on the map to draw where you would like to plant hedges
            </Typography>
        </FormControl>
        <Divider />
        <FormControl fullWidth sx={{my:2}}>
            <Button variant="contained" size="large">Submit Application</Button>
        </FormControl>
    </>
  )
}
