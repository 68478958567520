import {
    GET_PETROL_CARD_HOLDERS_SUCCESS,
    GET_PETROL_CARD_HOLDERS_FAIL,
    POST_PETROL_CARD_HOLDER_SUCCESS,
    POST_PETROL_CARD_HOLDER_FAIL,
    GET_PETROL_CARDS_DATA_SUCCESS,
    GET_PETROL_CARDS_DATA_FAIL,
    POST_PETROL_CARDS_DATA_SUCCESS,
    POST_PETROL_CARDS_DATA_FAIL,
    GET_PETROL_CARDS_DATA_TOTALS_SUCCESS,
    GET_PETROL_CARDS_DATA_TOTALS_FAIL
} from '../types/03-indirect-ghg-emissions-from-transportation';

import {
    API_GET_PETROL_CARD_HOLDERS,
    API_POST_PETROL_CARD_HOLDER,
    API_GET_PETROL_CARDS_DATA,
    API_POST_PETROL_CARDS_DATA,
    API_GET_PETROL_CARDS_DATA_TOTALS
} from '../constants/03-indirect-ghg-emissions-from-transportation';

import { setAlert } from '../../alert';

import axios from 'axios';

const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    },
    // withCredentials: true,
};

export const AddPetrolCardHolder = (formData, year, company, group) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_PETROL_CARD_HOLDER + `/${year}/${company}/${group}`,
            formData,
            config
        );
        dispatch({
            type: POST_PETROL_CARD_HOLDER_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_PETROL_CARD_HOLDER_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getPetrolCardHolders = (group) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_PETROL_CARD_HOLDERS + `/${group}`,
            config
        );
        dispatch({
            type: GET_PETROL_CARD_HOLDERS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_PETROL_CARD_HOLDERS_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getPetrolCardsData = (year, facility) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_PETROL_CARDS_DATA + `/${year}/${facility}`,
            config
        );
        dispatch({
            type: GET_PETROL_CARDS_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_PETROL_CARDS_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const postPetrolCardsData = (formData, year, company, group, facility) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_PETROL_CARDS_DATA + `/${year}/${company}/${group}/${facility}`,
            formData,
            config
        );
        dispatch({
            type: POST_PETROL_CARDS_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_PETROL_CARDS_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getPetrolCardsDataTotals = (year, facility) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_PETROL_CARDS_DATA_TOTALS + `/${year}/${facility}`,
            config
        );
        dispatch({
            type: GET_PETROL_CARDS_DATA_TOTALS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_PETROL_CARDS_DATA_TOTALS_FAIL,
            payload: err.response.data.detail,
        });
    }
};