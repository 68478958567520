import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  Divider,
  Typography,
  Paper,
  Modal,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from '@mui/material';
import { addOrUpdateUserProfile, getUserProfile } from '../../../actions/auth';
import CircularProgress from '@mui/material/CircularProgress';

const Account = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const user = JSON.parse(localStorage.getItem('token'));
  const { profileData, loading } = useSelector(state => state.auth);

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(null);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const setData = data => {
    setFormData(data);
  };

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleInsertOrUpdate = (data, type) => {
    dispatch(addOrUpdateUserProfile(data, type));
  };

  return (
    <>
      <Paper sx={{ p: 3 }}>
        {!loading ? (
          <Paper sx={{ p: 2 }}>
            <Typography variant='h3'>Profile</Typography>
            <Divider sx={{ my: 2 }} />
            {/* Profile */}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant='h6'>
                  <strong>Name:</strong> {user?.name}
                </Typography>

                {profileData?.phone && (
                  <Typography variant='h6'>
                    <strong>Phone:</strong> {profileData?.phone}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography variant='h6'>
                  <strong>Email:</strong> {user?.email}
                </Typography>

                {profileData?.property && (
                  <Typography variant='h6'>
                    <strong>Property Name:</strong> {profileData?.property}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {/* Address */}
            {profileData?.country && (
              <>
                <Divider sx={{ my: 2 }} />
                <Typography variant='h3'>Address</Typography>
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    {profileData?.country && (
                      <Typography variant='h6'>
                        <strong>Country:</strong> {profileData?.country}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    {profileData?.subregion && (
                      <Typography variant='h6'>
                        <strong>Sub-Region:</strong> {profileData?.subregion}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    {profileData?.zip && (
                      <Typography variant='h6'>
                        <strong>Postal Code:</strong> {profileData?.zip}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
              </>
            )}
            {profileData?.vehicle_type && (
              <>
                <Typography variant='h3'>Tech Details</Typography>
                <Divider sx={{ my: 2 }} />
                {profileData?.vehicle_type && (
                  <Typography variant='h6'>
                    <strong>Vehicle Type:</strong> {profileData?.vehicle_type}
                  </Typography>
                )}
                {profileData?.engine_type && (
                  <Typography variant='h6'>
                    <strong>Engine Type:</strong> {profileData?.engine_type}
                  </Typography>
                )}
                {profileData?.fuel_type && (
                  <Typography variant='h6'>
                    <strong>Fuel Type:</strong> {profileData?.fuel_type}
                  </Typography>
                )}
              </>
            )}
            <Divider sx={{ my: 2 }} />
            {profileData ? (
              <Button
                variant='contained'
                size='large'
                onClick={() => {
                  setOpen(true);
                  setData(profileData);
                }}
              >
                Update Account Information
              </Button>
            ) : (
              <Button
                variant='contained'
                size='large'
                onClick={() => {
                  setOpen(true);
                }}
              >
                Add Account Information
              </Button>
            )}
          </Paper>
        ) : (
          <CircularProgress />
        )}
      </Paper>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <TextField
            name='phone'
            label='Phone'
            sx={{ mb: 1 }}
            fullWidth
            value={formData?.phone}
            onChange={e => handleChange(e)}
          />
          <Grid container spacing={5} sx={{ mb: 1 }}>
            <Grid item xs={4}>
              <TextField
                name='country'
                label='Country'
                fullWidth
                value={formData?.country}
                onChange={e => handleChange(e)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name='subregion'
                label='Subregion'
                fullWidth
                value={formData?.subregion}
                onChange={e => handleChange(e)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name='zip'
                label='Postcode'
                type='number'
                fullWidth
                value={formData?.zip}
                onChange={e => handleChange(e)}
              />
            </Grid>
          </Grid>
          <TextField
            name='property'
            label='Property Name'
            sx={{ mb: 1 }}
            fullWidth
            value={formData?.property}
            onChange={e => handleChange(e)}
          />
          <Typography variant='h6' component='p' sx={{ mt: 2 }}>
            Personal Vehicle Used On Land
          </Typography>
          <RadioGroup row name='vehicle_type' onChange={e => handleChange(e)}>
            <FormControlLabel
              value='Single Cab'
              control={<Radio />}
              label='Single Cab'
              name='vehicle_type'
              checked={formData?.vehicle_type === 'Single Cab'}
            />
            <FormControlLabel
              value='Double Cab'
              control={<Radio />}
              label='Double Cab'
              name='vehicle_type'
              checked={formData?.vehicle_type === 'Double Cab'}
            />
            <FormControlLabel
              value='Club Cab'
              control={<Radio />}
              label='Club Cab'
              name='vehicle_type'
              checked={formData?.vehicle_type === 'Club Cab'}
            />
            <FormControlLabel
              value='Other'
              control={<Radio />}
              label='Other'
              name='vehicle_type'
              checked={formData?.vehicle_type === 'Other'}
            />
          </RadioGroup>
          <Typography variant='h6' component='p' sx={{ mt: 2 }}>
            Engine Type
          </Typography>
          <RadioGroup row name='engine_type' onChange={e => handleChange(e)}>
            <FormControlLabel
              value='Smaller than 2.5L'
              control={<Radio />}
              label='Smaller Than 2.5 L'
              name='engine_type'
              checked={formData?.engine_type === 'Smaller than 2.5L'}
            />
            <FormControlLabel
              value='Bigger than 2.5L'
              control={<Radio />}
              label='Bigger Than 2.5 L'
              name='engine_type'
              checked={formData?.engine_type === 'Bigger than 2.5L'}
            />
          </RadioGroup>
          <Typography variant='h6' component='p' sx={{ mt: 2 }}>
            Fuel Type
          </Typography>
          <RadioGroup row name='fuel_type' onChange={e => handleChange(e)}>
            <FormControlLabel
              value='Diesel'
              control={<Radio />}
              label='Diesel'
              name='fuel_type'
              checked={formData?.fuel_type === 'Diesel'}
            />
            <FormControlLabel
              value='Petrol'
              control={<Radio />}
              label='Petrol'
              name='fuel_type'
              checked={formData?.fuel_type === 'Petrol'}
            />
          </RadioGroup>
          <Divider sx={{ my: 2 }} />
          <Grid container sx={{ justifyContent: 'space-between' }}>
            <Grid item>
              <Button variant='outlined' onClick={() => setOpen(false)}>
                Close
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='outlined'
                sx={{ mr: 2 }}
                onClick={() => setFormData(profileData)}
              >
                Reset
              </Button>
              <Button
                variant='contained'
                onClick={() => {
                  setOpen(false);
                  handleInsertOrUpdate(formData, profileData ? 'update' : null);
                }}
              >
                {profileData ? 'Update' : 'Add'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default Account;
