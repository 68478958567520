import {
    GET_WASTE_TO_LANDFILL_DATA_SUCCESS,
    GET_WASTE_TO_LANDFILL_DATA_FAIL,
    POST_WASTE_TO_LANDFILL_DATA_SUCCESS,
    POST_WASTE_TO_LANDFILL_DATA_FAIL,
    GET_WASTE_TO_LANDFILL_DATA_TOTALS_SUCCESS,
    GET_WASTE_TO_LANDFILL_DATA_TOTALS_FAIL
} from '../../../actions/carbonfootprint-rework/types/05-indirect-ghg-emissions-from-other-sources';


const initialState = {
    loading: true,
    wasteToLandfillData: [],
    wasteToLandfillDataTotals: []
};

// PETROL REDUCERS
export default function
    indirectGhgEmissionsFromOtherSourcesForWasteToLandfill(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        // STATIONARY PETROL
        case POST_WASTE_TO_LANDFILL_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_WASTE_TO_LANDFILL_DATA_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_WASTE_TO_LANDFILL_DATA_SUCCESS:
            return {
                ...state,
                wasteToLandfillData: payload,
                loading: false,
            };
        case GET_WASTE_TO_LANDFILL_DATA_FAIL:
            return {
                ...state,
                wasteToLandfillData: payload,
                loading: false
            }
        case GET_WASTE_TO_LANDFILL_DATA_TOTALS_SUCCESS:
            return {
                ...state,
                wasteToLandfillDataTotals: payload,
                loading: false,
            };
        case GET_WASTE_TO_LANDFILL_DATA_TOTALS_FAIL:
            return {
                ...state,
                wasteToLandfillDataTotals: payload,
                loading: false
            }

        default:
            return state;
    }
}


