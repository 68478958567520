import { useState } from 'react';
import {
  FormControl,
  TextField,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';

export default function CBRForm() {
  const initialState = {
    hectares: '',
    species: '',
    biocharPast: '',
    biocharUtil: '',
  };

  const [formData, setFormData] = useState(initialState);
  const handleChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const { hectares, species, //biocharPast, biocharUtil 
  } = formData;

  return (
    <>
      <Typography variant='h4' component='h4' sx={{ mb: 2 }}>
        Biochar Production
      </Typography>

      <FormControl fullWidth sx={{ mb: 2 }}>
        <Typography variant='h6' component='p'>
          How many hectares of your land are invaded by alien invasive trees?
        </Typography>
        <TextField
          name='hectares'
          label='Your answer here'
          value={hectares}
          onChange={e => handleChange(e)}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Typography variant='h6' component='p'>
          What is the primary species or mix of invasive species?
        </Typography>
        <TextField
          name='species'
          label='Your answer here'
          value={species}
          onChange={e => handleChange(e)}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Typography variant='h6' component='p'>
          Have you used biochar in the past?
        </Typography>
        <RadioGroup row aria-labelledby='demo-radio-buttons-group-label'>
          <FormControlLabel value='yes' control={<Radio />} label='Yes' />
          <FormControlLabel value='no' control={<Radio />} label='No' />
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Typography variant='h6' component='p'>
          Are you interested in utilising biochar?
        </Typography>
        <RadioGroup row aria-labelledby='demo-radio-buttons-group-label'>
          <FormControlLabel value='yes' control={<Radio />} label='Yes' />
          <FormControlLabel value='no' control={<Radio />} label='No' />
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth sx={{ my: 2 }}>
        <Button variant='contained' size='large'>
          Submit Application
        </Button>
      </FormControl>
    </>
  );
}
