import { setAlert } from '../../alert';
import {
    API_UPLOAD_FILE,
} from '../constants/01-direct-ghg-emissions-and-removals-constants';
import {
    FILE_UPLOAD_SUCCESS,
    FILE_UPLOAD_FAIL
} from '../types/01-direct-ghg-emissions-and-removals-types';

import axios from 'axios';
const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
    headers: {
        'Authorization': `Bearer ${token}`,
    },
    // withCredentials: true
};

export const directGhgEnmissionsAndRemovalsFileUpload = (formData, type) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_UPLOAD_FILE + `/${type}`,
            formData,
            config
        );
        dispatch({
            type: FILE_UPLOAD_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: FILE_UPLOAD_FAIL,
            payload: err.response.data.detail,
        });
    }
};