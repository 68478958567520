import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  TextField,
  Link,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import {
  getBulkDensityData,
  verifyBiocharData,
} from '../../../../../../../actions/forms';
import Checkmark from '../../../../../../../assets/images/check.png';
import Cross from '../../../../../../../assets/images/cross.png';
import { UPLOADED_DOCS } from '../../../../../../../actions/constants';

function WeightOfEmptyBucket({ bulkDensityVerificationData, projectKey }) {
  const dispatch = useDispatch();
  const [bulkDensity, setBulkDensity] = useState([]);

  useEffect(() => {
    dispatch(getBulkDensityData(projectKey));
  }, [dispatch, projectKey]);

  const bulkDensityTableHeadings = [
    'Batch ID',
    'Kiln ID',
    'Bucket Weight Photo',
    'Hot Coals Weight',
    'Value Correct',
    'Correction',
    'Empty Bucket Photo',
    'Empty Bucket Weight',
    'Value Correct',
    'Correction',
    'Bulk Bucket Size',
    'Value Correct',
    'Correction',
    'Verified',
  ];

  useEffect(() => {
    if (bulkDensityVerificationData) {
      setBulkDensity(
        bulkDensityVerificationData.map(d => {
          return {
            id: d.id,
            batch: d.batch,
            kilnID: d.kilnUsed,
            hotCoalsWeight: d.hotCoalsWeight,
            emptyBucketWeight: d.emptyBucketWeight,
            bulkBucketSize: d.bulkBucketSize,
            bucketWeightPhoto: d.bucketWeightPhoto,
            emptyBucketPhoto: d.emptyBucketPhoto,
            value1Correct:
              d?.value1Correct === true || d?.value1Correct === false
                ? d.value1Correct
                : null,
            correction1: d?.correction1 ? d.correction1 : null,
            value2Correct:
              d?.value2Correct === true || d?.value2Correct === false
                ? d.value2Correct
                : null,
            correction2: d?.correction2 ? d.correction2 : null,
            value3Correct:
              d?.value3Correct === true || d?.value3Correct === false
                ? d.value3Correct
                : null,
            correction3: d?.correction3 ? d.correction3 : null,
            verified: d?.verified ? d?.verified : null,
          };
        })
      );
    }
  }, [bulkDensityVerificationData, dispatch]);
  const handleSubmitBulkDensity = async () => {
    await dispatch(verifyBiocharData(projectKey, 'bulkDensity', bulkDensity));
    await dispatch(getBulkDensityData(projectKey));
  };

  const handleValue1CorrectChange = (event, batch) => {
    setBulkDensity(bulkDensity => {
      const updated = bulkDensity.map(v => {
        if (v.batch === batch) {
          v.value1Correct = !!+event.target.value;
        }
        return v;
      });
      return updated;
    });
  };

  const handleValue2CorrectChange = (event, batch) => {
    setBulkDensity(bulkDensity => {
      const updated = bulkDensity.map(v => {
        if (v.batch === batch) {
          v.value2Correct = !!+event.target.value;
        }
        return v;
      });
      return updated;
    });
  };

  const handleValue3CorrectChange = (event, batch) => {
    setBulkDensity(bulkDensity => {
      const updated = bulkDensity.map(v => {
        if (v.batch === batch) {
          v.value3Correct = !!+event.target.value;
        }
        return v;
      });
      return updated;
    });
  };

  const handleCorrection1 = (event, batch) => {
    setBulkDensity(bulkDensity => {
      const updated = bulkDensity.map(v => {
        if (v.batch === batch) {
          v.correction1 = event.target.value;
        }
        return v;
      });
      return updated;
    });
  };

  const handleCorrection2 = (event, batch) => {
    setBulkDensity(bulkDensity => {
      const updated = bulkDensity.map(v => {
        if (v.batch === batch) {
          v.correction2 = event.target.value;
        }
        return v;
      });
      return updated;
    });
  };

  const handleCorrection3 = (event, batch) => {
    setBulkDensity(bulkDensity => {
      const updated = bulkDensity.map(v => {
        if (v.batch === batch) {
          v.correction3 = event.target.value;
        }
        return v;
      });
      return updated;
    });
  };

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Typography variant='h6' sx={{ mb: 2 }}>
        Weight of Empty Bucket
      </Typography>
      <TableContainer component={Paper} sx={{ height: 'auto', width: '100%' }}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              {bulkDensityTableHeadings.map((heading, i) => {
                return (
                  <TableCell align='left' key={i}>
                    {heading}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {bulkDensity?.length > 0
              ? bulkDensity?.map((d, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell align='left'>{d.batch}</TableCell>
                    <TableCell align='left'>{d.kilnID}</TableCell>
                    <TableCell align='left'>
                      <Link
                        target='_blank'
                        href={`${UPLOADED_DOCS}${d?.bucketWeightPhoto
                          ?.split('/')
                          .pop()}`}
                      >
                        <img
                          height='150'
                          width='150'
                          src={`${UPLOADED_DOCS}${d?.bucketWeightPhoto
                            ?.split('/')
                            .pop()}`}
                          alt='moisture measurement'
                        />
                      </Link>
                    </TableCell>
                    <TableCell align='left'>{d.hotCoalsWeight}</TableCell>
                    <TableCell align='left'>
                      <RadioGroup
                        value={
                          d?.value1Correct === true
                            ? 1
                            : d?.value1Correct === false
                              ? 0
                              : null
                        }
                        row
                        name='value1Correct'
                        onChange={event =>
                          handleValue1CorrectChange(event, d.batch)
                        }
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio required />}
                          label='Yes'
                          name='value1Correct'
                          disabled={d?.verified}
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio required />}
                          label='No'
                          name='value1Correct'
                          disabled={d?.verified}
                        />
                      </RadioGroup>
                    </TableCell>
                    <TableCell align='left'>
                      <TextField
                        name='correction1'
                        value={d.correction1}
                        onChange={event => handleCorrection1(event, d.batch)}
                        sx={{ background: '#fff' }}
                        required
                        fullWidth
                        type='number'
                        disabled={
                          d?.verified || d?.value1Correct === true
                            ? true
                            : false
                        }
                      />
                    </TableCell>
                    <TableCell align='left'>
                      <Link
                        target='_blank'
                        href={`${UPLOADED_DOCS}${d?.emptyBucketPhoto
                          ?.split('/')
                          .pop()}`}
                      >
                        <img
                          height='150'
                          width='150'
                          src={`${UPLOADED_DOCS}${d?.emptyBucketPhoto
                            ?.split('/')
                            .pop()}`}
                          alt='moisture measurement'
                        />
                      </Link>
                    </TableCell>
                    <TableCell align='left'>{d.emptyBucketWeight}</TableCell>
                    <TableCell align='left'>
                      <RadioGroup
                        value={
                          d?.value2Correct === true
                            ? 1
                            : d?.value2Correct === false
                              ? 0
                              : null
                        }
                        row
                        name='value2Correct'
                        onChange={event =>
                          handleValue2CorrectChange(event, d.batch)
                        }
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio required />}
                          label='Yes'
                          name='value2Correct'
                          disabled={d?.verified}
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio required />}
                          label='No'
                          name='value2Correct'
                          disabled={d?.verified}
                        />
                      </RadioGroup>
                    </TableCell>
                    <TableCell align='left'>
                      <TextField
                        name='correction2'
                        value={d.correction2}
                        onChange={event => handleCorrection2(event, d.batch)}
                        sx={{ background: '#fff' }}
                        required
                        fullWidth
                        type='number'
                        disabled={
                          d?.verified || d?.value2Correct === true
                            ? true
                            : false
                        }
                      />
                    </TableCell>
                    <TableCell align='left'>{d.bulkBucketSize}</TableCell>
                    <TableCell align='left'>
                      <RadioGroup
                        value={
                          d?.value3Correct === true
                            ? 1
                            : d?.value3Correct === false
                              ? 0
                              : null
                        }
                        row
                        name='value3Correct'
                        onChange={event =>
                          handleValue3CorrectChange(event, d.batch)
                        }
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio required />}
                          label='Yes'
                          name='value3Correct'
                          disabled={d?.verified}
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio required />}
                          label='No'
                          name='value3Correct'
                          disabled={d?.verified}
                        />
                      </RadioGroup>
                    </TableCell>
                    <TableCell align='left'>
                      <TextField
                        name='correction3'
                        value={d.correction3}
                        onChange={event => handleCorrection3(event, d.batch)}
                        sx={{ background: '#fff' }}
                        required
                        fullWidth
                        type='number'
                        disabled={
                          d?.verified || d?.value3Correct === true
                            ? true
                            : false
                        }
                      />
                    </TableCell>
                    <TableCell align='left'>
                      {d?.verified ? (
                        <img
                          src={Checkmark}
                          width={25}
                          height={25}
                          alt='checkmark'
                        />
                      ) : (
                        <img src={Cross} width={25} height={25} alt='cross' />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        sx={{ mt: 2 }}
        variant='contained'
        onClick={handleSubmitBulkDensity}
      >
        Verify Weight of Empty Bucket
      </Button>
    </Box>
  );
}

const mapStateToProps = state => ({
  bulkDensityVerificationData: state.postFormData.bulkDensityVerificationData,
});

const mapDispatchToProps = dispatch => {
  return {
    getBulkDensityData: projectId => dispatch(getBulkDensityData(projectId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeightOfEmptyBucket);
