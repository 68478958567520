import React from 'react'
import BF005VerificationRequest from '../types/BF005VerificationRequest';
import { Grid, Button } from '@mui/material';

const VerificationTables = ({ active, next, dataBF005, setData, setFormId, projectId, projectKey, validationItems }) => {
    return (
        <>
            <Grid item xs={2} sx={{ borderRight: '1px solid', p: 2 }}>
                {validationItems.map(item => (
                    <Button
                        fullWidth
                        key={item.id}
                        onClick={() => next(item.id)}
                        variant={active === item.id ? 'outlined' : null}
                    >
                        {item.name}
                    </Button>
                ))}
            </Grid>
            <Grid item xs={10} sx={{ p: 2 }}>
                <BF005VerificationRequest
                    data={dataBF005}
                    setData={setData}
                    setFormId={setFormId}
                    next={next}
                    projectId={projectId}
                    projectKey={projectKey}
                />
            </Grid>
        </>
    )
}

export default VerificationTables