import React from 'react'
import { Alert, Typography, Button, Select, MenuItem, Dialog, Divider, DialogContent, DialogActions, DialogTitle } from '@mui/material';


const AddBinToProjectDialog = ({
    openAssignBinToProjectModal,
    setOpenAssignBinToProjectModal,
    binId,
    projectId,
    handleCancel,
    formErrors,
    userProjects,
    handleSetProjectId,
    companyBins,
    handleSetBinId,
    handleAssignBinToProject,
}) => {

    return (
        <Dialog open={openAssignBinToProjectModal} onClose={() => setOpenAssignBinToProjectModal(false)} >
            <DialogTitle>Assign Bin to CAR Rppb Project</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
                <Divider sx={{ my: 1 }} />
                <Typography>Select Bin</Typography>
                <Select sx={{ mb: 2 }} name='bin' value={binId} fullWidth onChange={handleSetBinId}>
                    {companyBins?.map(bin => {
                        return (
                            <MenuItem key={bin.id} value={bin.id}>
                                {bin.name} ({bin.type})
                            </MenuItem>
                        );
                    })}
                </Select>
                <Typography>Select Project</Typography>
                <Select sx={{ mb: 2 }} name='project' value={projectId} fullWidth onChange={handleSetProjectId}>
                    {userProjects?.data?.map(project => {
                        return (
                            <MenuItem key={project.id} value={project.id}>
                                {project.project_name}
                            </MenuItem>
                        );
                    })}
                </Select>
                {projectId ? <Alert severity="info" sx={{ mb: 1 }}>This project belongs to the {userProjects?.data?.find((pro) => pro.id === projectId).project_car_rppb_entity_name} CAR Rppb</Alert> : null}
                {formErrors.map((error, index) => {
                    return (
                        <Alert sx={{ mb: 1 }} key={index} severity="error">{error}</Alert>
                    )
                })}
            </DialogContent>
            <DialogActions sx={{ mr: 'auto', mb: 3, ml: 2 }}>
                <Button variant='contained' onClick={handleAssignBinToProject}>
                    Add Bin To Project
                </Button>
                <Button variant='outlined' onClick={handleCancel}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddBinToProjectDialog