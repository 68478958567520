import { useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import F005ManagementPlans from './types/F005ManagementPlans';
import F012NeighbourAgreements from './types/F012NeighbourAgreements';
import F013FPAMembership from './types/F013FPAMembership';

export default function PlansAndAgreements({
  dataF005,
  dataF012,
  dataF013,
  setData,
  setFormId,
  totalItems,
  next,
  active,
}) {
  const items = [
    {
      id: 0,
      name: 'Management Plans',
    },
    {
      id: 1,
      name: 'Neighbour Agreements',
    },
    {
      id: 2,
      name: 'FPA Membership',
    },
  ];

  useEffect(() => {
    totalItems(items.length);
  }, [items.length, totalItems]);

  return (
    <Grid container>
      <Grid xs={3} sx={{ borderRight: '1px solid', p: 2 }}>
        {items.map(item => (
          <Button
            fullWidth
            key={item.id}
            onClick={() => next(item.id)}
            variant={active === item.id ? 'outlined' : null}
          >
            {item.name}
          </Button>
        ))}
      </Grid>
      <Grid xs={9} sx={{ p: 2 }}>
        {active === 0 ? (
          <F005ManagementPlans
            data={dataF005}
            setData={setData}
            setFormId={setFormId}
          />
        ) : active === 1 ? (
          <F012NeighbourAgreements
            data={dataF012}
            setData={setData}
            setFormId={setFormId}
          />
        ) : active === 2 ? (
          <F013FPAMembership
            data={dataF013}
            setData={setData}
            setFormId={setFormId}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}
