import { setAlert } from '../../alert';
import {
    API_POST_STATIONARY_OR_MOBILE_PETROL_DATA,
    API_GET_STATIONARY_OR_MOBILE_PETROL_DATA,
    API_GET_STATIONARY_OR_MOBILE_PETROL_DATA_TOTALS,
} from '../constants/01-direct-ghg-emissions-and-removals-constants';
import {
    POST_STATIONARY_OR_MOBILE_PETROL_DATA_SUCCESS,
    POST_STATIONARY_OR_MOBILE_PETROL_DATA_FAIL,
    GET_STATIONARY_OR_MOBILE_PETROL_DATA_SUCCESS,
    GET_STATIONARY_OR_MOBILE_PETROL_DATA_FAIL,
    GET_STATIONARY_OR_MOBILE_PETROL_DATA_TOTALS_SUCCESS,
    GET_STATIONARY_OR_MOBILE_PETROL_DATA_TOTALS_FAIL,
} from '../types/01-direct-ghg-emissions-and-removals-types';
import axios from 'axios';

const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    },
    // withCredentials: true,
};


// Fetch Plot List

export const postStationaryOrMobilePetrolData = (formData, type, year, company, group, facility) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_STATIONARY_OR_MOBILE_PETROL_DATA + `/${type}/${year}/${company}/${group}/${facility}`,
            formData,
            config
        );
        dispatch({
            type: POST_STATIONARY_OR_MOBILE_PETROL_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_STATIONARY_OR_MOBILE_PETROL_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getStationaryOrMobilePetrolData = (type, year, facility) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_STATIONARY_OR_MOBILE_PETROL_DATA + `/${type}/${year}/${facility}`,
            config
        );
        dispatch({
            type: GET_STATIONARY_OR_MOBILE_PETROL_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_STATIONARY_OR_MOBILE_PETROL_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getStationaryOrMobilePetrolDataTotals = (type, year, facility) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_STATIONARY_OR_MOBILE_PETROL_DATA_TOTALS + `/${type}/${year}/${facility}`,
            config
        );
        dispatch({
            type: GET_STATIONARY_OR_MOBILE_PETROL_DATA_TOTALS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_STATIONARY_OR_MOBILE_PETROL_DATA_TOTALS_FAIL,
            payload: err.response.data.detail,
        });
    }
};
