import { useEffect, useState } from 'react';
import {
    Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell, Tabs, Tab, Select, MenuItem, FormControl, InputLabel, Button, TextField
} from '@mui/material';
import { RefrigerantGasTotalTable } from '../../RefrigerantGasTotalTable';
import { getRefrigerantGasData, postRefrigerantGasData, getRefrigerantGasDataTotals } from '../../../../../actions/carbonfootprint-rework/01-direct-ghg-emissions-and-removals/04-refrigerant-gas-actions';
import { useDispatch, useSelector } from 'react-redux'
import { directGhgEnmissionsAndRemovalsFileUpload } from '../../../../../actions/carbonfootprint-rework/01-direct-ghg-emissions-and-removals/00-file-upload';
import { geHighestId } from '../../../../../actions/carbonfootprint-rework/01-direct-ghg-emissions-and-removals/01-diesel-actions';

const RefrigerantDataCapturing = () => {
    const dispatch = useDispatch()

    const refrigerantGasData = useSelector((state) => state.directGhgEmissionsAndRemovalsForRefrigerantGas.refrigerantGasData?.data)
    const refrigerantGasDataTotals = useSelector((state) => state.directGhgEmissionsAndRemovalsForRefrigerantGas.refrigerantGasDataTotals?.data)
    let highestId = useSelector((state) => state.directGhgEmissionsAndRemovalsForDiesel.highestId.max_id)

    const year = localStorage.getItem('year')
    const facility = localStorage.getItem('facility')
    const group = localStorage.getItem('group')
    const company = localStorage.getItem('company')

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Totals']
    const [month, setMonth] = useState(0);
    const [assetRows, setAssetRows] = useState({ 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [], 11: [] })

    useEffect(() => {
        dispatch(geHighestId('refrigerant-gas'))
        dispatch(getRefrigerantGasData(year, facility))
        dispatch(getRefrigerantGasDataTotals(year, facility))
    }, [dispatch, year, facility])

    useEffect(() => {
        if (refrigerantGasData && refrigerantGasData[month]?.length > 0) setAssetRows({ ...refrigerantGasData })
        else setAssetRows({ 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [], 11: [] })
    }, [refrigerantGasData, dispatch, month])

    const handleMonthChange = (event, newValue) => {
        setMonth(newValue);
        if (newValue === 12) {
            dispatch(getRefrigerantGasDataTotals(year, facility))
        } else {
            dispatch(getRefrigerantGasData(year, facility))
            dispatch(geHighestId('refrigerant-gas'))
        }
    };

    const handleAddAsset = async () => {
        await dispatch(geHighestId('refrigerant-gas')).then(() => {
            setAssetRows((prevRows) => {
                let highestIdInAssetRows = 0;
                for (let key in assetRows) {
                    let sublist = assetRows[key];
                    for (let item of sublist) {
                        highestIdInAssetRows = Math.max(highestId, item.id);
                    }
                }
                if (highestIdInAssetRows >= highestId) {
                    highestId = ++highestIdInAssetRows;
                } else {
                    highestId = ++highestId
                }
                const prevRowsMonth = [...prevRows[month], { id: highestId, asset: '', unit: 'Kilograms', quantity: '', file: '' }]
                prevRows[month] = prevRowsMonth
                return { ...prevRows }
            })
        })
    }

    const refrigerantItems = [
        { id: 1, value: 'HCFC-22 (R-22)' },
        { id: 2, value: 'HFC-23 (Trifluoromethane, R-23)' },
        { id: 3, value: 'HFC-32 (Difluoromethane, R-32)' },
        { id: 4, value: 'HFC-125 (Pentafluoroethane, R-125)' },
        { id: 5, value: 'HFC-134a (1,1,1,2-Tetrafluoroethane, R-134a)' },
        { id: 6, value: 'HFC-143a (1,1,1-Trifluoroethane, R-143a)' },
        { id: 7, value: 'HFC-152a (1,1-Difluoroethane, R-152a)' },
        { id: 8, value: 'HFC-227ea (Heptafluoropropane, R-227ea)' },
        { id: 9, value: 'Various mixtures like R-404A, R-407C, R-410A, R-507A' },
        { id: 10, value: 'PFC-14 (CF4)' },
        { id: 11, value: 'PFC-116 (C2F6)' },
        { id: 12, value: 'PFC-218 (C3F8)' },
        { id: 13, value: 'Ammonia (R-717)' },
        { id: 14, value: 'Carbon dioxide (R-744)' },
        { id: 15, value: 'Water (R-718)' },
        { id: 16, value: 'Isobutane (R-600a)' },
        { id: 17, value: 'Propane (R-290)' }
    ];

    const handleAssetSelection = (e, month, id) => {
        setAssetRows((prevAssets) => {
            const assetToEdit = prevAssets[month].find((data) => data.id === id)
            assetToEdit['asset'] = e.target.value
            const filteredMonth = prevAssets[month].filter((d) => d.id !== id)
            prevAssets[month] = [...filteredMonth, assetToEdit].sort((a, b) => a.id - b.id)
            return { ...prevAssets }
        })
    }

    const unitItems = [
        { id: 0, value: 'Kilograms' }
    ];

    const handlUnitSelection = (e, month, id) => {
        setAssetRows((prevUnits) => {
            const unitToEdit = prevUnits[month].find((data) => data.id === id)
            unitToEdit['unit'] = e.target.value
            const filteredMonth = prevUnits[month].filter((d) => d.id !== id)
            prevUnits[month] = [...filteredMonth, unitToEdit].sort((a, b) => a.id - b.id)
            return { ...prevUnits }
        })
    }

    const handleInputChange = (e, month, id) => {
        setAssetRows((prevRows) => {
            const quantityToEdit = prevRows[month].find((data) => data.id === id)
            quantityToEdit['quantity'] = e.target.value
            const filteredMonth = prevRows[month].filter((d) => d.id !== id)
            prevRows[month] = [...filteredMonth, quantityToEdit].sort((a, b) => a.id - b.id)
            return { ...prevRows }
        })
    }

    const handleFileUpload = (e, month, id) => {
        setAssetRows((prevRows) => {
            const fileToEdit = prevRows[month].find((data) => data.id === id)
            fileToEdit['file'] = e.target.files[0]
            const filteredMonth = prevRows[month].filter((d) => d.id !== id)
            prevRows[month] = [...filteredMonth, fileToEdit].sort((a, b) => a.id - b.id)
            return { ...prevRows }
        })
    }

    const handleRefrigerantGasDataSubmission = () => {
        function removeEmptyValues(obj) {
            for (let key in obj) {
                obj[key] = obj[key].filter(item => item.asset !== '' && item.quantity !== '');
                if (obj[key].length === 0) {
                    delete obj[key];
                }
            }
            return obj;
        }
        const clonedAssetRows = removeEmptyValues({ ...assetRows })

        let formData = new FormData();

        Object.keys(clonedAssetRows).forEach((key) => {
            clonedAssetRows[key].forEach(item => {
                if (item.file?.name) {
                    formData.append(item.id, item.file);
                }
            });
        });

        dispatch(postRefrigerantGasData(clonedAssetRows, year, company, group, facility)).then(() => {
            dispatch(directGhgEnmissionsAndRemovalsFileUpload(formData, 'refrigerant-gas'))

        })
    }

    return (
        <>
            <Tabs
                value={month}
                onChange={handleMonthChange}
                sx={{ width: '100%', float: 'left' }}
                textColor='inherit'
                indicatorColor='secondary'
            >
                {months.map((m, i) => {
                    return (
                        <Tab key={i} label={m} sx={{ minWidth: '25px' }} />
                    )
                })}
            </Tabs>
            {month === 12 ? <RefrigerantGasTotalTable refrigerantGasDataTotals={refrigerantGasDataTotals} months={months} /> :
                <>
                    <TableContainer component={Paper} sx={{ height: 'auto' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        Asset
                                    </TableCell>
                                    <TableCell align="left">
                                        Unit
                                    </TableCell>
                                    <TableCell align="left">
                                        Value
                                    </TableCell>
                                    <TableCell align="left">
                                        Soure Document
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {assetRows[month]?.map((r, index) => {
                                    return (
                                        // assetRows[month] {
                                        <TableRow key={index}>
                                            <TableCell align="left">
                                                <FormControl sx={{ width: '200px' }}>
                                                    <InputLabel id="refrigerant">{!!r.asset ? r.asset : 'Refrigerant'}</InputLabel>
                                                    <Select
                                                        required={true}
                                                        name="refrigerant"
                                                        value={!!r.asset ? r.asset : 'Refrigerant'}
                                                        onChange={(e) => handleAssetSelection(e, month, r.id)}
                                                        label='Refrigerant'
                                                    >
                                                        {refrigerantItems.map(item => {
                                                            return (
                                                                <MenuItem key={item.id} value={item.value}>
                                                                    {item.value}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell alsign="left">
                                                <FormControl sx={{ width: '200px' }}>
                                                    <InputLabel id="asset">{'Kilograms'}</InputLabel>
                                                    <Select
                                                        required={true}
                                                        name="unit"
                                                        value={'Kilograms'}
                                                        onChange={(e) => handlUnitSelection(e, month, r.id)}
                                                        label='Kilograms'
                                                    >
                                                        {unitItems.map(item => {
                                                            return (
                                                                <MenuItem key={item.id} value={item.value}>
                                                                    {item.value}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left">
                                                {/* {console.log('QUANTITY', r.quantity)} */}
                                                <TextField
                                                    required={true}
                                                    fullWidth
                                                    name={'quantity'}
                                                    value={!!r.quantity ? r.quantity : ""}
                                                    onChange={(e) => handleInputChange(e, month, r.id)}
                                                    type="text"
                                                    class="refrigerantGas"

                                                />
                                            </TableCell>{r.quanity}
                                            <TableCell align="left">
                                                {r?.file ? <>
                                                    <TextField
                                                        disabled
                                                        name={'Source document'}
                                                        value={!!r?.file?.name ? r.file.name : !!r?.file ? r.file : ''}
                                                        type='text'
                                                    />
                                                    <input
                                                        style={{ display: 'none' }}
                                                        name={'Source document'}
                                                        onChange={(e) => handleFileUpload(e, month, r.id)}
                                                        type='file'
                                                    />
                                                </> : <TextField
                                                    name={'Source document'}
                                                    // value={!!r?.file?.name ? r.file.name : !!r?.file ? r.file : ''}
                                                    onChange={(e) => handleFileUpload(e, month, r.id)}
                                                    type='file'
                                                />}                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button variant="contained" sx={{ m: 2 }} onClick={handleAddAsset}>Add Refrigerant Gas entry</Button>
                    <Button variant="contained" sx={{ m: 2 }} onClick={handleRefrigerantGasDataSubmission}>Submit data</Button>
                </>}
        </>
    )
}

export default RefrigerantDataCapturing;