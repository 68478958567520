import CarbonFootprintCalculator from "./CarbonFootprintCalculator";
import BiocharUnitEconCalculator from "./BiocharUnitEconimcsCalc";
import { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Modal,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function IkhalaCalculator() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCalculator, setSelectedCalculator] = useState(null);

  const handleModalOpen = (calculatorId) => {
    setSelectedCalculator(calculatorId);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedCalculator(null);
  };

  const items = [
    // {
    //   id: "cf-calculator",
    //   title: "Light Carbon Footprint Calculator",
    //   desc: "Calculate your high-level carbon footprint here (South Africa)",
    // },
    {
      id: "biochar-unit-calculator",
      title: "Biochar Unit Economics",
      desc: "Use this calculator to play around and understand the unit economics of your biochar project",
    },
  ];

  // Function to decide which calculator to display
  const renderCalculator = () => {
    switch (selectedCalculator) {
       case 'cf-calculator':
        return <CarbonFootprintCalculator />;
      case 'biochar-unit-calculator':
        return <BiocharUnitEconCalculator handleClose={handleModalClose} />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" component="h4" sx={{ mb: 3 }}>
        Quick access Calculators
      </Typography>

      <Grid container spacing={6}>
        {items.map((item) => (
          <Grid xs={3} item key={item.id}>
            <Card variant="outlined">
              <CardContent sx={{ minHeight: "5em" }}>
                <Typography variant="h5">{item.title}</Typography>
                <Typography color="text.secondary">{item.desc}</Typography>
              </CardContent>
              <CardActions>
                <Button variant="outlined" size="small" onClick={() => handleModalOpen(item.id)}>
                  Open Calculator
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Box sx={style}>
          {renderCalculator()}
        </Box>
      </Modal>
    </Box>
  );
}
