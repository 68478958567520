import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Link,
  TextareaAutosize,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import {
  getFlamingCombustionData,
  verifyBiocharData,
} from '../../../../../../../actions/forms';
import Checkmark from '../../../../../../../assets/images/check.png';
import Cross from '../../../../../../../assets/images/cross.png';
import { UPLOADED_DOCS } from '../../../../../../../actions/constants';

function FlamingCombustionVerification({ flamingCombustionData, projectKey }) {
  const dispatch = useDispatch();
  const [flamingCombustion, setFlamingCombustion] = useState([]);

  useEffect(() => {
    dispatch(getFlamingCombustionData(projectKey));
  }, [dispatch, projectKey]);

  const flamingCombustionTableHeadings = [
    'Batch ID',
    'Kiln ID',
    'Video Link',
    'Flaming Combustion',
    'Please make notes on burn quality',
    'Verified',
  ];

  useEffect(() => {
    if (flamingCombustionData) {
      setFlamingCombustion(
        flamingCombustionData.flatMap(d => {
          return [
            {
              id: d.id,
              batch: d.batch,
              kilnID: d.kilnUsed,
              burnVideo: d.burnVideo1,
              flamingCombustion:
                d?.valueCorrect === true || d?.valueCorrect === false
                  ? d.valueCorrect
                  : null,
              notes: d?.notesBurnVideo1 ? d.notesBurnVideo1 : '',
              verified: d?.burnVideo1Verified ? d?.burnVideo1Verified : null,
            },
            {
              id: d.id,
              batch: d.batch,
              kilnID: d.kilnUsed,
              burnVideo: d.burnVideo2,
              flamingCombustion:
                d?.valueCorrect === true || d?.valueCorrect === false
                  ? d.valueCorrect
                  : null,
              notes: d?.notesBurnVideo2 ? d.notesBurnVideo2 : '',
              verified: d?.burnVideo2Verified ? d?.burnVideo2Verified : null,
            },
            {
              id: d.id,
              batch: d.batch,
              kilnID: d.kilnUsed,
              burnVideo: d.burnVideo3,
              flamingCombustion:
                d?.valueCorrect === true || d?.valueCorrect === false
                  ? d.valueCorrect
                  : null,
              notes: d?.notesBurnVideo3 ? d.notesBurnVideo3 : '',
              verified: d?.burnVideo3Verified ? d?.burnVideo3Verified : null,
            },
          ];
        })
      );
    }
  }, [flamingCombustionData, dispatch]);
  // console.log("🚀 ~ BF005VerificationRequest ~ feedstockVerificationData:", feedStockMoisture)
  const handleSubmitFlamingCombustion = async () => {
    await dispatch(
      verifyBiocharData(projectKey, 'flamingCombustion', flamingCombustion)
    );
    await dispatch(getFlamingCombustionData(projectKey));
  };

  const handleFlamingCombustionChange = (event, burnVideo) => {
    setFlamingCombustion(flamingCombustion => {
      const updated = flamingCombustion.map(v => {
        if (v.burnVideo === burnVideo) {
          v.flamingCombustion = !!+event.target.value;
        }
        return v;
      });
      return updated;
    });
  };

  const handleNotes = (event, burnVideo) => {
    setFlamingCombustion(flamingCombustion => {
      const updated = flamingCombustion.map(v => {
        if (v.burnVideo === burnVideo) {
          v.notes = event.target.value;
        }
        return v;
      });
      return updated;
    });
  };

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Typography variant='h6' sx={{ mb: 2 }}>
        Flaming combustion
      </Typography>
      <TableContainer component={Paper} sx={{ height: 'auto', width: '100%' }}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              {flamingCombustionTableHeadings.map((heading, i) => {
                return (
                  <TableCell align='left' key={i}>
                    {heading}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {flamingCombustion?.length > 0
              ? flamingCombustion?.map((d, i) => {
                if (!!d.burnVideo) {
                  return (
                    <TableRow key={i}>
                      <TableCell align='left'>{d.batch}</TableCell>
                      <TableCell align='left'>{d.kilnID}</TableCell>
                      <TableCell align='left'>
                        <Link
                          target='_blank'
                          href={`${UPLOADED_DOCS}${d?.burnVideo
                            ?.split('/')
                            .pop()}`}
                        >
                          {`Download Burn Video ${++i}`}
                        </Link>
                      </TableCell>
                      <TableCell align='left'>
                        <RadioGroup
                          value={
                            d?.flamingCombustion === true
                              ? 1
                              : d?.flamingCombustion === false
                                ? 0
                                : null
                          }
                          row
                          name='flamingCombustion'
                          onChange={event =>
                            handleFlamingCombustionChange(event, d.burnVideo)
                          }
                        >
                          <FormControlLabel
                            value={1}
                            control={<Radio required />}
                            label='Yes'
                            name='flamingCombustion'
                            disabled={d?.verified}
                          />
                          <FormControlLabel
                            value={0}
                            control={<Radio required />}
                            label='No'
                            name='flamingCombustion'
                            disabled={d?.verified}
                          />
                        </RadioGroup>
                      </TableCell>
                      <TableCell align='left'>
                        <TextareaAutosize
                          // label='Site Manager Phone'
                          name='correction'
                          value={d.notes}
                          onChange={event => handleNotes(event, d.burnVideo)}
                          // sx={{ background: '#fff' }}
                          required
                          fullWidth
                          type='text'
                          disabled={d?.verified === true ? true : false}
                        />
                      </TableCell>
                      <TableCell align='left'>
                        {d?.verified ? (
                          <img src={Checkmark} alt="checkmark" width={25} height={25} />
                        ) : (
                          <img src={Cross} alt="cross" width={25} height={25} />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                } else return null
              })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        sx={{ mt: 2 }}
        variant='contained'
        onClick={handleSubmitFlamingCombustion}
      >
        Verify Flaming Combustion
      </Button>
    </Box>
  );
}

const mapStateToProps = state => ({
  flamingCombustionData: state.postFormData.flamingCombustionVerificationData,
  loading: state.postFormData.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    getFlamingCombustionVerificationData: projectId =>
      dispatch(getFlamingCombustionData(projectId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlamingCombustionVerification);
