import {
    GET_WOODEN_PRODUCTS_DATA_SUCCESS,
    GET_WOODEN_PRODUCTS_DATA_FAIL,
    POST_WOODEN_PRODUCTS_DATA_SUCCESS,
    POST_WOODEN_PRODUCTS_DATA_FAIL,
    GET_WOODEN_PRODUCTS_DATA_TOTALS_SUCCESS,
    GET_WOODEN_PRODUCTS_DATA_TOTALS_FAIL
} from '../../../actions/carbonfootprint-rework/types/04-indirect-ghg-emissions-from-products-used-by-an-organisation';

const initialState = {
    loading: true,
    woodenProductsData: [],
    woodenProductsDataTotals: []
};

export default function indirectGhgEmissionsFromProductsForWoodenProductsReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case POST_WOODEN_PRODUCTS_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_WOODEN_PRODUCTS_DATA_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_WOODEN_PRODUCTS_DATA_SUCCESS:
            return {
                ...state,
                woodenProductsData: payload,
                loading: false,
            };
        case GET_WOODEN_PRODUCTS_DATA_FAIL:
            return {
                ...state,
                woodenProductsData: payload,
                loading: false
            }
        case GET_WOODEN_PRODUCTS_DATA_TOTALS_SUCCESS:
            return {
                ...state,
                woodenProductsDataTotals: payload,
                loading: false,
            };
        case GET_WOODEN_PRODUCTS_DATA_TOTALS_FAIL:
            return {
                ...state,
                woodenProductsDataTotals: payload,
                loading: false
            }
        default:
            return state;
    }
}