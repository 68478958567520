import React, { useEffect, useRef, useState } from 'react';
import Map from 'ol/Map';
import { View } from 'ol';
import MapContext from '../../context/MapContext';

export const ReOlMap = ({ children, center, zoom }) => {
  const mapRef = useRef();
  const [map, setMap] = useState(null);

  // create map
  useEffect(() => {
    let mapObj = new Map({
      layers: [],
      view: new View({
        center,
        zoom,
      }),
    });

    mapObj.setTarget(mapRef.current);
    setMap(mapObj);
    return () => mapObj.setTarget(undefined);
  }, [zoom, center]);

  // // zoom change handler
  useEffect(() => {
    if (!map) return;

    map.getView().setZoom(zoom);
  });

  // center change handler
  useEffect(() => {
    if (!map) return;

    map.getView().setCenter(center);
  });
  return (
    <MapContext.Provider value={{ map }}>
      <div ref={mapRef} className='map'>
        {children}
      </div>
    </MapContext.Provider>
  );
};
