import {
    GET_WATER_DATA_SUCCESS,
    GET_WATER_DATA_FAIL,
    POST_WATER_DATA_SUCCESS,
    POST_WATER_DATA_FAIL,
    GET_WATER_DATA_TOTALS_SUCCESS,
    GET_WATER_DATA_TOTALS_FAIL
} from '../../../actions/carbonfootprint-rework/types/04-indirect-ghg-emissions-from-products-used-by-an-organisation';

const initialState = {
    loading: true,
    waterData: [],
    waterDataTotals: []
};

export default function indirectGhgEmissionsFromProductsForWaterReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case POST_WATER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_WATER_DATA_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_WATER_DATA_SUCCESS:
            return {
                ...state,
                waterData: payload,
                loading: false,
            };
        case GET_WATER_DATA_FAIL:
            return {
                ...state,
                waterData: payload,
                loading: false
            }
        case GET_WATER_DATA_TOTALS_SUCCESS:
            return {
                ...state,
                waterDataTotals: payload,
                loading: false,
            };
        case GET_WATER_DATA_TOTALS_FAIL:
            return {
                ...state,
                waterDataTotals: payload,
                loading: false
            }
        default:
            return state;
    }
}