import {
    ADD_ASSET_SUCCESS,
    ADD_ASSET_FAIL,
    GET_ASSETS_SUCCESS,
    GET_ASSETS_FAIL
} from '../../../actions/carbonfootprint-rework/types/01-direct-ghg-emissions-and-removals-types';


const initialState = {
    loading: true,
    assetData: []
};

// PETROL REDUCERS
export default function
    directGhgEmissionsAndRemovalsForAssets(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        // ADD ASSET
        case ADD_ASSET_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case ADD_ASSET_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_ASSETS_SUCCESS:
            return {
                ...state,
                loading: false,
                assetData: payload
            };
        case GET_ASSETS_FAIL:
            return {
                ...state,
                loading: false,
                assetData: payload
            }
        default:
            return state;
    }
}


