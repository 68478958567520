export const GET_WASTE_TO_LANDFILL_DATA_SUCCESS = 'GET_WASTE_TO_LANDFILL_DATA_SUCCESS';
export const GET_WASTE_TO_LANDFILL_DATA_FAIL = 'GET_WASTE_TO_LANDFILL_DATA_FAIL';
export const POST_WASTE_TO_LANDFILL_DATA_SUCCESS = 'POST_WASTE_TO_LANDFILL_DATA_SUCCESS';
export const POST_WASTE_TO_LANDFILL_DATA_FAIL = 'POST_WASTE_TO_LANDFILL_DATA_FAIL';
export const GET_WASTE_TO_LANDFILL_DATA_TOTALS_SUCCESS = 'GET_WASTE_TO_LANDFILL_DATA_TOTALS_SUCCESS';
export const GET_WASTE_TO_LANDFILL_DATA_TOTALS_FAIL = 'GET_WASTE_TO_LANDFILL_DATA_TOTALS_FAIL';

export const GET_WASTE_TO_RECYCLING_DATA_SUCCESS = 'GET_WASTE_TO_RECYCLING_DATA_SUCCESS';
export const GET_WASTE_TO_RECYCLING_DATA_FAIL = 'GET_WASTE_TO_RECYCLING_DATA_FAIL';
export const POST_WASTE_TO_RECYCLING_DATA_SUCCESS = 'POST_WASTE_TO_RECYCLING_DATA_SUCCESS';
export const POST_WASTE_TO_RECYCLING_DATA_FAIL = 'POST_WASTE_TO_RECYCLING_DATA_FAIL';
export const GET_WASTE_TO_RECYCLING_DATA_TOTALS_SUCCESS = 'GET_WASTE_TO_RECYCLING_DATA_TOTALS_SUCCESS';
export const GET_WASTE_TO_RECYCLING_DATA_TOTALS_FAIL = 'GET_WASTE_TO_RECYCLING_DATA_TOTALS_FAIL';

export const GET_WASTE_TO_COMPOSTING_DATA_SUCCESS = 'GET_WASTE_TO_COMPOSTING_DATA_SUCCESS';
export const GET_WASTE_TO_COMPOSTING_DATA_FAIL = 'GET_WASTE_TO_COMPOSTING_DATA_FAIL';
export const POST_WASTE_TO_COMPOSTING_DATA_SUCCESS = 'POST_WASTE_TO_COMPOSTING_DATA_SUCCESS';
export const POST_WASTE_TO_COMPOSTING_DATA_FAIL = 'POST_WASTE_TO_COMPOSTING_DATA_FAIL';
export const GET_WASTE_TO_COMPOSTING_DATA_TOTALS_SUCCESS = 'GET_WASTE_TO_COMPOSTING_DATA_TOTALS_SUCCESS';
export const GET_WASTE_TO_COMPOSTING_DATA_TOTALS_FAIL = 'GET_WASTE_TO_COMPOSTING_DATA_TOTALS_FAIL';

export const GET_WASTE_TO_BIOCHAR_PRODUCTION_DATA_SUCCESS = 'GET_WASTE_TO_BIOCHAR_PRODUCTION_DATA_SUCCESS';
export const GET_WASTE_TO_BIOCHAR_PRODUCTION_DATA_FAIL = 'GET_WASTE_TO_BIOCHAR_PRODUCTION_DATA_FAIL';
export const POST_WASTE_TO_BIOCHAR_PRODUCTION_DATA_SUCCESS = 'POST_WASTE_TO_BIOCHAR_PRODUCTION_DATA_SUCCESS';
export const POST_WASTE_TO_BIOCHAR_PRODUCTION_DATA_FAIL = 'POST_WASTE_TO_BIOCHAR_PRODUCTION_DATA_FAIL';
export const GET_WASTE_TO_BIOCHAR_PRODUCTION_DATA_TOTALS_SUCCESS = 'GET_WASTE_TO_BIOCHAR_PRODUCTION_DATA_TOTALS_SUCCESS';
export const GET_WASTE_TO_BIOCHAR_PRODUCTION_DATA_TOTALS_FAIL = 'GET_WASTE_TO_BIOCHAR_PRODUCTION_DATA_TOTALS_FAIL';