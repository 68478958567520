import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography, TextField } from '@mui/material';

const AddProjectDialog = ({
    openProjectModal,
    setOpenProjectModal,
    projectName,
    setProjectName,
    handleSubmitProject }) => {

    return (
        <Dialog open={openProjectModal} onClose={() => setOpenProjectModal(false)} >
            <DialogTitle>Add New Project</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
                <Divider sx={{ my: 1 }} />
                <Typography>Project Name</Typography>
                <TextField
                    name='projectName'
                    value={projectName}
                    onChange={e => setProjectName(e.target.value)}
                    fullWidth
                    sx={{ mb: 2 }}
                />
            </DialogContent>
            <DialogActions sx={{ mr: 'auto', mb: 3, ml: 2 }}>
                <Button variant='contained' onClick={handleSubmitProject}>
                    Add
                </Button>
                <Button variant='outlined' onClick={() => setOpenProjectModal(false)}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddProjectDialog