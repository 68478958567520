import { Alert, Box, Button, Divider, Grid, Typography } from '@mui/material';

export default function CatFiveSelection({ setSelection, setCatFive }) {

    const catFive = [
        'Waste Generation'
    ]

    return (
        <Grid item xs={12}>
            <Typography variant='h5'>Category</Typography>
            <Divider />
            <Alert severity='info'>Please select a category</Alert>
            <Box marginTop={2}>
                {catFive.map((c, i) => {
                    return (
                        <Button
                            key={i}
                            variant='contained'
                            size='large'
                            sx={{ width: '35%', display: 'block', mb: 2.5 }}
                            onClick={() => {
                                setCatFive(c)
                                setSelection({ id: 9, title: c })
                                localStorage.setItem('catFive', c)
                            }}
                        >
                            {c}
                        </Button>
                    )
                })
                }
            </Box>
        </Grid>
    );
}
