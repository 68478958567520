import { useEffect } from 'react';
import {
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField, Tooltip,
    Typography,
} from '@mui/material';
import tooltips from "../../../../../../store/tooltips";
import InfoIcon from "@mui/icons-material/Info";

export default function BF019BurnTeamPayments({ data, setData, setFormId }) {
  useEffect(() => {
    setFormId('BF019');
  }, [setFormId]);

  const { minWage, teamSize, prodDays, valuePaid, teamSizeWomen } = data;

  return (
    <>
      <Typography variant='h5' sx={{ mb: 2 }}>
        Burn team payments
      </Typography>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Have you asked, or can you verify that the team producing biochar is at
        least earning minimum wage?
          <Tooltip
              title={
                  <h2 style={{ color: "lightgreen" }}>
                      {tooltips.burnTeamPaymentsQ1}
                  </h2>
              }
          >
              {<InfoIcon sx={{ width: 15 }} />}
          </Tooltip>
      </Typography>
      <RadioGroup row name='minWage' onChange={setData} value={minWage}>
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='minWage'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='minWage'
        />
      </RadioGroup>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        How many team members were on site?
          <Tooltip
              title={
                  <h2 style={{ color: "lightgreen" }}>
                      {tooltips.burnTeamPaymentsQ2}
                  </h2>
              }
          >
              {<InfoIcon sx={{ width: 15 }} />}
          </Tooltip>
      </Typography>
      <TextField
        name='teamSize'
        label='How many team members were on site?'
        value={teamSize}
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        How many women were part of the team?
          <Tooltip
              title={
                  <h2 style={{ color: "lightgreen" }}>
                      {tooltips.burnTeamPaymentsQ3}
                  </h2>
              }
          >
              {<InfoIcon sx={{ width: 15 }} />}
          </Tooltip>
      </Typography>
      <TextField
        name='teamSizeWomen'
        label='How many women were part of the team?'
        value={teamSizeWomen}
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Total days of production
          <Tooltip
              title={
                  <h2 style={{ color: "lightgreen" }}>
                      {tooltips.burnTeamPaymentsQ4}
                  </h2>
              }
          >
              {<InfoIcon sx={{ width: 15 }} />}
          </Tooltip>
      </Typography>
      <TextField
        name='prodDays'
        label='Total days of production'
        value={prodDays}
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Total amount allocated to ground team salaries
          <Tooltip
              title={
                  <h2 style={{ color: "lightgreen" }}>
                      {tooltips.burnTeamPaymentsQ5}
                  </h2>
              }
          >
              {<InfoIcon sx={{ width: 15 }} />}
          </Tooltip>
      </Typography>
      <TextField
        name='valuePaid'
        label='Amount'
        value={valuePaid}
        onChange={setData}
        fullWidth
      />
    </>
  );
}
