import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  TextField,
  Link,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import {
  getFeedstockVerificationData,
  verifyBiocharData,
} from '../../../../../../../actions/forms';
import Checkmark from '../../../../../../../assets/images/check.png';
import Cross from '../../../../../../../assets/images/cross.png';
import { UPLOADED_DOCS } from '../../../../../../../actions/constants';

function FeedstockMoistureVerification({
  feedstockVerificationData,
  projectKey,
}) {
  const dispatch = useDispatch();
  const [feedStockMoisture, setFeedstockMoisture] = useState([]);

  useEffect(() => {
    dispatch(getFeedstockVerificationData(projectKey));
  }, [dispatch, projectKey]);

  const feedStockVerficationTableHeadings = [
    'Batch ID',
    'Kiln ID',
    'Value',
    'Image',
    'Value Correct',
    'Correction',
    'Verified',
  ];

  useEffect(() => {
    if (feedstockVerificationData) {
      console.log("🚀 ~ useEffect ~ feedstockVerificationData:", feedstockVerificationData)
      setFeedstockMoisture(
        feedstockVerificationData.map(d => {
          return {
            id: d.id,
            batch: d.batch,
            kilnID: d.kilnID,
            measurement: d.moistureMeasurement,
            moistureContentPhoto: d.moistureContentPhoto,
            valueCorrect:
              d?.valueCorrect === true || d?.valueCorrect === false
                ? d.valueCorrect
                : null,
            correction: d?.correction ? d.correction : null,
            verified: d?.verified ? d?.verified : null,
          };
        })
      );
    }
  }, [feedstockVerificationData, dispatch]);
  // console.log("🚀 ~ BF005VerificationRequest ~ feedstockVerificationData:", feedStockMoisture)
  const handleSubmitFeedStockMoisture = async () => {
    await dispatch(
      verifyBiocharData(projectKey, 'feedstockMoisture', feedStockMoisture)
    );
    await dispatch(getFeedstockVerificationData(projectKey));
  };

  const handleValueCorrectChange = (event, batch) => {
    setFeedstockMoisture(feedStockMoisture => {
      const updated = feedStockMoisture.map(v => {
        if (v.batch === batch) {
          v.valueCorrect = !!+event.target.value;
        }
        return v;
      });
      return updated;
    });
  };

  const handleCorrection = (event, batch) => {
    setFeedstockMoisture(feedStockMoisture => {
      const updated = feedStockMoisture.map(v => {
        if (v.batch === batch) {
          v.correction = event.target.value;
        }
        return v;
      });
      return updated;
    });
  };

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Typography variant='h6' sx={{ mb: 2 }}>
        Feedstock Moisture
      </Typography>
      <TableContainer component={Paper} sx={{ height: 'auto', width: '100%' }}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              {feedStockVerficationTableHeadings.map((heading, i) => {
                return (
                  <TableCell align='left' key={i}>
                    {heading}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {feedStockMoisture?.length > 0
              ? feedStockMoisture?.map((d, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell align='left'>{d.batch}</TableCell>
                    <TableCell align='left'>{d.kilnID}</TableCell>
                    <TableCell align='left'>{d.measurement}</TableCell>
                    <TableCell align='left'>
                      <Link
                        target='_blank'
                        href={`${UPLOADED_DOCS}${d?.moistureContentPhoto
                          ?.split('/')
                          .pop()}`}
                      >
                        <img
                          height='150'
                          width='150'
                          src={`${UPLOADED_DOCS}${d?.moistureContentPhoto
                            ?.split('/')
                            .pop()}`}
                          alt='moisture measurement'
                        />
                      </Link>
                    </TableCell>
                    <TableCell align='left'>
                      <RadioGroup
                        value={
                          d?.valueCorrect === true
                            ? 1
                            : d?.valueCorrect === false
                              ? 0
                              : null
                        }
                        row
                        name='valueCorrect'
                        onChange={event =>
                          handleValueCorrectChange(event, d.batch)
                        }
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio required />}
                          label='Yes'
                          name='valueCorrect'
                          disabled={d?.verified}
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio required />}
                          label='No'
                          name='valueCorrect'
                          disabled={d?.verified}
                        />
                      </RadioGroup>
                    </TableCell>
                    <TableCell align='left'>
                      <TextField
                        // label='Site Manager Phone'
                        name='correction'
                        value={d.correction}
                        onChange={event => handleCorrection(event, d.batch)}
                        sx={{ background: '#fff' }}
                        required
                        fullWidth
                        type='number'
                        disabled={
                          d?.verified || d?.valueCorrect === true
                            ? true
                            : false
                        }
                      />
                    </TableCell>
                    <TableCell align='left'>
                      {d?.verified ? (
                        <img src={Checkmark} alt="checkmark" width={25} height={25} />
                      ) : (
                        <img src={Cross} alt="cross" width={25} height={25} />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        sx={{ mt: 2 }}
        variant='contained'
        onClick={handleSubmitFeedStockMoisture}
      >
        Verify Feedstock Moisture
      </Button>
    </Box>
  );
}

const mapStateToProps = state => ({
  feedstockVerificationData: state.postFormData.feedstockVerificationData,
  loading: state.postFormData.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    getFeedstockVerificationData: projectId =>
      dispatch(getFeedstockVerificationData(projectId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedstockMoistureVerification);
