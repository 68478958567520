import {
    POST_LPG_DATA_SUCCESS,
    POST_LPG_DATA_FAIL,
    GET_LPG_DATA_SUCCESS,
    GET_LPG_DATA_FAIL,
    GET_LPG_DATA_TOTALS_SUCCESS,
    GET_LPG_DATA_TOTALS_FAIL
} from '../../../actions/carbonfootprint-rework/types/01-direct-ghg-emissions-and-removals-types';


const initialState = {
    loading: true,
    lpgData: [],
    lpgDataTotals: []
};

// PETROL REDUCERS
export default function
    directGhgEmissionsAndRemovalsForLpg(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        // STATIONARY PETROL
        case POST_LPG_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_LPG_DATA_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_LPG_DATA_SUCCESS:
            return {
                ...state,
                lpgData: payload,
                loading: false,
            };
        case GET_LPG_DATA_FAIL:
            return {
                ...state,
                lpgData: payload,
                loading: false
            }
        case GET_LPG_DATA_TOTALS_SUCCESS:
            return {
                ...state,
                lpgDataTotals: payload,
                loading: false,
            };
        case GET_LPG_DATA_TOTALS_FAIL:
            return {
                ...state,
                lpgDataTotals: payload,
                loading: false
            }

        default:
            return state;
    }
}


