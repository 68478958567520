import React from 'react'
import { TableContainer, Table, TableHead, TableCell, TableRow, Paper, TableBody } from '@mui/material';

const Gwp = () => {
    return (
        <>
            <h2>GWP</h2>
            <TableContainer key={0} component={Paper} sx={{ height: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                Description GWP
                            </TableCell>
                            <TableCell align="left">
                                GWP Emission Factor Type
                            </TableCell>
                            <TableCell align="left">
                                Emission Factor
                            </TableCell>
                            <TableCell align="left">
                                Unit
                            </TableCell>
                            <TableCell align="left">
                                Source
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">Methane GWP_100y</TableCell>
                            <TableCell align="left">gwpEFactor_ch4_100y</TableCell>
                            <TableCell align="left">28</TableCell>
                            <TableCell align="left">tCO2e/tCH4</TableCell>
                            <TableCell align="left">AR5</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">N2OGWP_100y</TableCell>
                            <TableCell align="left">gwpEFactor_n2o_100y</TableCell>
                            <TableCell align="left">265</TableCell>
                            <TableCell align="left">tCO2e/tN2O</TableCell>
                            <TableCell align="left">AR5</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">CO2GWP_100y</TableCell>
                            <TableCell align="left">gwpEFactor_co2_100y</TableCell>
                            <TableCell align="left">1</TableCell>
                            <TableCell align="left">tCO2e/tCO2</TableCell>
                            <TableCell align="left">AR5</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default Gwp