import React from 'react'
import { Divider, Grid, Button } from '@mui/material';
import Transport from './global-variable-tables/factors/Transport';
import Fuel from './global-variable-tables/factors/Fuel';
import Gwp from './global-variable-tables/factors/Gwp';
import AddBins from './global-variable-tables/AddBins';
import AddEquipment from './global-variable-tables/AddEquipment';
import ConversionFactors from './global-variable-tables/factors/ConversionFactors';
import EmissionFactors from './global-variable-tables/factors/EmissionFactors';

const GlobalVariables = () => {
    const [selected, setSelected] = React.useState(0)

    const items = [
        {
            id: 0,
            title: 'Add Biochar Bins',
        },
        {
            id: 1,
            title: 'Add Biochar Equipment',
        },
        {
            id: 2,
            title: 'Factors',
        }
    ]

    return (
        <>
            <h1>Global Variables</h1>
            <Divider sx={{ my: 2 }} />
            <Grid container>
                <Grid item xs={3} sx={{ p: 2 }} display='flex' flexDirection={'column'}>
                    {items.map((item, index) => {
                        return (
                            <Button sx={{ mb: 3 }} variant={selected === index ? 'contained' : 'outlined'} key={index} onClick={() => setSelected(index)}>{item.title}</Button>
                        )
                    })}
                </Grid>
                <Grid item xs={9} sx={{ p: 2 }}>
                    {selected === 0 ? <AddBins /> :
                        selected === 1 ? <AddEquipment /> :
                            selected === 2 ?
                                <>
                                    <Transport />
                                    <Fuel />
                                    <Gwp />
                                    <ConversionFactors />
                                    <EmissionFactors />
                                </>
                                : null}
                </Grid>
            </Grid>
        </>
    )
}

export default GlobalVariables