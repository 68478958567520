// POST ELETRICITY PRODUCED SUCCESS & FAIL
export const POST_ELECTRICITY_PURCHASED_DATA_SUCCESS = 'POST_ELECTRICITY_PURCHASED_DATA_SUCCESS';
export const POST_ELECTRICITY_PURCHASED_DATA_FAIL = 'POST_ELECTRICITY_PURCHASED_DATA_FAIL';

// GET ELETRICITY PRODUCED SUCCESS & FAIL
export const GET_ELECTRICITY_PURCHASED_DATA_SUCCESS = 'GET_ELECTRICITY_PURCHASED_DATA_SUCCESS';
export const GET_ELECTRICITY_PURCHASED_DATA_FAIL = 'GET_ELECTRICITY_PURCHASED_DATA_FAIL';

// GET ELETRICITY PRODUCED TOTALS
export const GET_ELECTRICITY_PURCHASED_DATA_TOTALS_SUCCESS = 'GET_ELECTRICITY_PURCHASED_DATA_TOTALS_SUCCESS';
export const GET_ELECTRICITY_PURCHASED_DATA_TOTALS_FAIL = 'GET_ELECTRICITY_PURCHASED_DATA_TOTALS_FAIL';

// POST RENEWABLE ENERGY SUCCESS & FAIL
export const POST_RENEWABLE_ENERGY_DATA_SUCCESS = 'POST_RENEWABLE_ENERGY_DATA_SUCCESS';
export const POST_RENEWABLE_ENERGY_DATA_FAIL = 'POST_RENEWABLE_ENERGY_DATA_FAIL';

// GET RENEWABLE ENERGY SUCCESS & FAIL
export const GET_RENEWABLE_ENERGY_DATA_SUCCESS = 'GET_RENEWABLE_ENERGY_DATA_SUCCESS';
export const GET_RENEWABLE_ENERGY_DATA_FAIL = 'GET_RENEWABLE_ENERGY_DATA_FAIL';

// GET RENEWABLE ENERGY TOTALS
export const GET_RENEWABLE_ENERGY_DATA_TOTALS_SUCCESS = 'GET_RENEWABLE_ENERGY_DATA_TOTALS_SUCCESS';
export const GET_RENEWABLE_ENERGY_DATA_TOTALS_FAIL = 'GET_RENEWABLE_ENERGY_DATA_TOTALS_FAIL';

