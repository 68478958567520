import { connect } from 'react-redux';
import { getChargingData } from '../../../../../../actions/forms';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
    Box, Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell, Grid, Button
} from '@mui/material';
import { UPLOADED_DOCS } from '../../../../../../actions/constants';
const Charging = ({ projectKey, active, next, chargingData }) => {
    const dispatch = useDispatch();

    const items = [
        {
            id: 0,
            name: 'Biochar Charging',
        }
    ];

    useEffect(() => {
        dispatch(getChargingData(projectKey))
    }, [dispatch, projectKey]);

    return (
        <Grid container>
            <Grid item xs={3} sx={{ borderRight: '1px solid', p: 2 }}>
                {items.map(item => (
                    <Button
                        fullWidth
                        key={item.id}
                        onClick={() => next(item.id)}
                        variant={active === item.id ? 'outlined' : null}
                    >
                        {item.name}
                    </Button>
                ))}
            </Grid>

            <Grid item xs={9} sx={{ p: 2 }}>
                <h3>Biochar Charging Data</h3>
                {chargingData.map((ad, index) => {
                    return (
                        <>
                            <TableContainer key={index} component={Paper} sx={{ height: 'auto' }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                Description
                                            </TableCell>
                                            <TableCell align="left">
                                                Value
                                            </TableCell>
                                            <TableCell align="left">
                                                Unit
                                            </TableCell>
                                            <TableCell align="left">
                                                Visual Proof
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        <TableRow >
                                            <TableCell align="left">
                                                Batch being charged
                                            </TableCell>
                                            <TableCell align="left">
                                                <b>{ad?.batchId ? <>{ad?.batchId}</> : null}</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                #
                                            </TableCell>
                                            <TableCell align="left">
                                                N/A
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left">
                                                {"Charging amount (% of batch)"}
                                            </TableCell>
                                            <TableCell align="left">
                                                {ad?.numberOfBags ? <>{ad?.numberOfBags}</> : null}
                                            </TableCell>
                                            <TableCell align="left">
                                                %
                                            </TableCell>
                                            <TableCell align="left">
                                                {ad?.chargePhoto ? <>{ad?.chargePhoto.split("/")[ad.chargePhoto.split("/").length - 1]}</> : null}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left">
                                                Charging type
                                            </TableCell>
                                            <TableCell align="left">
                                                {ad?.chargingType ? <>{ad?.chargingType}</> : null}
                                            </TableCell>
                                            <TableCell align="left">
                                                N/A
                                            </TableCell>
                                            <TableCell align="left">
                                                N/A
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left">
                                                Charging location
                                            </TableCell>
                                            <TableCell align="left">
                                                {ad?.chargingLatitude ? <>{ad?.chargingLatitude} / {ad?.chargingLongitude}</> : null}
                                            </TableCell>
                                            <TableCell align="left">
                                                Lat, Long
                                            </TableCell>
                                            <TableCell align="left">
                                                N/A
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', mt: 3 }}>
                                {ad?.chargePhoto ? <h4>Biochar Charging Media</h4> : null}
                                {ad?.chargePhoto ? <img height="300" width="300" src={`${UPLOADED_DOCS}${ad?.chargePhoto?.split("/").pop()}`} alt="url" /> : null}
                                {/* {ad?.biocharPhotoURL ? <h4>Biochar</h4> : null}
                            {ad?.biocharPhotoURL ? <img height="300" width="300" src={`https://ikhala-static-content1.s3.amazonaws.com/uploaded_docs/${ad?.biocharPhotoURL?.split("/").pop()}`} alt="url" /> : null} */}
                            </Box>
                        </>
                    )
                })}

            </Grid>
        </Grid>
    )
}

const mapStateToProps = state => ({
    chargingData: state.postFormData.chargingData,
    loading: state.postFormData.loading,
});

const mapDispatchToProps = dispatch => {
    return {
        getChargingData: projectKey => dispatch(getChargingData(projectKey))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Charging);