// GET routes
export const GET_BATCH_VOLUMES_SUCCESS = 'GET_BATCH_VOLUMES_SUCCESS';
export const GET_BATCH_VOLUMES_FAIL = 'GET_BATCH_VOLUMES_FAIL';

export const GET_BATCH_BULK_DENSITY_SUCCESS = 'GET_BATCH_BULK_DENSITY_SUCCESS';
export const GET_BATCH_BULK_DENSITY_FAIL = 'GET_BATCH_BULK_DENSITY_FAIL';

export const GET_VOL_AND_BD_AVERAGE_SUCCESS = 'GET_VOL_AND_BD_AVERAGE_SUCCESS';
export const GET_VOL_AND_BD_AVERAGE_FAIL = 'GET_VOL_AND_BD_AVERAGE_FAIL';

export const GET_BIOCHAR_MASS_SUCCESS = 'GET_BIOCHAR_MASS_SUCCESS';
export const GET_BIOCHAR_MASS_FAIL = 'GET_BIOCHAR_MASS_FAIL';

export const GET_BATCH_CARBON_CONTENT_SUCCESS = 'GET_BATCH_CARBON_CONTENT_SUCCESS';
export const GET_BATCH_CARBON_CONTENT_FAIL = 'GET_BATCH_CARBON_CONTENT_FAIL';

export const GET_BIOCHAR_STORED_CARBON_SUCCESS = 'GET_BIOCHAR_STORED_CARBON_SUCCESS';
export const GET_BIOCHAR_STORED_CARBON_FAIL = 'GET_BIOCHAR_STORED_CARBON_FAIL';

// DATA MONITORING ROUTES
// GET routes
export const GET_CB_BATCH_DATA_SUCCESS = 'GET_CB_BATCH_DATA_SUCCESS';
export const GET_CB_BATCH_DATA_FAIL = 'GET_CB_BATCH_DATA_FAIL';

export const GET_CB_ADDITIONAL_BATCH_DATA_SUCCESS = 'GET_CB_ADDITIONAL_BATCH_DATA_SUCCESS';
export const GET_CB_ADDITIONAL_BATCH_DATA_FAIL = 'GET_CB_ADDITIONAL_BATCH_DATA_FAIL';

// POST routes
export const POST_VOL_AND_BD_AVERAGE_SUCCESS = 'POST_VOL_AND_BD_AVERAGE_SUCCESS';
export const POST_VOL_AND_BD_AVERAGE_FAIL = 'POST_VOL_AND_BD_AVERAGE_FAIL';

export const POST_BATCH_CARBON_CONTENT_SUCCESS = 'POST_BATCH_CARBON_CONTENT_SUCCESS';
export const POST_BATCH_CARBON_CONTENT_FAIL = 'POST_BATCH_CARBON_CONTENT_FAIL';

export const POST_BIOCHAR_STORED_CARBON_SUCCESS = 'POST_BIOCHAR_STORED_CARBON_SUCCESS';
export const POST_BIOCHAR_STORED_CARBON_FAIL = 'POST_BIOCHAR_STORED_CARBON_FAIL';