import { useState } from 'react';
import { postFormData, postFormDataFiles } from '../../../../actions/forms';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Box, Typography, Grid, Button, Divider } from '@mui/material';

import FireFighting from './forms/FireFighting';
import PlansAndAgreements from './forms/PlansAndAgreements';
import ComplianceChecklist from './forms/ComplianceChecklist';
import Firebreaks from './forms/Firebreaks';

const FireBreakProof = ({ postFormData, postFormDataFiles }) => {
  const initStateF002 = {
    locatedOn: '',
    FPA_member: 'no',
    fpa_membership_certificate: '',
    EMP_CMP: 'no',
    currentFirebreaks: 'no',
    fireBreakPlan: 'no',
  };
  const initStateF003 = {
    nameOfFirebreak: '',
    firebreak_map: '',
    typeOfFirebreak: '',
    date: '',
    maintenanceScheduled: '',
    nextMaintenanceDate: '',
    fireBreaksShared: '',
    fppDefined: '',
    FPP: '',
  };
  const initStateF005 = {
    managementPlan: '',
    responsibleEntity: '',
    sameEntity: '',
    nameOfEntity: '',
    entity_doc: '',
  };
  const initStateF006 = {
    nameOfFirebreak: '',
    firebreak_map: '',
    dateOfPlansAndAgreements: '',
    fireBreakShared: '',
    fppDefined: '',
    FPP: '',
  };
  const initStateF011 = {
    typeOfFirebreak: '',
    maintenanceDate: '',
    nameOfSubmitter: '',
    phoneOfSubmitter: '',
    maintenanceType: '',
    method: '',
  };
  const initStateF012 = {
    name: '',
    farmName: '',
    firebreak: '',
    doc: '',
  };
  const initStateF013 = {
    nameOfFPA: '',
    membershipExpiryDate: '',
    fpa_certificate: '',
  };

  const initStateF017 = {
    name: '',
    contract: '',
  };
  const initStateF018 = {
    roads: '',
    roads_doc: '',
    signage: '',
    signage_doc: '',
    gateKeys: '',
    gate_keys_doc: '',
    disableElectricFences: '',
    disable_electric_fences_doc: '',
    turningCirclesForFiretrucks: '',
    turning_circles_for_firetrucks_doc: '',
  };
  const initStateF019 = {
    farmSize: '',
    bakkieSakkie: '',
    bakkie_sakkie_doc: '',
    waterTanker1000: '',
    water_tanker_1000_doc: '',
    waterTanker2000: '',
    water_tanker_2000_doc: '',
    rakeHoles: '',
    rake_holes_doc: '',
    beaters: '',
    beaters_doc: '',
    knapsacks: '',
    knapsacks_doc: '',
    dripTorch: '',
    drip_torch_doc: '',
    cellphone: '',
    cellphone_doc: '',
    handHeld: '',
    handheld_doc: '',
    mobileRadio: '',
    mobile_radio_doc: '',
    firstAidKit: '',
    first_aid_kit_doc: '',
    firefighters: '',
    firefighters_doc: '',
    crewLeaders: '',
    crew_leaders_doc: '',
  };
  const [formDataF002, setFormDataF002] = useState(initStateF002);
  const [formDataF003, setFormDataF003] = useState(initStateF003);
  const [formDataF005, setFormDataF005] = useState(initStateF005);
  const [formDataF006, setFormDataF006] = useState(initStateF006);
  const [formDataF011, setFormDataF011] = useState(initStateF011);
  const [formDataF012, setFormDataF012] = useState(initStateF012);
  const [formDataF013, setFormDataF013] = useState(initStateF013);
  const [formDataF017, setFormDataF017] = useState(initStateF017);
  const [formDataF018, setFormDataF018] = useState(initStateF018);
  const [formDataF019, setFormDataF019] = useState(initStateF019);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [activeForm, setActiveForm] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [formId, setFormId] = useState('');
  const items = [
    {
      id: 0,
      name: 'Firebreaks',
    },
    {
      id: 1,
      name: 'Fire Fighting Preparedness',
    },
    {
      id: 2,
      name: 'Plans and Agreements',
    },
    {
      id: 3,
      name: 'Compliance Checklist',
    },
  ];

  const handleNext = active => {
    setActiveForm(active);
  };

  const handleReset = () => {
    setFormDataF002(initStateF002);
    setFormDataF003(initStateF003);
    setFormDataF005(initStateF005);
    setFormDataF006(initStateF006);
    setFormDataF011(initStateF011);
    setFormDataF012(initStateF012);
    setFormDataF013(initStateF013);
    setFormDataF017(initStateF017);
    setFormDataF018(initStateF018);
    setFormDataF019(initStateF019);
    setUploadedFiles([]);
  };

  const handleChange = e => {
    setFormDataF002({ ...formDataF002, [e.target.name]: e.target.value });
    setFormDataF003({ ...formDataF003, [e.target.name]: e.target.value });
    setFormDataF005({ ...formDataF005, [e.target.name]: e.target.value });
    setFormDataF006({ ...formDataF006, [e.target.name]: e.target.value });
    setFormDataF011({ ...formDataF011, [e.target.name]: e.target.value });
    setFormDataF012({ ...formDataF012, [e.target.name]: e.target.value });
    setFormDataF013({ ...formDataF013, [e.target.name]: e.target.value });
    setFormDataF017({ ...formDataF017, [e.target.name]: e.target.value });
    setFormDataF018({ ...formDataF018, [e.target.name]: e.target.value });
    setFormDataF019({ ...formDataF019, [e.target.name]: e.target.value });
    if (e.target.files) {
      const chosenFiles = Array.prototype.slice.call(e.target.files);
      handleUploadFiles(chosenFiles);
    }
  };

  const handleUploadFiles = files => {
    const uploaded = [...uploadedFiles];
    // eslint-disable-next-line array-callback-return
    files.some(file => {
      if (uploaded.findIndex(f => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });
    setUploadedFiles(uploaded);
  };

  const handleSubmit = () => {
    postFormData({
      formId: formId,
      formData: JSON.stringify(
        formId === 'F002'
          ? formDataF002
          : formId === 'F003'
            ? formDataF003
            : formId === 'F005'
              ? formDataF005
              : formId === 'F006'
                ? formDataF006
                : formId === 'F011'
                  ? formDataF011
                  : formId === 'F012'
                    ? formDataF012
                    : formId === 'F013'
                      ? formDataF013
                      : formId === 'F017'
                        ? formDataF017
                        : formId === 'F018'
                          ? formDataF018
                          : formId === 'F019'
                            ? formDataF019
                            : null
      ),
    });

    handleReset();
  };

  const handleFileSubmit = () => {
    const data = new FormData();
    data.append('formId', formId);
    if (uploadedFiles) {
      // eslint-disable-next-line array-callback-return
      uploadedFiles.map(file => {
        data.append('files', file);
      });
      postFormDataFiles(data);
    }
  };

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Typography variant='h4' component='h4' sx={{ mb: 3 }}>
        Veldfire Management
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container sx={{ justifyContent: 'space-between', mb: 2 }}>
        {items.map(item => (
          <Grid key={item.id}>
            <Button
              variant={activeStep === item.id ? 'contained' : 'outlined'}
              onClick={() => {
                setActiveStep(item.id);
                handleNext(0, 0);
              }}
            >
              {item.name}
            </Button>
          </Grid>
        ))}
      </Grid>
      {activeStep === 0 ? (
        <Firebreaks
          dataF002={formDataF002}
          dataF003={formDataF003}
          dataF006={formDataF006}
          dataF011={formDataF011}
          setData={handleChange}
          setFormId={setFormId}
          next={handleNext}
          active={activeForm}
          totalItems={setTotalItems}
        />
      ) : activeStep === 1 ? (
        <FireFighting
          dataF018={formDataF018}
          dataF019={formDataF019}
          setData={handleChange}
          setFormId={setFormId}
          next={handleNext}
          active={activeForm}
          totalItems={setTotalItems}
        />
      ) : activeStep === 2 ? (
        <PlansAndAgreements
          dataF005={formDataF005}
          dataF012={formDataF012}
          dataF013={formDataF013}
          setData={handleChange}
          setFormId={setFormId}
          next={handleNext}
          active={activeForm}
          totalItems={setTotalItems}
        />
      ) : (
        <ComplianceChecklist
          dataF017={formDataF017}
          setData={handleChange}
          setFormId={setFormId}
          totalItems={setTotalItems}
        />
      )}
      <Box sx={{ textAlign: 'right', mt: 2 }}>
        {activeForm + 1 !== totalItems && (
          <Button
            sx={{ mr: 2 }}
            variant='outlined'
            onClick={() => handleNext(activeForm + 1, totalItems)}
          >
            Next Form
          </Button>
        )}
        <Button
          variant='contained'
          onClick={() => {
            handleSubmit();
            handleFileSubmit();
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

FireBreakProof.propTypes = {
  postFormData: PropTypes.func.isRequired,
  postFormDataFiles: PropTypes.func.isRequired,
};

export default connect(null, { postFormData, postFormDataFiles })(
  FireBreakProof
);
