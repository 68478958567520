import { connect } from 'react-redux';
// import { useDispatch } from 'react-redux';
import { useState } from 'react';
import {
    Box, Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell, Tabs, Tab
} from '@mui/material';
const OverviewTable = ({ category, data }) => {
    // const dispatch = useDispatch();
    const [month, selectedMonth] = useState(0);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    const handleMonthChange = (newValue) => {
        selectedMonth(newValue);
    };

    // useEffect(() => {
    //     dispatch(getChargingData(projectKey))
    // }, [dispatch, projectKey]);

    return (
        <Box>
            <Tabs
                value={month}
                onChange={handleMonthChange}
                style={{ marginBottom: 10 }}
                textColor='inherit'
                indicatorColor='secondary'
                className='theTabs'
                sx={{
                    float: 'left',
                }}
            >
                {months.map((m, i) => {
                    return (
                        <Tab label={m} key={i} />
                    )
                })}
            </Tabs>
            <TableContainer component={Paper} sx={{ height: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                {category}
                            </TableCell>
                            <TableCell align="left">
                                Unit
                            </TableCell>
                            <TableCell align="left">
                                Quantity
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((d, i) => {
                            return (
                                <TableRow key={i}>
                                    <TableCell align="left">
                                        {d.category}
                                    </TableCell>
                                    <TableCell align="left">
                                        {d.unit}
                                    </TableCell>
                                    <TableCell align="left">
                                        {d.quantity}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

const mapStateToProps = state => ({
    chargingData: state.postFormData.chargingData,
    loading: state.postFormData.loading,
});

const mapDispatchToProps = () => {
    return {
        // getChargingData: projectKey => dispatch(getChargingData(projectKey))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewTable);