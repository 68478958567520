import {
    Box, Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell
} from '@mui/material';

export function DryIceTotalTable({ dryIceDataTotals }) {

    return (
        <Box>
            <TableContainer component={Paper} sx={{ height: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                Month
                            </TableCell>
                            {/* <TableCell align="left">
                                Kilogram Value
                            </TableCell> */}
                            <TableCell align="left">
                                Total Kilograms
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {months.slice(0, 12).map((m) => { */}
                        {dryIceDataTotals?.map((data, i) => {
                            if (!Object.keys(data).includes('grand_total')) {
                                return (
                                    <TableRow key={i}>
                                        <TableCell align="left">
                                            {data.month === 'Grand Total' ? <b>Grand Total</b> : data.month}
                                        </TableCell>
                                        {/* <TableCell align="left">
                                            {data.kilogramsQuantity}
                                        </TableCell> */}
                                        <TableCell align="left">
                                            {data.total}
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        })}
                        <TableRow sx={{ border: '2px solid black' }}>
                            {/* <TableCell align="left"></TableCell> */}
                            <TableCell align="left"><b>Grand Total</b></TableCell>
                            <TableCell align="left"><b>{dryIceDataTotals[12].grand_total}</b></TableCell>
                        </TableRow>
                        {/* {data.map((d, i) => {
                            return (
                                <TableRow >
                                    <TableCell align="left">
                                        {d.category}
                                    </TableCell>
                                    <TableCell align="left">
                                        {d.unit}
                                    </TableCell>
                                    <TableCell align="left">
                                        {d.quantity}
                                    </TableCell>
                                </TableRow>
                            )
                        })} */}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
