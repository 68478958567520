import {
    Box, Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell
} from '@mui/material';
export function LpgTotalTable({ lpgDataTotals }) {

    return (
        <Box>
            <TableContainer component={Paper} sx={{ height: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                Month
                            </TableCell>
                            <TableCell align="left">
                                Kilogram Value
                            </TableCell>
                            <TableCell align="left">
                                Pound Value
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {months.slice(0, 12).map((m) => { */}
                        {lpgDataTotals?.map((data, i) => {

                            return (
                                <TableRow key={i}>
                                    <TableCell align="left">
                                        {data.month}
                                    </TableCell>
                                    <TableCell align="left">
                                        {data.kilogramsQuantity}
                                    </TableCell>
                                    <TableCell align="left">
                                        {data.poundsQuantity}
                                    </TableCell>
                                </TableRow>
                            )
                        })}

                        {/* {data.map((d, i) => {
                            return (
                                <TableRow >
                                    <TableCell align="left">
                                        {d.category}
                                    </TableCell>
                                    <TableCell align="left">
                                        {d.unit}
                                    </TableCell>
                                    <TableCell align="left">
                                        {d.quantity}
                                    </TableCell>
                                </TableRow>
                            )
                        })} */}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
