import React, { useState } from 'react';
import { Button, Typography, Box } from '@mui/material';
import Contractors from '../contractorService/Contractors';

const PricingCalculation = ({ feedstockModel, bookedDays, onContractorIdChange }) => {
  const [costBreakdownForContractor1, setCostBreakdownForContractor1] = useState(null);
  const [costBreakdownForContractor2, setCostBreakdownForContractor2] = useState(null);

  const calculateCost = (model, days, contractorId) => {
    const contractor = Contractors.find(contr => contr.id === contractorId);

    const kilns = 4;
    const transportCostPerDay = contractor.rates.transport;
    const miscellaneousCostPerDay = 200;

    const supervisorCost = contractor.rates.supervisor * days;
    const semiSkilledWorkerCost = contractor.rates.semiSkilledWorker * 4 * days;
    const generalWorkerCost = contractor.rates.generalWorker * (model === 'feedstockReady' ? 4 : 8) * days;
    const kilnRentalCost = contractor.rates.kilnRental * kilns * days;
    const transportCost = transportCostPerDay * days;
    const miscellaneousCost = miscellaneousCostPerDay * days;

    const totalCost = supervisorCost + semiSkilledWorkerCost + generalWorkerCost + kilnRentalCost + transportCost + miscellaneousCost;

    return { totalCost, supervisorCost, semiSkilledWorkerCost, generalWorkerCost, kilnRentalCost, transportCost, miscellaneousCost };
  };

  const handleClick = (event, contractorId) => {
    event.stopPropagation(); // Prevents the event from bubbling up the event chain
    if (contractorId === 1) {
      setCostBreakdownForContractor1(calculateCost(feedstockModel, bookedDays, contractorId));
    } else {
      setCostBreakdownForContractor2(calculateCost(feedstockModel, bookedDays, contractorId));
    }
    onContractorIdChange(contractorId);
  };

  const renderCostBreakdown = (costBreakdown, contractorId) => (
    costBreakdown && (
      <>
        <Typography variant='body1'>Cost for Contractor {contractorId}:</Typography>
        <Typography variant='body1'>Total Cost: R{costBreakdown.totalCost}</Typography>
        <Typography variant='body1'>Breakdown of Total Costs:</Typography>
        <Typography variant='body1'>Supervisor Cost: R{costBreakdown.supervisorCost}</Typography>
        <Typography variant='body1'>Semi-skilled Worker Cost: R{costBreakdown.semiSkilledWorkerCost}</Typography>
        <Typography variant='body1'>General Worker Cost: R{costBreakdown.generalWorkerCost}</Typography>
        <Typography variant='body1'>Kiln Rental Cost: R{costBreakdown.kilnRentalCost}</Typography>
        <Typography variant='body1'>Transport Cost: R{costBreakdown.transportCost}</Typography>
        <Typography variant='body1'>Miscellaneous Cost: R{costBreakdown.miscellaneousCost}</Typography>
      </>
    )
  );

  return (
    <Box>
      <Typography variant='body1'>Feedstock Model: {feedstockModel}</Typography>
      <Typography variant='body1'>Booked Days: {bookedDays}</Typography>
      <Button variant="contained" color="primary" onClick={(e) => handleClick(e, 1)}>
        Calculate Cost for Contractor 1
      </Button>
      {renderCostBreakdown(costBreakdownForContractor1, 1)}

      <Button variant="contained" color="secondary" onClick={(e) => handleClick(e, 2)}>
        Calculate Cost for Contractor 2
      </Button>
      {renderCostBreakdown(costBreakdownForContractor2, 2)}
    </Box>
  );
};

export default PricingCalculation;
