import React, { useState, useEffect } from 'react'
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from '@mui/material';
import tooltips from '../../../../../../../store/tooltips';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch } from 'react-redux';
import { postMeasurementType } from '../../../../../../../actions/forms';
// import { set } from 'ol/transform';

export const MeasurementUnits = ({ projectKey, measureType }) => {
    const dispatch = useDispatch()
    // const measurementType = useSelector(state => state.postFormData.measurementType)

    const measurementUnits = [{ id: 0, type: 'Metric' }, { id: 1, type: 'Imperial' }]
    const [units, setUnits] = useState('')

    const handleUnitsChange = (e) => {
        setUnits(e.target.value)
        dispatch(postMeasurementType({ measurementType: e.target.value }, projectKey))
    }

    useEffect(() => {
        if (measureType) setUnits(measureType)
    }, [measureType])

    return (
        <>
            <Typography variant='h5' sx={{ mb: 2 }}>
                Measurement Units
                <Tooltip
                    title={
                        <h2 style={{ color: 'lightgreen' }}>
                            {tooltips.measurementUnits}
                        </h2>
                    }
                >
                    {<InfoIcon sx={{ width: 15 }} />}
                </Tooltip>
            </Typography>
            <FormControl
                sx={{
                    mt: 1,
                    width: '200px'
                }}

            >
                <InputLabel sx={{ background: 'white', pl: 1, pr: 1 }}>
                    Select unit type
                </InputLabel>
                <Select
                    name='measurementUnits'
                    value={units}
                    onChange={handleUnitsChange}
                    disabled={measureType ? true : false}
                >
                    {measurementUnits.map(role => {
                        return (
                            <MenuItem key={role.id} value={role.type}>
                                {role.type}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <Typography variant='caption' sx={{ mt: 1, display: 'block' }}>
                {units === 'Imperial' ?
                    <>
                        Please ensure that when information is provided it is given in the following units at the respective form questions:<br />
                        - For Feedstock: cubic yard, acres, tonnes<br />
                        - For Kiln or other dimensions: Foot (ft)<br />
                        - For Field measurements: Gallons(gal), inches (in) and  pounds (lbs)<br />
                    </>
                    : units === 'Metric' ? <>
                        Please ensure that when information is provided it is given in the following units at the respective form questions:<br />
                        - For Feedstock: cubic meter, hectares, tonnes<br />
                        - For Kiln or other dimensions: Meters (m)<br />
                        - For Field measurements: Liters(L), Centimeters (cm) and  Kilogram (kg)<br />

                    </> : null
                }
            </Typography>
        </>
    )
}
