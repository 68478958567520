const Contractors = [
    {
      id: 1,
      serviceName: "Mthombo cleaning and vegetation management",
      fullName: "Mthombo cleaning and vegetation management",
      emailAddress: "admin@mthombo.co.za",
      phoneNumber: "0723275725/0726351293",
      location: "Capewinelands district Stellenbosch",
      equipment: "We make use of chainsaws as well as brush cutters and different herbicides and have all necessary PPE",
      insurance: "We have a public liability cover to the value of 25 million, we have a health and safety file",
      rates: {
        supervisor: 600,
        semiSkilledWorker: 350,
        generalWorker: 250,
        transport: 500,
        kilnRental: 150,
      },
    },
    {
      id: 2,
      serviceName: "K2- Contractors",
      fullName: "K2- Contractors",
      emailAddress: "admin@k2contractors.co.za",
      phoneNumber: "xxxx",
      location: "Capewinelands district Stellenbosch",
      equipment: "We make use of chainsaws as well as brush cutters and different herbicides and have all necessary PPE",
      insurance: "Unverified",
      rates: {
        supervisor: 600,
        semiSkilledWorker: 350,
        generalWorker: 250,
        transport: 500,
        kilnRental: 150,
      },
    },
  ];
  
  export default Contractors;
  