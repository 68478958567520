import { Grid, Typography } from '@mui/material';

const Introduction = () => {
  return (
    <Grid container>
      <Grid item xs={12} id='introduction'>
        <Typography
          id='introduction__support-text'
          sx={{ maxWidth: { lg: '1024px' } }}
        >
          In isiXhosa, one of many native languages in South Africa, Aloe ferox is named Ikhala. It is a beautiful, resilient plant that we see as a symbol of resilience and regeneration. It is strong enough to survive in harsh, dry conditions and has well-known medicinal properties.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Introduction;
