import React, { useState } from 'react';
import { Grid, Tabs, Tab } from '@mui/material';
// import { connect } from 'react-redux';

// import KilnDataTables from './DataTablesComponents/KilnDataTables';
import CbDataTables from './DataTablesComponents/CbDataTables';
import FuelTables from './DataTablesComponents/FuelTables';
import VerificationTables from './DataTablesComponents/VerificationTables';
import RofDataTables from './DataTablesComponents/RofDataTables';

export const DataTables = ({ dataBF005, setData, setFormId, next, projectId, projectKey, active, measureType }) => {

    const items = [
        {
            id: 0,
            name: 'Batch Volumes',
        },
        {
            id: 1,
            name: 'Batch Bulk Density',
        },
        {
            id: 2,
            name: 'Biochar Mass',
        },
        {
            id: 3,
            name: 'Batch Carbon Content',
        },
        {
            id: 4,
            name: 'Biochar Stored Carbon'
        },
    ];

    const cbItems = [
        {
            id: 0,
            name: 'Batch Volumes',
        },
        {
            id: 1,
            name: 'Batch Bulk Density',
        },
        {
            id: 2,
            name: 'Vol & BD Average',
        },
        {
            id: 3,
            name: 'Biochar Mass',
        },
        {
            id: 4,
            name: 'Batch Carbon Content',
        },
        {
            id: 5,
            name: 'Biochar Stored Carbon'
        },
    ];

    const validationItems = [
        {
            id: 0,
            name: 'Data Validation',
        }
    ];

    const [value, setValue] = useState(0);
    const handleChange = (event, index) => {
        setValue(index);
    };


    return (
        <Grid container>
            {/* {console.log(batchData)} */}
            <Grid item sx={{ mb: 2, display: 'flex', width: '100%' }}>
                <Tabs value={value} onChange={handleChange} style={{ marginBottom: 10 }}>
                    <Tab label="Biochar" />
                    <Tab label="Conservation Burns" />
                    <Tab label="Fuel" disabled={true} />
                    <Tab label="Data Validation" disabled={true} />
                </Tabs>
            </Grid>
            {value === 0 && (
                <RofDataTables items={items} active={active} next={next} projectKey={projectKey} measureType={measureType} />
                // <KilnDataTables items={items} active={active} next={next} projectKey={projectKey} measureType={measureType} />
            )}
            {value === 1 && (
                <CbDataTables cbItems={cbItems} active={active} next={next} projectKey={projectKey} />
            )}
            {value === 2 && (
                <FuelTables active={active} next={next} projectKey={projectKey} />
            )}
            {value === 3 && (
                <VerificationTables active={active} projectKey={projectKey}
                    data={dataBF005}
                    setData={setData}
                    setFormId={setFormId}
                    next={next}
                    projectId={projectId}
                    validationItems={validationItems}
                />
            )}
        </Grid>
    )
}
