import { Box, Button, Grid } from '@mui/material';
import { useState } from 'react'
// import { DataCapturing } from './DieselDataCapturingStationary';
import RefrigerantDataCapturing from './RefrigerantGasDataCapturing';

export default function RefrigerantGas() {
    const [selected, setSelected] = useState('Refrigerant Gas')
    const refrigerantGasCategories = ['Refrigerant Gas']

    return (
        <>
            <Grid item xs={3}>
                <Box marginTop={2}>
                    {refrigerantGasCategories.map((c, i) => {
                        return (
                            <Button
                                key={i}
                                variant={c === selected ? 'contained' : 'outlined'}
                                size='large'
                                sx={{ width: '100%', display: 'block', mb: 2.5 }}
                                onClick={() => {
                                    setSelected(c)
                                    localStorage.setItem('catOne', c)
                                }}
                            >
                                {c}
                            </Button>
                        )
                    })
                    }
                </Box>
            </Grid>
            <Grid item xs={9}>
                <RefrigerantDataCapturing />
            </Grid>
        </>
    );
}

/*
d. Refrigerant Gas

Select Category 1->Select Refrigerant Gas->Select month (Monthly basis)->
Select Refrigerant Gas Type from predefined drop down list->
Select unit from drop down list (kilograms only)->
Manually Enter Refrigerant consumption for the selected month with the corresponding unit->
Upload corresponding source documentation

Users should be able to see the annual sum table for corresponding line items in the TOTALS tab (i.e. 12 month total).
*/