import { Alert, Box, Button, Divider, Grid, Typography } from '@mui/material';

export default function CatTwoSelection({ setSelection, setCatTwo }) {

    const catTwo = ['Electricity purchased', 'Renewable Energy Produced']

    return (
        <Grid item xs={12}>
            <Typography variant='h5'>Category</Typography>
            <Divider />
            <Alert severity='info'>Please select a category</Alert>
            <Box marginTop={2}>
                {catTwo.map((c, i) => {
                    return (
                        <Button
                            key={i}
                            variant='contained'
                            size='large'
                            sx={{ width: '35%', display: 'block', mb: 2.5 }}
                            onClick={() => {
                                setCatTwo(c)
                                setSelection({ id: 6, title: c })
                                localStorage.setItem('catTwo', c)
                            }}
                        >
                            {c}
                        </Button>
                    )
                })
                }
            </Box>
        </Grid>
    );
}
