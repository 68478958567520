import { setAlert } from '../alert';
import {
    API_POST_TIGERCAT_BURNS_CREATE_CAR_RPPB,
    API_GET_TIGERCAT_BURNS_GET_COMPANY_USER_CAR_RPPBS,
    API_POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_PROJECT,
    API_GET_TIGERCAT_BURNS_GET_CAR_RPPB_PROJECTS,
    API_GET_TIGERCAT_BURNS_GET_USERS,
    API_POST_TIGERCAT_BURNS_CREATE_BIOCHAR_BINS,
    API_GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_BINS,
    API_POST_TIGERCAT_BURNS_CREATE_BIOCHAR_EQUIPMENT,
    API_GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_EQUIPMENT,
    API_GET_TIGERCAT_BURNS_GET_PROJECTS_FOR_USER,
    API_PATCH_TIGERCAT_BURNS_ASSIGN_ASSET_TO_PROJECT,
    API_GET_TIGERCAT_BURNS_GET_ALL_PROJECTS_WITH_ASSIGNED_ASSETS,
    API_PATCH_TIGERCAT_BURNS_UNASSIGN_ASSET_FROM_PROJECT
} from './constants/tigercat-base-constants';

import {
    POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_SUCCESS,
    POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_FAIL,
    GET_TIGERCAT_BURNS_GET_COMPANY_USER_CAR_RPPBS_SUCCESS,
    GET_TIGERCAT_BURNS_GET_COMPANY_USER_CAR_RPPBS_FAIL,
    POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_PROJECT_SUCCESS,
    POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_PROJECT_FAIL,
    GET_TIGERCAT_BURNS_GET_CAR_RPPB_PROJECTS_SUCCESS,
    GET_TIGERCAT_BURNS_GET_CAR_RPPB_PROJECTS_FAIL,
    GET_TIGERCAT_BURNS_GET_USERS_SUCCESS,
    GET_TIGERCAT_BURNS_GET_USERS_FAIL,
    POST_TIGERCAT_BURNS_CREATE_BIOCHAR_BINS_SUCCESS,
    POST_TIGERCAT_BURNS_CREATE_BIOCHAR_BINS_FAIL,
    GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_BINS_SUCCESS,
    GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_BINS_FAIL,
    POST_TIGERCAT_BURNS_CREATE_BIOCHAR_EQUIPMENT_SUCCESS,
    POST_TIGERCAT_BURNS_CREATE_BIOCHAR_EQUIPMENT_FAIL,
    GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_EQUIPMENT_SUCCESS,
    GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_EQUIPMENT_FAIL,
    GET_TIGERCAT_BURNS_GET_PROJECTS_FOR_USER_SUCCESS,
    GET_TIGERCAT_BURNS_GET_PROJECTS_FOR_USER_FAIL,
    PATCH_TIGERCAT_BURNS_ASSIGN_ASSET_TO_PROJECT_SUCCESS,
    PATCH_TIGERCAT_BURNS_ASSIGN_ASSET_TO_PROJECT_FAIL,
    GET_TIGERCAT_BURNS_GET_ALL_PROJECTS_WITH_ASSIGNED_ASSETS_SUCCESS,
    GET_TIGERCAT_BURNS_GET_ALL_PROJECTS_WITH_ASSIGNED_ASSETS_FAIL,
    PATCH_TIGERCAT_BURNS_UNASSIGN_ASSET_FROM_PROJECT_SUCCESS,
    PATCH_TIGERCAT_BURNS_UNASSIGN_ASSET_FROM_PROJECT_FAIL
} from './types/tigercat-base-types';

import axios from 'axios';
const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    },
};

export const getTigercatUsers = () => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_TIGERCAT_BURNS_GET_USERS,
            config
        );
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_USERS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_USERS_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const createCompanyCarRppb = (data, companyId) => async dispatch => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_TIGERCAT_BURNS_CREATE_CAR_RPPB + `/${companyId}`,
            data,
            config
        );
        dispatch({
            type: POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(response.data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getCompanyUserCarRppbs = (companyId) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_TIGERCAT_BURNS_GET_COMPANY_USER_CAR_RPPBS + `/${companyId}`,
            config
        );
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_COMPANY_USER_CAR_RPPBS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_COMPANY_USER_CAR_RPPBS_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const createUserCarRppbProject = (carRppbId, data) => async dispatch => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_PROJECT + `/${carRppbId}`,
            data,
            config
        );
        dispatch({
            type: POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_PROJECT_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(response.data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_PROJECT_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getCarRppbProjects = (carRppbId) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_TIGERCAT_BURNS_GET_CAR_RPPB_PROJECTS + `/${carRppbId}`,
            config
        );
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_CAR_RPPB_PROJECTS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_CAR_RPPB_PROJECTS_FAIL,
            payload: err.response.data.detail,
        });
    }
};


// Action to create biochar bins
export const createBiocharBins = (binData, companyId) => async (dispatch) => {
    try {
        const response = await axios.post(process.env.REACT_APP_API_URL + API_POST_TIGERCAT_BURNS_CREATE_BIOCHAR_BINS + `/${companyId}`, binData, config);
        dispatch({
            type: POST_TIGERCAT_BURNS_CREATE_BIOCHAR_BINS_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: POST_TIGERCAT_BURNS_CREATE_BIOCHAR_BINS_FAIL,
            payload: error.response ? error.response.data : error.message
        });
    }
};

// Action to get user biochar bins
export const getCompanyBiocharBins = (companyId) => async (dispatch) => {
    try {
        const { data } = await axios.get(process.env.REACT_APP_API_URL + API_GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_BINS + `/${companyId}`, config);
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_BINS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_BINS_FAIL,
            payload: error.response ? error.response.data : error.message
        });
    }
};

// Action to create biochar equipment
export const createBiocharEquipment = (equipmentData, companyId) => async (dispatch) => {
    try {
        const response = await axios.post(process.env.REACT_APP_API_URL + API_POST_TIGERCAT_BURNS_CREATE_BIOCHAR_EQUIPMENT + `/${companyId}`, equipmentData, config);
        dispatch({
            type: POST_TIGERCAT_BURNS_CREATE_BIOCHAR_EQUIPMENT_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: POST_TIGERCAT_BURNS_CREATE_BIOCHAR_EQUIPMENT_FAIL,
            payload: error.response ? error.response.data : error.message
        });
    }
};

// Action to get user biochar equipment
export const getCompanyBiocharEquipment = (companyId) => async (dispatch) => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + API_GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_EQUIPMENT + `/${companyId}`, config);
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_EQUIPMENT_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_EQUIPMENT_FAIL,
            payload: error.response ? error.response.data : error.message
        });
    }
};

// Action to get projects for company
export const getProjectsForCompany = (companyId) => async (dispatch) => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + API_GET_TIGERCAT_BURNS_GET_PROJECTS_FOR_USER + `/${companyId}`, config);
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_PROJECTS_FOR_USER_SUCCESS,
            payload: response
        });
    } catch (error) {
        dispatch(setAlert(error.response.data.detail, 'error'));
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_PROJECTS_FOR_USER_FAIL,
            payload: error.response ? error.response.data : error.message
        });
    }
};

// Action assign assets to project
export const assignAssetToProject = (projectId, assetType, assetId) => async (dispatch) => {
    try {
        const response = await axios.patch(process.env.REACT_APP_API_URL + API_PATCH_TIGERCAT_BURNS_ASSIGN_ASSET_TO_PROJECT + `/${projectId}/${assetType}/${assetId}`, {}, config);
        dispatch({
            type: PATCH_TIGERCAT_BURNS_ASSIGN_ASSET_TO_PROJECT_SUCCESS,
            payload: response
        });
    } catch (error) {
        dispatch(setAlert(error.response.data.detail, 'error'));
        dispatch({
            type: PATCH_TIGERCAT_BURNS_ASSIGN_ASSET_TO_PROJECT_FAIL,
            payload: error.response ? error.response.data : error.message
        });
    }
};

export const UnassignAssetFromProject = (projectId, assetType, assetId) => async (dispatch) => {
    try {
        const response = await axios.patch(process.env.REACT_APP_API_URL + API_PATCH_TIGERCAT_BURNS_UNASSIGN_ASSET_FROM_PROJECT + `/${projectId}/${assetType}/${assetId}`, {}, config);
        dispatch({
            type: PATCH_TIGERCAT_BURNS_UNASSIGN_ASSET_FROM_PROJECT_SUCCESS,
            payload: response
        });
    } catch (error) {
        dispatch(setAlert(error.response.data.detail, 'error'));
        dispatch({
            type: PATCH_TIGERCAT_BURNS_UNASSIGN_ASSET_FROM_PROJECT_FAIL,
            payload: error.response ? error.response.data : error.message
        });
    }
};

export const getAllProjectsWithAssignedAssets = (companyId, assetTyoe) => async (dispatch) => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + API_GET_TIGERCAT_BURNS_GET_ALL_PROJECTS_WITH_ASSIGNED_ASSETS + `/${companyId}/${assetTyoe}`, config);
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_ALL_PROJECTS_WITH_ASSIGNED_ASSETS_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        dispatch(setAlert(error.response.data.detail, 'error'));
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_ALL_PROJECTS_WITH_ASSIGNED_ASSETS_FAIL,
            payload: error.response ? error.response.data : error.message
        });
    }
};