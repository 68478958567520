import { useEffect } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import FeedStockMoistureVerfication from './verification-tables/FeedstockMoisture';
import FlamingCombustion from './verification-tables/FlamingCombustion';
import BiocharMeasurement from './verification-tables/BiocharMeasurement';
import WeightOfEmptyBucket from './verification-tables/WeightOfEmptyBucket';
import UnburntPiecesVolume from './verification-tables/UnburntPiecesVolume';
import BiocharCharging from './verification-tables/BiocharCharging';
// import BiocharApplication from './verification-tables/BiocharApplication';
export default function BF005VerificationRequest({
  setFormId,
  next,
  projectKey,
}) {
  useEffect(() => {
    setFormId('BF005');
  }, [setFormId]);
  useEffect(() => {
    next(2);
  }, [next]);

  return (
    <>
      <Box>
        <Typography variant='h5' sx={{ mb: 2, display: 'block' }}>
          Verification Requests
        </Typography>
      </Box>
      <FeedStockMoistureVerfication projectKey={projectKey} />
      <Divider />
      <FlamingCombustion projectKey={projectKey} />
      <Divider />
      <BiocharMeasurement projectKey={projectKey} />
      <Divider />
      <WeightOfEmptyBucket projectKey={projectKey} />
      <Divider />
      <UnburntPiecesVolume projectKey={projectKey} />
      <Divider />
      <BiocharCharging projectKey={projectKey} />
      {/* <Divider />
      <BiocharApplication projectKey={projectKey} /> */}
    </>
  );
}
