import { Alert, Paper } from '@mui/material';
import construction from '../construction.jpeg';

export default function Settings() {
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 1,
      }}
    >
      <Alert severity='error' sx={{ width: 1 }}>
        Page under construction
      </Alert>
      <img src={construction} width='70%' alt='page under construction' />
    </Paper>
  );
}
