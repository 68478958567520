import { useEffect } from 'react';
import {
  Button,
  // Card,
  // CardActions,
  // CardContent,
  Grid,
  Typography,
  Alert,
  Box
} from '@mui/material';
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux';
import { getProjects } from '../../actions/forms';
import { useDispatch } from 'react-redux';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';

const Home = ({ projects, userType }) => {
  const { isBiocharOrConservationBurnUser, isCarbonFootprintUser, isTigercatBurnsUser } = userType
  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    if (isBiocharOrConservationBurnUser)
      dispatch(getProjects());
  }, [dispatch]);

  const handleNavigate = (project) => {
    navigate(`/project/${project._id}?nickname=${project.nickname}&projectIdentifier=${project.projectId}`)
  }

  const renderHomeContent = () => {
    if (isBiocharOrConservationBurnUser)
      return (
        <>
          <Typography variant="h4" component="h4" sx={{ mb: 3 }}>
            Quick Project Access
          </Typography>
          <Grid xs={12} display="flex" alignContent={'space-between'} flexWrap={'wrap'}>
            {projects?.map((project, index) => (
              <>
                <Box className="biochar-project biochar-project-home" key={index}
                  onClick={() => handleNavigate(project)}>
                  <Typography sx={{ fontSize: '18px' }}><BookmarkBorderOutlinedIcon sx={{ mb: '-7px' }} /> {project.projectId}</Typography>
                  <Typography sx={project.nickname ? { fontSize: '14px' } : { visibility: 'hidden' }} mt={3}><strong>Nickname:</strong> {project.nickname}</Typography>
                  <Typography sx={{ fontSize: '14px' }}><strong>Date Created:</strong> {project.created_at.split('T')[0]}</Typography>
                </Box >
              </>
            ))}
          </Grid >
          <Alert severity='warning' sx={{ width: '100%', position: 'absolute', bottom: '0', left: '0', paddingLeft: '80px' }}>
            Please email wihan@ikhala.tech for the "Web App User Manual" and the "CHARR Mobile App Field Guide" as well as other biochar support documents such as the "Biochar Toolkit Overview" and "Tips for Data Quality Improvements"
          </Alert>
        </>
      )
    else if (isCarbonFootprintUser)
      return (
        <>
          <Typography variant="h4" component="h4" sx={{ mb: 3 }}>
            Carbon Footprinting
          </Typography>
          <Typography variant="body1" component="p" sx={{ mb: 3 }}>
            You have access to the carbon footprinting tool. Click on the link below to access the carbon footprinting section.
          </Typography>
          <Button variant="contained" onClick={() => navigate('/carbon-footprinting')}>
            Carbon Footprting Tool
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Alert severity='warning' sx={{ width: '100%', position: 'absolute', bottom: '0', left: '0', paddingLeft: '80px' }}>
              Please email wihan@ikhala.tech should you have any questions or need assistance.
            </Alert>
          </Box>
        </>
      )
    else if (isTigercatBurnsUser)
      return (
        <>
          <Typography variant="h4" component="h4" sx={{ mb: 3 }}>
            Earth Foundries Carbonization
          </Typography>
          <Typography variant="body1" component="p" sx={{ mb: 3 }}>
            You have access to the Earth Foundries Operations App. Click on the link below to access the biochar data management tool.
          </Typography>
          <Button variant="contained" onClick={() => navigate('/car-reporting')}>
            Biochar Data Management Tool
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Alert severity='warning' sx={{ width: '100%', position: 'absolute', bottom: '0', left: '0', paddingLeft: '80px' }}>
              Please email wihan@ikhala.tech should you have any questions or need assistance.
            </Alert>
          </Box>
        </>
      )
  }

  return (
    <>
      {renderHomeContent()}
    </>
  );
};

const mapStateToProps = state => ({
  projects: state.postFormData.projects,
  loading: state.postFormData.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    getProjects: () => dispatch(getProjects()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);