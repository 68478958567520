import {
    GET_SUPPLIERS_SUCCESS,
    GET_SUPPLIERS_FAIL,
    ADD_SUPPLIER_SUCCESS,
    ADD_SUPPLIER_FAIL,
} from '../types/04-indirect-ghg-emissions-from-products-used-by-an-organisation';

import {
    API_GET_SUPPLIERS,
    API_POST_SUPPLIER,
} from '../constants/04-indirect-ghg-emissions-from-products-used-by-an-organisation';

import { setAlert } from '../../alert';


import axios from 'axios';

const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    },
    // withCredentials: true,
};


export const addSupplier = (formData, year, company, group) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_SUPPLIER + `/${year}/${company}/${group}`,
            formData,
            config
        );
        dispatch({
            type: ADD_SUPPLIER_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: ADD_SUPPLIER_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getSuppliers = (group) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_SUPPLIERS + `/${group}`,
            config
        );
        dispatch({
            type: GET_SUPPLIERS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_SUPPLIERS_FAIL,
            payload: err.response.data.detail,
        });
    }
};