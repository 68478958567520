import React from 'react'
import { TableContainer, Table, TableHead, TableCell, TableRow, Paper, TableBody } from '@mui/material';

const Fuel = () => {
    return (
        <>
            <h2>Fuel</h2>
            <TableContainer key={0} component={Paper} sx={{ height: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                Description
                            </TableCell>
                            <TableCell align="left">
                                Fuel Type (ff)
                            </TableCell>
                            <TableCell align="left">
                                Emission Factor for Fuel Type (FF)
                            </TableCell>
                            <TableCell align="left">
                                Unit
                            </TableCell>
                            <TableCell align="left">
                                Source
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">Diesel - Red</TableCell>
                            <TableCell align="left">ff_diesel_red</TableCell>
                            <TableCell align="left">0.01021</TableCell>
                            <TableCell align="left">tCO2/gallon</TableCell>
                            <TableCell align="left">U.S. EPA 2024</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Diesel - Clear</TableCell>
                            <TableCell align="left">ff_diesel_clear</TableCell>
                            <TableCell align="left">0.01021</TableCell>
                            <TableCell align="left">tCO2/gallon</TableCell>
                            <TableCell align="left">U.S. EPA 2024</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Gasoline</TableCell>
                            <TableCell align="left">ff_gasoline</TableCell>
                            <TableCell align="left">0.0088</TableCell>
                            <TableCell align="left">tCO2/gallon</TableCell>
                            <TableCell align="left">U.S. EPA 2024</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Propane</TableCell>
                            <TableCell align="left">ff_propane</TableCell>
                            <TableCell align="left">0.0002</TableCell>
                            <TableCell align="left">tCO2/scf (standard cubic foot)</TableCell>
                            <TableCell align="left">U.S. EPA 2024</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default Fuel