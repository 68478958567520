import { useState } from "react"
import { FormControl, TextField, Typography, Button } from "@mui/material"

export default function MobGrazingForm() {

    const initialState = {
        veldType: '',
        cattleNumber: '',
        currentPractices: '',
        size: '',
        highDensity: '',
        collar: '',
        certifications: ''
    }

    const [formData, setFormData] = useState(initialState);
    const handleChange = (e) => setFormData({...formData, [e.target.name]: e.target.value});

    const {
        veldType,
        cattleNumber,
        currentPractices,
        size,
        highDensity,
        collar,
        certifications
     } = formData

  return (
    <>
        <Typography variant='h4' component='h4' sx={{mb:2}}>
          Mob Grazing
        </Typography>
    
        <FormControl fullWidth sx={{mb:2}}>
            <Typography variant='h6' component='p'>
              What type of veld do you have?
            </Typography>
            <TextField
                name='veldType'
                label='Your answer here'
                value={veldType}
                onChange={e => handleChange(e)}
            />
        </FormControl>
        <FormControl fullWidth sx={{mb:2}}>
            <Typography variant='h6' component='p'>
              How many cattle do you have?
            </Typography>
            <TextField
                name='cattleNumber'
                label='Your answer here'
                value={cattleNumber}
                onChange={e => handleChange(e)}
            />
        </FormControl>
        <FormControl fullWidth sx={{mb:2}}>
            <Typography variant='h6' component='p'>
              What are your current grazing practices?
            </Typography>
            <TextField
                name='currentPractices'
                label='Your answer here'
                value={currentPractices}
                onChange={e => handleChange(e)}
            />
        </FormControl>
        <FormControl fullWidth sx={{mb:2}}>
            <Typography variant='h6' component='p'>
              What is the size of your grazing fields?
            </Typography>
            <TextField
                name='size'
                label='Your answer here'
                value={size}
                onChange={e => handleChange(e)}
            />
        </FormControl>
        <FormControl fullWidth sx={{mb:2}}>
            <Typography variant='h6' component='p'>
              Do you have experience with ultra-high density grazing?
            </Typography>
            <TextField
                name='highDensity'
                label='Your answer here'
                value={highDensity}
                onChange={e => handleChange(e)}
            />
        </FormControl>
        <FormControl fullWidth sx={{mb:2}}>
            <Typography variant='h6' component='p'>
              Do you use a collar such as Farmranger?
            </Typography>
            <TextField
                name='collar'
                label='Your answer here'
                value={collar}
                onChange={e => handleChange(e)}
            />
        </FormControl>
        <FormControl fullWidth sx={{mb:2}}>
            <Typography variant='h6' component='p'>
              What certifications do you currently use relating to your cattle?
            </Typography>
            <TextField
                name='certifications'
                label='Your answer here'
                value={certifications}
                onChange={e => handleChange(e)}
            />
        </FormControl>
        <FormControl fullWidth sx={{my:2}}>
            <Button variant="contained" size="large">Submit Application</Button>
        </FormControl>
    </>
  )
}
