import { API_IRT_REGISTRIES, API_IRT_PROJECTS } from "./constants";
import { GET_IRT_REGISTRY_SUCCESS, REGISTER_FAIL, FETCH_IRT_PROJECTS_SUCCESS, FETCH_IRT_PROJECTS_FAIL } from "./types";
import { setAlert } from "./alert";
import api from "../util/api";

export const getIRTRegistry = () => async (dispatch) => {
  try {
    const res = await api().get(
      API_IRT_REGISTRIES
    );
    // dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: GET_IRT_REGISTRY_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: REGISTER_FAIL,
    });
  }
}

export const getIRTProjects = () => async (dispatch) => {
  try {
    const res = await api().get(
      API_IRT_PROJECTS
    );
    // dispatch(setAlert(res.data.detail, 'success'));
    dispatch({
      type: FETCH_IRT_PROJECTS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: FETCH_IRT_PROJECTS_FAIL,
    });
  }
}