import { useEffect } from "react";
import { TextField, Typography } from "@mui/material";


export default function BF026EntityDetails({ data, setData, setFormId }) {
    useEffect(() => {
        setFormId('BF026');
    }, [setFormId]);
    const {
        orgName,
        orgLogo,
        orgAddress,
        clientName,
        clientLogo,
        clientAddress,
    } = data;

    return (
        <>
            <Typography variant='h5' component='h5' sx={{ mb: 2 }}>
                Entity Details
            </Typography>
            <Typography variant='h6' component='h6' sx={{ width: '100%' }}>
                Name of your organisation
            </Typography>
            <TextField
                name='orgName'
                label='Enter your organisation name here'
                value={orgName}
                onChange={setData}
                fullWidth
            />
            <Typography variant='h6' component='p' sx={{ mt: 2 }}>
                Your logo
            </Typography>
            <TextField
                type="file"
                name='orgLogo'
                value={orgLogo}
                onChange={setData}
                fullWidth
            />
            <Typography variant='h6' component='p' sx={{ mt: 2 }}>
                Your organisation's address
            </Typography>
            <TextField
                name='orgAddress'
                label='Enter your organisation address here'
                value={orgAddress}
                onChange={setData}
                fullWidth
            />
            <Typography variant='h6' component='p' sx={{ mt: 2 }}>
                Client name (individual or organisation)
            </Typography>
            <TextField
                name='clientName'
                label='Enter client name here'
                value={clientName}
                onChange={setData}
                fullWidth
            />
            <Typography variant='h6' component='p' sx={{ mt: 2 }}>
                Client's Logo
            </Typography>
            <TextField
                type="file"
                name='clientLogo'
                value={clientLogo}
                onChange={setData}
                fullWidth
            />
            <Typography variant='h6' component='p' sx={{ mt: 2 }}>
                Client's address
            </Typography>
            <TextField
                name='clientAddress'
                label='Enter client address here'
                value={clientAddress}
                onChange={setData}
                fullWidth
            />
        </>
    );
}
