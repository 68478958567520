import { useState } from 'react';
import { Button, CardContent, MenuItem, Select } from '@mui/material';
import { DrawInter } from '../interactions/DrawInter';
import { Interactions } from '../interactions/Interactions';
import { AllLayer } from '../layers/AllLayer';
import OlTileLayer from '../layers/OlTileLayer';
import { OSMLayer } from '../layers/OSMLayer';
import { ReOlMap } from '../map/Map';
import { OlTileSource } from '../source/OlTileSource';
import XYZSource from '../source/XYZSource';

export default function MapTool() {
  const [drawState, setDrawState] = useState(false);
  const [type, setType] = useState('Point');
  const DrawEnder = e => {
    if (type === 'LineString') {
      e.feature.setProperties({
        length: e.feature.getGeometry().getLength(),
      });
    } else if (type === 'Polygon') {
      e.feature.setProperties({ area: e.feature.getGeometry().getArea() });
    }
  };

  const updateType = e => {
    setType(e.target.value);
  };
  return (
    <div style={{ height: '80vh', width: '80%', margin: 'auto' }}>
      <CardContent>
        <ReOlMap center={[0, 0]} zoom={2}>
          <AllLayer>
            <OSMLayer />
            <OlTileLayer
              source={XYZSource(
                'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
              )}
            />
            <OlTileLayer
              source={OlTileSource('https://ahocevar.com/geoserver/wms', {
                LAYERS: 'topp:states',
                TILED: true,
              })}
              zIndex={0}
              opacity={0.5}
              name='states_layer'
            />
          </AllLayer>
          <Interactions>
            {drawState && <DrawInter onDrawEnd={DrawEnder} type={type} />}
          </Interactions>
        </ReOlMap>
        <Button variant='outlined' onClick={() => setDrawState(!drawState)}>
          Toggle Draw
        </Button>
        <Select name='geomType' id='geomType' onChange={updateType}>
          <MenuItem value='Point'>Point</MenuItem>
          <MenuItem value='LineString'>LineString</MenuItem>
          <MenuItem value='Polygon'>Polygon</MenuItem>
        </Select>
      </CardContent>
    </div>
  );
}
