import React, { useState, useEffect, useRef } from 'react';
import { Alert, Button, TableContainer, Table, TableHead, TableCell, TableRow, Paper, TableBody, Divider, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { createBiocharEquipment, getProjectsForCompany, getCompanyBiocharEquipment, getAllProjectsWithAssignedAssets, assignAssetToProject, UnassignAssetFromProject } from '../../../../actions/tigercat-burns/tigercat-base-actions';
import AddEquipmentDialog from '../dialogs/AddEquipmentDialog';
import AddEquipmentToProjectDialog from '../dialogs/AddEquipmentToProjectDialog';

const AddEquipment = () => {
    const [openEquipmentModal, setOpenEquipmentModal] = useState(false);
    const [equipmentToAdd, setEquipmentToAdd] = useState([
        { id: 0, label: 'Equipment Name', name: 'name', type: 'text', value: null },
        { id: 1, label: 'Serial', name: 'serial', type: 'text', value: null },
        { id: 2, label: 'Gross Mass (ton)', name: 'mass', type: 'text', value: null },
        { id: 2, label: 'Description', name: 'description', type: 'text', value: null },
        { id: 2, label: 'Type', name: 'type', type: 'text', value: null },
    ]);
    const dispatch = useDispatch()
    const equipmentRefs = useRef([]);
    const userProjects = useSelector(state => state.tigercatBurnsBaseReducer.userProjects.data);
    const userEquipment = useSelector(state => state.tigercatBurnsBaseReducer.userEquipment.data);
    const { userData } = useSelector(state => state.auth);
    const projectsWithEquipmentAssigned = useSelector(state => state.tigercatBurnsBaseReducer.projectsWithAssignedAssets.data);
    const userCompanyId = userData?.tigercatBurnsUser?.companyId;


    // const [carRppbProjectId, setCarRppbProjectId] = useState(null);
    const [formErrors, setFormErrors] = useState([]);

    // State for assigning equipment to projects
    const [openAssignEquipmentToProjectModal, setOpenAssignEquipmentToProjectModal] = useState(false);
    const [projectId, setProjectId] = useState(null);
    const [equipmentId, setEquipmentId] = useState(null);

    useEffect(() => {
        dispatch(getAllProjectsWithAssignedAssets(userCompanyId, 'equipment'))
    }, [dispatch, userCompanyId])

    useEffect(() => {
        dispatch(getProjectsForCompany(userCompanyId));
        dispatch(getCompanyBiocharEquipment(userCompanyId))
    }, [dispatch, userCompanyId,])

    const handleAddEquipment = () => {
        let errors = [];
        let payload = {}

        equipmentToAdd.forEach((input, index) => {
            payload[input.name] = equipmentRefs.current[index].value;
        })

        for (let val in payload) {
            if (!payload[val]) {
                errors.push(`Please specify ${val.slice(0, 1).toUpperCase() + val.slice(1)}`);
            }
        }

        if (errors.length > 0) {
            setFormErrors(errors);
            return;
        }

        dispatch(createBiocharEquipment(payload, userCompanyId)).then(() => { // ADD COMPANY
            dispatch(getCompanyBiocharEquipment(userCompanyId))
            clearInputs()
            setOpenEquipmentModal(false)
        })
    };

    const clearInputs = () => {
        equipmentRefs.current.forEach(ref => {
            if (ref) ref.value = '';
        });
        setEquipmentToAdd((prevEquip) =>
            prevEquip.map((equip) => ({ ...equip, value: null }))
        );
        setProjectId(null);
        setEquipmentId(null);
    }

    const clearError = (fieldName) => {
        setFormErrors((prevErrors) => prevErrors.filter(error => !error.toLowerCase().includes(fieldName.toLowerCase())));
    };


    const handleEquipmentUpdate = (e) => {
        const { name, value } = e.target;
        setEquipmentToAdd((prevEquipment) =>
            prevEquipment.map((dim) =>
                dim.name === name ? { ...dim, value: value } : dim
            )
        );
        clearError(name);
    }


    const handleCancel = () => {
        setOpenEquipmentModal(false)
        clearInputs()
        setFormErrors([])
        setOpenAssignEquipmentToProjectModal(false)
        setProjectId(null)
        setEquipmentId(null)
    }

    // assign equipment to project methods

    const handleAssignEquipmentToProject = () => {
        let errors = [];

        if (!equipmentId) errors.push('Please specify Equipment');

        if (!projectId) errors.push('Please specify Project');


        if (errors.length > 0) {
            setFormErrors(errors);
            return;
        }

        dispatch(assignAssetToProject(projectId, 'equipment', equipmentId)).then(() => {
            dispatch(getAllProjectsWithAssignedAssets(userCompanyId, 'equipment'))
            setOpenAssignEquipmentToProjectModal(false);
            setProjectId(null);
            setEquipmentId(null);
        });
    }

    const handleSetEquipmentId = (e) => {
        const { name, value } = e.target;
        setEquipmentId(value)
        clearError(name);
    }

    const handleSetProjectId = (e) => {
        const { name, value } = e.target;
        setProjectId(value)
        clearError(name);
    }

    const assignedEquipment = projectsWithEquipmentAssigned?.filter((equip) => equip.project_id !== null);

    const handleUnassignAssetFromProject = (projectId, binId) => {
        dispatch(UnassignAssetFromProject(projectId, 'equipment', binId)).then(() => {
            dispatch(getAllProjectsWithAssignedAssets(userCompanyId, 'equipment'));
        });
    }

    return (
        <>
            <h2>Add Biochar Equipment</h2>
            <Button variant='contained' onClick={() => setOpenEquipmentModal(true)} sx={{ mb: 3 }}>
                Add Equipment
            </Button>
            {userEquipment?.length === 0 && <Alert severity='info' sx={{ my: 2 }}>No Equipment has been added</Alert>}
            {userEquipment?.length > 0 &&
                <>
                    <h3>Biochar Equipment</h3>
                    <TableContainer key={0} component={Paper} sx={{ height: 'auto' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        Id
                                    </TableCell>
                                    <TableCell align="left">
                                        Name
                                    </TableCell>
                                    <TableCell align="left">
                                        Type
                                    </TableCell>
                                    <TableCell align="left">
                                        Serial
                                    </TableCell>
                                    <TableCell align="left">
                                        Mass
                                    </TableCell>
                                    <TableCell align="left">
                                        Description
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userEquipment?.map((equip, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell align="left">{equip.id}</TableCell>
                                            <TableCell align="left">{equip.name}</TableCell>
                                            <TableCell align="left">{equip.type}</TableCell>
                                            <TableCell align="left">{equip.serial}</TableCell>
                                            <TableCell align="left">{equip.mass}</TableCell>
                                            <TableCell align="left">{equip.description}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>}

            <Divider sx={{ my: 2 }} />
            <h2>Assign Equipment to Projects</h2>
            <Button variant='contained' onClick={() => setOpenAssignEquipmentToProjectModal(true)} sx={{ mb: 3 }}>
                Assign Equipment
            </Button>
            <h3>Project Assigned Equipment</h3>
            {assignedEquipment?.length === 0 && (
                <Alert severity='info' sx={{ my: 2 }}>No equipment has been assigned</Alert>
            )}
            {assignedEquipment?.length > 0 && (
                <>
                    {assignedEquipment?.map((project, index) => {
                        if (project?.equipment?.length > 0)
                            return (
                                <Box key={index}>
                                    <h4>Project: {project.name}</h4>
                                    <h4>CAR Rppb Entity Name: {project.project_car_rppb_entity_name}</h4>
                                    <TableContainer component={Paper} key={project.id} sx={{ height: 'auto' }}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Id</TableCell>
                                                    <TableCell align="left">Name</TableCell>
                                                    <TableCell align="left">Unassign Equipment</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {project?.equipment.map((equip) => (
                                                    <TableRow key={equip.id}>
                                                        <TableCell align="left">{equip._id}</TableCell>
                                                        <TableCell align="left">{equip.name}</TableCell>
                                                        <TableCell align="left">
                                                            <Button variant="outlined" onClick={() => handleUnassignAssetFromProject(project.id, equip._id)}>Unassign</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer >
                                </Box>
                            )
                        else return (
                            <Box key={index}>
                                <h4>Project: {project.name}</h4>
                                <h4>CAR Rppb Entity Name: {project.project_car_rppb_entity_name}</h4>
                                <Alert severity='info' sx={{ my: 2 }}>No equipment has been assigned to this project</Alert>
                            </Box>
                        )
                    })}
                </>
            )
            }
            <AddEquipmentDialog
                openEquipmentModal={openEquipmentModal}
                setOpenEquipmentModal={setOpenEquipmentModal}
                equipmentToAdd={equipmentToAdd}
                handleEquipmentUpdate={handleEquipmentUpdate}
                handleAddEquipment={handleAddEquipment}
                handleCancel={handleCancel}
                formErrors={formErrors}
                equipmentRefs={equipmentRefs}
                clearError={clearError}
            />
            <AddEquipmentToProjectDialog
                openAssignEquipmentToProjectModal={openAssignEquipmentToProjectModal}
                setOpenAssignEquipmentToProjectModal={setOpenAssignEquipmentToProjectModal}
                projectId={projectId}
                equipmentId={equipmentId}
                handleCancel={handleCancel}
                formErrors={formErrors}
                userProjects={userProjects}
                handleSetProjectId={handleSetProjectId}
                compnayEquipment={userEquipment}
                handleSetEquipmentId={handleSetEquipmentId}
                handleAssignEquipmentToProject={handleAssignEquipmentToProject}
            />
        </>
    );
};

export default AddEquipment;