import {
    Box, Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell
} from '@mui/material';

export function WasteToRecyclingTotalTable({ wasteToRecyclingDataTotals }) {
    return (
        <Box>
            <TableContainer component={Paper} sx={{ height: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                Month
                            </TableCell>
                            <TableCell align="left">
                                Paper/Cardboard (kg)
                            </TableCell>
                            <TableCell align="left">
                                Plastics (kg)
                            </TableCell>
                            <TableCell align="left">
                                Glass (kg)
                            </TableCell>
                            <TableCell align="left">
                                Mixed Recyclables (kg)
                            </TableCell>
                            <TableCell align="left">
                                Total (kg)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {wasteToRecyclingDataTotals?.map((data, i) => {
                            if (!Object.keys(data).includes('grand_total')) {
                                return (
                                    <TableRow key={i}>
                                        <TableCell align="left">
                                            {data.month}
                                        </TableCell>
                                        <TableCell align="left">
                                            {data.paperQuantity}
                                        </TableCell>
                                        <TableCell align="left">
                                            {data.plasticsQuantity}
                                        </TableCell>
                                        <TableCell align="left">
                                            {data.glassQuantity}
                                        </TableCell>
                                        <TableCell align="left">
                                            {data.mixedQuantity}
                                        </TableCell>
                                        <TableCell align="left">
                                            {data.total}
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        })}
                        <TableRow sx={{ border: '2px solid black' }}>
                            <TableCell align="left"><b>Grand Total</b></TableCell>
                            <TableCell align="left"><b>{wasteToRecyclingDataTotals[12]?.grand_total_paper} kg</b></TableCell>
                            <TableCell align="left"><b>{wasteToRecyclingDataTotals[12]?.grand_total_plastics} kg</b></TableCell>
                            <TableCell align="left"><b>{wasteToRecyclingDataTotals[12]?.grand_total_glass} kg</b></TableCell>
                            <TableCell align="left"><b>{wasteToRecyclingDataTotals[12]?.grand_total_mixed} kg</b></TableCell>
                            <TableCell align="left"><b>{wasteToRecyclingDataTotals[12]?.grand_total} kg</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
