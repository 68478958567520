import {
    POST_CAR_HIRE_DATA_SUCCESS,
    POST_CAR_HIRE_DATA_FAIL,
    GET_CAR_HIRE_DATA_SUCCESS,
    GET_CAR_HIRE_DATA_FAIL,
    GET_CAR_HIRE_DATA_TOTALS_SUCCESS,
    GET_CAR_HIRE_DATA_TOTALS_FAIL,
} from '../../../actions/carbonfootprint-rework/types/03-indirect-ghg-emissions-from-transportation';

const initialState = {
    loading: true,
    carHireData: [],
    carHireDataTotals: []
};

// CAR HIRE REDUCERS
export default function carHireReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case POST_CAR_HIRE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_CAR_HIRE_DATA_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_CAR_HIRE_DATA_SUCCESS:
            return {
                ...state,
                carHireData: payload,
                loading: false,
            };
        case GET_CAR_HIRE_DATA_FAIL:
            return {
                ...state,
                carHireData: payload,
                loading: false
            }
        case GET_CAR_HIRE_DATA_TOTALS_SUCCESS:
            return {
                ...state,
                carHireDataTotals: payload,
                loading: false,
            };
        case GET_CAR_HIRE_DATA_TOTALS_FAIL:
            return {
                ...state,
                carHireDataTotals: payload,
                loading: false
            }

        default:
            return state;
    }
}