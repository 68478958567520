import React, { useState } from 'react'
import { useEffect } from 'react';
import {
    Grid,
    Button,
    Box, Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell, Alert,
    Typography
} from '@mui/material';
// import { getBatchData, postBatchVolumesAndMediaPdf } from '../../../../../../../actions/forms'; //
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { downloadBatchReport, getProjectBatches } from '../../../../../../../actions/rof-burns/rof-actions';
export const BatchReports = ({ next, totalItems, active, projectKey }) => {
    const dispatch = useDispatch();
    const items = [
        {
            id: 0,
            name: 'Batch Reports',
        }
    ];
    const batches = useSelector(state => state.rofBurnsReducer.projectBatches)?.batches;
    const [loadingRowKey, setLoadingRowKey] = useState(null);

    useEffect(() => {
        totalItems(items.length);
    }, [items.length, totalItems]);

    useEffect(() => {
        dispatch(getProjectBatches(projectKey));
    }, [dispatch, projectKey]);

    const handleReportDownload = async (event, projectKey, batchKey) => {
        event.preventDefault();
        setLoadingRowKey(batchKey)
        await dispatch(downloadBatchReport(projectKey, batchKey)).then(() => setLoadingRowKey(null)).then(() => dispatch(getProjectBatches(projectKey)))
    }

    return (
        <Grid container>
            {console.log(batches)}
            <Grid item xs={3} sx={{ borderRight: '1px solid', p: 2 }}>
                {items.map(item => (
                    <Button
                        fullWidth
                        key={item.id}
                        onClick={() => next(item.id)}
                        variant={active === item.id ? 'outlined' : null}
                    >
                        {item.name}
                    </Button>
                ))}
            </Grid>
            <Grid item xs={9} sx={{ p: 2 }}>
                {active === 0 ? (
                    <Box>
                        <Typography variant="h5" sx={{ mb: 2 }}>Batch Reports</Typography>

                        <TableContainer component={Paper} sx={{ height: 'auto' }}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">
                                            Batch
                                        </TableCell>
                                        <TableCell align="left">
                                            Download
                                        </TableCell>
                                        <TableCell align="left">
                                        </TableCell>
                                        <TableCell align="left">
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {batches?.map((batch, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell align="left">
                                                    {batch.batch_id}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {loadingRowKey === batch.id ? <CircularProgress /> : <Button
                                                        sx={{ mt: 2 }}
                                                        variant='contained'
                                                        onClick={(e) => handleReportDownload(e, projectKey, batch.id)}
                                                    >
                                                        Download Report
                                                    </Button>}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Alert severity='warning' sx={{ mt: 2 }}>Please be patient during downloads, reports are generated as a pdf and can take up to 5 minutes to complete</Alert>
                    </Box>
                ) : active === 1 ? (
                    <></>
                ) :
                    null
                }
            </Grid>
        </Grid >
    )
}
