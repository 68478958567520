import React, { useState, useEffect, useRef } from 'react';
import { Alert, Button, TableContainer, Table, TableHead, TableCell, TableRow, Paper, TableBody, Divider, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { createBiocharBins, getProjectsForCompany, getCompanyBiocharBins, assignAssetToProject, getAllProjectsWithAssignedAssets, UnassignAssetFromProject } from '../../../../actions/tigercat-burns/tigercat-base-actions';
import AddBinDialog from '../dialogs/AddBinDialog';
import AddBinToProjectDialog from '../dialogs/AddBinToProjectDialog';

const AddBins = () => {
    const [openBinModal, setOpenBinModal] = useState(false);
    const [binName, setBinName] = useState('');
    const [binType, setBinType] = useState(null);
    const [formErrors, setFormErrors] = useState([]);

    // State for assigning bins to projects
    const [openAssignBinToProjectModal, setOpenAssignBinToProjectModal] = useState(false);
    const [projectId, setProjectId] = useState(null);
    const [binId, setBinId] = useState(null);

    const dispatch = useDispatch();
    const userProjects = useSelector(state => state.tigercatBurnsBaseReducer.userProjects.data);
    const companyBins = useSelector(state => state.tigercatBurnsBaseReducer.userBins.data);
    const projectsWithBinsAssigned = useSelector(state => state.tigercatBurnsBaseReducer.projectsWithAssignedAssets.data);
    const { userData } = useSelector(state => state.auth);
    const userCompanyId = userData?.tigercatBurnsUser?.companyId;

    useEffect(() => {
        dispatch(getProjectsForCompany(userCompanyId));
        dispatch(getCompanyBiocharBins(userCompanyId));
        dispatch(getAllProjectsWithAssignedAssets(userCompanyId, 'bins'))
    }, [dispatch, userCompanyId]);

    const [rectDimensions, setRectDimensions] = useState([
        { id: 0, label: 'Length', name: 'length', type: 'text', value: null },
        { id: 1, label: 'Width', name: 'width', type: 'text', value: null },
        { id: 2, label: 'Height', name: 'height', type: 'text', value: null },
    ]);
    const [trapezoidalDimensions, setTrapezoidalDimensions] = useState([
        { id: 0, label: 'Bottom Width', name: 'bottom width', type: 'text', value: null },
        { id: 1, label: 'Top Width', name: 'top width', type: 'text', value: null },
        { id: 2, label: 'Bottom Length', name: 'bottom length', type: 'text', value: null },
        { id: 3, label: 'Top Length', name: 'top length', type: 'text', value: null },
        { id: 4, label: 'Height', name: 'height', type: 'text', value: null },
    ]);

    const rectRefs = useRef([]);
    const trapRefs = useRef([]);

    const handleAddBin = () => {
        let errors = [];

        let payload = { name: binName, type: binType };

        if (binType === 'Rectangular') {
            rectDimensions.forEach(dim => {
                payload[dim.name.replace(" ", "_")] = dim.value;
            });
        } else if (binType === 'Trapezoidal') {
            trapezoidalDimensions.forEach(dim => {
                payload[dim.name.replace(" ", "_")] = dim.value;
            });
        }

        for (let val in payload) {
            if (!payload[val]) {
                errors.push(`Please specify ${val.slice(0, 1).toUpperCase() + val.slice(1)}`);
            }
        }

        if (errors.length > 0) {
            setFormErrors(errors);
            return;
        }

        dispatch(createBiocharBins(payload, userCompanyId)).then(() => {
            dispatch(getCompanyBiocharBins(userCompanyId));
            clearInputs();
            setBinName('');
            setBinType(null);
            setOpenBinModal(false);
        });
    };

    const handleRectDimensions = (e) => {
        const { name, value } = e.target;
        setRectDimensions((prevDimensions) =>
            prevDimensions.map((dim) =>
                dim.name === name ? { ...dim, value: value } : dim
            )
        );
        clearError(name);
    };

    const handleTrapDimensions = (e) => {
        const { name, value } = e.target;
        setTrapezoidalDimensions((prevDimensions) =>
            prevDimensions.map((dim) =>
                dim.name === name ? { ...dim, value: value } : dim
            )
        );
        clearError(name);
    };

    const clearError = (fieldName) => {
        setFormErrors((prevErrors) => prevErrors.filter(error => !error.toLowerCase().includes(fieldName.toLowerCase())));
    };

    const clearInputs = () => {
        if (binType === 'Rectangular') {
            rectRefs.current.forEach(ref => {
                if (ref) ref.value = '';
            });
            setRectDimensions((prevDimensions) =>
                prevDimensions.map((dim) => ({ ...dim, value: null }))
            );
        } else if (binType === 'Trapezoidal') {
            trapRefs.current.forEach(ref => {
                if (ref) ref.value = '';
            });
            setTrapezoidalDimensions((prevDimensions) =>
                prevDimensions.map((dim) => ({ ...dim, value: null }))
            );
        } else {
            setProjectId(null);
            setBinId(null);
        }
    };

    const handleCancel = () => {
        setOpenBinModal(false);
        clearInputs();
        setFormErrors([]);
        setBinName('');
        setBinType(null);
        setOpenAssignBinToProjectModal(false);
        setProjectId(null);
        setBinId(null);
    };

    // Code for assigning bins to projects

    const handleAssignBinToProject = () => {
        let errors = [];

        if (!binId) {
            errors.push('Please select a bin');
        }

        if (!projectId) {
            errors.push('Please select a project');
        }

        if (errors.length > 0) {
            setFormErrors(errors);
            return;
        }

        dispatch(assignAssetToProject(projectId, 'bin', binId)).then(() => {
            dispatch(getAllProjectsWithAssignedAssets(userCompanyId, 'bins'))
            setOpenAssignBinToProjectModal(false);
            setProjectId(null);
            setBinId(null);
        });
    };

    const handleSetBinId = (e) => {
        const { name, value } = e.target;
        setBinId(value);
        clearError(name);
    };

    const handleSetProjectId = (e) => {
        const { name, value } = e.target;
        setProjectId(value);
        clearError(name);
    };

    const handleUnassignAssetFromProject = (projectId, binId) => {
        dispatch(UnassignAssetFromProject(projectId, 'bin', binId)).then(() => {
            dispatch(getAllProjectsWithAssignedAssets(userCompanyId, 'bins'));
        });
    }

    const rectangularBins = companyBins?.filter((bin) => bin.type === 'Rectangular');
    const trapezoidalBins = companyBins?.filter((bin) => bin.type === 'Trapezoidal');
    const assignedBins = companyBins?.filter((bin) => bin.project_id !== null);

    return (
        <>
            <h2>Add Biochar Bins</h2>
            <Button variant='contained' onClick={() => setOpenBinModal(true)} sx={{ mb: 3 }}>
                Add New Rectagular or Trapezoidal Bin
            </Button>
            <h3>Rectangular Bins</h3>
            {rectangularBins?.length === 0 && <Alert severity='info' sx={{ my: 2 }}>No Rectangular bins have been added</Alert>}
            {rectangularBins?.length > 0 && (
                <TableContainer component={Paper} sx={{ height: 'auto' }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Id</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Date Created</TableCell>
                                <TableCell align="left">Length</TableCell>
                                <TableCell align="left">Width</TableCell>
                                <TableCell align="left">Height</TableCell>
                                <TableCell align="left">Gross Volume</TableCell>
                                <TableCell align="left">Unit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rectangularBins.map((bin) => (
                                <TableRow key={bin.id}>
                                    <TableCell align="left">{bin.id}</TableCell>
                                    <TableCell align="left">{bin.name}</TableCell>
                                    <TableCell align="left">{bin.created_at.split('T')[0]}</TableCell>
                                    <TableCell align="left">{bin.rect_len}</TableCell>
                                    <TableCell align="left">{bin.rect_width}</TableCell>
                                    <TableCell align="left">{bin.rect_height}</TableCell>
                                    <TableCell align="left">{bin.gross_volume.toFixed(2)}</TableCell>
                                    <TableCell align="left">Inch</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <h3>Trapezoidal Bins</h3>
            {trapezoidalBins?.length === 0 && (
                <Alert severity='info' sx={{ my: 2 }}>No Trapezoidal bins have been added</Alert>
            )}
            {trapezoidalBins?.length > 0 && (
                <TableContainer component={Paper} sx={{ height: 'auto' }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Id</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Date Created</TableCell>
                                <TableCell align="left">Bottom Length</TableCell>
                                <TableCell align="left">Top Length</TableCell>
                                <TableCell align="left">Bottom Width</TableCell>
                                <TableCell align="left">Top Width</TableCell>
                                <TableCell align="left">Height</TableCell>
                                <TableCell align="left">Gross Volume (Inch3)</TableCell>
                                <TableCell align="left">Unit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trapezoidalBins.map((bin) => (
                                <TableRow key={bin.id}>
                                    <TableCell align="left">{bin.id}</TableCell>
                                    <TableCell align="left">{bin.name}</TableCell>
                                    <TableCell align="left">{bin.created_at.split('T')[0]}</TableCell>
                                    <TableCell align="left">{bin.trap_bottomLength}</TableCell>
                                    <TableCell align="left">{bin.trap_topLength}</TableCell>
                                    <TableCell align="left">{bin.trap_bottomWidth}</TableCell>
                                    <TableCell align="left">{bin.trap_topWidth}</TableCell>
                                    <TableCell align="left">{bin.trap_height}</TableCell>
                                    <TableCell align="left">{bin.gross_volume.toFixed(2)}</TableCell>
                                    <TableCell align="left">Inch</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <Divider sx={{ my: 2 }} />
            <h2>Assign Bins to Projects</h2>
            <Button variant='contained' onClick={() => setOpenAssignBinToProjectModal(true)} sx={{ mb: 3 }}>
                Assign Bins
            </Button>
            <h3>Project Assigned Bins</h3>
            {assignedBins?.length === 0 && (
                <Alert severity='info' sx={{ my: 2 }}>No bins have been assigned</Alert>
            )}
            {projectsWithBinsAssigned?.length > 0 && (
                <>
                    {projectsWithBinsAssigned.map((project, index) => {
                        if (project?.bins?.length > 0)
                            return (
                                <Box key={index}>
                                    <h4>Project: {project.name}</h4>
                                    <h4>CAR Rppb Entity Name: {project.project_car_rppb_entity_name}</h4>
                                    <TableContainer component={Paper} key={project.id} sx={{ height: 'auto' }}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Id</TableCell>
                                                    <TableCell align="left">Name</TableCell>
                                                    <TableCell align="left">Unassign Bin</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {project.bins.map((bin) => (
                                                    <TableRow key={bin.id}>
                                                        <TableCell align="left">{bin._id}</TableCell>
                                                        <TableCell align="left">{bin.name}</TableCell>
                                                        <TableCell align="left">
                                                            <Button variant="outlined" onClick={() => handleUnassignAssetFromProject(project.id, bin._id)}>Unassign</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer >
                                </Box>
                            )
                        else return (
                            <Box key={index}>
                                <h4>Project: {project.name} ({project.project_car_rppb_entity_name})</h4>
                                <h4>CAR Rppb Entity Name: {project.project_car_rppb_entity_name}</h4>
                                <Alert severity='info' sx={{ my: 2 }}>No bins have been assigned to this project</Alert>
                            </Box>
                        )
                    })}
                </>
            )
            }
            <AddBinDialog
                openBinModal={openBinModal}
                setOpenBinModal={setOpenBinModal}
                binName={binName}
                setBinName={setBinName}
                binType={binType}
                setBinType={setBinType}
                handleAddBin={handleAddBin}
                handleRectDimensions={handleRectDimensions}
                handleTrapDimensions={handleTrapDimensions}
                rectDimensions={rectDimensions}
                trapezoidalDimensions={trapezoidalDimensions}
                userProjects={userProjects}
                clearInputs={clearInputs}
                clearError={clearError}
                formErrors={formErrors}
                handleCancel={handleCancel}
            />
            <AddBinToProjectDialog
                openAssignBinToProjectModal={openAssignBinToProjectModal}
                setOpenAssignBinToProjectModal={setOpenAssignBinToProjectModal}
                userProjects={userProjects}
                binId={binId}
                projectId={projectId}
                setProjectId={setProjectId}
                handleSetBinId={handleSetBinId}
                handleSetProjectId={handleSetProjectId}
                handleCancel={handleCancel}
                handleAssignBinToProject={handleAssignBinToProject}
                formErrors={formErrors}
                companyBins={companyBins}
            />
        </>
    );
};

export default AddBins;