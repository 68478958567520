import { setAlert } from './alert';
import {
  API_PLOTLIST,
  API_CREATE_GENERAL_INFORMATION,
  API_GET_GENERAL_INFORMATION,
  API_CF_GET_OR_CREATE_FERTILIZERS,
  API_CF_UPDATE_FERTILIZER_ACTIVE_STATE,
  API_CF_GET_FERTILIZER_PLOTLIST_DATA,
  API_CF_SUBMIT_FERTILIZER_PLOTLIST_DATA,
  API_CF_GET_OR_CREATE_AGROCHEMICAL,
  API_CF_UPDATE_AGROCHEMICAL_ACTIVE_STATE,
  API_CF_GET_AGROCHEMICAL_PLOTLIST_DATA,
  API_CF_GET_COMPOST_PLOTLIST_DATA,
  CREATE_ON_FARM_COMPOST,
  CREATE_PURCHASED_COMPOST,
  GET_ON_FARM_COMPOST,
  GET_PURCHASED_COMPOST,
  API_CF_SUBMIT_AGROCHEMICAL_PLOTLIST_DATA,
  API_CF_SUBMIT_COMPOST_PLOTLIST_DATA,
  API_CREATE_CULTIVATION_GENERAL_INFORMATION,
  API_GET_CULTIVATION_GENERAL_INFORMATION,
} from './constants';
import {
  GET_PLOTLIST_FAIL,
  GET_PLOTLIST_SUCCESS,
  CREATE_PLOTLIST_SUCCESS,
  CREATE_PLOTLIST_FAIL,
  CREATE_GENERAL_INFORMATION_SUCCESS,
  CREATE_GENERAL_INFORMATION_FAIL,
  GET_GENERAL_INFORMATION_SUCCESS,
  GET_GENERAL_INFORMATION_FAIL,
  CF_GET_FERTILIZERS_SUCCESS,
  CF_GET_FERTILIZERS_FAIL,
  CF_CREATE_FERTILIZER_SUCCESS,
  CF_CREATE_FERTILIZER_FAIL,
  CF_ADD_FERTILIZER_TO_LIST_SUCCESS,
  CF_ADD_FERTILIZER_TO_LIST_FAIL,
  CF_GET_FERTILIZER_PLOTLIST_DATA_SUCCESS,
  CF_GET_FERTILIZER_PLOTLIST_DATA_FAIL,
  CF_SUBMIT_FERTILIZER_PLOTLIST_DATA_SUCCESS,
  CF_SUBMIT_FERTILIZER_PLOTLIST_DATA_FAIL,
  CF_GET_AGROCHEMICALS_SUCCESS,
  CF_GET_AGROCHEMICALS_FAIL,
  CF_CREATE_AGROCHEMICAL_SUCCESS,
  CF_CREATE_AGROCHEMICAL_FAIL,
  CF_ADD_AGROCHEMICAL_TO_LIST_SUCCESS,
  CF_ADD_AGROCHEMICAL_TO_LIST_FAIL,
  CF_GET_AGROCHEMICAL_PLOTLIST_DATA_SUCCESS,
  CF_GET_AGROCHEMICAL_PLOTLIST_DATA_FAIL,
  CF_GET_COMPOST_PLOTLIST_DATA_SUCCESS,
  CF_GET_COMPOST_PLOTLIST_DATA_FAIL,
  CF_CREATE_FARM_COMPOST_SUCCESS,
  CF_CREATE_FARM_COMPOST_FAIL,
  CF_CREATE_PURCHASED_COMPOST_SUCCESS,
  CF_CREATE_PURCHASED_COMPOST_FAIL,
  CF_GET_FARM_COMPOST_SUCCESS,
  CF_GET_FARM_COMPOST_FAIL,
  CF_GET_PURCHASED_COMPOST_SUCCESS,
  CF_GET_PURCHASED_COMPOST_FAIL,
  CF_SUBMIT_AGROCHEMICAL_PLOTLIST_DATA_SUCCESS,
  CF_SUBMIT_AGROCHEMICAL_PLOTLIST_DATA_FAIL,
  CF_SUBMIT_COMPOST_PLOTLIST_DATA_SUCCESS,
  CF_SUBMIT_COMPOST_PLOTLIST_DATA_FAIL,
  GET_CULTIVATION_GENERAL_INFORMATION_SUCCESS,
  GET_CULTIVATION_GENERAL_INFORMATION_FAIL,
  CREATE_CULTIVATION_GENERAL_INFORMATION_SUCCESS,
  CREATE_CULTIVATION_GENERAL_INFORMATION_FAIL
} from './types';
import axios from 'axios';

const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
  },
};

// Fetch Plot List
export const getPlotList = (group, year) => async dispatch => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + API_PLOTLIST + `${group}/${year}`,
      config
    );
    dispatch({
      type: GET_PLOTLIST_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: GET_PLOTLIST_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const createPlotList = (plotListData, group, year) => async dispatch => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + API_PLOTLIST + `${group}/${year}`,
      plotListData,
      config
    );
    dispatch({
      type: CREATE_PLOTLIST_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CREATE_PLOTLIST_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const postCompanyInformation = (formData) => async dispatch => {
  try {
    // console.log('formDataInAction', data)
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + API_CREATE_GENERAL_INFORMATION,
      formData,
      config
    );
    dispatch({
      type: CREATE_GENERAL_INFORMATION_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CREATE_GENERAL_INFORMATION_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const postCultivationCompanyInformation = (formData) => async dispatch => {
  try {
    // console.log('formDataInAction', data)
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + API_CREATE_CULTIVATION_GENERAL_INFORMATION,
      formData,
      config
    );
    // console.log(data)
    dispatch({
      type: CREATE_CULTIVATION_GENERAL_INFORMATION_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CREATE_CULTIVATION_GENERAL_INFORMATION_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const getCompanyInformation = (id, year) => async dispatch => {
  try {
    // console.log('formDataInAction', data)
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + API_GET_GENERAL_INFORMATION + id + `/${year}`,
      config
    );
    dispatch({
      type: GET_GENERAL_INFORMATION_SUCCESS,
      payload: data.generalInformation[0]
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: GET_GENERAL_INFORMATION_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const getCultivationGeneralInformation = (id, year) => async dispatch => {
  try {
    // console.log('formDataInAction', data)
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + API_GET_CULTIVATION_GENERAL_INFORMATION + id + `/${year}`,
      config
    );
    dispatch({
      type: GET_CULTIVATION_GENERAL_INFORMATION_SUCCESS,
      payload: data.generalInformation[0]
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: GET_CULTIVATION_GENERAL_INFORMATION_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const getFertilizers = (group, year) => async dispatch => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + API_CF_GET_OR_CREATE_FERTILIZERS + group + `/${year}`,
      config
    );
    dispatch({
      type: CF_GET_FERTILIZERS_SUCCESS,
      payload: data
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CF_GET_FERTILIZERS_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const createFertilizer = (fertilizerData, group, year) => async dispatch => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + API_CF_GET_OR_CREATE_FERTILIZERS + group + `/${year}`,
      fertilizerData,
      config
    );
    dispatch({
      type: CF_CREATE_FERTILIZER_SUCCESS,
      payload: data.fertilizer[0]
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CF_CREATE_FERTILIZER_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const getAgroChemicals = (group, year) => async dispatch => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + API_CF_GET_OR_CREATE_AGROCHEMICAL + group + `/${year}`,
      config
    );
    dispatch({
      type: CF_GET_AGROCHEMICALS_SUCCESS,
      payload: data
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CF_GET_AGROCHEMICALS_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const createAgroChemical = (agroChemicalData, group, year) => async dispatch => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + API_CF_GET_OR_CREATE_AGROCHEMICAL + group + `/${year}`,
      agroChemicalData,
      config
    );
    dispatch({
      type: CF_CREATE_AGROCHEMICAL_SUCCESS,
      payload: data.agrochemical[0]
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CF_CREATE_AGROCHEMICAL_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const updateFertilizerActiveState = (group, fertilizerId) => async dispatch => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + API_CF_UPDATE_FERTILIZER_ACTIVE_STATE + group + `/${fertilizerId}`,
      config
    );
    dispatch({
      type: CF_ADD_FERTILIZER_TO_LIST_SUCCESS,
      payload: data
    });
    //dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CF_ADD_FERTILIZER_TO_LIST_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const updateAgrochemicalActiveState = (group, agrochemicalId) => async dispatch => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + API_CF_UPDATE_AGROCHEMICAL_ACTIVE_STATE + group + `/${agrochemicalId}`,
      config
    );
    dispatch({
      type: CF_ADD_AGROCHEMICAL_TO_LIST_SUCCESS,
      payload: data
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CF_ADD_AGROCHEMICAL_TO_LIST_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const getFertilizerPlotlistData = (groupId, year) => async dispatch => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + API_CF_GET_FERTILIZER_PLOTLIST_DATA + groupId + `/${year}`,
      config
    );
    dispatch({
      type: CF_GET_FERTILIZER_PLOTLIST_DATA_SUCCESS,
      payload: data
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CF_GET_FERTILIZER_PLOTLIST_DATA_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const getAgrochemicalPlotlistData = (groupId, year) => async dispatch => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + API_CF_GET_AGROCHEMICAL_PLOTLIST_DATA + groupId + `/${year}`,
      config
    );
    dispatch({
      type: CF_GET_AGROCHEMICAL_PLOTLIST_DATA_SUCCESS,
      payload: data
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CF_GET_AGROCHEMICAL_PLOTLIST_DATA_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const submitFertilizerPlotlistData = (fertilizerPlotlistData, groupId, year) => async dispatch => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + API_CF_SUBMIT_FERTILIZER_PLOTLIST_DATA + groupId + `/${year}`,
      fertilizerPlotlistData,
      config
    );
    dispatch({
      type: CF_SUBMIT_FERTILIZER_PLOTLIST_DATA_SUCCESS,
      payload: data
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CF_SUBMIT_FERTILIZER_PLOTLIST_DATA_FAIL,
      payload: err.response.data.detail,
    });
  }
};


export const submitAgrochemicalPlotlistData = (agrochemicalPlotlistData, groupId, year) => async dispatch => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + API_CF_SUBMIT_AGROCHEMICAL_PLOTLIST_DATA + groupId + `/${year}`,
      agrochemicalPlotlistData,
      config
    );
    dispatch({
      type: CF_SUBMIT_AGROCHEMICAL_PLOTLIST_DATA_SUCCESS,
      payload: data
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CF_SUBMIT_AGROCHEMICAL_PLOTLIST_DATA_FAIL,
      payload: err.response.data.detail,
    });
  }
};


export const submitCompostPlotlistData = (compostPlotlistData, groupId, year) => async dispatch => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + API_CF_SUBMIT_COMPOST_PLOTLIST_DATA + groupId + `/${year}`,
      compostPlotlistData,
      config
    );
    dispatch({
      type: CF_SUBMIT_COMPOST_PLOTLIST_DATA_SUCCESS,
      payload: data
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CF_SUBMIT_COMPOST_PLOTLIST_DATA_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const getCompostPlotlistData = (groupId, year) => async dispatch => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + API_CF_GET_COMPOST_PLOTLIST_DATA + groupId + `/${year}`,
      config
    );
    dispatch({
      type: CF_GET_COMPOST_PLOTLIST_DATA_SUCCESS,
      payload: data
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CF_GET_COMPOST_PLOTLIST_DATA_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const createOnFarmCompost = (onFarmCompostData, id, year) => async dispatch => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + CREATE_ON_FARM_COMPOST + id + `/${year}`,
      onFarmCompostData,
      config
    );
    dispatch({
      type: CF_CREATE_FARM_COMPOST_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CF_CREATE_FARM_COMPOST_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const getOnFarmCompost = (groupId, year) => async dispatch => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + GET_ON_FARM_COMPOST + groupId + `/${year}`,
      config
    );
    dispatch({
      type: CF_GET_FARM_COMPOST_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CF_GET_FARM_COMPOST_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const createPurchasedCompost = (purchasedCompostData, id, year) => async dispatch => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + CREATE_PURCHASED_COMPOST + id + `/${year}`,
      purchasedCompostData,
      config
    );
    dispatch({
      type: CF_CREATE_PURCHASED_COMPOST_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CF_CREATE_PURCHASED_COMPOST_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const getPurchasedCompost = (groupId, year) => async dispatch => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + GET_PURCHASED_COMPOST + groupId + `/${year}`,
      config
    );
    dispatch({
      type: CF_GET_PURCHASED_COMPOST_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CF_GET_PURCHASED_COMPOST_FAIL,
      payload: err.response.data.detail,
    });
  }
};

// Submit Annual Sheet
// export const postAnnualSheet = data => async dispatch => {
//   try {
//     const res = await api().post(API_ANNUAL_SHEET, data, config);
//     dispatch(setAlert(res.data.detail, 'success'));
//     dispatch({
//       type: ANNUAL_SHEET_SUCCESS,
//       payload: res.data.formdata,
//     });
//   } catch (err) {
//     dispatch(setAlert(err.response.data.detail, 'error'));
//     dispatch({
//       type: ANNUAL_SHEET_FAIL,
//     });
//   }
// };
