import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTigercatUsers } from '../../../actions/tigercat-burns/tigercat-base-actions'
import { TableContainer, Table, TableHead, TableCell, TableRow, Paper, TableBody } from '@mui/material';

const Users = () => {
    const dispatch = useDispatch()
    const tigercatUsers = useSelector(state => state.tigercatBurnsBaseReducer.tigercatBurnsUsers.data)

    useEffect(() => {
        dispatch(getTigercatUsers())
    }, [])


    return (
        <>
            <h2>Users</h2>
            <TableContainer key={0} component={Paper} sx={{ height: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                Id
                            </TableCell>
                            <TableCell align="left">
                                Email
                            </TableCell>
                            <TableCell align="left">
                                Company
                            </TableCell>
                            <TableCell align="left">
                                Role
                            </TableCell>
                            <TableCell align="left">
                                Created
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tigercatUsers?.map((data, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell align="left">{data.id}</TableCell>
                                    <TableCell align="left">{data.user.email}</TableCell>
                                    <TableCell align="left">{data.company.name}</TableCell>
                                    <TableCell align="left">{data.role.name.toLowerCase()}</TableCell>
                                    <TableCell align="left">{data.created_at.split('T')[0]}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>

    )
}

export default Users