export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_INIT = 'LOGIN_INIT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAIL = 'USER_PROFILE_FAIL';
export const USER_PROGRAMMES_SUCCESS = 'USER_PROGRAMMES_SUCCESS';
export const USER_PROGRAMMES_FAIL = 'USER_PROGRAMMES_FAIL';
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';
export const GET_FAQ_FAIL = 'GET_FAQ_FAIL';
export const FORM_DATA_SUCCESS = 'FORM_DATA_SUCCESS';
export const FORM_DATA_FAIL = 'FORM_DATA_FAIL';
export const CREATE_FORM_DATA_SUCCESS = 'CREATE_FORM_DATA_SUCCESS';
export const CREATE_FORM_DATA_FAIL = 'CREATE_FORM_DATA_FAIL';
export const FIREBREAKS_SUCCESS = 'FIREBREAKS_SUCCESS';
export const FIREBREAKS_FAIL = 'FIREBREAKS_FAIL';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAIL = 'FILE_UPLOAD_FAIL';
export const PROJECTS_SUCCESS = 'PROJECTS_SUCCESS';
export const PROJECTS_FAIL = 'PROJECTS_FAIL';
export const UPDATE_PROJECT_NICKNAME_SUCCESS =
  'UPDATE_PROJECT_NICKNAME_SUCCESS';
export const UPDATE_PROJECT_NICKNAME_FAIL = 'UPDATE_PROJECT_NICKNAME_FAIL';
export const GET_PLOTLIST_SUCCESS = 'GET_PLOTLIST_SUCCESS';
export const GET_PLOTLIST_FAIL = 'GET_PLOTLIST_FAIL';
export const CREATE_PLOTLIST_SUCCESS = 'CREATE_PLOTLIST_SUCCESS';
export const CREATE_PLOTLIST_FAIL = 'CREATE_PLOTLIST_FAIL';
export const GET_IRT_REGISTRY_SUCCESS = 'GET_IRT_REGISTRY_SUCCESS';
export const GET_IRT_REGISTRY_FAIL = 'GET_IRT_REGISTRY_FAIL';
export const GET_GENERAL_INFORMATION_SUCCESS =
  'GET_GENERAL_INFORMATION_SUCCESS';
export const GET_GENERAL_INFORMATION_FAIL = 'GET_GENERAL_INFORMATION_FAIL';
export const CREATE_GENERAL_INFORMATION_SUCCESS =
  'CREATE_GENERAL_INFORMATION_SUCCESS';
export const CREATE_GENERAL_INFORMATION_FAIL =
  'CREATE_GENERAL_INFORMATION_FAIL';
export const GET_CULTIVATION_GENERAL_INFORMATION_SUCCESS =
  'GET_CULTIVATION_GENERAL_INFORMATION_SUCCESS';
export const GET_CULTIVATION_GENERAL_INFORMATION_FAIL =
  'GET_CULTIVATION_GENERAL_INFORMATION_FAIL';
export const CREATE_CULTIVATION_GENERAL_INFORMATION_SUCCESS =
  'CREATE_CULTIVATION_GENERAL_INFORMATION_SUCCESS';
export const CREATE_CULTIVATION_GENERAL_INFORMATION_FAIL =
  'CREATE_CULTIVATION_GENERAL_INFORMATION_FAIL';
export const CF_USER_DETAILS_SUCCESS = 'CF_USER_DETAILS_SUCCESS';
export const CF_USER_DETAILS_FAIL = 'CF_USER_DETAILS_FAIL';
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';
export const FETCH_NOTES_FAIL = 'FETCH_NOTES_FAIL';
// fertilizers
export const CF_GET_FERTILIZERS_SUCCESS = 'CF_GET_FERTILIZERS_SUCCESS';
export const CF_GET_FERTILIZERS_FAIL = 'CF_GET_FERTILIZERS_FAIL';
export const CF_CREATE_FERTILIZER_SUCCESS = 'CF_CREATE_FERTILIZERS_SUCCESS';
export const CF_CREATE_FERTILIZER_FAIL = 'CF_CREATE_FERTILIZERS_FAIL';
export const CF_ADD_FERTILIZER_TO_LIST_SUCCESS =
  'CF_ADD_FERTILIZER_TO_LIST_SUCCESS';
export const CF_ADD_FERTILIZER_TO_LIST_FAIL = 'CF_ADD_FERTILIZER_TO_LIST_FAIL';
export const CF_GET_FERTILIZER_PLOTLIST_DATA_SUCCESS =
  'CF_GET_FERTILIZER_PLOTLIST_DATA_SUCCESS';
export const CF_GET_FERTILIZER_PLOTLIST_DATA_FAIL =
  'CF_GET_FERTILIZER_PLOTLIST_DATA_FAIL';
export const CF_SUBMIT_FERTILIZER_PLOTLIST_DATA_SUCCESS =
  'CF_SUBMIT_FERTILIZER_PLOTLIST_DATA_SUCCESS';
export const CF_SUBMIT_FERTILIZER_PLOTLIST_DATA_FAIL =
  'CF_SUBMIT_FERTILIZER_PLOTLIST_DATA_FAIL';
// agro chemicals
export const CF_GET_AGROCHEMICALS_SUCCESS = 'CF_GET_AGROCHEMICALS_SUCCESS';
export const CF_GET_AGROCHEMICALS_FAIL = 'CF_GET_AGROCHEMICALS_FAIL';
export const CF_CREATE_AGROCHEMICAL_SUCCESS = 'CF_CREATE_AGROCHEMICAL_SUCCESS';
export const CF_CREATE_AGROCHEMICAL_FAIL = 'CF_CREATE_AGROCHEMICAL_FAIL';
export const CF_ADD_AGROCHEMICAL_TO_LIST_SUCCESS =
  'CF_ADD_AGROCHEMICAL_TO_LIST_SUCCESS';
export const CF_ADD_AGROCHEMICAL_TO_LIST_FAIL =
  'CF_ADD_AGROCHEMICAL_TO_LIST_FAIL';
export const CF_GET_AGROCHEMICAL_PLOTLIST_DATA_SUCCESS =
  'CF_GET_AGROCHEMICAL_PLOTLIST_DATA_SUCCESS';
export const CF_GET_AGROCHEMICAL_PLOTLIST_DATA_FAIL =
  'CF_GET_AGROCHEMICAL_PLOTLIST_DATA_FAIL';
export const CF_SUBMIT_AGROCHEMICAL_PLOTLIST_DATA_SUCCESS =
  'CF_SUBMIT_AGROCHEMICAL_PLOTLIST_DATA_SUCCESS';
export const CF_SUBMIT_AGROCHEMICAL_PLOTLIST_DATA_FAIL =
  'CF_SUBMIT_AGROCHEMICAL_PLOTLIST_DATA_FAIL';
// farm compost
export const CF_GET_FARM_COMPOST_SUCCESS = 'CF_GET_FARM_COMPOST_SUCCESS';
export const CF_GET_FARM_COMPOST_FAIL = 'CF_GET_FARM_COMPOST_FAIL';
export const CF_CREATE_FARM_COMPOST_SUCCESS = 'CF_CREATE_FARM_COMPOST_SUCCESS';
export const CF_CREATE_FARM_COMPOST_FAIL = 'CF_CREATE_FARM_COMPOST_FAIL';
// purchased compost
export const CF_GET_PURCHASED_COMPOST_SUCCESS =
  'CF_GET_PURCHASED_COMPOST_SUCCESS';
export const CF_GET_PURCHASED_COMPOST_FAIL = 'CF_GET_PURCHASED_COMPOST_FAIL';
export const CF_CREATE_PURCHASED_COMPOST_SUCCESS =
  'CF_CREATE_PURCHASED_COMPOST_SUCCESS';
export const CF_CREATE_PURCHASED_COMPOST_FAIL =
  'CF_CREATE_PURCHASED_COMPOST_FAIL';
export const CF_GET_COMPOST_PLOTLIST_DATA_SUCCESS =
  'CF_GET_COMPOST_PLOTLIST_DATA_SUCCESS';
export const CF_GET_COMPOST_PLOTLIST_DATA_FAIL =
  'CF_GET_COMPOST_PLOTLIST_DATA_FAIL';
export const CF_SUBMIT_COMPOST_PLOTLIST_DATA_SUCCESS =
  'CF_SUBMIT_COMPOST_PLOTLIST_DATA_SUCCESS';
export const CF_SUBMIT_COMPOST_PLOTLIST_DATA_FAIL =
  'CF_SUBMIT_COMPOST_PLOTLIST_DATA_FAIL';
// biochar
export const FETCH_BATCH_REPORTS_SUCCESS = 'FETCH_BATCH_REPORTS_SUCCESS';
export const FETCH_BATCH_REPORTS_FAIL = 'FETCH_BATCH_REPORTS_FAIL';
export const FETCH_PROJECT_REPORTS_SUCCESS = 'FETCH_PROJECT_REPORTS_SUCCESS';
export const FETCH_PROJECT_REPORTS_FAIL = 'FETCH_PROJECT_REPORTS_FAIL';
export const FETCH_BCR_REPORTS_SUCCESS = 'FETCH_BCR_REPORTS_SUCCESS';
export const FETCH_BCR_REPORTS_FAIL = 'FETCH_BCR_REPORTS_FAIL';
export const FETCH_KILN_DIMENSIONS_SUCCESS = 'FETCH_KILN_DIMENSIONS_SUCCESS';
export const FETCH_KILN_DIMENSIONS_FAIL = 'FETCH_KILN_DIMENSIONS_FAIL';
// mobile
export const FETCH_BATCH_MEDIA_SUCCESS = 'FETCH_BATCH_MEDIA_SUCCESS';
export const FETCH_BATCH_MEDIA_FAIL = 'FETCH_BATCH_MEDIA_FAIL';
export const FETCH_BATCH_DATA_SUCCESS = 'FETCH_BATCH_DATA_SUCCESS';
export const FETCH_BATCH_DATA_FAIL = 'FETCH_BATCH_DATA_FAIL';
export const FETCH_ADDITIONAL_BATCH_DATA_SUCCESS =
  'FETCH_ADDITIONAL_BATCH_DATA_SUCCESS';
export const FETCH_ADDITIONAL_BATCH_DATA_FAIL =
  'FETCH_ADDITIONAL_BATCH_DATA_FAIL';
//
export const FETCH_CHARGING_DATA_SUCCESS = 'FETCH_CHARGING_DATA_SUCCESS';
export const FETCH_CHARGING_DATA_FAIL = 'FETCH_CHARGING_DATA_FAIL';
export const FETCH_APPLICATION_DATA_SUCCESS = 'FETCH_APPLICATION_DATA_SUCCESS';
export const FETCH_APPLICATION_DATA_FAIL = 'FETCH_APPLICATION_DATA_FAIL';
//
export const FETCH_IRT_PROJECTS_SUCCESS = 'FETCH_IRT_PROJECTS_SUCCESS';
export const FETCH_IRT_PROJECTS_FAIL = 'FETCH_IRT_PROJECTS_FAIL';
// verification
export const API_FETCH_FEEDSTOCK_VERIFICATION_DATA_SUCCESS =
  'FETCH_FEEDSTOCK_VERIFICATION_DATA_SUCCESS';
export const API_FETCH_FEEDSTOCK_VERIFICATION_DATA_FAIL =
  'FETCH_FEEDSTOCK_VERIFICATION_DATA_FAIL';
export const API_FETCH_FLAMING_COMBUSTION_DATA_SUCCESS =
  'FETCH_FLAMING_COMBUSTION_DATA_SUCCESS';
export const API_FETCH_FLAMING_COMBUSTION_DATA_FAIL =
  'FETCH_FLAMING_COMBUSTION_DATA_FAIL';
export const API_FETCH_BIOCHAR_MEASUREMENT_DATA_SUCCESS =
  'FETCH_BIOCHAR_MEASUREMENT_DATA_SUCCESS';
export const API_FETCH_BIOCHAR_MEASUREMENT_DATA_FAIL =
  'FETCH_BIOCHAR_MEASUREMENT_DATA_FAIL';
export const API_FETCH_BULK_DENSITY_DATA_SUCCESS =
  'FETCH_BULK_DENSITY_DATA_SUCCESS';
export const API_FETCH_BULK_DENSITY_DATA_FAIL = 'FETCH_BULK_DENSITY_DATA_FAIL';
export const API_FETCH_UNBURNT_VOLUME_SUCCESS = 'FETCH_UNBURNT_VOLUME_SUCCESS';
export const API_FETCH_UNBURNT_VOLUME_FAIL = 'FETCH_UNBURNT_VOLUME_FAIL';
export const API_VERIFY_BIOCHAR_DATA_SUCCESS = 'VERIFY_BIOCHAR_DATA_SUCCESS';
export const API_VERIFY_BIOCHAR_DATA_FAIL = 'VERIFY_BIOCHAR_DATA_FAIL';
// ADD ASSET
export const ADD_BIOCHAR_ASSET_SUCCESS = 'ADD_BIOCHAR_ASSET_SUCCESS';
export const ADD_BIOCHAR_ASSET_FAIL = 'ADD_BIOCHAR_ASSET_FAIL';

// GET ASSETS
export const GET_BIOCHAR_ASSETS_SUCCESS = 'GET_BIOCHAR_ASSETS_SUCCESS';
export const GET_BIOCHAR_ASSETS_FAIL = 'GET_BIOCHAR_ASSETS_FAIL';

// POST STATIONARY DIESEL SUCCESS & FAIL
export const POST_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_SUCCESS =
  'POST_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_SUCCESS';
export const POST_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_FAIL =
  'POST_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_FAIL';

// GET STATIONARY DIESEL SUCCESS & FAIL
export const GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_SUCCESS =
  'GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_SUCCESS';
export const GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_FAIL =
  'GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_FAIL';

// GET STATIONARY DIESEL TOTALS SUCCESS & FAIL
export const GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_TOTALS_SUCCESS =
  'GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_TOTALS_SUCCESS';
export const GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_TOTALS_FAIL =
  'GET_STATIONARY_OR_MOBILE_BIOCHAR_DIESEL_DATA_TOTALS_FAIL';

/*
STATIONARY & MOBILE PETROL API CALLS
*/

// POST STATIONARY PETROL SUCCESS & FAIL
export const POST_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_SUCCESS =
  'POST_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_SUCCESS';
export const POST_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_FAIL =
  'POST_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_FAIL';

// GET STATIONARY PETROL SUCCESS & FAIL
export const GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_SUCCESS =
  'GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_SUCCESS';
export const GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_FAIL =
  'GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_FAIL';

// GET STATIONARY PETROL TOTALS SUCCESS & FAIL
export const GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_TOTALS_SUCCESS =
  'GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_TOTALS_SUCCESS';
export const GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_TOTALS_FAIL =
  'GET_STATIONARY_OR_MOBILE_BIOCHAR_PETROL_DATA_TOTALS_FAIL';

// GET HIGHEST ID
export const GET_HIGHEST_BIOCHAR_ID_SUCCESS = 'GET_HIGHEST_BIOCHAR_ID_SUCCESS';
export const GET_HIGHEST_BIOCHAR_ID_FAIL = 'GET_HIGHEST_BIOCHAR_ID_FAIL';

/*
LPG API CALLS
*/

export const POST_LPG_BIOCHAR_DATA_SUCCESS = 'POST_LPG_BIOCHAR_DATA_SUCCESS';
export const POST_LPG_BIOCHAR_DATA_FAIL = 'POST_LPG_BIOCHAR_DATA_FAIL';

export const GET_LPG_BIOCHAR_DATA_SUCCESS = 'GET_LPG_BIOCHAR_DATA_SUCCESS';
export const GET_LPG_BIOCHAR_DATA_FAIL = 'GET_LPG_BIOCHAR_DATA_FAIL';

export const GET_LPG_BIOCHAR_DATA_TOTALS_SUCCESS =
  'GET_LPG_BIOCHAR_DATA_TOTALS_SUCCESS';
export const GET_LPG_BIOCHAR_DATA_TOTALS_FAIL =
  'GET_LPG_BIOCHAR_DATA_TOTALS_FAIL';

export const BIOCHAR_FILE_UPLOAD_SUCCESS = 'BIOCHAR_FILE_UPLOAD_SUCCESS';
export const BIOCHAR_FILE_UPLOAD_FAIL = 'BIOCHAR_FILE_UPLOAD_FAIL';

export const SET_MEASUREMENT_TYPE_SUCCESS = 'SET_MEASUREMENT_TYPE_SUCCESS';
export const SET_MEASUREMENT_TYPE_FAIL = 'SET_MEASUREMENT_TYPE_FAIL';

export const FETCH_DATA_TABLES_DATA_SUCCESS = 'FETCH_DATA_TABLES_DATA_SUCCESS';
export const FETCH_DATA_TABLES_DATA_FAIL = 'FETCH_DATA_TABLES_DATA_FAIL';

export const POST_BATCH_CCIK_SUCCESS = 'POST_BATCH_CCIK_SUCCESS';
export const POST_BATCH_CCIK_FAIL = 'POST_BATCH_CCIK_FAIL';

export const POST_LAB_REPORT_FILE_UPLOAD_SUCCESS =
  'POST_LAB_REPORT_FILE_UPLOAD_SUCCESS';
export const POST_LAB_REPORT_FILE_UPLOAD_FAIL =
  'POST_LAB_REPORT_FILE_UPLOAD_FAIL';


export const POST_BIOCHAR_STORED_CARBON_SUCCESS = 'POST_BIOCHAR_STORED_CARBON_SUCCESS';
export const POST_BIOCHAR_STORED_CARBON_FAIL = 'POST_BIOCHAR_STORED_CARBON_FAIL';

export const POST_BATCH_VOLUMES_AND_MEDIA_PDF_SUCCESS = 'POST_BATCH_VOLUMES_AND_MEDIA_PDF_SUCCESS';
export const POST_BATCH_VOLUMES_AND_MEDIA_PDF_FAIL = 'POST_BATCH_VOLUMES_AND_MEDIA_PDF_FAIL';
