
import {
    Box, Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell
} from '@mui/material';

export function WineAndGrapesTotalTable({ wineAndGrapesDataTotals }) {

    return (
        <Box>
            <TableContainer component={Paper} sx={{ height: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                Month
                            </TableCell>
                            <TableCell align="left">
                                Bottled Wine (kl)
                            </TableCell>
                            <TableCell align="left">
                                Bulk Wine (kl)
                            </TableCell>
                            <TableCell align="left">
                                Bulk Grapes (kg)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {wineAndGrapesDataTotals?.map((data, i) => {
                            if (!Object.keys(data).includes('grand_total_bottled_wine')) {
                                return (
                                    <TableRow key={i}>
                                        <TableCell align="left">
                                            {data.month}
                                        </TableCell>
                                        <TableCell align="left">
                                            {data.bottledWineQuantity}
                                        </TableCell>
                                        <TableCell align="left">
                                            {data.bulkWineQuantity}
                                        </TableCell>
                                        <TableCell align="left">
                                            {data.bulkGrapesQuantity}
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        })}
                        <TableRow sx={{ border: '2px solid black' }}>
                            <TableCell align="left"><b>Grand Total</b></TableCell>
                            <TableCell align="left"><b>{wineAndGrapesDataTotals[12].grand_total_bottled_wine} kl</b></TableCell>
                            <TableCell align="left"><b>{wineAndGrapesDataTotals[12].grand_total_bulk_wine} kl</b></TableCell>
                            <TableCell align="left"><b>{wineAndGrapesDataTotals[12].grand_total_bulk_grapes} kg</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
