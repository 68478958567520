import { useContext, useEffect } from 'react';
import MapContext from '../../context/MapContext';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import Draw from 'ol/interaction/Draw';
// import GeoJSON from 'ol/format/GeoJSON';

export const DrawInter = ({ type, onDrawEnd }) => {
  const { map } = useContext(MapContext);
  // const GeoJSONFormat = new GeoJSON();
  const source = new VectorSource({ wrapX: false });

  const vector = new VectorLayer({
    source: source,
  });

  useEffect(() => {
    if (!map) return;
    let draw = new Draw({
      source,
      type,
    });
    map.addLayer(vector);
    map.addInteraction(draw);
    if (onDrawEnd) {
      draw.on('drawend', onDrawEnd);
    }
    return () => {
      map.removeLayer(vector);
      map.removeInteraction(draw);
      // console.log(GeoJSONFormat.writeFeatures(source.getFeatures()));
    };
  });
  return null;
};
