import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  // IconButton,
  TableCell,
  TableRow,
  Typography,
  TableBody,
  TableContainer,
  Table,
  Paper,
  Alert,
  // Link,
  CircularProgress,
} from '@mui/material';
import FireBreakProof from './myProgrammes/FireBreakProof';
// import Hedge from './myProgrammes/Hedge';
// import MobGrazing from './myProgrammes/MobGrazing';
import pdf from '../../../reports/Nordhoek-FirebreakMaintenanceReport.pdf';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import GrassIcon from '@mui/icons-material/Grass';
import { getUserProgrammes } from '../../../actions/auth';
import { connect } from 'react-redux';
import Projects from './myProgrammes/Projects';
import { useLocation } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const MyProgrammes = ({ getProgrammes, programmes, loading }) => {
  const [open, setOpen] = useState(false);
  const [activeProgram, setActiveProgram] = useState(null);
  // console.log(activeProgram)
  // const roleId = JSON.parse(localStorage.getItem('token')).roleId;
  const iconStyle = { p: 2, fontSize: '7em', '&:hover': { color: '#212746' } };
  const location = useLocation();
  const [hide, setHide] = useState(true);

  useEffect(() => {
    getProgrammes();

    if (location.state) {
      setActiveProgram(2)
    }

  }, [getProgrammes, location.state]);

  // useEffect(() => {

  // })

  const rows = [
    {
      id: 0,
      name: 'Firebreak Maintenance Report',
    },
    {
      id: 1,
      name: 'Fire Fighting Equipment Report',
    },
    {
      id: 2,
      name: 'Neighbour Agreements Report ',
    },
  ];

  return (
    <Grid>
      <Box sx={{ background: '#535988', borderRadius: 2, p: 2, mb: 2, }}>
        <Typography variant='h5' sx={{ fontWeight: 'bold', color: 'whitesmoke' }}>
          My Programs
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />

      {loading ? (
        <CircularProgress />
      ) : (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setHide(!hide)}
          >
            <Typography>{hide ? "Hide" : "Expand"} Programmes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              {programmes?.[0]?.programmes?.map(programme => {
                return (
                  <Grid
                    key={programme}
                    sx={{ placeItems: 'center', textAlign: 'center', m: '1rem 2rem 1rem 0' }}
                  >
                    {/* <IconButton
                      sx={{ mb: 2, border: '1px solid lightgray' }}
                      onClick={() => setActiveProgram(programme)}
                    > */}
                    {programme === 1 ? (
                      <LocalFireDepartmentIcon
                        sx={{
                          ...iconStyle,
                          color:
                            programme === activeProgram
                              ? '#212746'
                              : 'text.secondary',
                        }}
                      />
                    ) : (
                      <Box className="biochar-project biochar-project-home"
                        onClick={() => setActiveProgram(programme)}
                        sx={programme === activeProgram && { background: 'linear-gradient(to bottom right, #535988, #313551)', color: '#fff' }}
                      >
                        <Typography sx={{ fontSize: '18px' }}><GrassIcon sx={{ mb: '-5px' }} /> {programme === 1
                          ? 'Veldfire Management'
                          : 'Biochar Program'}</Typography>

                      </Box>
                      // <GrassIcon
                      //   sx={{
                      //     ...iconStyle,
                      //     color:
                      //       programme === activeProgram
                      //         ? '#212746'
                      //         : 'text.secondary',
                      //   }}
                      // />
                    )}
                    {/* </IconButton>
                    <Typography variant='h6'>
                      {programme === 1
                        ? 'Veldfire Management'
                        : 'Biochar Program'}
                    </Typography> */}
                  </Grid>
                );
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}

      {/* <Divider sx={{ mb: 2 }} /> */}
      {/* Report (start) */}
      {/* {activeProgram && (
        <Button variant='contained' size='large' onClick={() => setOpen(true)}>
          View Report
        </Button>
      )} */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Download report?</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableBody>
                {rows.map(row => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component='th' scope='row'>
                      {row.name}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant='outlined'
                        onClick={() => {
                          setOpen(false);
                          window.open(pdf);
                        }}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => setOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {activeProgram === 1 ? (
        <FireBreakProof />
      ) : activeProgram === 2 ? (
        <Projects quickLinks={location.state} />) : null
        // ) : (
        //   <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
        //     {programmes?.length !== 0 ? (
        //       'Please select a program'
        //     ) : (
        //       <>
        //         Apply for a Program <Link href='/apply'>Here</Link>
        //       </>
        //     )}
        //   </Typography>
        // )
      }
      {programmes && programmes[0]?.programmes.length > 0 ? null : <Alert severity='info'>Please contact wihan@ikhala.tech to activate your programs</Alert>}
      {/*
      <Divider />
        <Hedge />
        <Divider />
        <MobGrazing /> */}
    </Grid>
  );
};

const mapStateToProps = state => ({
  programmes: state.auth.userProgrammes,
  loading: state.auth.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    getProgrammes: id => dispatch(getUserProgrammes(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProgrammes);
