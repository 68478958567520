import { useEffect } from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

export default function F002GeneralQuestions({ data, setData, setFormId }) {
  useEffect(() => {
    setFormId('F002');
  }, [setFormId]);
  const { locatedOn, fpa_membership_certificate } = data;
  return (
    <>
      <Typography variant='h5' sx={{ mb: 2 }}>
        General Questions
      </Typography>
      <Typography variant='h6' component='p'>
        What region/area is your land located on?
      </Typography>
      <TextField
        name='locatedOn'
        label='What region/area is your land located on?'
        value={locatedOn}
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Are you a member of the local FPA?
      </Typography>
      <RadioGroup
        row
        aria-labelledby='demo-radio-buttons-group-label'
        name='FPA_member'
        onChange={setData}
      >
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='FPA_member'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='FPA_member'
        />
      </RadioGroup>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Upload FPA membership certificate
      </Typography>
      <TextField
        name='fpa_membership_certificate'
        type='file'
        value={fpa_membership_certificate}
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Do you have an EMP/CMP in place for your land?
      </Typography>
      <RadioGroup
        row
        aria-labelledby='demo-radio-buttons-group-label'
        name='EMP_CMP'
        onChange={setData}
      >
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='EMP_CMP'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='EMP_CMP'
        />
      </RadioGroup>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Do you currently have firebreaks on your land?
      </Typography>
      <RadioGroup
        row
        aria-labelledby='demo-radio-buttons-group-label'
        name='currentFirebreaks'
        onChange={setData}
      >
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='currentFirebreaks'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='currentFirebreaks'
        />
      </RadioGroup>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Do you have a plan for firebreak implementation?
      </Typography>
      <RadioGroup
        row
        aria-labelledby='demo-radio-buttons-group-label'
        name='fireBreakPlan'
        onChange={setData}
      >
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='fireBreakPlan'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='fireBreakPlan'
        />
      </RadioGroup>
    </>
  );
}
