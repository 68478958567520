import {
    POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_SUCCESS,
    POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_FAIL,
    GET_TIGERCAT_BURNS_GET_COMPANY_USER_CAR_RPPBS_SUCCESS,
    GET_TIGERCAT_BURNS_GET_COMPANY_USER_CAR_RPPBS_FAIL,
    POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_PROJECT_SUCCESS,
    POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_PROJECT_FAIL,
    GET_TIGERCAT_BURNS_GET_CAR_RPPB_PROJECTS_SUCCESS,
    GET_TIGERCAT_BURNS_GET_CAR_RPPB_PROJECTS_FAIL,
    GET_TIGERCAT_BURNS_GET_USERS_SUCCESS,
    GET_TIGERCAT_BURNS_GET_USERS_FAIL,
    POST_TIGERCAT_BURNS_CREATE_BIOCHAR_BINS_SUCCESS,
    POST_TIGERCAT_BURNS_CREATE_BIOCHAR_BINS_FAIL,
    GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_BINS_SUCCESS,
    GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_BINS_FAIL,
    POST_TIGERCAT_BURNS_CREATE_BIOCHAR_EQUIPMENT_SUCCESS,
    POST_TIGERCAT_BURNS_CREATE_BIOCHAR_EQUIPMENT_FAIL,
    GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_EQUIPMENT_SUCCESS,
    GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_EQUIPMENT_FAIL,
    GET_TIGERCAT_BURNS_GET_PROJECTS_FOR_USER_SUCCESS,
    GET_TIGERCAT_BURNS_GET_PROJECTS_FOR_USER_FAIL,
    PATCH_TIGERCAT_BURNS_ASSIGN_ASSET_TO_PROJECT_SUCCESS,
    PATCH_TIGERCAT_BURNS_ASSIGN_ASSET_TO_PROJECT_FAIL,
    GET_TIGERCAT_BURNS_GET_ALL_PROJECTS_WITH_ASSIGNED_ASSETS_SUCCESS,
    GET_TIGERCAT_BURNS_GET_ALL_PROJECTS_WITH_ASSIGNED_ASSETS_FAIL,
    PATCH_TIGERCAT_BURNS_UNASSIGN_ASSET_FROM_PROJECT_SUCCESS,
    PATCH_TIGERCAT_BURNS_UNASSIGN_ASSET_FROM_PROJECT_FAIL
} from '../../actions/tigercat-burns/types/tigercat-base-types';

const initialState = {
    loading: true,
    carRppbs: [],
    carRppbProjects: [],
    tigercatBurnsUsers: [],
    userBins: [],
    userEquipment: [],
    userProjects: [],
    projectsWithAssignedAssets: []
};

export default function tigercatBurnsBaseReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_TIGERCAT_BURNS_GET_COMPANY_USER_CAR_RPPBS_SUCCESS:
            return {
                ...state,
                loading: false,
                carRppbs: payload
            };
        case GET_TIGERCAT_BURNS_GET_COMPANY_USER_CAR_RPPBS_FAIL:
            return {
                ...state,
                loading: false
            }
        case POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_PROJECT_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_TIGERCAT_BURNS_GET_CAR_RPPB_PROJECTS_SUCCESS:
            return {
                ...state,
                loading: false,
                carRppbProjects: payload
            };
        case GET_TIGERCAT_BURNS_GET_CAR_RPPB_PROJECTS_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_TIGERCAT_BURNS_GET_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                tigercatBurnsUsers: payload
            };
        case GET_TIGERCAT_BURNS_GET_USERS_FAIL:
            return {
                ...state,
                loading: false
            }
        case POST_TIGERCAT_BURNS_CREATE_BIOCHAR_BINS_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case POST_TIGERCAT_BURNS_CREATE_BIOCHAR_BINS_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_BINS_SUCCESS:
            return {
                ...state,
                loading: false,
                userBins: payload
            };
        case GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_BINS_FAIL:
            return {
                ...state,
                loading: false
            }
        case POST_TIGERCAT_BURNS_CREATE_BIOCHAR_EQUIPMENT_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case POST_TIGERCAT_BURNS_CREATE_BIOCHAR_EQUIPMENT_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_EQUIPMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                userEquipment: payload
            };
        case GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_EQUIPMENT_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_TIGERCAT_BURNS_GET_PROJECTS_FOR_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                userProjects: payload
            };
        case GET_TIGERCAT_BURNS_GET_PROJECTS_FOR_USER_FAIL:
            return {
                ...state,
                loading: false
            }
        case PATCH_TIGERCAT_BURNS_ASSIGN_ASSET_TO_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case PATCH_TIGERCAT_BURNS_ASSIGN_ASSET_TO_PROJECT_FAIL:
            return {
                ...state,
                loading: false
            }
        case PATCH_TIGERCAT_BURNS_UNASSIGN_ASSET_FROM_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case PATCH_TIGERCAT_BURNS_UNASSIGN_ASSET_FROM_PROJECT_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_TIGERCAT_BURNS_GET_ALL_PROJECTS_WITH_ASSIGNED_ASSETS_SUCCESS:
            return {
                ...state,
                loading: false,
                projectsWithAssignedAssets: payload
            };
        case GET_TIGERCAT_BURNS_GET_ALL_PROJECTS_WITH_ASSIGNED_ASSETS_FAIL:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }
}