import React, { useState } from 'react';
import { FormControl, InputLabel, Select, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Divider, Box } from '@mui/material';
import PricingCalculation from './PricingCalculation';
import ContractorOutput from './ContractorOutput';

export default function ContractorDiscoveryForm() {
  const [feedstockModel, setFeedstockModel] = useState('');
  const [bookedDays, setBookedDays] = useState(0);
  const [open, setOpen] = useState(false);
  const [contractorId, setContractorId] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleClose();
  };

  const handleContractorIdChange = (id) => {
    setContractorId(id);
  }

  return (
    <Box>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Open
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth={true}>
        <DialogTitle>Contractor Discovery</DialogTitle>
        <DialogContent>
          <form onSubmit={handleFormSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <FormControl variant="outlined">
              <InputLabel>Feedstock Model</InputLabel>
              <Select
                native
                value={feedstockModel}
                onChange={(e) => setFeedstockModel(e.target.value)}
                label="Feedstock Model"
              >
                <option aria-label="None" value="" />
                <option value={'feedstockReady'}>Feedstock Ready</option>
                <option value={'feedstockNotReady'}>Feedstock Not Ready</option>
              </Select>
            </FormControl>

            <TextField
              id="outlined-basic"
              label="Days to book"
              variant="outlined"
              type="number"
              onChange={(e) => setBookedDays(e.target.value)}
              required
            />

            <ContractorOutput bookedDays={bookedDays} contractorId={contractorId} />

            <Divider variant="middle" style={{ margin: '1rem 0' }} />

            <PricingCalculation feedstockModel={feedstockModel} bookedDays={bookedDays} onContractorIdChange={handleContractorIdChange} />

          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleFormSubmit} color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
