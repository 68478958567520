import { Alert, Paper } from '@mui/material';

export default function Dashboard() {
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 1,
      }}
    >
      <Alert severity='error' sx={{ width: 1 }}>
        Page under construction. Please use sidebar navigation for other
        functionalities
      </Alert>
    </Paper>
  );
}
