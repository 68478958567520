import { useState } from 'react';
import {
  FormControl,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Divider,
  RadioGroup,
  Radio,
} from '@mui/material';

export default function FireBreakProofForm() {
  const initialState = {
    closestTown: '',
    fbNumber: '',
    fbNeighbors: '',
    lastWildFire: '',
    concerned: '',
  };

  const [formData, setFormData] = useState(initialState);
  const handleChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const { closestTown, fbNumber, fbNeighbors, lastWildFire, concerned } =
    formData;

  return (
    <>
      <Typography variant='h4' component='h4' sx={{ mb: 2 }}>
        Veldfire Management
      </Typography>

      <FormControl fullWidth sx={{ mb: 2 }}>
        <Typography variant='h6' component='p'>
          Closest town to your farm?
        </Typography>
        <TextField
          name='closestTown'
          label='Your answer here'
          value={closestTown}
          onChange={e => handleChange(e)}
        />
      </FormControl>
      <Divider sx={{ my: 2 }} />
      <Typography variant='h6' component='p'>
        Do you have firebreak on your land?
      </Typography>
      <RadioGroup row aria-labelledby='demo-radio-buttons-group-label'>
        <FormControlLabel value='yes' control={<Radio />} label='Yes' />
        <FormControlLabel value='no' control={<Radio />} label='No' />
      </RadioGroup>
      <Divider sx={{ my: 2 }} />
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Typography variant='h6' component='p'>
          If yes, How many firebreaks do you have?
        </Typography>
        <TextField
          name='fbNumber'
          label='Your answer here'
          value={fbNumber}
          onChange={e => handleChange(e)}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Typography variant='h6' component='p'>
          If yes, Approximately how many fire breaks are on your land and shared
          by a neighboring farmer?
        </Typography>
        <TextField
          name='fbNeighbors'
          label='Your answer here'
          value={fbNeighbors}
          onChange={e => handleChange(e)}
        />
      </FormControl>
      <Divider sx={{ my: 2 }} />
      <Typography variant='h6' component='p'>
        Do you currently have fire insurance?
      </Typography>
      <RadioGroup row aria-labelledby='demo-radio-buttons-group-label'>
        <FormControlLabel value='yes' control={<Radio />} label='Yes' />
        <FormControlLabel value='no' control={<Radio />} label='No' />
      </RadioGroup>
      <Divider sx={{ my: 2 }} />
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Typography variant='h6' component='p'>
          If yes, In what range is your current annual insurance premium?
        </Typography>
        <TextField
          name='lastWildFire'
          label='Your answer here'
          value={lastWildFire}
          onChange={e => handleChange(e)}
        />
      </FormControl>
      <Divider sx={{ my: 2 }} />
      <Typography variant='h6' component='p'>
        Have you had wildfire in your land?
      </Typography>
      <RadioGroup row aria-labelledby='demo-radio-buttons-group-label'>
        <FormControlLabel value='yes' control={<Radio />} label='Yes' />
        <FormControlLabel value='no' control={<Radio />} label='No' />
      </RadioGroup>
      <Divider sx={{ my: 2 }} />
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Typography variant='h6' component='p'>
          If yes, what year was the last wildfire in your land?
        </Typography>
        <TextField
          name='concerned'
          label='Your answer here'
          value={concerned}
          onChange={e => handleChange(e)}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Button variant='contained' size='large'>
          Submit Application
        </Button>
      </FormControl>
    </>
  );
}
