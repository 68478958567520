import { useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';

export default function F006PlanNewFirebreak({ data, setData, setFormId }) {
  useEffect(() => {
    setFormId('F006');
  }, [setFormId]);

  const { nameOfFirebreak, firebreak_map, dateOfImplementation, FPP } = data;

  return (
    <>
      <Typography variant='h5' sx={{ mb: 2 }}>
        Plan New Firebreak
      </Typography>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Name
      </Typography>
      <TextField
        name='nameOfFirebreak'
        label='Name'
        type='text'
        value={nameOfFirebreak}
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Please upload Firebreak map file
      </Typography>
      <TextField
        name='firebreak_map'
        id='doc'
        type='file'
        value={firebreak_map}
        onChange={setData}
        fullWidth
      />
      {/* <TextField name='distance' label='Distance' type='text' fullWidth /> */}
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Select planned date for implementation
      </Typography>
      <TextField
        name='dateOfImplementation'
        type='date'
        value={dateOfImplementation}
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Will this firebreak be shared with a neighbour?
      </Typography>
      <RadioGroup
        row
        aria-labelledby='demo-radio-buttons-group-label'
        name='fireBreakShared'
        onChange={setData}
      >
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='fireBreakShared'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='fireBreakShared'
        />
      </RadioGroup>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Do you have a defined FPP from where you will be able to clearly see
        this firebreak?
      </Typography>
      <RadioGroup row name='fppDefined' onChange={setData}>
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='fppDefined'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='fppDefined'
        />
      </RadioGroup>
      <FormControl
        sx={{
          mt: 1,
        }}
        fullWidth
      >
        <InputLabel id='demo-simple-select-label'>
          If yes, please select the FPP
        </InputLabel>
        <Select name='FPP' label='Select FPP' value={FPP} onChange={setData}>
          <MenuItem value={10}>Item 1</MenuItem>
          <MenuItem value={20}>Item 2</MenuItem>
          <MenuItem value={30}>Item 3</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
