import {
    GET_ROF_BURNS_BATCH_DATA_SUCCESS,
    GET_ROF_BURNS_BATCH_DATA_FAIL,
    GET_ROF_BURNS_ADDITIONAL_BATCH_DATA_SUCCESS,
    GET_ROF_BURNS_ADDITIONAL_BATCH_DATA_FAIL,
    GET_ROF_BURNS_BATCH_VOLUMES_SUCCESS,
    GET_ROF_BURNS_BATCH_VOLUMES_FAIL,
    GET_ROF_BURNS_BATCH_BULK_DENSITY_SUCCESS,
    GET_ROF_BURNS_BATCH_BULK_DENSITY_FAIL,
    GET_ROF_BURNS_BIOCHAR_MASS_SUCCESS,
    GET_ROF_BURNS_BIOCHAR_MASS_FAIL,
    GET_ROF_BURNS_BATCH_CARBON_CONTENT_SUCCESS,
    GET_ROF_BURNS_BATCH_CARBON_CONTENT_FAIL,
    GET_ROF_BURNS_BIOCHAR_STORED_CARBON_SUCCESS,
    GET_ROF_BURNS_BIOCHAR_STORED_CARBON_FAIL,
    POST_ROF_BURNS_BATCH_CCIK_SUCCESS,
    POST_ROF_BURNS_BATCH_CCIK_FAIL,
    POST_ROF_BURNS_BIOCHAR_STORED_CARBON_SUCCESS,
    POST_ROF_BURNS_BIOCHAR_STORED_CARBON_FAIL,
    POST_ROF_BURNS_LAB_REPORT_FILE_SUCCESS,
    POST_ROF_BURNS_LAB_REPORT_FILE_FAIL,
    GET_ROF_BURNS_LAB_REPORT_FILES_SUCCESS,
    GET_ROF_BURNS_LAB_REPORT_FILES_FAIL,
    POST_BASE_LOG_KILN_SUCCESS,
    POST_BASE_LOG_KILN_FAIL,
    GET_BASE_LOG_KILNS_SUCCESS,
    GET_BASE_LOG_KILNS_FAIL,
    GET_ROF_BURNS_BATCH_MEDIA_SUCCESS,
    GET_ROF_BURNS_BATCH_MEDIA_FAIL,
    GET_PROJECT_BATCHES_SUCCESS,
    GET_PROJECT_BATCHES_FAIL,
    GET_DOWNLOAD_BATCH_REPORT_SUCCESS,
    GET_DOWNLOAD_BATCH_REPORT_FAIL
} from '../../actions/rof-burns/types/rof-burn-types';

const initialState = {
    loading: true,
    batchData: [],
    batchMedia: [],
    additionalBatchData: [],
    batchVolumes: [],
    bulkDensity: [],
    biocharMass: [],
    batchCarbonContent: [],
    biocharStoredCarbon: [],
    labReportFiles: [],
    logKilns: [],
    projectBatches: []
};

export default function rofBurnsReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ROF_BURNS_BATCH_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                batchData: payload
            };
        case GET_ROF_BURNS_BATCH_DATA_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_ROF_BURNS_ADDITIONAL_BATCH_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                additionalBatchData: payload
            };
        case GET_ROF_BURNS_ADDITIONAL_BATCH_DATA_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_ROF_BURNS_BATCH_VOLUMES_SUCCESS:
            return {
                ...state,
                loading: false,
                batchVolumes: payload
            };
        case GET_ROF_BURNS_BATCH_VOLUMES_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_ROF_BURNS_BATCH_BULK_DENSITY_SUCCESS:
            return {
                ...state,
                loading: false,
                bulkDensity: payload
            };
        case GET_ROF_BURNS_BATCH_BULK_DENSITY_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_ROF_BURNS_BIOCHAR_MASS_SUCCESS:
            return {
                ...state,
                loading: false,
                biocharMass: payload
            };
        case GET_ROF_BURNS_BIOCHAR_MASS_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_ROF_BURNS_BATCH_CARBON_CONTENT_SUCCESS:
            return {
                ...state,
                loading: false,
                batchCarbonContent: payload
            };
        case GET_ROF_BURNS_BATCH_CARBON_CONTENT_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_ROF_BURNS_BIOCHAR_STORED_CARBON_SUCCESS:
            return {
                ...state,
                loading: false,
                biocharStoredCarbon: payload
            };
        case GET_ROF_BURNS_BIOCHAR_STORED_CARBON_FAIL:
            return {
                ...state,
                loading: false
            };

        case POST_ROF_BURNS_BATCH_CCIK_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_ROF_BURNS_BATCH_CCIK_FAIL:
            return {
                ...state,
                loading: false
            };
        case POST_ROF_BURNS_BIOCHAR_STORED_CARBON_SUCCESS:
            return {
                ...state,
                loading: false,
                biocharStoredCarbon: payload
            };
        case POST_ROF_BURNS_BIOCHAR_STORED_CARBON_FAIL:
            return {
                ...state,
                loading: false
            };
        case POST_ROF_BURNS_LAB_REPORT_FILE_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case POST_ROF_BURNS_LAB_REPORT_FILE_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_ROF_BURNS_LAB_REPORT_FILES_SUCCESS:
            return {
                ...state,
                labReportFiles: payload,
                loading: false
            };
        case GET_ROF_BURNS_LAB_REPORT_FILES_FAIL:
            return {
                ...state,
                loading: false
            };
        case POST_BASE_LOG_KILN_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case POST_BASE_LOG_KILN_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_BASE_LOG_KILNS_SUCCESS:
            return {
                ...state,
                loading: false,
                logKilns: payload
            };
        case GET_BASE_LOG_KILNS_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_ROF_BURNS_BATCH_MEDIA_SUCCESS:
            return {
                ...state,
                loading: false,
                batchMedia: payload
            };
        case GET_ROF_BURNS_BATCH_MEDIA_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_PROJECT_BATCHES_SUCCESS:
            return {
                ...state,
                loading: false,
                projectBatches: payload
            };
        case GET_PROJECT_BATCHES_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_DOWNLOAD_BATCH_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                projectBatches: payload
            };
        case GET_DOWNLOAD_BATCH_REPORT_FAIL:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}