import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import { Check } from '@mui/icons-material';
import { useEffect, useRef } from 'react';

export default function Pricing() {
  const items = [
    {
      id: 0,
      title: 'Flame-Cap Kilns',
      price: 'From $100',
      description: 'per kiln per year',
      features: [
        'CHARR mobile app',
        'Web app',
        'Unlimited Projects',
        'Unlimited Batches',
        'Batch Production Reports',
        'Biochar Carbon Removal Reports',
        'Data validation'
      ],
    },
    {
      id: 1,
      title: 'Air Curtain Burners',
      price: 'From $2000',
      description: 'per unit per year',
      features: [
        'CHARR mobile app',
        'Web app',
        'Unlimited Projects',
        'Unlimited Batches',
        'Batch Production Reports',
        'Biochar Carbon Removal Reports',
        'Data validation'
      ],
    },
    {
      id: 2,
      title: 'Custom D-MMRV Policy',
      price: 'From $10 000',
      description: 'per custom policy',
      features: [
        'CHARR mobile app',
        'Web app',
        'Unlimited Projects',
        'Unlimited Batches',
        'Batch Production Reports',
        'Customizable reporting templates',
        'Biochar Carbon Removal Reports',
        'Unlimited users',
        'User support via email',
        'Data validation'
      ],
    },
  ];

  const cardRefs = useRef([]);

  const setCardRef = (el) => {
    if (el && !cardRefs.current.includes(el)) {
      cardRefs.current.push(el);
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log('yesss');
            entry.target.classList.add('visible');
            // Do not unobserve here if you want to re-trigger the animation
          } else {
            entry.target.classList.remove('visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    cardRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      cardRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <Grid
      container
      sx={{ background: { xs: '#212746', lg: '#DA9DE3' }, height: { xs: 'auto', lg: '100vh' } }}
      id="pricing"
    >
      <Grid item xs={12} sx={{ width: '100%' }}>
        <Typography
          sx={{
            fontSize: '36px',
            fontWeight: '600',
            fontStyle: 'normal',
            lineHeight: '44px',
            letterSpacing: '-0.72px',
            // color: '#DA9DE3',
            padding: '10px',
            display: { xs: 'inline-block', lg: 'none' }
          }}
          id="pricing__heading"
        >
          Pricing & Packages
        </Typography>

        <Box
          sx={{
            background: 'linear-gradient(to bottom, #212746, #3e4877)',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            height: { xs: 'auto', lg: '100%' },
            clipPath: {
              lg: 'polygon(0 7%, 100% 0, 100% 90%, 0% 98%)',
              xs: 'none',
            },
            position: 'relative'
          }}
        >
          <Typography
            sx={{
              fontSize: '36px',
              fontWeight: '600',
              fontStyle: 'normal',
              lineHeight: '44px',
              letterSpacing: '-0.72px',
              // color: '#DA9DE3',
              position: { lg: 'absolute' },
              top: '100px',
              left: '100px',
              display: { xs: 'none', lg: 'block' },
            }}
            id="pricing__heading"
          >
            Pricing & Packages
          </Typography>

          {items.map((item, i) => {
            return (
              <Card
                ref={setCardRef}
                id="pricing__card"
                key={i}
                display='flex'
                sx={{
                  borderRadius: 2,
                  m: 5,
                  background: '#ffffff',
                  maxWidth: '300px',
                  minWidth: '300px',
                  height: '480px',
                  minHeight: '480px',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <CardContent
                  display='flex'
                  sx={{
                    height: '100%',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    <Typography align='center' variant='h5'>
                      {item.title}
                    </Typography>
                    <Divider />
                    <Typography
                      align='center'
                      variant='h4'
                      sx={{ fontWeight: 'bold', mt: 2 }}
                    >
                      {item.price}
                    </Typography>
                    <Typography align='center' sx={{ m: 2 }}>
                      {item.description}
                    </Typography>
                    {item.features.map((feature, i) => {
                      return (
                        <Box key={i} sx={{ display: 'flex', mt: 0.5, mb: 0.5 }}>
                          <Check></Check>
                          <Typography sx={{ ml: 1 }}>{feature}</Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </CardContent>
                <Button variant='contained' sx={{ mt: 'auto' }} fullWidth>
                  Click here for more info
                </Button>
              </Card>
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
}
