import { GET_IRT_REGISTRY_SUCCESS, GET_IRT_REGISTRY_FAIL, FETCH_IRT_PROJECTS_SUCCESS, FETCH_IRT_PROJECTS_FAIL } from '../actions/types';

const initialState = {
  loading: true,
  irtProjects: [],
  irtRegistries: [],
  preIrtRegistries: []
};

export default function irtRegistries(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_IRT_REGISTRY_SUCCESS:
      return {
        ...state,
        irtRegistries: payload.irtRegistry,
        preIrtRegistries: payload.preIrtRegistry,
        loading: false,
      };
    case GET_IRT_REGISTRY_FAIL:
      return {
        ...state,
        loading: false,
      };
    case FETCH_IRT_PROJECTS_SUCCESS:
      return {
        ...state,
        irtProjects: payload.irtProjects,
        loading: false,
      };
    case FETCH_IRT_PROJECTS_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
