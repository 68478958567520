/* DIESEL URLS */
export const API_POST_STATIONARY_OR_MOBILE_DIESEL_DATA = 'api/cf/post-stationary-or-mobile-diesel-data'
export const API_GET_STATIONARY_OR_MOBILE_DIESEL_DATA = 'api/cf/get-stationary-or-mobile-diesel-data'
export const API_GET_STATIONARY_OR_MOBILE_DIESEL_DATA_TOTALS = 'api/cf/get-stationary-or-mobile-diesel-data-totals'
/* PETROL URLS */
export const API_POST_STATIONARY_OR_MOBILE_PETROL_DATA = 'api/cf/post-stationary-or-mobile-petrol-data'
export const API_GET_STATIONARY_OR_MOBILE_PETROL_DATA = 'api/cf/get-stationary-or-mobile-petrol-data'
export const API_GET_STATIONARY_OR_MOBILE_PETROL_DATA_TOTALS = 'api/cf/get-stationary-or-mobile-petrol-data-totals'
/* HIGHEST ID */
export const API_GET_HIGHEST_ID = 'api/cf/get-highest-id/'
/* DRY ICE URLS */
export const API_POST_DRY_ICE_DATA = 'api/cf/post-dry-ice-data'
export const API_GET_DRY_ICE_DATA = 'api/cf/get-dry-ice-data'
export const API_GET_DRY_ICE_DATA_TOTALS = 'api/cf/get-dry-ice-data-totals'
/* LPG URLS */
export const API_POST_LPG_DATA = 'api/cf/post-lpg-data'
export const API_GET_LPG_DATA = 'api/cf/get-lpg-data'
export const API_GET_LPG_DATA_TOTALS = 'api/cf/get-lpg-data-totals'
/* REFRIGERANT GAS URLS */
export const API_POST_REFRIGERANT_GAS_DATA = 'api/cf/post-refrigerant-gas-data'
export const API_GET_REFRIGERANT_GAS_DATA = 'api/cf/get-refrigerant-gas-data'
export const API_GET_REFRIGERANT_GAS_DATA_TOTALS = 'api/cf/get-refrigerant-gas-data-totals'
/* ADD ASSET URLS */
export const API_POST_ADD_ASSET = 'api/cf/post-add-asset'
export const API_GET_ASSETS = 'api/cf/get-assets'
/* FILE UPLOAD */
export const API_UPLOAD_FILE = 'api/cf/upload-file'