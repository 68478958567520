import { createTheme } from '@mui/material';
import tinycolor from 'tinycolor2';

let theme = createTheme({
  palette: {
    primary: {
      main: tinycolor('#212746').toString(),
    },
    secondary: {
      main: tinycolor('#535988').toString(),
    },
    tertiary: {
      main: tinycolor('#d7ccff').toString(),
    },
  },
  text: {
    primary: {
      main: tinycolor('#212746').toString(),
    },
  },
  typography: {
    fontFamily: 'Cairo',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500
  }
});

export default theme;
