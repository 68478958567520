import { Alert, Box, Button, Divider, Grid, Typography, Tab, Tabs } from '@mui/material';
import { useState, useEffect } from 'react';

export default function FacilitySelection({ setSelection, setFacility, setCorrectTab }) {

    const group = localStorage.getItem('group')

    const renderList = () => {
        switch (group) {
            case '1': {
                return vinimarkTradingfacilities
            }
            case '2': {
                return ReynekeWinesfacilities
            }
            default: {
                return TweeJongeFacilities
            }
        }
    }

    const vinimarkTradingfacilities = ['Klapmuts', 'Head Office', 'Johannesburg', 'KwaZulu-Natal', 'Nelspruit', 'Gqeberha']
    const ReynekeWinesfacilities = ['Reyneke Wines Properties', 'Reyneke Wines']
    const TweeJongeFacilities = ['Twee Jonge Gezellen']

    let dataInputCategories = [
        { cat: 'assetList', name: 'Asset List' },
        { cat: 'supplierList', name: 'Supplier List' },
        { cat: 'petrolCardHolderList', name: 'Petrol Card Holder List' },
        { cat: 'plotList', name: 'Plot List' },
        { cat: 'fertilizerList', name: 'Fertiliser List' },
        { cat: 'agrochemicalList', name: 'Agrochemical List' },
    ]

    if (localStorage.getItem('group') === '1') {
        dataInputCategories = dataInputCategories.splice(0, 3)
    }

    const [currentTab, setCurrentTab] = useState(0)
    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        if (setCorrectTab === 0) {
            setCurrentTab(0)
        } else setCurrentTab(1)
    }, [setCorrectTab])


    return (
        <Grid item xs={12}>
            <Tabs
                value={currentTab}
                onChange={handleTabChange}
                style={{ marginBottom: 10 }}
                textColor='inherit'
                indicatorColor='secondary'
                className='theTabs'
                sx={{
                    float: 'left',
                    display: 'block'
                }}
            >
                <Tab label='Facilities' />
                <Tab label='General Information' />
            </Tabs>
            {currentTab === 0 ?
                <Box sx={{ clear: 'left' }} >
                    <Typography variant='h5'>Facility</Typography>
                    <Divider />
                    <Alert severity='info' sx={{ mb: 2 }}>Please select a facility</Alert>
                    {renderList().map((f, i) => {
                        return (
                            <Button
                                key={i}
                                variant='contained'
                                size='large'
                                sx={{ width: '50%', display: 'block', mb: 2.5 }}
                                onClick={() => {
                                    let facilityId = ''
                                    if (f === 'Reyneke Wines') {
                                        facilityId = '1'
                                    } else if (f === 'Reyneke Wines Properties') {
                                        facilityId = '2'
                                    } else if (f === 'Twee Jonge Gezellen') {
                                        facilityId = '3'
                                    } else if (f === 'Klapmuts') {
                                        facilityId = '4'
                                    } else if (f === 'Head Office') {
                                        facilityId = '5'
                                    } else if (f === 'Johannesburg') {
                                        facilityId = '6'
                                    } else if (f === 'KwaZulu-Natal') {
                                        facilityId = '7'
                                    } else if (f === 'Nelspruit') {
                                        facilityId = '8'
                                    } else if (f === 'Gqeberha') {
                                        facilityId = '9'
                                    }
                                    localStorage.setItem('facility', facilityId)
                                    setSelection({
                                        id: 4,
                                        // title: vinimarkTradingfacilities.includes(f) ? 'category' : 'winery'
                                        title: 'category'
                                    })
                                    setFacility(f)
                                }}
                            >
                                {f}
                            </Button>
                        )
                    })
                    }
                </Box> :
                <Box sx={{ clear: 'left' }}>
                    <Typography variant='h5'>General Information</Typography>
                    <Divider />
                    <Alert severity='info' sx={{ mb: 2 }}>Please select a data input category</Alert>
                    {dataInputCategories.map((c, i) => (
                        <Button
                            key={i}
                            variant='contained'
                            size='large'
                            sx={{ width: '35%', display: 'block', mb: 2.5 }}
                            onClick={() => {
                                setFacility(c.name)
                                setSelection({ id: 4, title: c.cat })
                            }}
                        >
                            {c.name}
                        </Button>
                    ))}
                </Box>
            }
        </Grid>
    );
}
