import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Box } from '@mui/system';
// import infoLogo from '../../../icons/352432_info_icon.png';
//import HelpIcon from '@material-ui/icons/Help';
import { Button, Typography, Paper, TextField } from '@mui/material';

const CarbonFootprintCalculator = () => {
  // Define the state for the input values and the result
  const [cfFarmSize, setCfFarmSize] = useState(0);
  const [dieselUsage, setDieselUsage] = useState(0);
  const [electricityUsage, setElectricityUsage] = useState(0);
  const [petrolUsage, setPetrolUsage] = useState(0);
  const [limeUsage, setLimeUsage] = useState(0);
  const [compostUsage, setCompostUsage] = useState(0);
  const [syntheticNitrogenFertiliser, setSyntheticNitrogenFertiliser] =
    useState(0);
  const [wineElectricityUsage, setWineElectricityUsage] = useState(0);
  const [wineGlassBottles, setWineGlassBottles] = useState(0);
  const [result, setResult] = useState(0);

  const [cfEmail, setCFEmail] = useState('');
  const [setUserFeedback] = useState('');

  // Rand Spend (R) and Timeframe (yearly/monthly) in Electricity, Diesel, and Petrol Usage
  const [isElectricitySpend, setIsElectricitySpend] = useState(false);
  const [isElectricityMonthly, setIsElectricityMonthly] = useState(false);
  const [isWineElectricitySpend, setIsWineElectricitySpend] = useState(false);
  const [isWineElectricityMonthly, setIsWineElectricityMonthly] =
    useState(false);
  const [isDieselSpend, setIsDieselSpend] = useState(false);
  const [isDieselMonthly, setIsDieselMonthly] = useState(false);
  const [isPetrolSpend, setIsPetrolSpend] = useState(false);
  const [isPetrolMonthly, setIsPetrolMonthly] = useState(false);

  // Define the emission factors
  const dieselEmissionFactor = 2.8667; // kgCO2/L_diesel
  const dieselSpendEmissionFactor = 0.1292850528; // kgCO2/R_diesel   - emission factor for diesel usage per spend (R)
  const electricityEmissionFactor = 0.95; //  kgCO2/kWh  - this will vary depending on the source of electricity
  const electricitySpendEmissionFactor = 0.7857733664; // kgCO2/R
  const petrolEmissionFactor = 2.4306624; // kgCO2/L
  const petrolSpendEmissionFactor = 0.1090284011; // kgCO2/R_petrol
  const limeEmissionFactor = 0.4935032895; // kgCO2/ton_lime
  const compostEmissionFactor = 0.1132929838; // kgC02/m3
  const syntheticNitrogenFertiliserEmissionFactor = 12.03985317; // kgCO2/kg_N
  const wineGlassBottlesEmissionFactor = 1.21; // kgCO2/750ml_bottle
  // const indgTree20yCO2seq = 12; //kg CO2 over 2 years
  const cinnamonTree20yCO2seq = 88; // kgCO2 over 20years

  // Define a function to handle the form submission
  const handleSubmit = event => {
    event.preventDefault();

    // Calculate the carbon footprint
    let carbonFootprint =
      limeUsage * limeEmissionFactor +
      compostUsage * compostEmissionFactor +
      syntheticNitrogenFertiliser * syntheticNitrogenFertiliserEmissionFactor +
      wineGlassBottles * wineGlassBottlesEmissionFactor;
    // How is Diesel consumed (litres or spend)
    if (isDieselSpend) {
      // Timeframe (yearly or monthly)
      if (isDieselMonthly) {
        carbonFootprint += dieselUsage * 12 * dieselSpendEmissionFactor;
      } else {
        carbonFootprint += dieselUsage * dieselSpendEmissionFactor;
      }
    } else if (!isDieselSpend) {
      // Timeframe (yearly or monthly)
      if (isDieselMonthly) {
        carbonFootprint += dieselUsage * 12 * dieselEmissionFactor;
      } else {
        carbonFootprint += dieselUsage * dieselEmissionFactor;
      }
    }

    if (isElectricitySpend) {
      if (isElectricityMonthly) {
        carbonFootprint +=
          electricityUsage * (12 * electricitySpendEmissionFactor);
      } else {
        carbonFootprint += electricityUsage * electricitySpendEmissionFactor;
      }
    } else if (!isElectricitySpend) {
      if (isElectricityMonthly) {
        carbonFootprint += electricityUsage * 12 * electricityEmissionFactor;
      } else {
        carbonFootprint += electricityUsage * electricityEmissionFactor;
      }
    }

    if (isPetrolSpend) {
      if (isPetrolMonthly) {
        carbonFootprint += petrolUsage * 12 * petrolSpendEmissionFactor;
      } else {
        carbonFootprint += petrolUsage * petrolSpendEmissionFactor;
      }
    } else if (!isPetrolSpend) {
      if (isPetrolMonthly) {
        carbonFootprint += petrolUsage * 12 * petrolEmissionFactor;
      } else {
        carbonFootprint += petrolUsage * petrolEmissionFactor;
      }
    }

    // Winery Electricity
    if (isWineElectricitySpend) {
      if (isWineElectricityMonthly) {
        carbonFootprint +=
          wineElectricityUsage * 12 * electricitySpendEmissionFactor;
      } else {
        carbonFootprint +=
          wineElectricityUsage * electricitySpendEmissionFactor;
      }
    } else if (!isWineElectricitySpend) {
      if (isWineElectricityMonthly) {
        carbonFootprint +=
          wineElectricityUsage * 12 * electricityEmissionFactor;
      } else {
        carbonFootprint += wineElectricityUsage * electricityEmissionFactor;
      }
    }

    // Update the state with the result
    setResult(carbonFootprint);

    // JSON object storing inputs and output data of form
    // const carbonFootprintData = {
    //   diesel: JSON.stringify('Diesel Usage: '),
    //   dieselUsage: JSON.stringify(dieselUsage),
    //   dieselSpend: JSON.stringify('Is Diesel Consumed in Rands (R) Spend? '),
    //   isDieselSpend: JSON.stringify(isDieselSpend),
    //   dieselMonthly: JSON.stringify('Is Diesel Usage per Month? '),
    //   isDieselMonthly: JSON.stringify(isDieselMonthly),
    //   electricity: JSON.stringify('Electricity Usage: '),
    //   electricityUsage: JSON.stringify(electricityUsage),
    //   electricitySpend: JSON.stringify(
    //     'Is Electricity Consumed in Rands (R) Spend?'
    //   ),
    //   isElectricitySpend: JSON.stringify(isElectricitySpend),
    //   electricityMonthly: JSON.stringify('Is Electricity Usage per Month?'),
    //   isElectricityMonthly: JSON.stringify(isElectricityMonthly),
    //   petrol: JSON.stringify('Petrol Usage: '),
    //   petrolUsage: JSON.stringify(petrolUsage),
    //   petrolSpend: JSON.stringify('Is Petrol Consumed in Rands (R) Spend? '),
    //   isPetrolSpend: JSON.stringify(isPetrolSpend),
    //   petrolMonthly: JSON.stringify('Is Petrol Usage Monthly? '),
    //   isPetrolMonthly: JSON.stringify(isPetrolMonthly),
    //   lime: JSON.stringify('Lime Usage: '),
    //   limeUsage: JSON.stringify(limeUsage),
    //   compost: JSON.stringify('Compost Usage: '),
    //   compostUsage: JSON.stringify(compostUsage),
    //   syntheticNitrogenFertiliserUsage: JSON.stringify(
    //     'Synthetic Nitrogen Fertiliser Usage: '
    //   ),
    //   syntheticNitrogenFertiliser: JSON.stringify(syntheticNitrogenFertiliser),
    //   wineElectricity: JSON.stringify('Winery Electricity Usage: '),
    //   wineElectricityUsage: JSON.stringify(wineElectricityUsage),
    //   wineElectricitySpend: JSON.stringify(
    //     'Is Winery Electricity Consumed in Rands (R) Spend? '
    //   ),
    //   isWineElectricitySpend: JSON.stringify(isWineElectricitySpend),
    //   wineElectricityMonthly: JSON.stringify(
    //     'Is Winery Electricity Usage Monthly? '
    //   ),
    //   isWineElectricityMonthly: JSON.stringify(isWineElectricityMonthly),
    //   glassBottles: JSON.stringify('Wine Glass Bottles: '),
    //   wineGlassBottles: JSON.stringify(wineGlassBottles),
    //   carbonFootprint: JSON.stringify('Carbon Footprint: '),
    //   result: JSON.stringify(result),
    // };
    // Server request to post carbon footprint data
    // const options = {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(carbonFootprintData),
    // };
  };

  const style = {
    width: '90%',
    height: 1,
    overflow: 'scroll',
    margin: 'auto',
  };

  return (
    <Box sx={style}>
      <form onSubmit={handleSubmit}>
        {/* Add the input fields and submit button here */}

        <Paper sx={{ p: 3 }}>
          <Box>
            <Typography fontSize={30}>
              Ikhala Impact Light Carbon Footprint Calculator
            </Typography>
            <Box
              style={{
                border: 'solid 2px',
                padding: 5,
                margin: 5,
                borderRadius: '16px',
              }}
            >
              <b>
                How many hectares of vineyards are relevant to your farm
                management?
              </b>
              <Tooltip
                title={
                  <h2 style={{ color: 'lightgreen' }}>
                    Considering that you will be adding fuel and fertilizer data
                    for your farm, you can specify the total bearing hectares of
                    vineyard or the total of bearing + non-bearing heactares
                  </h2>
                }
              >
                {<InfoIcon width={18} height={18} />}
              </Tooltip>
              <TextField
                borderRadius='12px'
                type='text'
                variant='outlined'
                onChange={event => setCfFarmSize(event.target.value)}
              />
              <Box style={{ fontSize: 25 }}>
                <Box variant='h6'>
                  <b>Farm</b>
                  <Tooltip
                    title={
                      <h2 style={{ color: 'lightgreen' }}>
                        The farm boundary tracks all on-site fuel usage,
                        electricity consumption and fertiliser application
                        linked to farm activities. To calculate your farm carbon
                        footprint, please fill out the diesel, electricity,
                        petrol and fertiliser usage on your farm below.
                      </h2>
                    }
                  >
                    {<InfoIcon width={18} height={18} />}
                  </Tooltip>
                </Box>
              </Box>
              <Box style={{ display: 'flex', margin: 5 }}>
                <Box
                  style={{
                    border: 'solid 1px',
                    padding: 2,
                    display: 'grid',
                    borderRadius: '12px',
                  }}
                >
                  <b>Please define your DIESEL usage</b>
                  <Box style={{ display: 'flex', padding: 2, margin: 2 }}>
                    <b>Used by:</b>
                    <Tooltip
                      title={
                        <h2 style={{ color: 'lightgreen' }}>
                          Please select whether you will be entering your diesel
                          usage by either the amount you use (consumption in
                          Liters) or the amount you pay (spend in Rands)
                        </h2>
                      }
                    >
                      {<InfoIcon width={18} height={18} />}
                    </Tooltip>
                    <input
                      type='radio'
                      checked={!isDieselSpend}
                      onChange={() => {
                        setIsDieselSpend(false);
                      }}
                    />
                    <label>Per Consumption (L)</label>
                  </Box>
                  <label>
                    <input
                      type='radio'
                      checked={isDieselSpend}
                      onChange={() => {
                        setIsDieselSpend(true);
                      }}
                    />
                    <label>Per Spend (R)</label>
                  </label>
                  <Box style={{ display: 'flex', padding: 2, margin: 2 }}>
                    <b>Timeframe:</b>
                    <Tooltip
                      title={
                        <h2 style={{ color: 'lightgreen' }}>
                          Please select whether you will be entering your diesel
                          usage per month or per year
                        </h2>
                      }
                    >
                      {<InfoIcon width={18} height={18} />}
                    </Tooltip>
                    <input
                      type='radio'
                      value='Units'
                      checked={!isDieselMonthly}
                      onChange={() => {
                        setIsDieselMonthly(false);
                      }}
                    />
                    <label>Per Year</label>
                    <input
                      type='radio'
                      value='Spend'
                      checked={isDieselMonthly}
                      onChange={() => {
                        setIsDieselMonthly(true);
                      }}
                    />
                    <label>Per Month</label>
                  </Box>
                  <Box style={{ display: 'flex', padding: 2, margin: 2 }}>
                    <b>Diesel usage:</b>
                    <Tooltip
                      title={
                        <h2 style={{ color: 'lightgreen' }}>
                          Please enter your diesel usage as per the selected
                          units. The diesel usage refers to all diesel fuel
                          utilised within the farm boundary linked to farming
                          activities e.g. tractors, farm vehicles etc
                        </h2>
                      }
                    >
                      {<InfoIcon width={18} height={18} />}
                    </Tooltip>
                    <TextField
                      type='number'
                      variant='outlined'
                      value={dieselUsage}
                      onChange={event => setDieselUsage(event.target.value)}
                    />
                  </Box>
                </Box>

                <Box
                  style={{
                    border: 'solid 1px',
                    padding: 2,
                    display: 'grid',
                    borderRadius: '12px',
                  }}
                >
                  <b>Please define your ELECTRICITY usage</b>
                  <Box style={{ display: 'flex', padding: 2, margin: 2 }}>
                    <b>Used by:</b>
                    <Tooltip
                      title={
                        <h2 style={{ color: 'lightgreen' }}>
                          Please select whether you will be entering your
                          electricity usage by either the amount you use
                          (consumption in kWh) or the amount you pay (spend in
                          Rands)
                        </h2>
                      }
                    >
                      {<InfoIcon width={18} height={18} />}
                    </Tooltip>
                    <input
                      type='radio'
                      checked={!isElectricitySpend}
                      onChange={() => {
                        setIsElectricitySpend(false);
                      }}
                    />
                    <label>Per Consumption (kWh)</label>
                    <input
                      type='radio'
                      checked={isElectricitySpend}
                      onChange={() => {
                        setIsElectricitySpend(true);
                      }}
                    />
                    <label>Per Spend (R)</label>
                  </Box>
                  <Box style={{ display: 'flex', padding: 2, margin: 2 }}>
                    <b>Timeframe:</b>
                    <Tooltip
                      title={
                        <h2 style={{ color: 'lightgreen' }}>
                          Please select whether you will be entering your
                          electricity usage per month or per year
                        </h2>
                      }
                    >
                      {<InfoIcon width={18} height={18} />}
                    </Tooltip>
                    <input
                      type='radio'
                      value='Units'
                      checked={!isElectricityMonthly}
                      onChange={() => {
                        setIsElectricityMonthly(false);
                      }}
                    />
                    <label>Per Year</label>
                    <input
                      type='radio'
                      value='Spend'
                      checked={isElectricityMonthly}
                      onChange={() => {
                        setIsElectricityMonthly(true);
                      }}
                    />
                    <label>Per Month</label>
                  </Box>
                  <Box style={{ display: 'flex', padding: 2, margin: 2 }}>
                    <b>Electricity usage:</b>
                    <Tooltip
                      title={
                        <h2 style={{ color: 'lightgreen' }}>
                          Please enter your electricity usage as per the
                          selected units. The electriciting usage refers to all
                          electricity utilised within the farm boundary linked
                          to farming activities e.g. water pumps for irrigation
                        </h2>
                      }
                    >
                      {<InfoIcon width={18} height={18} />}
                    </Tooltip>
                    <TextField
                      type='number'
                      variant='outlined'
                      value={electricityUsage}
                      onChange={event =>
                        setElectricityUsage(event.target.value)
                      }
                    />
                  </Box>
                </Box>

                <Box
                  style={{
                    border: 'solid 1px',
                    padding: 2,
                    display: 'grid',
                    borderRadius: '12px',
                  }}
                >
                  <b>Please define your PETROL usage</b>
                  <Box style={{ display: 'flex', padding: 2, margin: 2 }}>
                    <b>Used by:</b>
                    <Tooltip
                      title={
                        <h2 style={{ color: 'lightgreen' }}>
                          Please select whether you will be entering your petrol
                          usage by either the amount you use (consumption in
                          Liters) or the amount you pay (spend in Rands)
                        </h2>
                      }
                    >
                      {<InfoIcon width={18} height={18} />}
                    </Tooltip>
                    <input
                      type='radio'
                      checked={!isPetrolSpend}
                      onChange={() => {
                        setIsPetrolSpend(false);
                      }}
                    />
                    <label>Per Consumption (L)</label>
                    <input
                      type='radio'
                      checked={isPetrolSpend}
                      onChange={() => {
                        setIsPetrolSpend(true);
                      }}
                    />
                    <label>Per Spend (R)</label>
                  </Box>
                  <Box style={{ display: 'flex', padding: 2, margin: 2 }}>
                    <b>Timeframe:</b>
                    <Tooltip
                      title={
                        <h2 style={{ color: 'lightgreen' }}>
                          Please select whether you will be entering your petrol
                          usage per month or per year
                        </h2>
                      }
                    >
                      {<InfoIcon width={18} height={18} />}
                    </Tooltip>
                    <input
                      type='radio'
                      value='Units'
                      checked={!isPetrolMonthly}
                      onChange={() => {
                        setIsPetrolMonthly(false);
                      }}
                    />
                    <label>Per Year</label>
                    <input
                      type='radio'
                      value='Spend'
                      checked={isPetrolMonthly}
                      onChange={() => {
                        setIsPetrolMonthly(true);
                      }}
                    />
                    <label>Per Month</label>
                  </Box>
                  <Box style={{ display: 'flex', padding: 2, margin: 2 }}>
                    <b>Petrol usage:</b>
                    <Tooltip
                      title={
                        <h2 style={{ color: 'lightgreen' }}>
                          Please enter your petrol usage as per the selected
                          units. The petrol usage refers to all electricity
                          utilised within the farm boundary linked to farming
                          activities e.g. vehicles
                        </h2>
                      }
                    >
                      {<InfoIcon width={18} height={18} />}
                    </Tooltip>
                    <TextField
                      type='number'
                      variant='outlined'
                      value={petrolUsage}
                      onChange={event => setPetrolUsage(event.target.value)}
                    />
                  </Box>
                </Box>
              </Box>

              <Box style={{ display: 'flex', margin: 5 }}>
                <Box
                  style={{
                    display: 'flex',
                    padding: 2,
                    margin: 2,
                    borderRadius: '12px',
                  }}
                >
                  <b>Lime usage:</b>
                  <Tooltip
                    title={
                      <h2 style={{ color: 'lightgreen' }}>
                        Please enter the total amount of lime applied to the
                        farm per year in tonnes. 1 tonne = 1000 kg
                      </h2>
                    }
                  >
                    {<InfoIcon width={18} height={18} />}
                  </Tooltip>

                  <TextField
                    type='number'
                    variant='outlined'
                    value={limeUsage}
                    onChange={event => setLimeUsage(event.target.value)}
                  />
                </Box>

                <Box
                  style={{
                    display: 'flex',
                    padding: 2,
                    margin: 2,
                    borderRadius: '12px',
                  }}
                >
                  <b>Compost usage:</b>
                  <Tooltip
                    title={
                      <h2 style={{ color: 'lightgreen' }}>
                        Please enter the total amount of compost applied to the
                        farm per year in m3
                      </h2>
                    }
                  >
                    {<InfoIcon width={18} height={18} />}
                  </Tooltip>
                  <TextField
                    variant='outlined'
                    type='number'
                    value={compostUsage}
                    onChange={event => setCompostUsage(event.target.value)}
                  />
                </Box>

                <Box
                  style={{
                    display: 'flex',
                    padding: 2,
                    margin: 2,
                    borderRadius: '12px',
                  }}
                >
                  <b>Synthetic Nitrogen Fertiliser usage:</b>
                  <Tooltip
                    title={
                      <h2 style={{ color: 'lightgreen' }}>
                        Please enter the total amount of synthetic nitrogen
                        applied to the farm per year in kilograms (kg). Note: To
                        calulcate this you will need to multiply the Nitrogen
                        content in the synthetic fertiliser (%) by the total
                        amount of synthetic fertiliser applied to the farm (kg).
                        Eg. A farmer applies 10 kg of synthetic fertiliser to
                        their farm per year with a Nitrogen content of 25%.
                        Therefore, the total amount of synthetic nitrogen
                        applied to the farm is 10 kg x 25% = 2.5 kg.
                      </h2>
                    }
                  >
                    {<InfoIcon width={18} height={18} />}
                  </Tooltip>
                  <TextField
                    type='number'
                    variant='outlined'
                    value={syntheticNitrogenFertiliser}
                    onChange={event =>
                      setSyntheticNitrogenFertiliser(event.target.value)
                    }
                  />
                </Box>
              </Box>
            </Box>

            <br />

            {/*WINERY*/}
            <Box
              style={{
                border: 'solid 2px',
                padding: 5,
                margin: 5,
                borderRadius: '16px',
              }}
            >
              <Box style={{ fontSize: 25 }}>
                <label>
                  <b>Winery</b>
                  <Tooltip
                    title={
                      <h2 style={{ color: 'lightgreen' }}>
                        The winery boundary includes all electricity consumption
                        for processing and bottling activities as well as glass
                        production for wine packaging. To calculate your
                        winery's carbon footprint, please fill out the
                        electricity and glass bottles usage in your winery
                        below. select whether you will be entering your
                        electricity usage by either the amount you use
                        (consumption in kWh) or the amount you pay (spend in
                        Rands)
                      </h2>
                    }
                  >
                    {<InfoIcon width={18} height={18} />}
                  </Tooltip>
                </label>
              </Box>
              <Box style={{ display: 'flex', margin: 5 }}>
                <Box
                  style={{
                    border: 'solid 1px',
                    padding: 2,
                    display: 'grid',
                    borderRadius: '12px',
                  }}
                >
                  <b>Please define your WINERY ELECTRICITY usage</b>
                  <Box style={{ display: 'flex', padding: 2, margin: 2 }}>
                    <b>Used by:</b>
                    <Tooltip
                      title={
                        <h2 style={{ color: 'lightgreen' }}>
                          Please select whether you will be entering your
                          electricity usage by either the amount you use
                          (consumption in kWh) or the amount you pay (spend in
                          Rands)
                        </h2>
                      }
                    >
                      {<InfoIcon width={18} height={18} />}
                    </Tooltip>
                    <input
                      type='radio'
                      checked={!isWineElectricitySpend}
                      onChange={() => {
                        setIsWineElectricitySpend(false);
                      }}
                    />
                    <label>Per Consumption (kWh)</label>
                    <input
                      type='radio'
                      checked={isWineElectricitySpend}
                      onChange={() => {
                        setIsWineElectricitySpend(true);
                      }}
                    />
                    <label>Per Spend (R)</label>
                  </Box>
                  {/*<br/>*/}
                  <Box style={{ display: 'flex', padding: 2, margin: 2 }}>
                    <b>Timeframe:</b>
                    <Tooltip
                      title={
                        <h2 style={{ color: 'lightgreen' }}>
                          Please select whether you will be entering your
                          electricity usage per month or per year
                        </h2>
                      }
                    >
                      {<InfoIcon width={18} height={18} />}
                    </Tooltip>
                    <input
                      type='radio'
                      value='Units'
                      checked={!isWineElectricityMonthly}
                      onChange={() => {
                        setIsWineElectricityMonthly(false);
                      }}
                    />
                    <label>Per Year</label>
                    <input
                      type='radio'
                      value='Spend'
                      checked={isWineElectricityMonthly}
                      onChange={() => {
                        setIsWineElectricityMonthly(true);
                      }}
                    />
                    <label>Per Month</label>
                  </Box>
                  <Box style={{ display: 'flex', padding: 2, margin: 2 }}>
                    <b>Electricity usage:</b>
                    <Tooltip
                      title={
                        <h2 style={{ color: 'lightgreen' }}>
                          Please enter your electricity usage as per the
                          selected units. The electricity usage refers to all
                          electricity utilised within the winery boundary linked
                          to cooling, bottling and processing of wine.
                        </h2>
                      }
                    >
                      {<InfoIcon width={18} height={18} />}
                    </Tooltip>
                    <TextField
                      type='number'
                      variant='outlined'
                      value={wineElectricityUsage}
                      onChange={event =>
                        setWineElectricityUsage(event.target.value)
                      }
                    />
                  </Box>
                </Box>

                <Box
                  style={{
                    border: 'solid 1px',
                    display: 'flex',
                    padding: 5,
                    margin: 2,
                    borderRadius: '12px',
                  }}
                >
                  <b>Please define your WINE BOTTLES used</b>

                  <Tooltip
                    title={
                      <h2 style={{ color: 'lightgreen' }}>
                        Please enter the number of 750ml wine bottles produced
                        per year in the winery
                      </h2>
                    }
                  >
                    {<InfoIcon width={18} height={18} />}
                  </Tooltip>
                  <TextField
                    borderRadius='12px'
                    variant='outlined'
                    type='number'
                    value={wineGlassBottles}
                    onChange={event => setWineGlassBottles(event.target.value)}
                  />
                </Box>
              </Box>
            </Box>

            <Button type='submit' color='success' variant='contained'>
              Calculate Carbon Footprint
            </Button>

            {/* Add the result here */}

            <Typography style={{ fontSize: 30 }}>
              Total Carbon footprint: {result.toFixed(2)} kgCO2e
              <Tooltip
                placement='top'
                title={
                  <h2 style={{ color: 'lightgreen' }}>
                    This high-level carbon footprint tool aims to provide a
                    preliminary baseline assessment of your farm and winery’s
                    carbon footprint based on known-hotspots in the industry.
                    The results will give you a basic indication of your
                    business’s carbon footprint and is the first step in
                    starting to understand your business’s environmental impact.
                    Given the high-level nature of this tool, it is not a
                    complete or sufficient representation of your farm/winery’s
                    footprint. While the tool focusses on emission sources that
                    are known to contribute a significant amount to the
                    farm/winery's footprint, it excludes other emission sources
                    including, but not limited to, pesticides and fungicides,
                    land-use change, waste, refrigerant and other packaging
                    material. Therefore, it can be expected that your actual
                    carbon footprint will be higher than the value calculated.
                    Additionally, site-specific factors are not taken into
                    account such as if your winery buys in grapes or bulk wine
                  </h2>
                }
              >
                {<InfoIcon width={18} height={18} />}
              </Tooltip>
            </Typography>

            <Typography style={{ fontSize: 30 }}>
              Total Carbon footprint per hectare:{' '}
              {(result / cfFarmSize).toFixed(2)} kgCO2e/ha
              <Tooltip
                placement='top'
                title={
                  <h2 style={{ color: 'lightgreen' }}>
                    The Total Carbon Footprint estimated above divided by your
                    hectares defined at the top
                  </h2>
                }
              >
                {<InfoIcon width={18} height={18} />}
              </Tooltip>
            </Typography>

            <Typography style={{ fontSize: 24, color: 'darkgreen' }}>
              Total trees required to remove CO2 footprint from the atmosphere ={' '}
              {(result / cinnamonTree20yCO2seq).toFixed(2)} cinnamon trees
              <Tooltip
                placement='top'
                title={
                  <h2 style={{ color: 'lightgreen' }}>
                    The amount of cinnamon trees you would have to plant and
                    grow for 20 years to remove your calculated carbon footprint
                    from the atmoshpere
                  </h2>
                }
              >
                {<InfoIcon width={18} height={18} />}
              </Tooltip>
            </Typography>

            {/*more info section */}
            <Box
              style={{
                border: 'solid 1px',
                display: 'flex',
                padding: 5,
                margin: 2,
                borderRadius: '12px',
              }}
            >
              <b>
                Please enter your email if you would like to find out more about
                carbon footprinting for your farm:{' '}
              </b>
              <TextField
                borderRadius='12px'
                variant='outlined'
                fullWidth={true}
                type='text'
                value={cfEmail}
                onChange={event => setCFEmail(event.target.value)}
              />
            </Box>

            <Box>
              <Box
                style={{
                  border: 'solid 1px',
                  display: 'flex',
                  padding: 5,
                  margin: 2,
                  borderRadius: '12px',
                }}
              >
                <b>
                  Please give us feedback on the tool or ask any questions you
                  might have:
                </b>
                <TextField
                  borderRadius='12px'
                  type='text'
                  variant='outlined'
                  fullWidth={true}
                  multiline={true}
                  onChange={event => setUserFeedback(event.target.value)}
                />
              </Box>
            </Box>
          </Box>
        </Paper>
      </form>
    </Box>
  );
};

export default CarbonFootprintCalculator;
