import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider, Typography, CircularProgress, Grid } from "@mui/material";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getIRTRegistry } from "../../../actions/landing";
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function Registry() {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getIRTRegistry());
  }, [dispatch])
  const { loading, irtRegistries, preIrtRegistries } = useSelector(state => state.irtRegistries);

  const preIRTColumns = [
    { field: 'symbol', headerName: 'Symbol' },
    { field: 'project_name', headerName: 'Project Name' },
    { field: 'methodology', headerName: 'Methodology' },
    { field: 'credit_type', headerName: 'Credit Type' },
    { field: 'expected_co_benefits', headerName: 'Expected Co-Benefits', },
    { field: 'price_range', headerName: 'Price range per tCO2e' },
    { field: 'potential', headerName: 'Potential tCO2e' },
    { field: 'committed', headerName: '% Committed' },
    { field: 'open_until', headerName: 'Open until' },
    { field: 'token_type', headerName: 'Token Type' },
    { field: 'country', headerName: 'Country' },
  ];
  const IRTColumns = [
    { field: 'date', headerName: 'Date' },
    { field: 'retired_irts', headerName: 'Retired IRTs' },
    { field: 'token_id', headerName: 'Token_ID' },
    { field: 'credit_type', headerName: 'Credit Type' },
    { field: 'supplier', headerName: 'Supplier' },
    { field: 'beneficiary', headerName: 'Beneficiary' },
    { field: 'methodology', headerName: 'Methodology' },
    { field: 'retirement_purpose', headerName: 'Retirement Purpose' },
    { field: 'country_of_consumption', headerName: 'Country of Consumption' },
    { field: 'project_country', headerName: 'Project Country' },
    { field: 'project_name', headerName: 'Project Name' },
    { field: 'public_retirement_statement', headerName: 'Public Retirement Statement' },
  ];

  return (
    <>
      {loading ? <CircularProgress /> :
        <Grid>
          <Grid xs={12}>
            <Box display={'flex'} alignItems={'center'} sx={{ background: 'white', p: 1, cursor: 'pointer' }} onClick={() => navigate('/')}>
              <ArrowBack /><Typography variant="h6">Home</Typography>
            </Box>
          </Grid>
          <Grid sx={{ background: "#212746", p: 2 }}>
            <Typography variant="h5" sx={{ color: "white", mb: 1 }}>Pre-IRT Registry</Typography>
            <DataGrid
              rows={preIrtRegistries}
              getRowId={row => row._id}
              columns={preIRTColumns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              components={{ Toolbar: GridToolbar }}
              sx={{
                background: "white", height: 500,
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal"
                },
                "& .MuiDataGrid-columnHeader": {
                  // Forced to use important since overriding inline styles
                  height: "unset !important"
                },
                "& .MuiDataGrid-columnHeaders": {
                  // Forced to use important since overriding inline styles
                  maxHeight: "168px !important"
                }
              }}
            />
            <Divider width={0} sx={{ mb: 5 }} />
            <Typography variant="h5" sx={{ color: "white", mb: 1 }}>IRT Registry</Typography>
            <DataGrid
              rows={irtRegistries}
              getRowId={row => row._id}
              columns={IRTColumns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              components={{ Toolbar: GridToolbar }}
              sx={{
                background: "white", height: 500, mb: 5,
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal"
                },
                "& .MuiDataGrid-columnHeader": {
                  height: "unset !important"
                },
                "& .MuiDataGrid-columnHeaders": {
                  maxHeight: "168px !important"
                }
              }}
            />
          </Grid>
        </Grid>}
    </>
  );
}