import {
    FETCH_BATCH_REPORTS_SUCCESS,
    FETCH_BATCH_REPORTS_FAIL,
    FETCH_PROJECT_REPORTS_SUCCESS,
    FETCH_PROJECT_REPORTS_FAIL,
    FETCH_BCR_REPORTS_SUCCESS,
    FETCH_BCR_REPORTS_FAIL
} from '../actions/types';

const initialState = {
    batchReports: [],
    projectReports: [],
    bcrReports: [],
    loading: true,
    error: null
};

export default function biocharReporting(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case FETCH_BATCH_REPORTS_SUCCESS:
            return {
                ...state,
                batchReports: payload,
                loading: false,
            };
        case FETCH_BATCH_REPORTS_FAIL:
            return {
                ...state,
                loading: false,
            };
        case FETCH_PROJECT_REPORTS_SUCCESS:
            return {
                ...state,
                projectReports: payload,
                loading: false,
            };
        case FETCH_PROJECT_REPORTS_FAIL:
            return {
                ...state,
                loading: false,
            };
        case FETCH_BCR_REPORTS_SUCCESS:
            return {
                ...state,
                bcrReports: payload,
                loading: false,
            };
        case FETCH_BCR_REPORTS_FAIL:
            return {
                ...state,
                loading: false,
            };
        default: {
            return state
        }
    }
}
