import { Box, Grid, Typography, Link } from '@mui/material';

const BuyAKiln = () => {
    return (
        <Grid container id='kilnpurchase'>
            <Grid
                lg={12}
                item
                display={'flex'}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        flexDirection: {
                            xs: 'column',
                            sm: 'column',
                            md: 'column',
                            lg: 'row',
                            xl: 'row',
                        },
                        maxHeight: '100%',
                        width: '100%'
                    }}
                >
                    <Box
                        display={'flex'}
                        sx={{
                            // pl: { xs: '0px', sm: '0px', md: '64px', lg: '64px', xl: '64px' },
                            // pr: '99px',
                            background: 'linear-gradient(to bottom, #212746, #3e4877)',
                            color: 'white',
                            width: { lg: '50%' },
                            height: '100%',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            maxHeight: { lg: '700px' }
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '36px',
                                fontWeight: '600',
                                fontStyle: 'normal',
                                lineHeight: '44px',
                                letterSpacing: '-0.72px',
                                // ml: '80px',
                                color: '#DA9DE3',
                                display: 'inline-block',
                                pt: '3%',
                                pl: '5%',
                                pr: '5%'
                            }}
                            id="kilnpurchase__heading"
                        >
                            Buy a Ring of Fire Kiln
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '22px',
                                fontWeight: '400',
                                fontStyle: 'normal',
                                lineHeight: '28px',
                                pt: '2%',
                                pl: '5%',
                                pr: '5%'
                            }}
                        >
                            Looking for place based biochar production technologies? Have a look through the options listed below

                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontWeight: '400',
                                fontStyle: 'normal',
                                lineHeight: '28px',
                                pb: '1%',
                                pl: '5%',
                                pr: '5%'
                            }}
                        >
                            <Box sx={{ m: '1.25rem 0' }}>
                                <Typography
                                    sx={{
                                        fontSize: '20px',
                                        fontWeight: '400',
                                        fontStyle: 'normal',
                                        lineHeight: '30px',
                                        mb: '15px',
                                        textDecoration: 'underline'
                                    }}
                                >
                                    Flame Cap Kilns
                                </Typography>
                                <Link variant="outlined"
                                    href="https://ringoffire.earth/"
                                    sx={{
                                        background: '#fff',
                                        p: '5px 10px',
                                        borderRadius: '5px',
                                        textDecoration: 'none',
                                        cursor: 'pointer',
                                        display: 'block',
                                        width: '90px',
                                        textAlign: 'center',
                                        mb: '15px'
                                    }}
                                    target="_blank">
                                    Ring of Fire
                                </Link>
                                <Link variant="outlined"
                                    href="https://caas.usu.edu/uaes/publications/science-magazine/fall-20/big-box-kilns"
                                    sx={{
                                        background: '#fff',
                                        p: '5px 10px',
                                        borderRadius: '5px',
                                        textDecoration: 'none',
                                        cursor: 'pointer',
                                        display: 'block',
                                        width: '90px',
                                        textAlign: 'center'
                                    }}
                                    target="_blank">
                                    Big Box
                                </Link>
                            </Box>
                            <Box sx={{ m: '1.25rem 0' }}>
                                <Typography
                                    sx={{
                                        fontSize: '20px',
                                        fontWeight: '400',
                                        fontStyle: 'normal',
                                        lineHeight: '30px',
                                        mb: '15px',
                                        textDecoration: 'underline'
                                    }}
                                >
                                    Air Curtain Burners
                                </Typography>
                                <Link variant="outlined"
                                    href="https://airburners.com/products/boss-series/"
                                    sx={{
                                        background: '#fff',
                                        p: '5px 10px',
                                        borderRadius: '5px',
                                        textDecoration: 'none',
                                        cursor: 'pointer',
                                        display: 'block',
                                        width: '90px',
                                        textAlign: 'center',
                                        mb: '20px'
                                    }}
                                    target="_blank">
                                    Air Burners
                                </Link>
                                <Link variant="outlined"
                                    href="https://www.tigercat.com/wp-content/uploads/2020/01/6050-carbonator-1.0-1119-web.pdf"
                                    sx={{
                                        background: '#fff',
                                        p: '5px 10px',
                                        borderRadius: '5px',
                                        textDecoration: 'none',
                                        cursor: 'pointer',
                                        display: 'block',
                                        width: '90px',
                                        textAlign: 'center',
                                        mb: '20px'
                                    }}
                                    target="_blank">
                                    Carbonator
                                </Link>
                            </Box>
                            <Box sx={{ m: '1.25rem 0' }}>
                                <Typography
                                    sx={{
                                        fontSize: '20px',
                                        fontWeight: '400',
                                        fontStyle: 'normal',
                                        lineHeight: '30px',
                                        mb: '20px',
                                        textDecoration: 'underline'
                                    }}
                                >
                                    Conservation Burns
                                </Typography>
                                <Link variant="outlined"
                                    href="https://wilsonbiochar.com/"
                                    sx={{
                                        background: '#fff',
                                        p: '5px 10px',
                                        borderRadius: '5px',
                                        textDecoration: 'none',
                                        cursor: 'pointer',
                                        display: 'block',
                                        width: '200px',
                                        textAlign: 'center'
                                    }}
                                    target="_blank">
                                    Conservation Burns
                                </Link>
                            </Box>
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: '400',
                                    fontStyle: 'normal',
                                    lineHeight: '28px',
                                }}
                            >
                                Contact wihan@ikhala.tech for more information on the respective technologies or technology assessment consultations
                            </Typography>
                        </Typography>
                    </Box>
                    <Box
                        id='kilnpurchase__image'
                        sx={{ width: { xs: '100%', lg: '50%' }, height: '700px', minHeight: '700px' }}
                    >
                    </Box>

                </Box>

            </Grid>
        </Grid>
    );
};

export default BuyAKiln;
