import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  TextField,
  Link,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import {
  getUnburntVolume,
  verifyBiocharData,
} from '../../../../../../../actions/forms';
import Checkmark from '../../../../../../../assets/images/check.png';
import Cross from '../../../../../../../assets/images/cross.png';
import { UPLOADED_DOCS } from '../../../../../../../actions/constants';

function UnburntPiecesVolume({ unburntVolumeVerificationData, projectKey }) {
  const dispatch = useDispatch();
  const [unburntVolume, setUnburntVolume] = useState([]);

  useEffect(() => {
    dispatch(getUnburntVolume(projectKey));
  }, [dispatch, projectKey]);

  const unburntVolumeTableHeadings = [
    'Batch ID',
    'Kiln ID',
    'Largest Piece Diameter',
    'Image',
    'Value Correct',
    'Correction',
    'Unburnt Piece Panel Area',
    'Image',
    'Value Correct',
    'Correction',
    'Verified',
  ];

  useEffect(() => {
    if (unburntVolumeVerificationData) {
      setUnburntVolume(
        unburntVolumeVerificationData.map(d => {
          return {
            id: d.id,
            batch: d.batch,
            kilnID: d.kilnUsed,
            largestPieceDiameter: d.largestPieceDiameter,
            largestPieceDiameterPhoto: d.largestPieceDiameterPhoto,
            unburntPiecePanelArea: d.unburntPiecePanelArea,
            unburntPiecesPanelPhotoURL: d.unburntPiecesPanelPhotoURL,
            value1Correct:
              d?.value1Correct === true || d?.value1Correct === false
                ? d.value1Correct
                : null,
            correction1: d?.correction1 ? d.correction1 : null,
            value2Correct:
              d?.value2Correct === true || d?.value2Correct === false
                ? d.value2Correct
                : null,
            correction2: d?.correction2 ? d.correction2 : null,
            value3Correct: null,
            verified: d?.verified ? d?.verified : null,
          };
        })
      );
    }
  }, [unburntVolumeVerificationData, dispatch]);
  const handleSubmitUnburntVolume = async () => {
    await dispatch(
      verifyBiocharData(projectKey, 'unburntVolume', unburntVolume)
    );
    await dispatch(getUnburntVolume(projectKey));
  };

  const handleValue1CorrectChange = (event, batch) => {
    setUnburntVolume(unburntVolume => {
      const updated = unburntVolume.map(v => {
        if (v.batch === batch) {
          v.value1Correct = !!+event.target.value;
        }
        return v;
      });
      return updated;
    });
  };

  const handleValue2CorrectChange = (event, batch) => {
    setUnburntVolume(unburntVolume => {
      const updated = unburntVolume.map(v => {
        if (v.batch === batch) {
          v.value2Correct = !!+event.target.value;
        }
        return v;
      });
      return updated;
    });
  };

  const handleCorrection1 = (event, batch) => {
    setUnburntVolume(unburntVolume => {
      const updated = unburntVolume.map(v => {
        if (v.batch === batch) {
          v.correction1 = event.target.value;
        }
        return v;
      });
      return updated;
    });
  };

  const handleCorrection2 = (event, batch) => {
    setUnburntVolume(unburntVolume => {
      const updated = unburntVolume.map(v => {
        if (v.batch === batch) {
          v.correction2 = event.target.value;
        }
        return v;
      });
      return updated;
    });
  };

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Typography variant='h6' sx={{ mb: 2 }}>
        Unburnt Pieces Volume
      </Typography>
      <TableContainer component={Paper} sx={{ height: 'auto', width: '100%' }}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              {unburntVolumeTableHeadings.map((heading, i) => {
                return (
                  <TableCell align='left' key={i}>
                    {heading}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {unburntVolume?.length > 0
              ? unburntVolume?.map((d, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell align='left'>{d.batch}</TableCell>
                    <TableCell align='left'>{d.kilnID}</TableCell>
                    <TableCell align='left'>
                      {d.largestPieceDiameter}
                    </TableCell>
                    <TableCell align='left'>
                      <Link
                        target='_blank'
                        href={`${UPLOADED_DOCS}${d?.largestPieceDiameterPhoto
                          ?.split('/')
                          .pop()}`}
                      >
                        <img
                          height='150'
                          width='150'
                          src={`${UPLOADED_DOCS}${d?.largestPieceDiameterPhoto
                            ?.split('/')
                            .pop()}`}
                          alt='moisture measurement'
                        />
                      </Link>
                    </TableCell>
                    <TableCell align='left'>
                      <RadioGroup
                        value={
                          d?.value1Correct === true
                            ? 1
                            : d?.value1Correct === false
                              ? 0
                              : null
                        }
                        row
                        name='value1Correct'
                        onChange={event =>
                          handleValue1CorrectChange(event, d.batch)
                        }
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio required />}
                          label='Yes'
                          name='value1Correct'
                          disabled={d?.verified}
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio required />}
                          label='No'
                          name='value1Correct'
                          disabled={d?.verified}
                        />
                      </RadioGroup>
                    </TableCell>
                    <TableCell align='left'>
                      <TextField
                        // label='Site Manager Phone'
                        name='correction1'
                        value={d.correction1}
                        onChange={event => handleCorrection1(event, d.batch)}
                        sx={{ background: '#fff' }}
                        required
                        fullWidth
                        type='number'
                        disabled={
                          d?.verified || d?.value1Correct === true
                            ? true
                            : false
                        }
                      />
                    </TableCell>
                    <TableCell align='left'>
                      {d.unburntPiecePanelArea}
                    </TableCell>
                    <TableCell align='left'>
                      <Link
                        target='_blank'
                        href={`${UPLOADED_DOCS}${d?.unburntPiecesPanelPhotoURL
                          ?.split('/')
                          .pop()}`}
                      >
                        <img
                          height='150'
                          width='150'
                          src={`${UPLOADED_DOCS}${d?.unburntPiecesPanelPhotoURL
                            ?.split('/')
                            .pop()}`}
                          alt='moisture measurement'
                        />
                      </Link>
                    </TableCell>
                    <TableCell align='left'>
                      <RadioGroup
                        value={
                          d?.value2Correct === true
                            ? 1
                            : d?.value2Correct === false
                              ? 0
                              : null
                        }
                        row
                        name='value2Correct'
                        onChange={event =>
                          handleValue2CorrectChange(event, d.batch)
                        }
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio required />}
                          label='Yes'
                          name='value2Correct'
                          disabled={d?.verified}
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio required />}
                          label='No'
                          name='value2Correct'
                          disabled={d?.verified}
                        />
                      </RadioGroup>
                    </TableCell>
                    <TableCell align='left'>
                      <TextField
                        name='correction2'
                        value={d.correction1}
                        onChange={event => handleCorrection2(event, d.batch)}
                        sx={{ background: '#fff' }}
                        required
                        fullWidth
                        type='number'
                        disabled={
                          d?.verified || d?.value2Correct === true
                            ? true
                            : false
                        }
                      />
                    </TableCell>
                    <TableCell align='left'>
                      {d?.verified ? (
                        <img
                          src={Checkmark}
                          width={25}
                          height={25}
                          alt='checkmark'
                        />
                      ) : (
                        <img src={Cross} width={25} height={25} alt='cross' />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        sx={{ mt: 2 }}
        variant='contained'
        onClick={handleSubmitUnburntVolume}
      >
        Verify Unburnt Pieces Volume
      </Button>
    </Box>
  );
}

const mapStateToProps = state => ({
  unburntVolumeVerificationData:
    state.postFormData.unburntVolumeVerificationData,
  loading: state.postFormData.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    getUnburntVolume: projectId => dispatch(getUnburntVolume(projectId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnburntPiecesVolume);
