import {
  GET_PLOTLIST_SUCCESS,
  GET_PLOTLIST_FAIL,
  CREATE_GENERAL_INFORMATION_SUCCESS,
  CREATE_GENERAL_INFORMATION_FAIL,
  GET_GENERAL_INFORMATION_SUCCESS,
  GET_GENERAL_INFORMATION_FAIL,
  CF_GET_FERTILIZERS_SUCCESS,
  CF_GET_FERTILIZERS_FAIL,
  CF_CREATE_FERTILIZER_SUCCESS,
  CF_CREATE_FERTILIZER_FAIL,
  CF_ADD_FERTILIZER_TO_LIST_SUCCESS,
  CF_ADD_FERTILIZER_TO_LIST_FAIL,
  CF_GET_FERTILIZER_PLOTLIST_DATA_SUCCESS,
  CF_GET_FERTILIZER_PLOTLIST_DATA_FAIL,
  CF_SUBMIT_FERTILIZER_PLOTLIST_DATA_SUCCESS,
  CF_SUBMIT_FERTILIZER_PLOTLIST_DATA_FAIL,
  CREATE_PLOTLIST_SUCCESS,
  CREATE_PLOTLIST_FAIL,
  CF_GET_AGROCHEMICALS_SUCCESS,
  CF_GET_AGROCHEMICALS_FAIL,
  CF_CREATE_AGROCHEMICAL_SUCCESS,
  CF_CREATE_AGROCHEMICAL_FAIL,
  CF_ADD_AGROCHEMICAL_TO_LIST_SUCCESS,
  CF_ADD_AGROCHEMICAL_TO_LIST_FAIL,
  CF_GET_AGROCHEMICAL_PLOTLIST_DATA_SUCCESS,
  CF_GET_AGROCHEMICAL_PLOTLIST_DATA_FAIL,
  CF_GET_COMPOST_PLOTLIST_DATA_SUCCESS,
  CF_GET_COMPOST_PLOTLIST_DATA_FAIL,
  CF_GET_PURCHASED_COMPOST_SUCCESS,
  CF_GET_PURCHASED_COMPOST_FAIL,
  CF_GET_FARM_COMPOST_SUCCESS,
  CF_GET_FARM_COMPOST_FAIL,
  CF_SUBMIT_AGROCHEMICAL_PLOTLIST_DATA_SUCCESS,
  CF_SUBMIT_AGROCHEMICAL_PLOTLIST_DATA_FAIL,
  CF_SUBMIT_COMPOST_PLOTLIST_DATA_SUCCESS,
  CF_SUBMIT_COMPOST_PLOTLIST_DATA_FAIL,
  CREATE_CULTIVATION_GENERAL_INFORMATION_SUCCESS,
  CREATE_CULTIVATION_GENERAL_INFORMATION_FAIL,
  GET_CULTIVATION_GENERAL_INFORMATION_SUCCESS,
  GET_CULTIVATION_GENERAL_INFORMATION_FAIL,
} from '../actions/types';

const initialState = {
  loading: true,
  plotlist: [],
  generalInformation: [],
  cultivationGeneralInformation: [],
  fertilizers: [],
  agrochemicals: [],
  fertilizerPlotlistData: [],
  agrochemicalPlotlistData: [],
  agrochemicalAmountAppliedTotals: [],
  compostPlotlistData: [],
  fertilizerAmountAppliedTotals: [],
  purchasedCompost: [],
  onFarmCompost: [],
  compostAmountAppliedTotals: [],
  error: null,
};

export default function carbonfootprint(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PLOTLIST_SUCCESS:
      return {
        ...state,
        plotlist: payload,
        loading: false,
      };
    case GET_PLOTLIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CREATE_PLOTLIST_SUCCESS:
      return {
        ...state,
        plotlist: payload,
        loading: false,
      };
    case CREATE_PLOTLIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CREATE_GENERAL_INFORMATION_SUCCESS:
      return {
        ...state,
        generalInformation: payload,
        loading: false,
      };
    case CREATE_GENERAL_INFORMATION_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CREATE_CULTIVATION_GENERAL_INFORMATION_SUCCESS:
      return {
        ...state,
        cultivationGeneralInformation: payload,
        loading: false,
      };
    case CREATE_CULTIVATION_GENERAL_INFORMATION_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_GENERAL_INFORMATION_SUCCESS:
      return {
        ...state,
        generalInformation: payload,
        loading: false,
      };
    case GET_GENERAL_INFORMATION_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_CULTIVATION_GENERAL_INFORMATION_SUCCESS:
      return {
        ...state,
        cultivationGeneralInformation: payload,
        loading: false,
      };
    case GET_CULTIVATION_GENERAL_INFORMATION_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CF_GET_FERTILIZERS_SUCCESS:
      return {
        ...state,
        fertilizers: payload.fertilizers,
        loading: false,
      };
    case CF_GET_FERTILIZERS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CF_GET_AGROCHEMICALS_SUCCESS:
      return {
        ...state,
        agrochemicals: payload.agrochemicals,
        loading: false,
      };
    case CF_GET_AGROCHEMICALS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CF_CREATE_FERTILIZER_SUCCESS:
      return {
        ...state,
        fertilizers: [...state.fertilizers, payload],
        loading: false,
      };
    case CF_CREATE_FERTILIZER_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CF_CREATE_AGROCHEMICAL_SUCCESS:
      return {
        ...state,
        agrochemicals: [...state.agrochemicals, payload],
        loading: false,
      };
    case CF_CREATE_AGROCHEMICAL_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CF_ADD_FERTILIZER_TO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CF_ADD_FERTILIZER_TO_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CF_GET_FERTILIZER_PLOTLIST_DATA_SUCCESS:
      return {
        ...state,
        fertilizerPlotlistData: payload,
        loading: false,
      };
    case CF_GET_FERTILIZER_PLOTLIST_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CF_GET_AGROCHEMICAL_PLOTLIST_DATA_SUCCESS:
      return {
        ...state,
        agrochemicalPlotlistData: payload,
        loading: false,
      };
    case CF_GET_AGROCHEMICAL_PLOTLIST_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CF_SUBMIT_FERTILIZER_PLOTLIST_DATA_SUCCESS:
      return {
        ...state,
        fertilizerAmountAppliedTotals: payload,
        loading: false,
      };
    case CF_SUBMIT_FERTILIZER_PLOTLIST_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CF_SUBMIT_AGROCHEMICAL_PLOTLIST_DATA_SUCCESS:
      return {
        ...state,
        agrochemicalAmountAppliedTotals: payload,
        loading: false,
      };
    case CF_SUBMIT_AGROCHEMICAL_PLOTLIST_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CF_SUBMIT_COMPOST_PLOTLIST_DATA_SUCCESS:
      return {
        ...state,
        compostAmountAppliedTotals: payload,
        loading: false,
      };
    case CF_SUBMIT_COMPOST_PLOTLIST_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CF_ADD_AGROCHEMICAL_TO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CF_ADD_AGROCHEMICAL_TO_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CF_GET_COMPOST_PLOTLIST_DATA_SUCCESS:
      return {
        ...state,
        compostPlotlistData: payload,
        loading: false,
      };
    case CF_GET_COMPOST_PLOTLIST_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CF_GET_PURCHASED_COMPOST_SUCCESS:
      return {
        ...state,
        purchasedCompost: payload,
        loading: false,
      };
    case CF_GET_PURCHASED_COMPOST_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CF_GET_FARM_COMPOST_SUCCESS:
      return {
        ...state,
        onFarmCompost: payload,
        loading: false,
      };
    case CF_GET_FARM_COMPOST_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
