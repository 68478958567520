import { useEffect, useState } from 'react';
import { Grid, Button, Tabs, Tab, Alert } from '@mui/material';
import BF002UsualFeedstock from './types/BF002UsualFeedstock';
import BF003IABEquipment from './types/BF003IABEquipment';
import BF003aLogKiln from './types/BF003aLogKiln';
import BF000ProjectDescription from './types/BF000ProjectDescription';
import BF007aFeedstockSource from './types/BF007aFeedstockSource';
import BF020BurnSupervisor from './types/BF020BurnSupervisor';
import BF022ProofProject from './types/BF022ProofProject';
import BF019BurnTeamPayments from './types/BF019BurnTeamPayments';
import BF026EntityDetails from './types/BF026EntityDetails';
import BF004BiocharCharacteristics from './types/BF004BiocharCharacteristics';
import AssetList from './types/data-tables/assets/AssetList';
import { useSelector } from 'react-redux';
import { MeasurementUnits } from './types/project-info/MeasurementUnits';

export default function Baseline({
  dataBF000,
  dataBF002,
  dataBF003,
  dataBF004,
  setLoopData,
  dataBF003a,
  dataBF007a,
  dataBF020,
  dataBF022,
  dataBF019,
  dataBF026,
  setData,
  setFormId,
  feedstockSourceTypes,
  active,
  next,
  totalItems,
  projectKey,
  measureType,
  formErrors
}) {
  const items = [
    {
      id: 0,
      name: 'Project Description',
      active: true
    },
    {
      id: 1,
      name: 'Business as a usual for feedstock',
    },
    {
      id: 2,
      name: 'Equipment for Biochar Burns',
    },
    // {
    //   id: 3,
    //   name: 'Log Kiln',
    // },
    // new
    {
      id: 3,
      name: 'Feedstock Source',
    },
    {
      id: 4,
      name: 'Burn Supervisor'
    },
    {
      id: 5,
      name: 'Proof of Project'
    },
    {
      id: 6,
      name: 'Burn Team Payments'
    },
    {
      id: 7,
      name: 'Entity Details'
    },
    {
      id: 8,
      name: 'Measurement Units'
    }
  ];

  const items2 = [
    {
      id: 0,
      name: 'Log Kiln',
      active: true,
    },
    {
      id: 1,
      name: 'Add Assets',
    }
  ];

  const items3 = [
    {
      id: 0,
      name: 'Biochar Characteristics',
      active: true,
    }
  ];

  useEffect(() => {
    totalItems(items.length);
  }, [items.length, totalItems]);

  const [value, setValue] = useState(0);
  const handleChange = (event, index) => {
    setValue(index);
  };

  const { userData } = useSelector(state => state.auth);
  // const projects = useSelector(state => state.formData.projects)

  // console.log(projects, 'hfdskjhfdsklj')

  return (
    <Grid container>
      <Grid item sx={{ mb: 2, display: 'flex', width: '100%' }}>
        <Tabs value={value} onChange={handleChange} style={{ marginBottom: 10 }}>
          <Tab label="General Info" />
          <Tab label="Assets" />
          <Tab label="Lab Tests" disabled={true} />
        </Tabs>
      </Grid>
      {value === 0 ? (
        <>
          <Grid item xs={2} sx={{ borderRight: '1px solid', p: 2 }}>
            {items.map(item => (
              <Button
                fullWidth
                key={item.id}
                onClick={() => next(item.id)}
                variant={active === item.id ? 'outlined' : null}
                disabled={[1, 2, 3, 4, 5, 6, 7].includes(item.id)}
              >
                {item.name}
              </Button>
            ))}
          </Grid>
          <Grid item xs={10} sx={{ p: 2 }}>
            {active === 0 ? (
              <BF000ProjectDescription
                data={dataBF000}
                setData={setData}
                setFormId={setFormId}
              />
            ) : active === 1 ? (
              <BF002UsualFeedstock
                data={dataBF002}
                setData={setData}
                setFormId={setFormId}
                feedstockSourceTypes={feedstockSourceTypes}
              />
            ) : active === 2 ? (
              <BF003IABEquipment
                data={dataBF003}
                setData={e => setData(e)}
                setFormId={setFormId}
              />
            )
              : active === 3 ? (
                <BF007aFeedstockSource
                  data={dataBF007a}
                  setData={setData}
                  setFormId={setFormId}
                  feedstockSourceTypes={feedstockSourceTypes}
                />) :
                active === 4 ? (
                  <BF020BurnSupervisor
                    data={dataBF020}
                    setData={setData}
                    setFormId={setFormId}
                  />) :
                  active === 5 ? (
                    <BF022ProofProject
                      data={dataBF022}
                      setData={setData}
                      setFormId={setFormId}
                    />) :
                    active === 6 ? (
                      <BF019BurnTeamPayments

                        data={dataBF019}
                        setData={setData}
                        setFormId={setFormId}

                      />) :
                      active === 7 ? (
                        <BF026EntityDetails
                          data={dataBF026}
                          setData={setData}
                          setFormId={setFormId}
                        />
                      ) :
                        active === 8 ? (
                          <MeasurementUnits
                            projectKey={projectKey} measureType={measureType} />
                        ) :
                          null}
          </Grid>
        </>) :
        value === 1 ? (
          <>
            <Grid item xs={2} sx={{ borderRight: '1px solid', p: 2 }}>
              {items2.map(item => (
                <Button
                  fullWidth
                  key={item.id}
                  onClick={() => next(item.id)}
                  variant={active === item.id ? 'outlined' : null}
                >
                  {item.name}
                </Button>
              ))}
            </Grid>
            <Grid item xs={10} sx={{ p: 2 }}>
              {active === 0 ? (
                <BF003aLogKiln
                  data={dataBF003a}
                  setData={e => setData(e)}
                  setFormId={setFormId}
                  projectKey={projectKey}
                  formErrors={formErrors}
                />
              ) : active === 1 ? <AssetList farm={userData.farm[0]._id} project={projectKey} /> : null}
              {formErrors.length > 0 ? <Alert severity='error' sx={{ mt: 2 }}>{formErrors[0]}</Alert> : null}

            </Grid>
          </>) : value === 2 ? (
            <>
              <Grid item xs={2} sx={{ borderRight: '1px solid', p: 2 }}>
                {items3.map(item => (
                  <Button
                    fullWidth
                    key={item.id}
                    onClick={() => next(item.id)}
                    variant={active === item.id ? 'outlined' : null}
                  >
                    {item.name}
                  </Button>
                ))}

              </Grid>
              <Grid item xs={10} sx={{ p: 2 }}>
                <BF004BiocharCharacteristics
                  data={dataBF004}
                  setData={setData}
                  handleLoopData={val => setLoopData(val)}
                  setFormId={setFormId}
                  feedstockSourceTypes={feedstockSourceTypes}
                />
              </Grid>
            </>) : null}

    </Grid>
  );
}
