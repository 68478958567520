import React from 'react'
import { Button, Dialog, DialogActions, FormControl, DialogContent, DialogTitle, Divider, Typography, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
const AddCarRppbDialog = ({
    openCarRppbModal,
    setOpenCarRppbModal,
    reportingEntityName,
    setReportingEntityName,
    setStartDate,
    setEndDate,
    handleSubmitCarRppb }) => {

    return (
        <Dialog open={openCarRppbModal} onClose={() => setOpenCarRppbModal(false)}>
            <DialogTitle>Add New CAR Rppb</DialogTitle>
            <DialogContent>
                <Divider sx={{ my: 1 }} />
                <Typography>Reporting Entity Name</Typography>
                <TextField
                    name='nickname'
                    value={reportingEntityName}
                    onChange={e => setReportingEntityName(e.target.value)}
                    fullWidth
                    sx={{ mb: 2 }}
                />

                <FormControl fullWidth sx={{ mb: 2 }}>
                    Start Date
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker name="Start Date"
                            // onChange={(e) => handleInputChange(e, field.name)} 
                            onChange={(e) => setStartDate(e)}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    End Date
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker name="Start Date"
                            // onChange={(e) => handleInputChange(e, field.name)} 
                            onChange={(e) => setEndDate(e)}
                        />
                    </LocalizationProvider>
                </FormControl>
            </DialogContent>
            <DialogActions sx={{ mr: 'auto', mb: 3, ml: 2 }}>
                <Button variant='contained' onClick={handleSubmitCarRppb}>
                    Add
                </Button>
                <Button variant='outlined' onClick={() => setOpenCarRppbModal(false)}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddCarRppbDialog