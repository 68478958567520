import {
    POST_RENEWABLE_ENERGY_DATA_SUCCESS,
    POST_RENEWABLE_ENERGY_DATA_FAIL,
    GET_RENEWABLE_ENERGY_DATA_SUCCESS,
    GET_RENEWABLE_ENERGY_DATA_FAIL,
    GET_RENEWABLE_ENERGY_DATA_TOTALS_SUCCESS,
    GET_RENEWABLE_ENERGY_DATA_TOTALS_FAIL,
} from '../../../actions/carbonfootprint-rework/types/02-indirect-ghg-emissions-from-imported-energy-types';


const initialState = {
    loading: true,
    renewableEnergyData: [],
    renewableEnergyDataTotals: []
};

// PETROL REDUCERS
export default function
    directGhgEmissionsAndRemovalsForRenewableEnergy(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        case POST_RENEWABLE_ENERGY_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_RENEWABLE_ENERGY_DATA_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_RENEWABLE_ENERGY_DATA_SUCCESS:
            return {
                ...state,
                renewableEnergyData: payload,
                loading: false,
            };
        case GET_RENEWABLE_ENERGY_DATA_FAIL:
            return {
                ...state,
                renewableEnergyData: payload,
                loading: false
            }
        case GET_RENEWABLE_ENERGY_DATA_TOTALS_SUCCESS:
            return {
                ...state,
                renewableEnergyDataTotals: payload,
                loading: false,
            };
        case GET_RENEWABLE_ENERGY_DATA_TOTALS_FAIL:
            return {
                ...state,
                renewableEnergyDataTotals: payload,
                loading: false
            }

        default:
            return state;
    }
}


