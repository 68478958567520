import { useContext, useEffect } from 'react';
import MapContext from '../../context/MapContext';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';

export const OSMLayer = () => {
  const { map } = useContext(MapContext);

  let OsmLayer = new TileLayer({
    source: new OSM(),
  });
  useEffect(() => {
    if (!map) return;

    map.addLayer(OsmLayer);
    return () => {
      if (map) {
        map.removeLayer(OsmLayer);
      }
    };
  });
  return null;
};
