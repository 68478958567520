import { Alert, Box, Button, Divider, Grid, Typography } from '@mui/material';

export default function YearSelection({ setSelection, setYear }) {

    const years = ['2022', '2023', '2024']

    return (
        <Grid item xs={12}>
            <Typography variant='h5'>Year</Typography>
            <Divider />
            <Alert severity='info'>Please select a year</Alert>
            <Box marginTop={2}>
                {years.map((y, i) => {
                    return (
                        <Button
                            key={i}
                            variant='contained'
                            size='large'
                            sx={{ width: '20%', display: 'block', mb: 2.5 }}
                            onClick={() => {
                                setYear(y)
                                setSelection({ id: 1, title: 'company' })
                                localStorage.setItem('year', y)
                            }}
                        >
                            {y}
                        </Button>
                    )
                })
                }
            </Box>
        </Grid>
    );
}
