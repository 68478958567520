import React, { useEffect, useState, useRef } from 'react';
import { Alert, Typography, TextField, Grid, Button, TableContainer, Table, TableHead, TableCell, TableRow, Paper, TableBody, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../../../../../actions/auth';
import { getRofBurnsBatchBulkDensity, getRofBurnsBatchCarbonContent, getRofBurnsBatchVolumes, getRofBurnsBiocharMass, getRofBurnsBiocharStoredCarbon, postRofBatchCarbonContentCcik, postRofBurnsBiocharStoredCarbon, rofLabReportFileUpload, rofLabReportFiles } from '../../../../../../actions/rof-burns/rof-actions';

const RofDataTables = ({ items, active, next, projectKey, measureType }) => {
    const fileInputRef = useRef(null);
    const [batchCarbonContentState, setBatchCarbonContentState] = useState([]);
    const [biocharStoredCarbonState, setBiocharStoredCarbonState] = useState([]);
    const [labReportFile, setLabReportFile] = useState(null)

    const batchVolumes = useSelector(state => state.rofBurnsReducer.batchVolumes)
    const batchBulkDensity = useSelector(state => state.rofBurnsReducer.bulkDensity)
    const biocharMass = useSelector(state => state.rofBurnsReducer.biocharMass)
    const batchCarbonContent = useSelector(state => state.rofBurnsReducer.batchCarbonContent)
    const biocharStoredCarbon = useSelector(state => state.rofBurnsReducer.biocharStoredCarbon)
    const labReportFiles = useSelector(state => state.rofBurnsReducer.labReportFiles)

    // const { userData } = useSelector(state => state.auth);
    // const year = new Date().getFullYear()
    const dispatch = useDispatch();
    // const [loadedBatchData, setLoadedBatchData] = useState([]);

    // useEffect(() => {
    //     setLoadedBatchData(batchData);
    // }, [dispatch, batchData]);

    useEffect(() => {
        dispatch(getUserDetails());
        if (active === 0)
            dispatch(getRofBurnsBatchVolumes(projectKey));
        else if (active === 1)
            dispatch(getRofBurnsBatchBulkDensity(projectKey));
        else if (active === 2)
            dispatch(getRofBurnsBiocharMass(projectKey))
        else if (active === 3) {
            dispatch(getRofBurnsBatchCarbonContent(projectKey))
            dispatch(rofLabReportFiles(projectKey))
        } else if (active === 4)
            dispatch(getRofBurnsBiocharStoredCarbon(projectKey))
    }, [dispatch, active, projectKey]);

    useEffect(() => {
        if (active === 3) {
            setBatchCarbonContentState(Array.isArray(batchCarbonContent?.data) ? [...batchCarbonContent.data] : []);
        }
        else if (active === 4) {
            setBiocharStoredCarbonState(Array.isArray(biocharStoredCarbon?.data) ? [...biocharStoredCarbon.data] : []);
        }
    }, [batchCarbonContent, biocharStoredCarbon, active]);

    const handleCcikInputChange = (e, project, batch, kiln) => {
        setBatchCarbonContentState((prevData) => {
            return prevData.map((data) => {
                if (data.batchId === batch && data.kilnUsed === kiln) {
                    return { ...data, ccik: +e.target.value }
                }
                return data
            })
        })
    }

    const handleLabResultsFile = (e) => {
        const file = e.target.files[0];
        let formData = new FormData();
        formData.append('file', file);
        setLabReportFile(formData)
    }

    const handleLabResultsFileUpload = () => {
        labReportFile.forEach((value, key) => {
            console.log(`${key}: ${value}`);
        });
        dispatch(rofLabReportFileUpload(labReportFile, projectKey)).then(() => {
            dispatch(rofLabReportFiles(projectKey))
        })
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        setLabReportFile(null);
    }

    const prdebOptions = [{ id: 0, type: 'ACB (0.8)', value: 0.8 }, { id: 1, type: 'FCK (0.8)', value: 0.8 }, { id: 2, type: 'CB (0.65)', value: 0.65 }]

    const handlePrdebOptionsChange = (e, batch, kiln) => {

        setBiocharStoredCarbonState((prevData) => {
            return prevData.map((data) => {
                if (data.batchId === batch && data.kilnUsed === kiln) {
                    console.log('yes')
                    return { ...data, prdeb: +e.target.value }
                }
                return data
            })
        })
    }

    const handleSubmitBatchCarbonContent = () => {
        dispatch(postRofBatchCarbonContentCcik(batchCarbonContentState, projectKey)).then(() => {
            dispatch(getRofBurnsBatchCarbonContent(projectKey))
        })

    }

    const handleSubmitBiocharStoredCarbon = () => {
        dispatch(postRofBurnsBiocharStoredCarbon(biocharStoredCarbonState, projectKey)).then(() => {
            dispatch(getRofBurnsBiocharStoredCarbon(projectKey))
        })
    }

    return (
        <>
            <Grid item xs={2} sx={{ borderRight: '1px solid', p: 2 }}>
                {items.map(item => (
                    <Button
                        fullWidth
                        key={item.id}
                        onClick={() => next(item.id)}
                        variant={active === item.id ? 'outlined' : null}
                    >
                        {item.name}
                    </Button>
                ))}
            </Grid>
            {active === 0 && (
                <Grid item xs={10} sx={{ p: 2 }}>
                    <TableContainer key={0} component={Paper} sx={{ height: 'auto' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        ProjectId
                                    </TableCell>
                                    <TableCell align="left">
                                        BatchId
                                    </TableCell>
                                    <TableCell align="left">
                                        KilnId
                                    </TableCell>
                                    <TableCell align="left">
                                        Gross Volume
                                    </TableCell>
                                    <TableCell align="left">
                                        Partially Burnt Volume
                                    </TableCell>
                                    <TableCell align="left">
                                        Net Volume
                                    </TableCell>
                                    <TableCell align="left">
                                        Unit
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {batchVolumes?.data?.map((data, i) => {
                                    const isLastIteration = i === batchVolumes.length - 1
                                    return (
                                        <TableRow key={i}>
                                            <TableCell align="left">{data.projectId}</TableCell>
                                            <TableCell align="left">{data.batchId}</TableCell>
                                            <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>{data.kilnUsed}</TableCell>
                                            <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>{data.grossVol}</TableCell>
                                            <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>{data.partiallyBurntVol}</TableCell>
                                            <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>{data.netVol}</TableCell>
                                            <TableCell align="left">{measureType === 'Imperial' ? <> yrd<sup>3</sup></> : <> m<sup>3</sup></>}</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {/* {batchData && Object.keys(batchData)?.map((key, i, arr) => {
                                    return batchData[key].map((data, j, subArr) => {
                                        const isLastIteration = i === arr.length - 1 && j === subArr.length - 1;
                                       
                                    });
                                })} */}
                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}><b>TOTAL</b></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}><b>{batchVolumes?.totals?.totalGrossVol}</b></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}><b>{batchVolumes?.totals?.totalPartiallyBurntVol}</b></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}><b>{batchVolumes?.totals?.totalNetVol}</b></TableCell>
                                    <TableCell align="left">{measureType === 'Imperial' ? <> yrd<sup>3</sup></> : <> m<sup>3</sup></>}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
            {active === 1 && (

                <Grid item xs={10} sx={{ p: 2 }}>

                    <TableContainer key={0} component={Paper} sx={{ height: 'auto', mt: 3 }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        ProjectId
                                    </TableCell>
                                    <TableCell align="left">
                                        BatchId
                                    </TableCell>
                                    <TableCell align="left">
                                        KilnId
                                    </TableCell>
                                    <TableCell align="left">
                                        Empty Bucket Mass
                                    </TableCell>
                                    <TableCell align="left">
                                        Hot Char and Bucket Mass
                                    </TableCell>
                                    <TableCell align="left">
                                        Bucket Volume
                                    </TableCell>
                                    <TableCell align="left">
                                        Bulk Density
                                    </TableCell>
                                    <TableCell align="left">
                                        Unit
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {batchBulkDensity?.data?.map((data, i) => {
                                    const isLastIteration = i === batchBulkDensity.length - 1
                                    return (
                                        <TableRow key={i}>
                                            <TableCell align="left">
                                                {data.projectId}
                                            </TableCell>
                                            <TableCell align="left">
                                                {data.batchId}
                                            </TableCell>
                                            <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                {data.kilnUsed}
                                            </TableCell>
                                            <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                {data.emptyBucketMass}
                                            </TableCell>
                                            <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                {data.hotCharAndBucketMass}
                                            </TableCell>
                                            <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                {data.bucketVolume}
                                            </TableCell>
                                            <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                {data.bulkDensity}
                                            </TableCell>
                                            <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                {measureType === 'Imperial' ? <> lbs/yrd<sup>3</sup></> : <> kg/m<sup>3</sup></>}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}><b>AVERAGE</b></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}>{batchBulkDensity?.total?.totalAverageBulkDensity}</TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}>{measureType === 'Imperial' ? <> lbs/yrd<sup>3</sup></> : <> kg/m<sup>3</sup></>}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
            {active === 2 && (
                <Grid item xs={10} sx={{ p: 2 }}>
                    <TableContainer key={0} component={Paper} sx={{ height: 'auto', mt: 3 }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        ProjectId
                                    </TableCell>
                                    <TableCell align="left">
                                        BatchId
                                    </TableCell>
                                    <TableCell align="left">
                                        KilnId
                                    </TableCell>
                                    <TableCell align="left">
                                        Net Vol {measureType === 'Imperial' ? <> (yrd<sup>3</sup>)</> : <> (m<sup>3</sup>)</>}
                                    </TableCell>
                                    <TableCell align="left">
                                        BD {measureType === 'Imperial' ? <> (lbs/yrd<sup>3</sup>)</> : <> (kg/m<sup>3</sup>)</>}
                                    </TableCell>
                                    <TableCell align="left">
                                        Biochar Mass {measureType === 'Imperial' ? <>(lbs)</> : <>(kg)</>}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {biocharMass?.data?.map((data, i) => {
                                    const isLastIteration = i === biocharMass.length - 1;
                                    return (
                                        <TableRow key={i}>
                                            <TableCell align="left">
                                                {data.projectId}
                                            </TableCell>
                                            <TableCell align="left">
                                                {data.batchId}
                                            </TableCell>
                                            <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                {data.kilnUsed}
                                            </TableCell>
                                            <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                {data.netVolume}
                                            </TableCell>
                                            <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                {data.bulkDensity}
                                            </TableCell>
                                            <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                {data.biocharMass}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}><b>TOTAL</b></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}><b>{biocharMass.totals?.totalNetVolume}</b></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}><b>{biocharMass.totals?.totalBiocharMass}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}><b>AVERAGE</b></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}><b>{biocharMass.totals?.averageNetVolume}</b></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}><b>{biocharMass.totals?.averageBulkDensity}</b></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}><b>{biocharMass.totals?.averageBiocharMass}</b></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
            {active === 3 && (
                <Grid item xs={10} sx={{ p: 2 }}>

                    <TableContainer key={0} component={Paper} sx={{ height: 'auto', mt: 3 }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        ProjectId
                                    </TableCell>
                                    <TableCell align="left">
                                        BatchId
                                    </TableCell>
                                    <TableCell align="left">
                                        KilnId
                                    </TableCell>
                                    <TableCell align="left">
                                        CCi, k (%)
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {batchCarbonContentState?.map((data, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell align="left">
                                                {data.project}
                                            </TableCell>
                                            <TableCell align="left">
                                                {data.batch}
                                            </TableCell>
                                            <TableCell align="left">
                                                {data.kilnUsed}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    required={true}
                                                    fullWidth
                                                    name={'fcptb'}
                                                    // value={!!inlandData.average_value ? inlandData.average_value : ''}
                                                    value={data?.ccik ? data.ccik : null}
                                                    onChange={(e) => handleCcikInputChange(e, data.projectId, data.batchId, data.kilnUsed)}
                                                    type="number"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button variant="contained" sx={{ m: 2 }} onClick={handleSubmitBatchCarbonContent}>Submit Batch Carbon Content</Button>
                    <Typography variant='h6' component='p' sx={{ mt: 2 }}>
                        Please upload lab results:
                    </Typography>
                    <TextField
                        name={'Lab results'}
                        value={labReportFile ? labReportFile.name : ''}
                        onChange={(e) => handleLabResultsFile(e, projectKey)}
                        type='file'
                        inputRef={fileInputRef}
                    />
                    <Button variant="contained" sx={{ m: 2, display: 'block' }} onClick={handleLabResultsFileUpload}>Upload Lab Report File</Button>
                    {console.log(labReportFiles)}
                    <TableContainer key={0} component={Paper} sx={{ height: 'auto', mt: 3 }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">File Name</TableCell>
                                    <TableCell align="left">URL</TableCell>
                                    <TableCell align="left">Uploaded</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {labReportFiles?.data?.map((data, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell align="left">{data.fileName.split('/')[data.fileName.split('/').length - 1]}</TableCell>
                                            <TableCell align="left"><a href={data.fileUrl} about="_blank">{data.fileUrl}</a></TableCell>
                                            <TableCell align="left">{new Date(data.uploadedAt).toDateString()}</TableCell>
                                        </TableRow>
                                    )
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
            {active === 4 && (
                <Grid item xs={10} sx={{ p: 2 }}>

                    <TableContainer key={0} component={Paper} sx={{ height: 'auto', mt: 3 }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">BM,i,k</TableCell>
                                    <TableCell align="left">Cc,i,k</TableCell>
                                    <TableCell align="left">BSC (batch total)</TableCell>
                                    <TableCell align="left">PRde,b</TableCell>
                                    <TableCell align="left">BSC (100y permanence)</TableCell>
                                    <TableCell align="left">Unit</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {biocharStoredCarbonState.map((data, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell align="left">{data?.biocharMass}</TableCell>
                                            <TableCell align="left">
                                                {data.ccik ? data.ccik : <Typography sx={{ color: 'darkorange' }}>CC,i,k not defined</Typography>}
                                            </TableCell>
                                            <TableCell align="left">
                                                {data.bsc || <Typography sx={{ color: 'darkorange' }}>CC,i,k not defined</Typography>}
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormControl sx={{ width: '175px' }}>
                                                    <InputLabel sx={{ background: 'white', pl: 1, pr: 1 }}>Select unit type</InputLabel>
                                                    <Select
                                                        name='prdebOptions'
                                                        value={data?.prdeb ? data.prdeb : null}
                                                        onChange={(e) => handlePrdebOptionsChange(e, data.batchId, data.kilnUsed)}
                                                        disabled={data.ccik ? false : true}
                                                    >
                                                        {prdebOptions.map(option => (
                                                            <MenuItem key={option.id} value={option.value}>
                                                                {option.type}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left">
                                                {data.bscPermanence || <Typography sx={{ color: 'darkorange' }}>PRde,b not defined</Typography>}
                                            </TableCell>
                                            <TableCell align="left">kg CO2e</TableCell>
                                        </TableRow>
                                    );
                                })}
                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}><b>TOTAL</b></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}>{biocharStoredCarbon?.totals?.bscTotal}</TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}></TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}>{biocharStoredCarbon?.totals?.bscPermanenceTotal}</TableCell>
                                    <TableCell align="left" sx={{ border: '2px solid black' }}></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Alert severity='warning'>Please submit data if PRde,b is adjusted</Alert>
                    <Button variant="contained" sx={{ m: 2 }} onClick={handleSubmitBiocharStoredCarbon}>Submit Biochar Stored Carbon</Button>
                </Grid>
            )}
        </>
    )
}

export default RofDataTables