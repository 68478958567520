import {
  // Link,
  // Button as ScrollButton,
  // Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from 'react-scroll';
import React, { useState, useEffect } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Button,
  AppBar,
  Toolbar,
  Grid,
} from '@mui/material';
import Projects2 from './Projects2';
// import Registry from './Registry';
import Partners from './Partners'
import Pricing from './Pricing';
import Contact from './Contact';
import Introduction from './Introduction';
import About from './About';
import Team from './Team';
import Footer from './Footer';

import { useNavigate } from 'react-router-dom';
import Logo from '../../../assets/images/IkhalaLogo.svg';
import BuyAKiln from './BuyAKiln';

export default function LandingPage() {
  const [curTab, setCurTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setCurTab(newValue);
  };
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate('/login');
  };

  const handleRegistry = () => {
    navigate('/registry');
  };

  // useEffect is used to perform side effects in functional components.
  // Here, it's used to register scroll events and update scrollSpy when the component mounts.
  useEffect(() => {
    // Registering the 'begin' event and logging it to the console when triggered.
    Events.scrollEvent.register('begin', () => {
      // console.log('begin', );
    });

    // Registering the 'end' event and logging it to the console when triggered.
    Events.scrollEvent.register('end', () => {
      // console.log('end', );
    });

    // Updating scrollSpy when the component mounts.
    scrollSpy.update();

    // Returning a cleanup function to remove the registered events when the component unmounts.
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  const scrollToTop = () => {
    const options = {
      smooth: true,
      duration: 1250,
    };
    scroll.scrollToTop(options);
  };

  return (
    <Box className='bg' style={{ position: 'relative' }}>
      <AppBar position='absolute' sx={{ opacity: '0.93' }}>
        <Toolbar sx={{ alignItems: 'center', justifyContent: 'center' }}>
          <Box className='theBox' sx={{ display: 'flex', width: '100%' }}>
            <img
              src={Logo}
              alt='Ikhala Logo'
              width={80}
              height={80}
              style={{ position: 'absolute', left: '5px', top: '-8px', opacity: '0.7' }}
            />
            <Typography
              sx={{
                m: 1,
                fontWeight: 'bold',
                fontSize: 30,
                position: 'absolute',
                left: '55px',
                color: '#DA9DE3',
                opacity: '0.9'
              }}
              className='bg__heading'
            >
              Ikhala
            </Typography>
            <Tabs
              value={curTab}
              onChange={handleTabChange}
              style={{ marginBottom: 10 }}
              textColor='inherit'
              indicatorColor='secondary'
              className='theTabs'
              sx={{
                ml: 'auto',
                mr: 'auto',
                width: '100%',
                visibility: 'hidden',
              }}
            >
              <Tab label='About' />
              <Tab label='Projects' />
              <Tab label='Registry' />
              <Tab label='Pricing' />
              <Tab label='Contact' />
            </Tabs>
            <Button
              onClick={handleLogin}
              variant='contained'
              color='secondary'
              sx={{
                m: 1,
                position: 'absolute',
                right: '20px',
                color: '#DA9DE3',
                letterSpacing: '0.3px',
                fontWeight: 'bold',
                width: '95px',
                fontSize: '15px'
              }}
            >
              L<span style={{ textTransform: 'lowercase' }}>ogin</span>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Grid id='hero' item height={'100vh'}>
        <Box id='hero__content'>
          <Typography id='hero__content__heading'>
            Credible D-MMRV Policies for Impact Projects
          </Typography>
          <Typography id='hero__content__support-text'>
            Credible refers to the reliability, trustworthiness, and accuracy of
            information or actions. <br />In the context of impact projects,
            credibility encompasses various aspects, such as <br />lawfulness, legitimacy,
            and lasting positive change.
          </Typography>
        </Box>
      </Grid>
      <Introduction />

      <About />
      <Projects2 handleRegistry={handleRegistry} />
      <Pricing />
      <BuyAKiln />
      <Team scrollToTop={scrollToTop} />
      <Partners />
      <Contact />
      <Footer />
    </Box>
  );
}
