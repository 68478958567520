import {
  Paper,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box
} from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function FireBreakProofContract() {
  return (
    <Paper sx={{p:3, mt:2}}>
      <Typography variant="h4" sx={{mb:5}}>Fire Break Proof</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Accordion sx={{my: 2}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='data-managers-content'
              id='data-managers-header'
            >
              <Typography variant="h6">Data Managers</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{my:2}}>
                <Button variant="outlined" size="large">
                  Maintenance Contract
                </Button>
              </Box>
              <Box sx={{my:2}}>
                <Button variant="outlined" size="large">
                  Farm Manager
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{my: 2}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='verifiers-content'
              id='verifiers-header'
            >
              <Typography variant='h6'>Verifiers</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{my:2}}>
                <Button variant="outlined" size="large">
                  Fire Protection Association
                </Button>
              </Box>
              <Box sx={{my:2}}>
                <Button variant="outlined" size="large">
                  System Verification
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={6}>
          <Accordion sx={{my: 2}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='funders-content'
              id='funders-header'
            >
              <Typography variant='h6'>Funders</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{my:2}}>
                <Button variant="outlined" size="large">
                  Funder 1
                </Button>
              </Box>
              <Box sx={{my:2}}>
                <Button variant="outlined" size="large">
                  Funder 2
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{my: 2}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='neighboring-land-owners-content'
              id='neighboring-land-owners-header'
            >
              <Typography variant='h6'>Neighboring Land Owners</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{my:2}}>
                <Button variant="outlined" size="large">
                  Neighbor 1
                </Button>
              </Box>
              <Box sx={{my:2}}>
                <Button variant="outlined" size="large">
                  Neighbor 2
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Paper>
  )
}
