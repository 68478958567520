import React from "react";
import { Grid, FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, Typography } from "@mui/material";

const currencies = ['R-ZAR', '$-USD', '$-AUS'];
const measurementSystems = [
    {value: 'Metric', label: 'Metric - m3'},
    {value: 'Imperial', label: 'Imperial - yrd3'}
];

const Step1Form = ({
    currency,
    setCurrency,
    measurementSystem,
    setMeasurementSystem,
  }) => {
    const handleChange = (setter) => ({ target: { value } }) => {
      setter(value);
    };
  
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Choose Currency and Measurement System
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Currency</FormLabel>
            <RadioGroup
              label="currency"
              name="currency"
              value={currency}
              onChange={handleChange(setCurrency)}
            >
              {currencies.map((curr) => 
                <FormControlLabel key={curr} value={curr} control={<Radio />} label={curr} />
              )}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Measurement System</FormLabel>
            <RadioGroup
              label="measurement system"
              name="measurement-system"
              value={measurementSystem}
              onChange={handleChange(setMeasurementSystem)}
            >
              {measurementSystems.map(({value, label}) => 
                <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
              )}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    );
  };

export default Step1Form;
