import React from 'react';
import { Typography, Box } from '@mui/material';

const ContractorOutput = ({ bookedDays }) => {
  // const kilns = 4;

  const biocharVolumePerDay = 12.8;
  const biocharWeightPerDay = 1920;
  const biocharCarbonRemovalPerDay = 3.59;
  const feedstockProcessedPerDay = 9.6;

  const totalBiocharVolume = biocharVolumePerDay * bookedDays;
  const totalBiocharWeight = biocharWeightPerDay * bookedDays;
  const totalBiocharCarbonRemoval = biocharCarbonRemovalPerDay * bookedDays;
  const totalFeedstockProcessed = feedstockProcessedPerDay * bookedDays;

  return (
    <Box>
      <Typography variant='body1'>Estimated Output for {bookedDays} days:</Typography>
      <Typography variant='body1'>Total Volume of Biochar: {totalBiocharVolume} m3</Typography>
      <Typography variant='body1'>Total Weight of Biochar: {totalBiocharWeight} kg</Typography>
      <Typography variant='body1'>Total Biochar Carbon Removal: {totalBiocharCarbonRemoval} ton CO2e</Typography>
      <Typography variant='body1'>Total Feedstock Processed: {totalFeedstockProcessed} ton</Typography>
    </Box>
  );
};

export default ContractorOutput;
