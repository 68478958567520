import { Box, Button, Grid } from '@mui/material';
import { useState } from 'react';
import CapitalGAndSDataCapturing from './CapitalGAndSDataCapturing';

export default function PurchasedGoodsAndServices() {
    const [selected, setSelected] = useState('Capital Goods & Services')
    let categories = ['Capital Goods & Services']

    const outputComponent = () => {
        if (selected === 'Capital Goods & Services') {
            return <CapitalGAndSDataCapturing />
        } else {
            return null
        }
    }

    return (
        <>
            <Grid item xs={3}>
                <Box marginTop={2}>
                    {categories.map((c, i) => {
                        return (
                            <Button
                                key={i}
                                variant={c === selected ? 'contained' : 'outlined'}
                                size='large'
                                sx={{ width: '100%', display: 'block', marginBottom: 2.5 }}
                                onClick={() => {
                                    setSelected(c)
                                    localStorage.setItem('catFour', c)
                                }}
                            >
                                {c}
                            </Button>
                        )
                    })
                    }
                </Box>
            </Grid>
            <Grid item xs={9}>
                {outputComponent()}
            </Grid>
        </>
    );
}
