import { useEffect } from 'react';
// import FormData from '../../../../admin/screens/FormData';
import {
  Grid,
  Button
} from '@mui/material';
// import MediaFeature from './types/MediaFeature';
// import BatchData from './types/BatchData';
import RofBatchData from './types/RofBatchData';
import RofMedia from './types/RofMedia';


export default function Implementation({ totalItems, next, active, projectKey, measureType }) {


  const items = [
    {
      id: 0,
      name: 'Batch Data',
    },
    {
      id: 1,
      name: 'Batch Media',
    }
    // ,
    // {
    //   id: 2,
    //   name: 'Raw Data',
    // }
  ];


  useEffect(() => {
    totalItems(items.length);
  }, [items.length, totalItems]);

  return (
    <Grid container>
      <Grid item xs={3} sx={{ borderRight: '1px solid', p: 2 }}>
        {items.map(item => (
          <Button
            fullWidth
            key={item.id}
            onClick={() => next(item.id)}
            variant={active === item.id ? 'outlined' : null}
          >
            {item.name}
          </Button>
        ))}
      </Grid>

      <Grid item xs={9} sx={{ p: 2 }}>
        {active === 0 ? (
          <>
            {/* <BatchData projectKey={projectKey} measureType={measureType} /> */}
            <RofBatchData projectKey={projectKey} measureType={measureType} />
            {/* <AdditionalBatchData projectKey={projectKey} /> */}
          </>
        ) : active === 1 ? (
          <RofMedia projectKey={projectKey} />
          // <MediaFeature projectKey={projectKey} />
        ) :
          // active === 2 ?
          //   <FormData projectKey={projectKey} /> :
          null
        }
      </Grid>
    </Grid>
  );

}


