import React, { useState } from 'react';
import { Grid, Tabs, Tab } from '@mui/material';
import { BatchReports } from './types/reports/BatchReports';

export const Reports = ({ next, totalItems, active, projectId, projectKey }) => {
    // const items = [
    //     {
    //         id: 0,
    //         name: 'Batch Data',
    //     },
    //     {
    //         id: 1,
    //         name: 'Batch Media',
    //     },
    //     {
    //         id: 2,
    //         name: 'Raw Data',
    //     },
    // ];

    const [value, setValue] = useState(0);
    const handleChange = (event, index) => {
        setValue(index);
    };



    return (
        <Grid container>
            <Grid item sx={{ mb: 2, display: 'flex', width: '100%' }}>
                <Tabs value={value} onChange={handleChange} style={{ marginBottom: 10 }}>
                    <Tab label="Batch" />
                    <Tab disabled label="Month" />
                    <Tab disabled label="Project" />
                    <Tab disabled label="BCR" />
                    <Tab disabled label="ISO" />
                </Tabs>
            </Grid>
            <BatchReports next={next} totalItems={totalItems} active={active} projectId={projectId} projectKey={projectKey} />
        </Grid>
    )
}
