import { useState } from 'react';
import {
  Paper,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Divider,
  Alert,
} from '@mui/material';
import FireBreakProofForm from './apply/FireBreakProofForm';
import HedgeForm from './apply/HedgeForm';
import CBRForm from './apply/CBRForm';
import MobGrazingForm from './apply/MobGrazingForm';

export default function Apply() {
  const [radio, setRadio] = useState('fbp');

  return (
    <>
      <Alert severity='error' sx={{ mb: 2 }}>
        Forms on this page are not yet connected to a database
      </Alert>
      <Alert severity='info' sx={{ mb: 2 }}>
        This page is currently a mockup and not functional
      </Alert>
      <Paper elevation={5} sx={{ p: 2 }}>
        <Typography variant='h2'>Apply to a Program</Typography>
        <Divider sx={{ my: 2 }} />
        <FormControl>
          <FormLabel id='demo-radio-buttons-group-label'>
            Choose Programme
          </FormLabel>
          <RadioGroup
            aria-labelledby='demo-radio-buttons-group-label'
            defaultValue='fbp'
            name='radio-buttons-group'
            onChange={e => setRadio(e.target.value)}
          >
            <FormControlLabel
              name='fbp'
              value='fbp'
              control={<Radio />}
              label='Veldfire Management'
            />
            {/* <FormControlLabel
                            name="hedge"
                            value="hedge"
                            control={<Radio />}
                            label="Hedge"
                        /> */}
            <FormControlLabel
              name='cbr'
              value='cbr'
              control={<Radio />}
              label='Biochar Production'
            />
            {/* <FormControlLabel
                            name="grazing"
                            value="grazing"
                            control={<Radio />}
                            label="Mob-Grazing"
                        /> */}
          </RadioGroup>
        </FormControl>
      </Paper>
      <Paper elevation={5} sx={{ p: 2, mt: 2 }}>
        {radio === 'fbp' ? (
          <FireBreakProofForm />
        ) : radio === 'hedge' ? (
          <HedgeForm />
        ) : radio === 'cbr' ? (
          <CBRForm />
        ) : (
          <MobGrazingForm />
        )}
      </Paper>
    </>
  );
}
