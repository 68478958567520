export const GET_ROF_BURNS_BATCH_DATA_SUCCESS = 'GET_ROF_BURNS_BATCH_DATA_SUCCESS';
export const GET_ROF_BURNS_BATCH_DATA_FAIL = 'GET_ROF_BURNS_BATCH_DATA_FAIL';

export const GET_ROF_BURNS_BATCH_MEDIA_SUCCESS = 'GET_ROF_BURNS_BATCH_MEDIA_SUCCESS';
export const GET_ROF_BURNS_BATCH_MEDIA_FAIL = 'GET_ROF_BURNS_BATCH_MEDIA_FAIL';

export const GET_ROF_BURNS_ADDITIONAL_BATCH_DATA_SUCCESS = 'GET_ROF_BURNS_ADDITIONAL_BATCH_DATA_SUCCESS';
export const GET_ROF_BURNS_ADDITIONAL_BATCH_DATA_FAIL = 'GET_ROF_BURNS_ADDITIONAL_BATCH_DATA_FAIL';

export const GET_ROF_BURNS_BATCH_VOLUMES_SUCCESS = 'GET_ROF_BURNS_BATCH_VOLUMES_SUCCESS';
export const GET_ROF_BURNS_BATCH_VOLUMES_FAIL = 'GET_ROF_BURNS_BATCH_VOLUMES_FAIL';

export const GET_ROF_BURNS_BATCH_BULK_DENSITY_SUCCESS = 'GET_ROF_BURNS_BATCH_BULK_DENSITY_SUCCESS';
export const GET_ROF_BURNS_BATCH_BULK_DENSITY_FAIL = 'GET_ROF_BURNS_BATCH_BULK_DENSITY_FAIL';

export const GET_ROF_BURNS_BIOCHAR_MASS_SUCCESS = 'GET_ROF_BURNS_BIOCHAR_MASS_SUCCESS';
export const GET_ROF_BURNS_BIOCHAR_MASS_FAIL = 'GET_ROF_BURNS_BIOCHAR_MASS_FAIL';

export const GET_ROF_BURNS_BATCH_CARBON_CONTENT_SUCCESS = 'GET_ROF_BURNS_BATCH_CARBON_CONTENT_SUCCESS';
export const GET_ROF_BURNS_BATCH_CARBON_CONTENT_FAIL = 'GET_ROF_BURNS_BATCH_CARBON_CONTENT_FAIL';

export const GET_ROF_BURNS_BIOCHAR_STORED_CARBON_SUCCESS = 'GET_ROF_BURNS_BIOCHAR_STORED_CARBON_SUCCESS';
export const GET_ROF_BURNS_BIOCHAR_STORED_CARBON_FAIL = 'GET_ROF_BURNS_BIOCHAR_STORED_CARBON_FAIL';

export const POST_ROF_BURNS_BATCH_CCIK_SUCCESS = 'POST_ROF_BURNS_BATCH_CCIK_SUCCESS';
export const POST_ROF_BURNS_BATCH_CCIK_FAIL = 'POST_ROF_BURNS_BATCH_CCIK_FAIL';

export const POST_ROF_BURNS_BIOCHAR_STORED_CARBON_SUCCESS = 'POST_ROF_BURNS_BIOCHAR_STORED_CARBON_SUCCESS';
export const POST_ROF_BURNS_BIOCHAR_STORED_CARBON_FAIL = 'POST_ROF_BURNS_BIOCHAR_STORED_CARBON_FAIL';

export const POST_ROF_BURNS_LAB_REPORT_FILE_SUCCESS = 'POST_ROF_BURNS_LAB_REPORT_FILE_SUCCESS';
export const POST_ROF_BURNS_LAB_REPORT_FILE_FAIL = 'POST_ROF_BURNS_LAB_REPORT_FILE_FAIL';

export const GET_ROF_BURNS_LAB_REPORT_FILES_SUCCESS = 'GET_ROF_BURNS_LAB_REPORT_FILES_SUCCESS';
export const GET_ROF_BURNS_LAB_REPORT_FILES_FAIL = 'GET_ROF_BURNS_LAB_REPORT_FILES_FAIL';

export const POST_BASE_LOG_KILN_SUCCESS = 'POST_BASE_LOG_KILN_SUCCESS';
export const POST_BASE_LOG_KILN_FAIL = 'POST_BASE_LOG_KILN_FAIL';

export const GET_BASE_LOG_KILNS_SUCCESS = 'GET_BASE_LOG_KILNS_SUCCESS';
export const GET_BASE_LOG_KILNS_FAIL = 'GET_BASE_LOG_KILNS_FAIL';

export const GET_PROJECT_BATCHES_SUCCESS = 'GET_PROJECT_BATCHES_SUCCESS';
export const GET_PROJECT_BATCHES_FAIL = 'GET_PROJECT_BATCHES_FAIL';

export const GET_DOWNLOAD_BATCH_REPORT_SUCCESS = 'GET_DOWNLOAD_BATCH_REPORT_SUCCESS';
export const GET_DOWNLOAD_BATCH_REPORT_FAIL = 'GET_DOWNLOAD_BATCH_REPORT_FAIL';