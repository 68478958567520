import { Typography, Divider, Button, Box } from '@mui/material';

export default function EcologicalAttributes() {
  return (
    <Box>
      <Typography variant='h3'>Ecological Attributes</Typography>
      <Divider sx={{ my: 1 }} />
      <Typography variant='h6'>Structure</Typography>
      <Typography>
        Ecosystem structure is a network of interactions between abiotic and
        biotic components of the system. The biotic structure of the ecosystem
        is characterized by the composition of the biological community
        including species numbers, biomass, life-form, life-history and spatial
        distribution of species.
      </Typography>
      <Typography variant='h6' sx={{ mt: 5 }}>
        Functions
      </Typography>
      <Typography>
        List of field notes submitted via the Ikhala Impact Platform App <br />
        - Note 1 <br />
        - Note 2
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Button variant='outlined' size='large'>Add Field Notes</Button>
    </Box>
  );
}
