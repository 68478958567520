import { Box, Button, Grid, } from '@mui/material';
import { useState } from 'react'
import LPGDataCapturing from './LPGDataCapturing';

export default function LPG() {
    const [selected, setSelected] = useState('LPG')
    const lpgCategories = ['LPG']

    return (
        <>
            <Grid item xs={3}>
                <Box marginTop={2}>
                    {lpgCategories.map((c, i) => {
                        return (
                            <Button
                                key={i}
                                variant={c === selected ? 'contained' : 'outlined'}
                                size='large'
                                sx={{ width: '100%', display: 'block', mb: 2.5 }}
                                onClick={() => {
                                    setSelected(c)
                                    localStorage.setItem('catOne', c)
                                }}
                            >
                                {c}
                            </Button>
                        )
                    })
                    }
                </Box>
            </Grid>
            <Grid item xs={9}>
                <LPGDataCapturing />
            </Grid>
        </>
    );
}

/*
Select Category 1->
Select LPG->
Select month->
Select unit from drop down list (rand or kilograms)->
Manually Enter LPG consumption for the selected selected month with the corresponding unit->Upload corresponding source documentation

Users should be able to see the annual sum table for corresponding line items in the TOTALS tab (i.e. 12 month total).
*/
