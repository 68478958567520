import { setAlert } from '../../alert';
import {
    API_POST_RENEWABLE_ENERGY_DATA,
    API_GET_RENEWABLE_ENERGY_DATA,
    API_GET_RENEWABLE_ENERGY_DATA_TOTALS,
} from '../constants/02-indirect-ghg-emissions-from-imported-energy';

import {
    POST_RENEWABLE_ENERGY_DATA_SUCCESS,
    POST_RENEWABLE_ENERGY_DATA_FAIL,
    GET_RENEWABLE_ENERGY_DATA_SUCCESS,
    GET_RENEWABLE_ENERGY_DATA_FAIL,
    GET_RENEWABLE_ENERGY_DATA_TOTALS_SUCCESS,
    GET_RENEWABLE_ENERGY_DATA_TOTALS_FAIL,
} from '../types/02-indirect-ghg-emissions-from-imported-energy-types';

import axios from 'axios';

const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    },
    // withCredentials: true,
};


export const postRenewableEnergyData = (formData, year, company, group, facility) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_RENEWABLE_ENERGY_DATA + `/${year}/${company}/${group}/${facility}`,
            formData,
            config
        );
        dispatch({
            type: POST_RENEWABLE_ENERGY_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_RENEWABLE_ENERGY_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getRenewableEnergyData = (year, facility) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_RENEWABLE_ENERGY_DATA + `/${year}/${facility}`,
            config
        );
        dispatch({
            type: GET_RENEWABLE_ENERGY_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_RENEWABLE_ENERGY_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getRenewableEnergyDataTotals = (year, facility) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_RENEWABLE_ENERGY_DATA_TOTALS + `/${year}/${facility}`,
            config
        );
        dispatch({
            type: GET_RENEWABLE_ENERGY_DATA_TOTALS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_RENEWABLE_ENERGY_DATA_TOTALS_FAIL,
            payload: err.response.data.detail,
        });
    }
};
