export const API_POST_TIGERCAT_BURNS_CREATE_CAR_RPPB = 'api/tigercat-burns/create-car-rppb';
export const API_GET_TIGERCAT_BURNS_GET_COMPANY_USER_CAR_RPPBS = 'api/tigercat-burns/get-company-user-car-rppbs';
export const API_POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_PROJECT = 'api/tigercat-burns/create-car-rppb-project';
export const API_GET_TIGERCAT_BURNS_GET_CAR_RPPB_PROJECTS = 'api/tigercat-burns/get-car-rppb-projects';
export const API_GET_TIGERCAT_BURNS_GET_USERS = 'api/user/tigercat/tigercat-users';
// CREATE & FETCH BINS
export const API_POST_TIGERCAT_BURNS_CREATE_BIOCHAR_BINS = 'api/tigercat-burns/create-biochar-bin';
export const API_GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_BINS = 'api/tigercat-burns/get-company-biochar-bins';
// CREATE & FETCH EQUIPMENT
export const API_POST_TIGERCAT_BURNS_CREATE_BIOCHAR_EQUIPMENT = 'api/tigercat-burns/create-biochar-equipment';
export const API_GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_EQUIPMENT = 'api/tigercat-burns/get-company-biochar-equipment';
// GET USER PROJECTS
export const API_GET_TIGERCAT_BURNS_GET_PROJECTS_FOR_USER = 'api/tigercat-burns/get-projects-for-company';
// ASSIGN ASSET TO PROJECT
export const API_PATCH_TIGERCAT_BURNS_ASSIGN_ASSET_TO_PROJECT = 'api/tigercat-burns/assign-asset-to-project'
export const API_PATCH_TIGERCAT_BURNS_UNASSIGN_ASSET_FROM_PROJECT = 'api/tigercat-burns/unassign-asset-from-project'

// GET PROJECT ASSIGNED BINS
export const API_GET_TIGERCAT_BURNS_GET_ALL_PROJECTS_WITH_ASSIGNED_ASSETS = 'api/tigercat-burns/get-all-projects-with-assigned-assets';
