import {
    GET_BATCH_VOLUMES_SUCCESS,
    GET_BATCH_VOLUMES_FAIL,
    GET_BATCH_BULK_DENSITY_SUCCESS,
    GET_BATCH_BULK_DENSITY_FAIL,
    GET_VOL_AND_BD_AVERAGE_SUCCESS,
    GET_VOL_AND_BD_AVERAGE_FAIL,
    GET_BIOCHAR_MASS_SUCCESS,
    GET_BIOCHAR_MASS_FAIL,
    GET_BATCH_CARBON_CONTENT_SUCCESS,
    GET_BATCH_CARBON_CONTENT_FAIL,
    GET_BIOCHAR_STORED_CARBON_SUCCESS,
    GET_BIOCHAR_STORED_CARBON_FAIL,
    POST_VOL_AND_BD_AVERAGE_SUCCESS,
    POST_VOL_AND_BD_AVERAGE_FAIL,
    POST_BATCH_CARBON_CONTENT_SUCCESS,
    POST_BATCH_CARBON_CONTENT_FAIL,
    POST_BIOCHAR_STORED_CARBON_SUCCESS,
    POST_BIOCHAR_STORED_CARBON_FAIL,
    GET_CB_BATCH_DATA_SUCCESS,
    GET_CB_BATCH_DATA_FAIL,
    GET_CB_ADDITIONAL_BATCH_DATA_SUCCESS,
    GET_CB_ADDITIONAL_BATCH_DATA_FAIL
} from '../../actions/conservation-burns/types/conservation-burn-types';

const initialState = {
    loading: true,
    conversionData: [],
    batchVolumes: [],
    bulkDensity: [],
    volAndBdAverage: [],
    biocharMass: [],
    batchCarbonContent: [],
    biocharStoredCarbon: [],
    batchData: [],
    additionalBatchData: []
};

export default function conservationBurnsReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_CB_BATCH_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                batchData: payload
            };
        case GET_CB_BATCH_DATA_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_CB_ADDITIONAL_BATCH_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                additionalBatchData: payload
            };
        case GET_CB_ADDITIONAL_BATCH_DATA_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_BATCH_VOLUMES_SUCCESS:
            return {
                ...state,
                loading: false,
                batchVolumes: payload
            };
        case GET_BATCH_VOLUMES_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_BATCH_BULK_DENSITY_SUCCESS:
            return {
                ...state,
                loading: false,
                bulkDensity: payload
            };
        case GET_BATCH_BULK_DENSITY_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_VOL_AND_BD_AVERAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                volAndBdAverage: payload
            };
        case GET_VOL_AND_BD_AVERAGE_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_BIOCHAR_MASS_SUCCESS:
            return {
                ...state,
                loading: false,
                biocharMass: payload
            };
        case GET_BIOCHAR_MASS_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_BATCH_CARBON_CONTENT_SUCCESS:
            return {
                ...state,
                loading: false,
                batchCarbonContent: payload
            };
        case GET_BATCH_CARBON_CONTENT_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_BIOCHAR_STORED_CARBON_SUCCESS:
            return {
                ...state,
                loading: false,
                biocharStoredCarbon: payload
            };
        case GET_BIOCHAR_STORED_CARBON_FAIL:
            return {
                ...state,
                loading: false
            };
        case POST_VOL_AND_BD_AVERAGE_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case POST_VOL_AND_BD_AVERAGE_FAIL:
            return {
                ...state,
                loading: false
            };
        case POST_BATCH_CARBON_CONTENT_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case POST_BATCH_CARBON_CONTENT_FAIL:
            return {
                ...state,
                loading: false
            };
        case POST_BIOCHAR_STORED_CARBON_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case POST_BIOCHAR_STORED_CARBON_FAIL:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}