import {
    Box, Button, Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell, Tabs, Tab, TextField
} from '@mui/material';
import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConversions, postConversions } from '../../../actions/carbonfootprint-rework/00-base/00-base-actions';

export function ConversionTable() {
    const [month] = useState(0);
    const [convData, setConvData] = useState([])
    const dispatch = useDispatch();
    const conversionData = useSelector((state) => state.carbonFootprintBase.conversionData.data)

    const year = localStorage.getItem('year')
    const company = localStorage.getItem('company')

    useEffect(() => {
        dispatch(getConversions(year, company))
    }, [year, company, dispatch])

    useEffect(() => {
        if (conversionData && conversionData?.length > 0)
            setConvData([...conversionData])
    }, [conversionData])

    // const handleMonthChange = async (event, newValue) => {
    //     console.log(newValue)
    //     setMonth(newValue);
    //     dispatch(getConversions(year, company))
    // };

    const groupedData = convData?.reduce((acc, d) => {
        if (d.variable === 'Diesel Rand-to-Liters') {
            if (!acc[d.variable]) {
                acc[d.variable] = { 'Coastal': null, 'Inland': null };
            }
            acc[d.variable][d.type] = d;
        }
        if (d.variable === 'Petrol Rand-to-Liters') {
            if (!acc[d.variable]) {
                acc[d.variable] = { 'Coastal': null, 'Inland': null };
            }
            acc[d.variable][d.type] = d;
        }
        if (d.variable === 'LPG Rand-to-KG') {
            if (!acc[d.variable]) {
                acc[d.variable] = { 'Coastal': null, 'Inland': null };
            }
            acc[d.variable][d.type] = d;
        }
        return acc;
    }, {});

    const handleInputChange = (e, variable, type,) => {
        setConvData((prevRows) => {
            return prevRows.map((row) => {
                if (row.variable === variable && row.type === type) {
                    return { ...row, average_value: e.target.value }
                }
                return { ...row }
            })
        })
    }

    const handleConversionDataSubmission = () => {
        dispatch(postConversions(convData, year, company))
    }

    return (
        <Box mt='2.5' sx={{ width: '100%' }}>
            <Tabs
                value={month}
                onChange={() => null}
                sx={{ float: 'left' }}
                textColor='inherit'
                indicatorColor='secondary'
            >
                {[year].reverse().map((m, i) => {
                    return (
                        <Tab key={i} label={m} sx={{ minWidth: '25px' }} />
                    )
                })}
            </Tabs>
            <TableContainer component={Paper} sx={{ height: 'auto', width: '100%' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                            </TableCell>
                            <TableCell align="left" sx={{ background: '#535988', color: '#fff' }}>
                                Coastal
                            </TableCell>
                            <TableCell align="left" sx={{ background: '#535988' }}>
                            </TableCell>
                            <TableCell align="left" sx={{ background: '#535988' }}>
                            </TableCell>
                            <TableCell align="left" sx={{ background: '#535150', color: '#fff' }}>
                                Inland
                            </TableCell>
                            <TableCell align="left" sx={{ background: '#535150' }}>
                            </TableCell>
                            <TableCell align="left" sx={{ background: '#535150' }}>
                            </TableCell>
                            <TableCell align="left">
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">
                                Variable
                            </TableCell>
                            <TableCell align="left">
                                Average Value
                            </TableCell>
                            <TableCell align="left">
                                Unit
                            </TableCell>
                            <TableCell align="left">
                                Facilities Included
                            </TableCell>
                            <TableCell align="left">
                                Average Value
                            </TableCell>
                            <TableCell align="left">
                                Unit
                            </TableCell>
                            <TableCell align="left">
                                Facilities Included
                            </TableCell>
                            <TableCell align="left">
                                Default Value
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {groupedData && Object.entries(groupedData)?.map(([variable, types], i) => {
                            const coastalData = types['Coastal'];
                            const inlandData = types['Inland'];
                            return (
                                <TableRow key={i}>
                                    <TableCell align="left">{variable}</TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            required={true}
                                            fullWidth
                                            name={'averageValue'}
                                            value={!!coastalData.average_value ? coastalData.average_value : ''}
                                            onChange={(e) => handleInputChange(e, variable, 'Coastal')}
                                            type="text"
                                        />
                                    </TableCell>
                                    <TableCell align="left">{coastalData ? coastalData.unit : ''}</TableCell>
                                    <TableCell align="left">
                                        {coastalData ? coastalData.facility.map((f, index) =>
                                            <Fragment key={index}>{f}<br /></Fragment>
                                        ) : ''}
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            required={true}
                                            fullWidth
                                            name={'average_value'}
                                            value={!!inlandData.average_value ? inlandData.average_value : ''}
                                            onChange={(e) => handleInputChange(e, variable, 'Inland')}
                                            type="text"
                                        />

                                    </TableCell>
                                    <TableCell align="left">{inlandData ? inlandData.unit : ''}</TableCell>
                                    <TableCell align="left">
                                        {inlandData ? inlandData.facility.map((f, index) =>
                                            <Fragment key={index}>{f}<br /></Fragment>
                                        ) : ''}
                                    </TableCell>
                                    <TableCell align="left">{inlandData ? inlandData.default : ''}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button variant="contained" sx={{ m: 2 }} onClick={handleConversionDataSubmission}>Submit conversion factors</Button>
        </Box>
    )
}
