import {
    POST_CONVERSIONS_SUCCESS,
    POST_CONVERSIONS_FAIL,
    GET_CONVERSIONS_SUCCESS,
    GET_CONVERSIONS_FAIL
} from '../../../actions/carbonfootprint-rework/types/00-base-types';


const initialState = {
    loading: true,
    conversionData: []
};


export default function
    carbonFootprintBase(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        case POST_CONVERSIONS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_CONVERSIONS_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_CONVERSIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                conversionData: payload
            };
        case GET_CONVERSIONS_FAIL:
            return {
                ...state,
                loading: false,
                conversionData: payload
            }
        default:
            return state;
    }
}


