import { useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import F018AccessChecklist from './types/F018AccessChecklist';
import F019FirefightingEquipment from './types/F019FirefightingEquipment';

export default function FireFighting({
  dataF018,
  dataF019,
  setData,
  setFormId,
  next,
  active,
  totalItems,
}) {
  const items = [
    {
      id: 0,
      name: 'Access Checklist',
    },
    {
      id: 1,
      name: 'Firefighting Equipment Checklist',
    },
  ];

  useEffect(() => {
    totalItems(items.length);
  }, [items.length, totalItems]);

  return (
    <Grid container>
      <Grid xs={3} sx={{ borderRight: '1px solid', p: 2 }}>
        {items.map(item => (
          <Button
            fullWidth
            key={item.id}
            onClick={() => next(item.id, items.length)}
            variant={active === item.id ? 'outlined' : null}
          >
            {item.name}
          </Button>
        ))}
      </Grid>
      <Grid xs={9} sx={{ p: 2 }}>
        {active === 0 ? (
          <F018AccessChecklist
            data={dataF018}
            setData={setData}
            setFormId={setFormId}
          />
        ) : active === 1 ? (
          <F019FirefightingEquipment
            data={dataF019}
            setData={setData}
            setFormId={setFormId}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}
