import { setAlert } from '../../alert';
import {
  API_POST_ADD_ASSET,
  API_GET_ASSETS,
} from '../constants/01-direct-ghg-emissions-and-removals-constants';
import {
  ADD_ASSET_SUCCESS,
  ADD_ASSET_FAIL,
  GET_ASSETS_SUCCESS,
  GET_ASSETS_FAIL
} from '../types/01-direct-ghg-emissions-and-removals-types';

import axios from 'axios';
const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
  },
  // withCredentials: true,
};

export const addAsset = (formData, year, company, group) => async dispatch => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + API_POST_ADD_ASSET + `/${year}/${company}/${group}`,
      formData,
      config
    );
    dispatch({
      type: ADD_ASSET_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: ADD_ASSET_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const getAssets = (group) => async dispatch => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + API_GET_ASSETS + `/${group}`,
      config
    );
    dispatch({
      type: GET_ASSETS_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: GET_ASSETS_FAIL,
      payload: err.response.data.detail,
    });
  }
};