import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { register } from '../../actions/auth';
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Alert,
} from '@mui/material';
import AdditionalInfo from '../sections/register/AdditionalInfo';
import { useNavigate } from 'react-router-dom';

const Register = ({ register }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    role: '',
    farmName: '',
    farmManagerEmail: '',
    landownerName: '',
    landownerSurname: '',
    landownerEmail: '',
    companyName: '',
    siteManagerName: '',
    siteManagerPhone: '',
    emissionScope: ''
  });
  const [passErr, setPassErr] = useState(false);
  const [registrationType, setRegistrationType] = useState({ bioChar: false, carbonFootprint: false })

  const { name, email, password, password_confirmation } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async e => {
    e.preventDefault();
    if (password !== password_confirmation) {
      setPassErr(true);
    } else {
      register({ ...formData, username: email }).then(() => {
        navigate('/programs')
      })
    }
  }
  const userRoles = [
    {
      id: 1,
      name: 'Land Owner',
      role: 'landowners',
    },
    {
      id: 2,
      name: 'Farm Manager',
      role: 'farm_managers',
    },
    {
      id: 3,
      name: 'Contractor',
      role: 'contractors',
    },
  ];

  const fields = [
    {
      id: 0,
      name: 'name',
      type: 'text',
      value: name,
    },
    {
      id: 1,
      name: 'email',
      type: 'email',
      value: email,
    },
    {
      id: 2,
      name: 'password',
      type: 'password',
      value: password,
    },
    {
      id: 3,
      name: 'password_confirmation',
      type: 'password',
      value: password_confirmation,
    },
  ];

  const handleBackAction = () => {
    setRegistrationType({ carbonFootprint: false, bioChar: false })
    setFormData({
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      role: '',
      farmName: '',
      farmManagerEmail: '',
      landownerName: '',
      landownerSurname: '',
      landownerEmail: '',
      companyName: '',
      siteManagerName: '',
      siteManagerPhone: '',
    })
  }

  const handleCarbonFootprintRegistration = () => {
    setRegistrationType({ bioChar: false, carbonFootprint: true })
    setFormData({ ...formData, role: 'carbonFootprint' })
  }

  return (
    <>
      <Grid container sx={{ background: '#d7ccff', height: '100vh' }}>
        <Grid item xs={6} sx={{ background: '#d7ccff' }}>
          <Box
            component='form'
            onSubmit={handleSubmit}
            sx={{
              height: 1,
              pl: 30,
              pr: 30,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography variant='h3' color={'#212746'}>
              {registrationType.bioChar && <>Biochar Registration</>}
              {registrationType.carbonFootprint && <>Carbon Footprint Registration</>}
              {(!registrationType.bioChar && !registrationType.carbonFootprint) && <>Register</>}
            </Typography>
            {(!registrationType.carbonFootprint && !registrationType.bioChar) &&
              <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2 }}>
                <Button
                  variant='contained'
                  size='large'
                  sx={{ mt: 2 }}
                  type='button'
                  fullWidth
                  onClick={() => setRegistrationType({ carbonFootprint: false, bioChar: true })}
                >
                  Register as Bio Char user
                </Button>
                <Button
                  variant='contained'
                  size='large'
                  sx={{ mt: 2 }}
                  type='button'
                  fullWidth
                  onClick={handleCarbonFootprintRegistration}
                >
                  Register as Carbon Footprint user
                </Button>
              </Box>}
            {(registrationType.carbonFootprint || registrationType.bioChar) &&
              <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2 }}>
                <Button
                  variant='contained'
                  size='large'
                  sx={{ mt: 2 }}
                  type='button'
                  fullWidth
                  onClick={handleBackAction}
                >
                  Back
                </Button>
              </Box>}
            {passErr && (
              <Alert severity='error' sx={{ my: 3 }}>
                Passwords Do Not Match
              </Alert>
            )}
            {(registrationType.carbonFootprint || registrationType.bioChar) &&
              <>
                {fields.map(field => {
                  return (
                    <Box component='div' sx={{ mt: 2 }} key={field.id}>
                      <TextField
                        label={field.name}
                        name={field.name}
                        value={field.value}
                        type={field.type}
                        onChange={onChange}
                        required
                        fullWidth
                        sx={{ background: '#fff' }}
                      />
                    </Box>
                  );
                })}
                {(registrationType.bioChar) &&
                  <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2 }}>
                    <Typography>What type of Biochar user are you? *</Typography>
                    <RadioGroup name='stakeholders' onChange={onChange} required>
                      {userRoles.map(role => {
                        return (
                          <FormControlLabel
                            key={role.id}
                            value={role.role}
                            control={<Radio required />}
                            label={role.name}
                            name='role'
                          />
                        );
                      })}
                    </RadioGroup>
                  </Box>}
                <Divider />
                <AdditionalInfo formData={formData} onChange={onChange} />
                <Divider />

                <Button
                  variant='contained'
                  size='large'
                  sx={{ mt: 2 }}
                  type='submit'
                  fullWidth
                >
                  Register
                </Button>
                <Box sx={{ my: 2 }}>
                  <Button href='/login' fullWidth>
                    Already have an account? Login
                  </Button>
                </Box>
              </>}
          </Box>

        </Grid>
        <Grid item xs={6} sx={{ background: '#d7ccff' }}>
          <img src='ikhala_logo.png' width='100%' alt='ikhala logo' />
        </Grid>
        {/* <Box sx={{ height: 1, background: '#212746', color: '#fff', p: 8 }}>
          <Typography>&copy; 2022 - Ikhala Platform</Typography>
        </Box> */}
      </Grid>
    </>
  );
};

Register.propTypes = {
  register: PropTypes.func.isRequired,
};

export default connect(null, { register })(Register);
