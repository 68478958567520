import { useEffect } from 'react';
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { getFirebreaks } from '../../../../../../actions/forms';
import { connect } from 'react-redux';

const F012NeighbourAgreements = ({
  data,
  setData,
  setFormId,
  fetchFirebreaks,
  firebreaks,
}) => {
  useEffect(() => {
    setFormId('F012');
    fetchFirebreaks();
  }, [setFormId, fetchFirebreaks]);

  const { name, farmName, firebreak, doc } = data;

  const filteredItems = firebreaks?.filter(
    item =>
      JSON.parse(item.userId) === JSON.parse(localStorage.getItem('token')).id
  );

  return (
    <>
      <Typography variant='h5' sx={{ mb: 2 }}>
        Neighbour Agreements
      </Typography>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Name of neighbour
      </Typography>
      <Alert severity='info'>If more than one, please separate by comma</Alert>
      <TextField
        name='name'
        label='Name of neighbour'
        value={name}
        type='text'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Farm Name
      </Typography>
      <Alert severity='info'>If more than one, please separate by comma</Alert>
      <TextField
        name='farmName'
        label='Farm Name'
        value={farmName}
        type='text'
        onChange={setData}
        fullWidth
      />
      <FormControl
        sx={{
          mt: 1,
        }}
        fullWidth
      >
        <InputLabel id='demo-simple-select-label'>Select firebreak</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          label='Type of firebreak'
          name='firebreak'
          value={firebreak}
          onChange={setData}
        >
          {filteredItems?.map(item => {
            return (
              <MenuItem
                key={item.id}
                value={JSON.parse(item.formData).nameOfFirebreak}
              >
                {JSON.parse(item.formData).nameOfFirebreak}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Upload document
      </Typography>
      <TextField
        name='doc'
        value={doc}
        type='file'
        onChange={setData}
        fullWidth
      />
    </>
  );
};

const mapStateToProps = state => ({
  firebreaks: state.postFormData.firebreaks,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchFirebreaks: () => dispatch(getFirebreaks()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(F012NeighbourAgreements);
