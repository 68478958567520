export const API_GET_TIGERCAT_BURNS_GET_BIOCHAR_VOLUMES = 'api/tigercat-burns/biochar-volumes';

export const API_POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY = 'api/tigercat-burns/create-bulk-fuel-delivery';
export const API_GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY = 'api/tigercat-burns/get-bulk-fuel-delivery';

export const API_POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE = 'api/tigercat-burns/create-biochar-production-fuel-use';
export const API_GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE = 'api/tigercat-burns/get-biochar-production-fuel-use';

export const API_GET_TIGERCAT_BURNS_FEEDSTOCK_PROCESSING_FUEL = 'api/tigercat-burns/get-feedstock-processing-fuel';

export const API_POST_TIGERCAT_BURNS_POST_PROPANE_REFILL = 'api/tigercat-burns/create-propane-refill';
export const API_GET_TIGERCAT_BURNS_GET_PROPANE_REFILL = 'api/tigercat-burns/get-propane-refill';

export const API_POST_TIGERCAT_BURNS_POST_FEEDSTOCK_TRANSPORT = 'api/tigercat-burns/post-feedstock-transport';
export const API_GET_TIGERCAT_BURNS_GET_FEEDSTOCK_TRANSPORT = 'api/tigercat-burns/get-feedstock-transport';

export const API_POST_TIGERCAT_BURNS_ADD_BASE_CALLER = 'api/tigercat-burns/add-base-caller';
export const API_GET_TIGERCAT_BURNS_GET_BASE_CALLERS = 'api/tigercat-burns/get-base-callers';
export const API_POST_TIGERCAT_BURNS_ADD_CALL_SUBMISSION = 'api/tigercat-burns/add-call-submission';
export const API_GET_TIGERCAT_BURNS_GET_CALL_SUBMISSION_LOG = 'api/tigercat-burns/get-call-submission-log';
