import { useEffect, Fragment } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { getFeedstockProcessingFuel } from '../../../../../actions/tigercat-burns/tigercat-actions';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const FeedstockProcessingFuel = ({ projectId }) => {
    const dispatch = useDispatch()
    const feedstockProcessingFuel = useSelector(state => state.tigercatBurnsReducer.feedstockProcessingFuel.data)

    useEffect(() => {
        dispatch(getFeedstockProcessingFuel(projectId));
    }, [dispatch, projectId])

    const card = (
        <Fragment>
            <CardContent>
                <Typography variant="h5" component="div">
                    Diesel
                </Typography>
                <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>Diesel Red</Typography>
                <TableContainer sx={{ maxWidth: 400 }} component={Paper}>
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell align="left"><b>Total Bulk Delivery:</b></TableCell>
                                <TableCell align="left">{feedstockProcessingFuel?.fuelDeliveryDieselRedRunningTotal} (gal)</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left"><b>Total Biochar Production:</b></TableCell>
                                <TableCell align="left">{feedstockProcessingFuel?.fuelUseRunningTotal} (gal)</TableCell>
                            </TableRow>
                            <TableRow sx={{ border: '2px solid black' }}>
                                <TableCell align="left"><b>Total Feedstock Processing Fuel:</b></TableCell>
                                <TableCell align="left"> {feedstockProcessingFuel?.feedStockProcessingFuel} (gal)</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            {/* <CardActions>
                <Button size="small">Learn More</Button>
            </CardActions> */}
        </Fragment>
    );

    return (
        <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined">{card}</Card>
        </Box>
    )
}

export default FeedstockProcessingFuel;