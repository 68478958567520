import { useState } from 'react';
import {
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Divider
} from '@mui/material';

export default function AdditionalInfo({ formData, onChange }) {
  const [trigger, setTrigger] = useState(false);
  const [triggerSite, setTriggerSite] = useState(false);

  const {
    role,
    farmName,
    farmManagerEmail,
    landownerName,
    landownerSurname,
    landownerEmail,
    companyName,
    siteManagerName,
    siteManagerPhone,
  } = formData;

  const emissionScopes = [
    {
      id: 1,
      name: 'Scope 1',
      scope: '1',
    },
    {
      id: 2,
      name: 'Scope 2',
      scope: '2',
    },
    {
      id: 3,
      name: 'Scope 3',
      scope: '3',
    },
  ];

  return role === 'landowners' ? (
    <>
      <Box component='div' sx={{ mt: 2 }}>
        <TextField
          label='Farm Name'
          name='farmName'
          value={farmName}
          onChange={onChange}
          sx={{ background: '#fff' }}
          required={true}
          fullWidth
        />
        <Typography sx={{ mt: 2 }}>Do you have a farm manager?</Typography>
        <RadioGroup row name='hasFarmManager' onChange={onChange}>
          <FormControlLabel
            value={1}
            control={<Radio required />}
            label='Yes'
            name='hasFarmManager'
            onClick={() => setTrigger(true)}
          />
          <FormControlLabel
            value={0}
            control={<Radio required />}
            label='No'
            name='hasFarmManager'
            onClick={() => setTrigger(false)}
          />
        </RadioGroup>
        {trigger && (
          <TextField
            label='Farm Manager Email'
            name='farmManagerEmail'
            type='email'
            value={farmManagerEmail}
            onChange={onChange}
            sx={{ background: '#fff' }}
            required
            fullWidth
          />
        )}
      </Box>
    </>
  ) : role === 'farm_managers' ? (
    <>
      <Box component='div' sx={{ mt: 2 }}>
        <TextField
          label='Farm Name'
          name='farmName'
          value={farmName}
          onChange={onChange}
          sx={{ background: '#fff' }}
          required
          fullWidth
        />
      </Box>
      <Typography sx={{ mt: 2 }}>
        Please fill out details for the landowner
      </Typography>
      <Box component='div' sx={{ mt: 2 }}>
        <TextField
          label='Landowner Name'
          name='landownerName'
          value={landownerName}
          onChange={onChange}
          sx={{ background: '#fff' }}
          required
          fullWidth
        />
      </Box>
      <Box component='div' sx={{ mt: 2 }}>
        <TextField
          label='Landowner Surname'
          name='landownerSurname'
          value={landownerSurname}
          onChange={onChange}
          sx={{ background: '#fff' }}
          required
          fullWidth
        />
      </Box>
      <Box component='div' sx={{ mt: 2 }}>
        <TextField
          label='Landowner Email'
          name='landownerEmail'
          value={landownerEmail}
          onChange={onChange}
          sx={{ background: '#fff' }}
          required
          fullWidth
        />
      </Box>
      <Box component='div' sx={{ mt: 2 }}>
        <Typography sx={{ mt: 2 }}>
          Has the landowner created an account?
        </Typography>
        <RadioGroup
          row
          name='landownerAccountCreated'
          onChange={onChange}
          required
        >
          <FormControlLabel
            value={1}
            control={<Radio required />}
            label='Yes'
            name='landownerAccountCreated'
          />
          <FormControlLabel
            value={0}
            control={<Radio required />}
            label='No'
            name='landownerAccountCreated'
          />
        </RadioGroup>
      </Box>
    </>
  ) : role === 'contractors' ? (
    <>
      <Box component='div' sx={{ mt: 2 }}>
        <TextField
          label='Company Name'
          name='companyName'
          value={companyName}
          onChange={onChange}
          sx={{ background: '#fff' }}
          required
          fullWidth
        />
      </Box>
      <Typography sx={{ mt: 2 }}>Do you have a site manager?</Typography>
      <RadioGroup row name='hasSiteManager' onChange={onChange}>
        <FormControlLabel
          value={1}
          control={<Radio required />}
          label='Yes'
          name='hasSiteManager'
          onClick={() => setTriggerSite(true)}
        />
        <FormControlLabel
          value={0}
          control={<Radio required />}
          label='No'
          name='hasSiteManager'
          onClick={() => setTriggerSite(false)}
        />
      </RadioGroup>
      {triggerSite && (
        <>
          <Box component='div' sx={{ mt: 2 }}>
            <TextField
              label='Site Manager Name'
              name='siteManagerName'
              value={siteManagerName}
              onChange={onChange}
              sx={{ background: '#fff' }}
              required
              fullWidth
            />
          </Box>
          <Box component='div' sx={{ mt: 2 }}>
            <TextField
              label='Site Manager Phone'
              name='siteManagerPhone'
              value={siteManagerPhone}
              onChange={onChange}
              sx={{ background: '#fff' }}
              required
              fullWidth
            />
          </Box>
        </>
      )}
    </>
  ) : role === 'carbonFootprint' ?
    <>
      <Divider />
      <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2 }}>
        <Typography>Please provide the following additional information: *</Typography>
        <Box component='div' sx={{ mt: 2 }}>
          <TextField
            label='Company Name'
            name='companyName'
            value={companyName}
            onChange={onChange}
            sx={{ background: '#fff' }}
            required
            fullWidth
          />
        </Box>
        <Box component='div' sx={{ mt: 2 }}>
          <Typography>Please select the GHG Emission Scopes relevant to your assessment</Typography>
          <RadioGroup name='emission-scopes' onChange={onChange} required>
            {emissionScopes.map(es => {
              return (
                <FormControlLabel
                  key={es.id}
                  value={es.scope}
                  control={<Radio required />}
                  label={es.name}
                  name='emissionScope'
                />
              );
            })}
          </RadioGroup>
        </Box>
      </Box>
    </> :

    null;
}
