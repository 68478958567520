import {
    GET_OTHER_DATA_SUCCESS,
    GET_OTHER_DATA_FAIL,
    POST_OTHER_DATA_SUCCESS,
    POST_OTHER_DATA_FAIL,
    GET_OTHER_DATA_TOTALS_SUCCESS,
    GET_OTHER_DATA_TOTALS_FAIL
} from '../../../actions/carbonfootprint-rework/types/04-indirect-ghg-emissions-from-products-used-by-an-organisation';

const initialState = {
    loading: true,
    otherData: [],
    otherDataTotals: []
};

export default function indirectGhgEmissionsFromProductsForOtherReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case POST_OTHER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_OTHER_DATA_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_OTHER_DATA_SUCCESS:
            return {
                ...state,
                otherData: payload,
                loading: false,
            };
        case GET_OTHER_DATA_FAIL:
            return {
                ...state,
                otherData: payload,
                loading: false
            }
        case GET_OTHER_DATA_TOTALS_SUCCESS:
            return {
                ...state,
                otherDataTotals: payload,
                loading: false,
            };
        case GET_OTHER_DATA_TOTALS_FAIL:
            return {
                ...state,
                otherDataTotals: payload,
                loading: false
            }
        default:
            return state;
    }
}