import { Alert, Box, Button, Divider, Grid, Typography, Tab, Tabs } from '@mui/material';
import { useState } from 'react';

export default function CategorySelection({ setSelection, setCategory }) {
    const categories = [
        { cat: 'catOne', name: 'Fuel, Dry Ice, Refrigerant, Fertilisers & Agrochemicals' },
        { cat: 'catTwo', name: 'Electricity' },
        { cat: 'catThree', name: 'Business Travel & Downstream Distribution' },
        { cat: 'catFour', name: 'Purchased Goods & Services, Capital Goods & Services' },
        { cat: 'catFive', name: 'Waste Generation' },
    ]

    const [currentTab, setCurrentTab] = useState(0)
    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };



    return (
        <Grid item xs={12}>
            <Tabs
                value={currentTab}
                onChange={handleTabChange}
                style={{ marginBottom: 10 }}
                textColor='inherit'
                indicatorColor='secondary'
                className='theTabs'
                sx={{
                    float: 'left',
                    display: 'block'
                }}
            >
                <Tab label='Data Capturing' />
            </Tabs>
            <Box marginTop={2} sx={{ clear: 'left' }}>
                <Typography variant='h5'>Categories</Typography>
                <Divider />
                <Alert severity='info' sx={{ mb: 2 }}>Please select a data capturing category</Alert>
                {categories.map((c, i) => {
                    return (
                        <Button
                            key={i}
                            variant='contained'
                            size='large'
                            sx={{ width: '35%', display: 'block', mb: 2.5 }}
                            onClick={() => {
                                setCategory(c.name)
                                setSelection({ id: 4, title: c.cat })
                                localStorage.setItem('category', c.cat)
                            }}
                        >
                            {c.name}
                        </Button>
                    )
                })}
            </Box>
        </Grid>
    );
}
