import { useEffect } from 'react';
import {
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField, Tooltip,
    Typography,
} from '@mui/material';
import tooltips from "../../../../../../store/tooltips";
import InfoIcon from "@mui/icons-material/Info";

export default function BF020BurnSupervisor({ data, setData, setFormId }) {
  useEffect(() => {
    setFormId('BF020');
  }, [setFormId]);

  const { supervisorName, supervisorInfo } = data;

  return (
    <>
      <Typography variant='h5' sx={{ mb: 2 }}>
        Burn Supervisor
      </Typography>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Is a trained contractor team managing the process on site?
          <Tooltip
              title={
                  <h2 style={{ color: "lightgreen" }}>
                      {tooltips.burnSupervisorQ1}
                  </h2>
              }
          >
              {<InfoIcon sx={{ width: 15 }} />}
          </Tooltip>
      </Typography>
      <RadioGroup row name='trainedContractor' onChange={setData}>
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='trainedContractor'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='trainedContractor'
        />
      </RadioGroup>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        If No, Who will supervise the burn?
          <Tooltip
              title={
                  <h2 style={{ color: "lightgreen" }}>
                      {tooltips.burnSupervisorQ2}
                  </h2>
              }
          >
              {<InfoIcon sx={{ width: 15 }} />}
          </Tooltip>
      </Typography>
      <TextField
        name='supervisorName'
        value={supervisorName}
        label='Supervisor Name'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        If No, What is their role and relevant experience?
          <Tooltip
              title={
                  <h2 style={{ color: "lightgreen" }}>
                      {tooltips.burnSupervisorQ3}
                  </h2>
              }
          >
              {<InfoIcon sx={{ width: 15 }} />}
          </Tooltip>
      </Typography>
      <TextField
        name='supervisorInfo'
        value={supervisorInfo}
        label='Supervisor Info'
        onChange={setData}
        fullWidth
      />
    </>
  );
}
