import React, { useState, useEffect } from 'react'
import { Alert, Box, TextField, Button, TableContainer, Table, TableHead, TableCell, TableRow, Paper, TableBody, Select, MenuItem, FormControl, CircularProgress } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getPropaneRefill, postPropaneRefill } from '../../../../../actions/tigercat-burns/tigercat-actions';

const IgnitionFuel = ({ projectId }) => {
    const [propaneRefillState, setPropaneRefillState] = useState([])
    const dispatch = useDispatch()
    const propaneRefills = useSelector(state => state.tigercatBurnsReducer.propaneRefills.data)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        dispatch(getPropaneRefill(projectId));
    }, [dispatch, projectId]);

    useEffect(() => {
        const formDataArray = propaneRefills?.map((item) => {
            const formData = new FormData();
            formData.append('id', item.id);
            formData.append('date', item.date);
            formData.append('fuelType', item.fuelType);
            formData.append('refillVolume', item.refillVolume);
            formData.append('sourceDocument', item.sourceDocumentUrl);
            formData.append('disabled', true)
            return formData;
        });
        setPropaneRefillState(formDataArray);
    }, [propaneRefills]);

    const handleAddItem = () => {
        setPropaneRefillState((prevState) => {
            const state = [...prevState]
            let id;
            if (state.length === 0) {
                id = null
            } else {
                id = Number(state[state.length - 1].get('id')) + 1
            }
            const formData = new FormData();
            formData.append('id', id);
            formData.append('date', null);
            formData.append('fuelType', 'Propane');
            formData.append('refillVolume', null);
            formData.append('sourceDocument', null);
            state.push(formData)
            return state
        })
    }

    const handleDateSelection = (date, dataId) => {
        setPropaneRefillState((prevState) => {
            return prevState.map((item) => {
                if (item.get('id') === dataId) {
                    const formattedDate = date.toISOString().split('T')[0];
                    item.set('date', formattedDate);
                }
                return item;
            });
        });
    }

    const handleFuelTypeChange = (e, dataId) => {
        setPropaneRefillState((prevState) => {
            return prevState.map((item) => {
                if (item.get('id') === dataId) {
                    item.set('fuelType', e.target.value);
                }
                return item;
            });
        });
    }

    const handleVolumeDeliveredChange = (e, dataId) => {
        setPropaneRefillState((prevState) => {
            return prevState.map((item) => {
                if (item.get('id') === dataId) {
                    item.set('refillVolume', e.target.value);
                }
                return item;
            });
        });
    }

    const handleSourceDoc = (e, dataId) => {
        const file = e.target.files[0];
        setPropaneRefillState((prevState) => {
            return prevState.map((item) => {
                if (item.get('id') === dataId) {
                    item.set('sourceDocument', file);
                }
                return item;
            });
        });
    }

    const fuelTypes = [
        { id: 0, name: 'Propane' }
    ]

    const handleSubmitData = () => {
        setLoading(true)

        dispatch(postPropaneRefill(projectId, propaneRefillState)).then(() => {
            dispatch(getPropaneRefill(projectId))
            setLoading(false)
        })
    }

    return (
        <>
            <h2>Propane Refill</h2>
            <TableContainer key={0} component={Paper} >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                Date
                            </TableCell>
                            <TableCell align="left">
                                Fuel Type (ff)
                            </TableCell>
                            <TableCell align="left">
                                Refill Volume (gal)
                            </TableCell>
                            <TableCell align="left">
                                Source document
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {propaneRefillState?.map((data, index) => {
                            const sourceDocument = data.get('sourceDocument');
                            const isString = typeof sourceDocument === 'string' && sourceDocument !== 'null';
                            return (

                                <TableRow key={index}>
                                    <TableCell align="left">
                                        <FormControl>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    name={'date'}
                                                    value={data.get('date') ? moment(data.get('date'), 'YYYYMMDD') : null}
                                                    onChange={(e) => handleDateSelection(e._d, data.get('id'))}
                                                    disabled={data.get('disabled') ?? false}
                                                    format="DD/MM/YYYY"
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Select name='fuel type' value={data.get('fuelType')} disabled={true} fullWidth onChange={(e) => handleFuelTypeChange(e, data.get('id'))}>
                                            {fuelTypes?.map(type => {
                                                return (
                                                    <MenuItem key={type.id} value={type.name} >
                                                        {type.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            name='fuel volume delivered'
                                            value={JSON.parse(data.get('refillVolume')) === null ? '' : data.get('refillVolume')}
                                            onChange={(e) => handleVolumeDeliveredChange(e, data.get('id'))}
                                            type="text"
                                            disabled={data.get('disabled') ?? false}

                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        {isString ? (
                                            <a href={sourceDocument} target="_blank" rel="noopener noreferrer">
                                                {sourceDocument}
                                            </a>
                                        ) : (
                                            <TextField
                                                name={'Lab results'}
                                                defaultValue={sourceDocument && sourceDocument.name ? sourceDocument.name : ''}
                                                onChange={(e) => handleSourceDoc(e, data.get('id'))}
                                                type="file"
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ position: 'relative', mb: 10 }}>
                <Button variant='contained' sx={{ height: 'auto', mt: 2 }} onClick={handleAddItem}>Add Entry</Button>
                {loading ? <CircularProgress sx={{ position: 'absolute', left: '125px', top: '12px' }}></CircularProgress> : <Button variant='contained' sx={{ height: 'auto', mt: 2, ml: 3 }} onClick={handleSubmitData}>Submit Data</Button>}
                {loading && <Alert severity='info' sx={{ my: 2 }}>Please be patient during data submission as file uploads can take some time to complete</Alert>}
            </Box>
        </>
    )
}

export default IgnitionFuel