import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  TextField,
  Link,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import {
  getChargingData,
  verifyBiocharData,
} from '../../../../../../../actions/forms';
import Checkmark from '../../../../../../../assets/images/check.png';
import Cross from '../../../../../../../assets/images/cross.png';
import { UPLOADED_DOCS } from '../../../../../../../actions/constants';

function BiocharCharging({ biocharChargingData, projectKey, id }) {
  const dispatch = useDispatch();
  const [chargingData, setChargingData] = useState([]);

  useEffect(() => {
    dispatch(getChargingData(projectKey));
  }, [dispatch, projectKey]);

  const chargingVerificationTableHeadings = [
    'Batch ID',
    'Charging Type',
    'Percentage Charged',
    'Image',
    'Note',
    'Value Correct',
    'Remarks',
    'Verified',
  ];

  useEffect(() => {
    if (biocharChargingData) {
      setChargingData(
        biocharChargingData.map(d => {
          return {
            id: id,
            batch: d.chargeBatchNum,
            chargingTypeValue: d.chargingTypeValue,
            numberOfBags: d.numberOfBags,
            biocharPhotoURL: d.biocharPhotoURL,
            notes: d.notes,
            valueCorrect:
              d?.valueCorrect === true || d?.valueCorrect === false
                ? d.valueCorrect
                : null,
            correction: d?.correction ? d.correction : null,
            verified: d?.verified ? d?.verified : null,
          };
        })
      );
    }
  }, [biocharChargingData, dispatch, id]);
  const handleSubmitChargingData = async () => {
    await dispatch(verifyBiocharData(projectKey, 'chargingData', chargingData));
    await dispatch(getChargingData(projectKey));
  };

  const handleValueCorrectChange = (event, batch) => {
    setChargingData(chargingData => {
      const updated = chargingData.map(v => {
        if (v.batch === batch) {
          v.valueCorrect = !!+event.target.value;
        }
        return v;
      });
      return updated;
    });
  };

  const handleCorrection = (event, batch) => {
    setChargingData(chargingData => {
      const updated = chargingData.map(v => {
        if (v.batch === batch) {
          v.correction = event.target.value;
        }
        return v;
      });
      return updated;
    });
  };

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Typography variant='h6' sx={{ mb: 2 }}>
        Biochar Charging Data
      </Typography>
      <TableContainer component={Paper} sx={{ height: 'auto', width: '100%' }}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              {chargingVerificationTableHeadings.map((heading, i) => {
                return (
                  <TableCell align='left' key={i}>
                    {heading}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {chargingData?.length > 0
              ? chargingData?.map((d, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell align='left'>{d.batch}</TableCell>
                    <TableCell align='left'>{d.chargingTypeValue}</TableCell>
                    <TableCell align='left'>{d.numberOfBags}</TableCell>
                    <TableCell align='left'>
                      <Link
                        target='_blank'
                        href={`${UPLOADED_DOCS}${d?.biocharPhotoURL
                          ?.split('/')
                          .pop()}`}
                      >
                        <img
                          height='150'
                          width='150'
                          src={`${UPLOADED_DOCS}${d?.biocharPhotoURL
                            ?.split('/')
                            .pop()}`}
                          alt='moisture measurement'
                        />
                      </Link>
                    </TableCell>
                    <TableCell align='left'>{d.notes}</TableCell>
                    <TableCell align='left'>
                      <RadioGroup
                        value={
                          d?.valueCorrect === true
                            ? 1
                            : d?.valueCorrect === false
                              ? 0
                              : null
                        }
                        row
                        name='valueCorrect'
                        onChange={event =>
                          handleValueCorrectChange(event, d.batch)
                        }
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio required />}
                          label='Yes'
                          name='valueCorrect'
                          disabled={d?.verified}
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio required />}
                          label='No'
                          name='valueCorrect'
                          disabled={d?.verified}
                        />
                      </RadioGroup>
                    </TableCell>
                    <TableCell align='left'>
                      <TextField
                        // label='Site Manager Phone'
                        name='correction'
                        value={d.correction}
                        onChange={event => handleCorrection(event, d.batch)}
                        sx={{ background: '#fff' }}
                        required
                        fullWidth
                        type='number'
                        disabled={
                          d?.verified || d?.valueCorrect === true
                            ? true
                            : false
                        }
                      />
                    </TableCell>
                    <TableCell align='left'>
                      {d?.verified ? (
                        <img
                          src={Checkmark}
                          width={25}
                          height={25}
                          alt='checkmark'
                        />
                      ) : (
                        <img src={Cross} width={25} height={25} alt='cross' />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        sx={{ mt: 2 }}
        variant='contained'
        onClick={handleSubmitChargingData}
      >
        Verify Biochar Charging Data
      </Button>
    </Box>
  );
}

const mapStateToProps = state => ({
  biocharChargingData: state.postFormData.chargingData,
  id: state.postFormData.id,
});

const mapDispatchToProps = dispatch => {
  return {
    getChargingData: projectId => dispatch(getChargingData(projectId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BiocharCharging);
