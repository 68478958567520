import { useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { getFirebreaks } from '../../../../../../actions/forms';
import { connect } from 'react-redux';

const F011ScheduleMaintenance = ({
  data,
  setData,
  setFormId,
  fetchFirebreaks,
  firebreaks,
}) => {
  useEffect(() => {
    setFormId('F011');
    fetchFirebreaks();
  }, [setFormId, fetchFirebreaks]);

  const {
    typeOfFirebreak,
    maintenanceDate,
    nameOfSubmitter,
    phoneOfSubmitter,
    maintenanceType,
    method,
  } = data;

  return (
    <>
      <Typography variant='h5' sx={{ mb: 2 }}>
        Schedule Maintenance
      </Typography>
      <FormControl
        sx={{
          mt: 1,
        }}
        fullWidth
      >
        <InputLabel id='demo-simple-select-label'>Select firebreak</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          label='Type of firebreak'
          name='typeOfFirebreak'
          value={typeOfFirebreak}
          onChange={setData}
        >
          {firebreaks?.map(item => {
            return (
              <MenuItem
                key={item.id}
                value={JSON.parse(item.formData).nameOfFirebreak}
              >
                {JSON.parse(item.formData).nameOfFirebreak}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Select date
      </Typography>
      <TextField
        name='maintenanceDate'
        type='date'
        value={maintenanceDate}
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Name and Surname of person who will be submitting data on site
      </Typography>
      <TextField
        name='nameOfSubmitter'
        label='Name and Surname of person who will be submitting data on site'
        value={nameOfSubmitter}
        onChange={setData}
        fullWidth
        sx={{ my: 2 }}
      />
      <Typography variant='h6' component='p'>
        Cellphone number of person who will be submitting data on site
      </Typography>
      <TextField
        name='phoneOfSubmitter'
        label='Cellphone number of person who will be submitting data on site'
        value={phoneOfSubmitter}
        onChange={setData}
        fullWidth
      />
      <FormControl
        sx={{
          mt: 2,
        }}
        fullWidth
      >
        <InputLabel id='demo-simple-select-label'>
          Will the maintenance be done by an external contractor or farm team?
        </InputLabel>
        <Select
          name='maintenanceType'
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          label='Type of firebreak'
          value={maintenanceType}
          onChange={setData}
        >
          <MenuItem value='Contractor'>Contractor</MenuItem>
          <MenuItem value='Farm Staff'>Farm Staff</MenuItem>
        </Select>
      </FormControl>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Method
      </Typography>
      <TextField
        name='method'
        label='Method'
        type='text'
        value={method}
        onChange={setData}
        fullWidth
      />
    </>
  );
};

const mapStateToProps = state => ({
  firebreaks: state.postFormData.firebreaks,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchFirebreaks: () => dispatch(getFirebreaks()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(F011ScheduleMaintenance);
