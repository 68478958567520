import { React, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography, Button } from '@mui/material';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Card, CardMedia, CardContent, CardActions } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getIRTProjects } from "../../../actions/landing";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Projects(props) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        dispatch(getIRTProjects());
    }, [dispatch])
    const { irtProjects } = useSelector(state => state.irtRegistries);

    const settings = {
        dots: true,
        infinite: true,
        speed: 3000, // Adjust speed for slow glide
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        cssEase: 'linear',
    };

    const mappedProjects = () => {
        return (
            irtProjects.map((project, i) => {
                return (
                    <Box key={i} sx={isSmallScreen ? {
                        display: 'flex',
                        // justifyContent: 'space-around',
                        // alignItems: 'center',
                        // width: '100%',
                        // flexWrap: 'wrap'
                        background: '#fff',
                        direction: 'column'
                    } : null}>
                        <Card id="projects__card" sx={{ width: '340px', minWidth: 'auto', maxHeight: '500px', height: '500px', margin: 'auto', mb: '3rem', background: '#fff' }} raised={false}>
                            <Carousel axis={isSmallScreen ? 'vertical' : 'horizontal'} showArrows={false} showStatus={false} showIndicators={false} autoFocus autoPlay={true} infiniteLoop={true} interval={7000} showThumbs={false}>
                                {project?.project_image ? <CardMedia
                                    sx={{ height: 200 }}
                                    image={project.project_image}
                                    title="project"
                                /> : null}
                                {project?.team_image ? <CardMedia
                                    sx={{ height: 200 }}
                                    image={project.team_image}
                                    title="team"
                                /> : null}
                            </Carousel>
                            <CardContent sx={{ background: '#fff' }}>
                                <Typography variant='h5' sx={{ mb: 2 }}>{project.project_name}</Typography>
                                <Typography sx={{ fontSize: '16px', mb: 1.2, textAlign: 'left' }}>
                                    <ChevronRightIcon sx={{ mb: '-7px' }} /> Date: {project.project_date}
                                </Typography>
                                <Typography sx={{ fontSize: '16px', mb: 1.2, textAlign: 'left' }}>
                                    <ChevronRightIcon sx={{ mb: '-7px' }} /> Location: {project.location}
                                </Typography>

                                <Typography sx={{ mb: 1.2, textAlign: 'left' }}>
                                    <ChevronRightIcon sx={{ mb: '-7px' }} /> IRT Potential: {project.irt_potential}
                                </Typography>
                                <Typography sx={{ mb: 1.2, textAlign: 'left' }}>
                                    <ChevronRightIcon sx={{ mb: '-7px' }} /> Technology: {project.technology_used}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                {/* <Button size="small">Share</Button> */}
                                {/* <Button size="small">Learn More</Button> */}
                            </CardActions>
                        </Card>
                    </Box>
                )
            })
        )
    }

    return (
        <Grid container id='projects' sx={{ background: { sm: 'lightgrey' } }}>
            <Grid
                lg={12}
                item
                sx={{ width: '100%', padding: '2rem', background: '#fff' }}
            >
                <Typography
                    sx={{
                        fontSize: '36px',
                        fontWeight: '600',
                        fontStyle: 'normal',
                        lineHeight: '44px',
                        letterSpacing: '-0.72px',
                        // color: '#DA9DE3',
                        mt: '10px',
                        mb: '50px'
                    }}
                    id="projects__heading"
                >
                    Projects
                </Typography>
                <Box>

                    {isSmallScreen ? mappedProjects() :
                        <Slider {...settings}>
                            {mappedProjects()}
                        </Slider>}

                </Box>
                <Box>
                    <Button variant="outlined" sx={{ mt: '2rem' }} onClick={props.handleRegistry}>View Registries</Button>
                </Box>
            </Grid>
        </Grid >
    );
}
