import { Box, Button, Grid } from '@mui/material';
import { useState } from 'react'
import FlightsAndAccommodationDataCapturing from './FlightsAndAccommodationDataCapturing';
import CarHireDataCapturing from './CarHireDataCapturing';
import PetrolCardDataCapturing from './PetrolCardDataCapturing';

export default function BusinessTravel() {
    const [selected, setSelected] = useState('flights & accommodation')
    const travelCategories = ['flights & accommodation', 'Car Hire', 'Petrol Cards']

    const outputComponent = () => {
        switch (selected) {
            case 'flights & accommodation':
                return <FlightsAndAccommodationDataCapturing />
            case 'Car Hire':
                return <CarHireDataCapturing />
            case 'Petrol Cards':
                return <PetrolCardDataCapturing />
            default:
                return null
        }
    }

    return (
        <>
            <Grid item xs={2}>
                <Box marginTop={2}>
                    {travelCategories.map((c, i) => {
                        return (
                            <Button
                                key={i}
                                variant={c === selected ? 'contained' : 'outlined'}
                                size='large'
                                sx={{ width: '100%', display: 'block', mb: 2.5 }}
                                onClick={() => {
                                    setSelected(c)
                                    localStorage.setItem('catThree', c)
                                }}
                            >
                                {c}
                            </Button>
                        )
                    })}
                </Box>
            </Grid>
            <Grid item xs={10}>
                {outputComponent()}
            </Grid>
        </>
    );
}
