import React from 'react'
import { Alert, Button, Dialog, Divider, DialogContent, DialogActions, DialogTitle, Typography, TextField } from '@mui/material';

const AddEquipmentDialog = ({
    openEquipmentModal,
    setOpenEquipmentModal,
    equipmentToAdd,
    handleEquipmentUpdate,
    handleAddEquipment,
    handleCancel,
    formErrors,
    equipmentRefs
}) => {

    const renderInputs = () => {
        return equipmentToAdd.map((input, index) => (
            <React.Fragment key={input.id}>
                <Typography>{input.label}</Typography>
                <TextField
                    name={input.name}
                    value={input.value}
                    onChange={handleEquipmentUpdate}
                    fullWidth
                    sx={{ mb: 2 }}
                    type={input.type}
                    inputRef={el => equipmentRefs.current[index] = el}
                />
            </React.Fragment>
        ));
    };

    return (
        <Dialog open={openEquipmentModal} onClose={() => setOpenEquipmentModal(false)} >
            <DialogTitle>Add Equipment</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
                <Divider sx={{ my: 1 }} />
                {renderInputs()}
                {formErrors.map((error, index) => {
                    return (
                        <Alert sx={{ mb: 1 }} key={index} severity="error">{error}</Alert>
                    )
                })}
            </DialogContent>
            <DialogActions sx={{ mr: 'auto', mb: 3, ml: 2 }}>
                <Button variant='contained' onClick={handleAddEquipment}>
                    Add Equipment
                </Button>
                <Button variant='outlined' onClick={handleCancel}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddEquipmentDialog