import {
    Box, Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell
} from '@mui/material';

export function RenewableEnergyProducedTotalTable({ renewableEnergyDataTotals }) {
    console.log(renewableEnergyDataTotals)
    return (
        <Box>
            <TableContainer component={Paper} sx={{ height: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                Month
                            </TableCell>
                            {/* <TableCell align="left">
                                kWh Value
                            </TableCell> */}
                            <TableCell align="left">
                                Wind (kWh)
                            </TableCell>
                            <TableCell align="left">
                                Solar (kWh)
                            </TableCell>
                            <TableCell align="left">
                                Biogas (kWh)
                            </TableCell>
                            <TableCell align="left">
                                Total kWh
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {months.slice(0, 12).map((m) => { */}
                        {renewableEnergyDataTotals?.map((data, i) => {
                            if (!Object.keys(data).includes('grand_total')) {
                                return (
                                    <TableRow key={i}>
                                        <TableCell align="left">
                                            {data.month}
                                        </TableCell>
                                        {/* <TableCell align="left">
                                            {data.kWhQuantity}
                                        </TableCell> */}
                                        <TableCell align="left">
                                            {data.wind}
                                        </TableCell>
                                        <TableCell align="left">
                                            {data.solar}
                                        </TableCell>
                                        <TableCell align="left">
                                            {data.biogas}
                                        </TableCell>
                                        <TableCell align="left">
                                            {data.total}
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        })}
                        <TableRow sx={{ border: '2px solid black' }}>
                            {/* <TableCell align="left"></TableCell> */}
                            <TableCell align="left"><b>Grand Total</b></TableCell>
                            <TableCell align="left"><b>{renewableEnergyDataTotals[12]?.grand_total_wind}</b></TableCell>
                            <TableCell align="left"><b>{renewableEnergyDataTotals[12]?.grand_total_solar}</b></TableCell>
                            <TableCell align="left"><b>{renewableEnergyDataTotals[12]?.grand_total_biogas}</b></TableCell>
                            <TableCell align="left"><b>{renewableEnergyDataTotals[12]?.grand_total}</b></TableCell>
                        </TableRow>
                        {/* {data.map((d, i) => {
                            return (
                                <TableRow >
                                    <TableCell align="left">
                                        {d.category}
                                    </TableCell>
                                    <TableCell align="left">
                                        {d.unit}
                                    </TableCell>
                                    <TableCell align="left">
                                        {d.quantity}
                                    </TableCell>
                                </TableRow>
                            )
                        })} */}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
