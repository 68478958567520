import { setAlert } from '../alert';
import {
    API_GET_TIGERCAT_BURNS_GET_BIOCHAR_VOLUMES,
    API_POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY,
    API_GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY,
    API_POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE,
    API_GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE,
    API_GET_TIGERCAT_BURNS_FEEDSTOCK_PROCESSING_FUEL,
    API_POST_TIGERCAT_BURNS_POST_PROPANE_REFILL,
    API_GET_TIGERCAT_BURNS_GET_PROPANE_REFILL,
    API_POST_TIGERCAT_BURNS_POST_FEEDSTOCK_TRANSPORT,
    API_GET_TIGERCAT_BURNS_GET_FEEDSTOCK_TRANSPORT,
    API_POST_TIGERCAT_BURNS_ADD_BASE_CALLER,
    API_POST_TIGERCAT_BURNS_ADD_CALL_SUBMISSION,
    API_GET_TIGERCAT_BURNS_GET_CALL_SUBMISSION_LOG,
    API_GET_TIGERCAT_BURNS_GET_BASE_CALLERS
} from './constants/tigercat-constants';

import {
    GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_SUCCESS,
    GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_FAIL,
    //
    POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_SUCCESS,
    POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_FAIL,
    //
    GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_SUCCESS,
    GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_FAIL,
    //
    POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS,
    POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_FAIL,
    //
    GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS,
    GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_FAIL,
    //
    GET_TIGERCAT_BURNS_GET_FEEDSTOCK_PROCESSING_FUEL_SUCCESS,
    GET_TIGERCAT_BURNS_GET_FEEDSTOCK_PROCESSING_FUEL_FAIL,
    //
    POST_TIGERCAT_BURNS_POST_PROPANE_REFILL_SUCCESS,
    POST_TIGERCAT_BURNS_POST_PROPANE_REFILL_FAIL,
    //
    GET_TIGERCAT_BURNS_GET_PROPANE_REFILL_SUCCESS,
    GET_TIGERCAT_BURNS_GET_PROPANE_REFILL_FAIL,
    //
    GET_TIGERCAT_BURNS_GET_FEEDSTOCK_TRANSPORT_SUCCESS,
    GET_TIGERCAT_BURNS_GET_FEEDSTOCK_TRANSPORT_FAIL,
    //
    POST_TIGERCAT_BURNS_POST_FEEDSTOCK_TRANSPORT_SUCCESS,
    POST_TIGERCAT_BURNS_POST_FEEDSTOCK_TRANSPORT_FAIL,
    //
    POST_TIGERCAT_BURNS_ADD_BASE_CALLER_SUCCESS,
    POST_TIGERCAT_BURNS_ADD_BASE_CALLER_FAIL,
    POST_TIGERCAT_BURNS_ADD_CALL_SUBMISSION_SUCCESS,
    POST_TIGERCAT_BURNS_ADD_CALL_SUBMISSION_FAIL,
    GET_TIGERCAT_BURNS_GET_CALL_SUBMISSION_LOG_SUCCESS,
    GET_TIGERCAT_BURNS_GET_CALL_SUBMISSION_LOG_FAIL,
    //
    GET_TIGERCAT_BURNS_GET_BASE_CALLERS_SUCCESS,
    GET_TIGERCAT_BURNS_GET_BASE_CALLERS_FAIL
} from './types/tigercat-types';

import axios from 'axios';
const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    },
};

const formDataConfig = {
    headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
    }
}

export const getBiocharVolumes = (carRppbProjectId) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_TIGERCAT_BURNS_GET_BIOCHAR_VOLUMES + `/${carRppbProjectId}`,
            config
        );
        dispatch({
            type: GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const postBulkFuelDelivery = (carRppbProjectId, formDataArray) => async dispatch => {
    try {
        const formData = new FormData();
        formDataArray.forEach((formDataItem, index) => {
            for (const [key, value] of formDataItem.entries()) {
                formData.append(`data[${index}][${key}]`, value);
            }
        });

        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY + `/${carRppbProjectId}`,
            formData,
            formDataConfig
        );
        dispatch({
            type: POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getBulkFuelDelivery = (carRppbProjectId) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY + `/${carRppbProjectId}`,
            config
        );
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const postBiocharProductionFuelUse = (carRppbProjectId, fuelUseData) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE + `/${carRppbProjectId}`,
            fuelUseData,
            config
        );
        dispatch({
            type: POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getBiocharProductionFuelUse = (carRppbProjectId) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE + `/${carRppbProjectId}`,
            config
        );
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getFeedstockProcessingFuel = (carRppbProjectId) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_TIGERCAT_BURNS_FEEDSTOCK_PROCESSING_FUEL + `/${carRppbProjectId}`,
            config
        );
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_FEEDSTOCK_PROCESSING_FUEL_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_FEEDSTOCK_PROCESSING_FUEL_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const postPropaneRefill = (carRppbProjectId, formDataArray) => async dispatch => {
    try {
        const formData = new FormData();
        formDataArray.forEach((formDataItem, index) => {
            for (const [key, value] of formDataItem.entries()) {
                formData.append(`data[${index}][${key}]`, value);
            }
        });

        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_TIGERCAT_BURNS_POST_PROPANE_REFILL + `/${carRppbProjectId}`,
            formData,
            formDataConfig
        );
        dispatch({
            type: POST_TIGERCAT_BURNS_POST_PROPANE_REFILL_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_TIGERCAT_BURNS_POST_PROPANE_REFILL_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getPropaneRefill = (carRppbProjectId) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_TIGERCAT_BURNS_GET_PROPANE_REFILL + `/${carRppbProjectId}`,
            config
        );
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_PROPANE_REFILL_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_PROPANE_REFILL_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const postFeedstockTransport = (carRppbProjectId, formDataArray) => async dispatch => {
    try {
        const formData = new FormData();
        formDataArray.forEach((formDataItem, index) => {
            for (const [key, value] of formDataItem.entries()) {
                formData.append(`data[${index}][${key}]`, value);
            }
        });

        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_TIGERCAT_BURNS_POST_FEEDSTOCK_TRANSPORT + `/${carRppbProjectId}`,
            formData,
            formDataConfig
        );
        dispatch({
            type: POST_TIGERCAT_BURNS_POST_FEEDSTOCK_TRANSPORT_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_TIGERCAT_BURNS_POST_FEEDSTOCK_TRANSPORT_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getFeedstockTransport = (carRppbProjectId) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_TIGERCAT_BURNS_GET_FEEDSTOCK_TRANSPORT + `/${carRppbProjectId}`,
            config
        );
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_FEEDSTOCK_TRANSPORT_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_FEEDSTOCK_TRANSPORT_FAIL,
            payload: err.response.data.detail,
        });
    }
};


export const addBaseCaller = (carRppbProjectId, callerData) => async dispatch => {
    try {
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}${API_POST_TIGERCAT_BURNS_ADD_BASE_CALLER}/${carRppbProjectId}`,
            callerData,
            config
        );
        dispatch({
            type: POST_TIGERCAT_BURNS_ADD_BASE_CALLER_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_TIGERCAT_BURNS_ADD_BASE_CALLER_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getBaseCallers = (carRppbProjectId) => async dispatch => {
    try {
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}${API_GET_TIGERCAT_BURNS_GET_BASE_CALLERS}/${carRppbProjectId}`,
            config
        );
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_BASE_CALLERS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_BASE_CALLERS_FAIL,
            payload: err.response.data.detail,
        });
    }
}

export const addCallSubmission = (carRppbProjectId, callSubmissionData) => async dispatch => {
    try {
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}${API_POST_TIGERCAT_BURNS_ADD_CALL_SUBMISSION}/${carRppbProjectId}`,
            callSubmissionData,
            config
        );
        dispatch({
            type: POST_TIGERCAT_BURNS_ADD_CALL_SUBMISSION_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_TIGERCAT_BURNS_ADD_CALL_SUBMISSION_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getCallSubmissionLog = (carRppbProjectId) => async dispatch => {
    try {
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}${API_GET_TIGERCAT_BURNS_GET_CALL_SUBMISSION_LOG}/${carRppbProjectId}`,
            config
        );
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_CALL_SUBMISSION_LOG_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_CALL_SUBMISSION_LOG_FAIL,
            payload: err.response.data.detail,
        });
    }
};