import { useEffect } from 'react';
import {
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField, Tooltip,
    Typography,
} from '@mui/material';
import tooltips from "../../../../../../store/tooltips";
import InfoIcon from "@mui/icons-material/Info";

export default function BF022ProofProject({ data, setData, setFormId }) {
  useEffect(() => {
    setFormId('BF022');
  }, [setFormId]);

  const { sName, sRole, sContact, stakeholder_doc } = data;

  return (
    <>
      <Typography variant='h5' sx={{ mb: 2 }}>
        Proof of Project
      </Typography>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Are there any other stakeholders involved in project planning? (soil
        specialists, office administration)
          <Tooltip
              title={
                  <h2 style={{ color: "lightgreen" }}>
                      {tooltips.proofProjectQ1}
                  </h2>
              }
          >
              {<InfoIcon sx={{ width: 15 }} />}
          </Tooltip>
      </Typography>
      <RadioGroup row name='stakeholders' onChange={setData}>
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='stakeholders'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='stakeholders'
        />
      </RadioGroup>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Name
          <Tooltip
              title={
                  <h2 style={{ color: "lightgreen" }}>
                      {tooltips.proofProjectQ2}
                  </h2>
              }
          >
              {<InfoIcon sx={{ width: 15 }} />}
          </Tooltip>
      </Typography>
      <TextField
        name='sName'
        value={sName}
        label='Name'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Role
          <Tooltip
              title={
                  <h2 style={{ color: "lightgreen" }}>
                      {tooltips.proofProjectQ3}
                  </h2>
              }
          >
              {<InfoIcon sx={{ width: 15 }} />}
          </Tooltip>
      </Typography>
      <TextField
        name='sRole'
        value={sRole}
        label='Role'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Contact
          <Tooltip
              title={
                  <h2 style={{ color: "lightgreen" }}>
                      {tooltips.proofProjectQ4}
                  </h2>
              }
          >
              {<InfoIcon sx={{ width: 15 }} />}
          </Tooltip>
      </Typography>
      <TextField
        name='sContact'
        value={sContact}
        label='Contact'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Please provide proof of their involvement
          <Tooltip
              title={
                  <h2 style={{ color: "lightgreen" }}>
                      {tooltips.proofProjectQ5}
                  </h2>
              }
          >
              {<InfoIcon sx={{ width: 15 }} />}
          </Tooltip>
      </Typography>
      <TextField
        name='stakeholder_doc'
        value={stakeholder_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
    </>
  );
}
