import {
    GET_WASTE_TO_BIOCHAR_PRODUCTION_DATA_SUCCESS,
    GET_WASTE_TO_BIOCHAR_PRODUCTION_DATA_FAIL,
    POST_WASTE_TO_BIOCHAR_PRODUCTION_DATA_SUCCESS,
    POST_WASTE_TO_BIOCHAR_PRODUCTION_DATA_FAIL,
    GET_WASTE_TO_BIOCHAR_PRODUCTION_DATA_TOTALS_SUCCESS,
    GET_WASTE_TO_BIOCHAR_PRODUCTION_DATA_TOTALS_FAIL
} from '../../../actions/carbonfootprint-rework/types/05-indirect-ghg-emissions-from-other-sources';


const initialState = {
    loading: true,
    wasteToBiocharProductionData: [],
    wasteToBiocharProductionDataTotals: []
};

// PETROL REDUCERS
export default function
    indirectGhgEmissionsFromOtherSourcesForWasteToBiocharProduction(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        // STATIONARY PETROL
        case POST_WASTE_TO_BIOCHAR_PRODUCTION_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_WASTE_TO_BIOCHAR_PRODUCTION_DATA_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_WASTE_TO_BIOCHAR_PRODUCTION_DATA_SUCCESS:
            return {
                ...state,
                wasteToBiocharProductionData: payload,
                loading: false,
            };
        case GET_WASTE_TO_BIOCHAR_PRODUCTION_DATA_FAIL:
            return {
                ...state,
                wasteToBiocharProductionData: payload,
                loading: false
            }
        case GET_WASTE_TO_BIOCHAR_PRODUCTION_DATA_TOTALS_SUCCESS:
            return {
                ...state,
                wasteToBiocharProductionDataTotals: payload,
                loading: false,
            };
        case GET_WASTE_TO_BIOCHAR_PRODUCTION_DATA_TOTALS_FAIL:
            return {
                ...state,
                wasteToBiocharProductionDataTotals: payload,
                loading: false
            }

        default:
            return state;
    }
}


