import { setAlert } from '../alert';
import {
    API_GET_ROF_BURNS_BATCH_DATA,
    API_GET_ROF_BURNS_BATCH_MEDIA,
    API_GET_ROF_BURNS_ADDITIONAL_BATCH_DATA,
    API_GET_ROF_BURNS_BATCH_VOLUMES,
    API_GET_ROF_BURNS_BATCH_BULK_DENSITY,
    API_GET_ROF_BURNS_BIOCHAR_MASS,
    API_GET_ROF_BURNS_BATCH_CARBON_CONTENT,
    API_GET_ROF_BURNS_BIOCHAR_STORED_CARBON,
    API_POST_ROF_BURNS_BATCH_CCIK,
    API_POST_ROF_BURNS_BIOCHAR_STORED_CARBON,
    API_POST_ROF_BURNS_LAB_REPORT_FILE,
    API_GET_ROF_BURNS_LAB_REPORT_FILES,
    API_POST_BASE_LOG_KILN,
    API_GET_BASE_LOG_KILNS,
    API_GET_PROJECT_BATCHES,
    API_GET_DOWNLOAD_BATCH_REPORT
} from './constants/rof-burn-constants';
import {
    GET_ROF_BURNS_BATCH_DATA_SUCCESS,
    GET_ROF_BURNS_BATCH_DATA_FAIL,
    GET_ROF_BURNS_ADDITIONAL_BATCH_DATA_SUCCESS,
    GET_ROF_BURNS_ADDITIONAL_BATCH_DATA_FAIL,
    GET_ROF_BURNS_BATCH_VOLUMES_SUCCESS,
    GET_ROF_BURNS_BATCH_VOLUMES_FAIL,
    GET_ROF_BURNS_BATCH_BULK_DENSITY_SUCCESS,
    GET_ROF_BURNS_BATCH_BULK_DENSITY_FAIL,
    GET_ROF_BURNS_BIOCHAR_MASS_SUCCESS,
    GET_ROF_BURNS_BIOCHAR_MASS_FAIL,
    GET_ROF_BURNS_BATCH_CARBON_CONTENT_SUCCESS,
    GET_ROF_BURNS_BATCH_CARBON_CONTENT_FAIL,
    GET_ROF_BURNS_BIOCHAR_STORED_CARBON_SUCCESS,
    GET_ROF_BURNS_BIOCHAR_STORED_CARBON_FAIL,
    POST_ROF_BURNS_BATCH_CCIK_SUCCESS,
    POST_ROF_BURNS_BATCH_CCIK_FAIL,
    POST_ROF_BURNS_BIOCHAR_STORED_CARBON_SUCCESS,
    POST_ROF_BURNS_BIOCHAR_STORED_CARBON_FAIL,
    POST_ROF_BURNS_LAB_REPORT_FILE_SUCCESS,
    POST_ROF_BURNS_LAB_REPORT_FILE_FAIL,
    GET_ROF_BURNS_LAB_REPORT_FILES_SUCCESS,
    GET_ROF_BURNS_LAB_REPORT_FILES_FAIL,
    POST_BASE_LOG_KILN_SUCCESS,
    POST_BASE_LOG_KILN_FAIL,
    GET_BASE_LOG_KILNS_SUCCESS,
    GET_BASE_LOG_KILNS_FAIL,
    GET_ROF_BURNS_BATCH_MEDIA_SUCCESS,
    GET_ROF_BURNS_BATCH_MEDIA_FAIL,
    GET_PROJECT_BATCHES_SUCCESS,
    GET_PROJECT_BATCHES_FAIL,
    GET_DOWNLOAD_BATCH_REPORT_SUCCESS,
    GET_DOWNLOAD_BATCH_REPORT_FAIL
} from './types/rof-burn-types';

import axios from 'axios';
const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    },
    // withCredentials: true,
};

const configFiles = {
    headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
    }
};

const configBlob = {
    method: "GET",
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    // withCredentials: true,
    responseType: 'blob',
};

// New GET Actions
export const getRofBurnsBatchData = (project) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_ROF_BURNS_BATCH_DATA + `/${project}`,
            config
        );
        dispatch({
            type: GET_ROF_BURNS_BATCH_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_ROF_BURNS_BATCH_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getRofBurnsBatchMedia = (project) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_ROF_BURNS_BATCH_MEDIA + `/${project}`,
            config
        );
        dispatch({
            type: GET_ROF_BURNS_BATCH_MEDIA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_ROF_BURNS_BATCH_MEDIA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getRofBurnsAdditionalBatchData = (project) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_ROF_BURNS_ADDITIONAL_BATCH_DATA + `/${project}`,
            config
        );
        dispatch({
            type: GET_ROF_BURNS_ADDITIONAL_BATCH_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_ROF_BURNS_ADDITIONAL_BATCH_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getRofBurnsBatchVolumes = (project) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_ROF_BURNS_BATCH_VOLUMES + `/${project}`,
            config
        );
        dispatch({
            type: GET_ROF_BURNS_BATCH_VOLUMES_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_ROF_BURNS_BATCH_VOLUMES_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getRofBurnsBatchBulkDensity = (project) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_ROF_BURNS_BATCH_BULK_DENSITY + `/${project}`,
            config
        );
        dispatch({
            type: GET_ROF_BURNS_BATCH_BULK_DENSITY_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_ROF_BURNS_BATCH_BULK_DENSITY_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getRofBurnsBiocharMass = (project) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_ROF_BURNS_BIOCHAR_MASS + `/${project}`,
            config
        );
        dispatch({
            type: GET_ROF_BURNS_BIOCHAR_MASS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_ROF_BURNS_BIOCHAR_MASS_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getRofBurnsBatchCarbonContent = (project) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_ROF_BURNS_BATCH_CARBON_CONTENT + `/${project}`,
            config
        );
        dispatch({
            type: GET_ROF_BURNS_BATCH_CARBON_CONTENT_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_ROF_BURNS_BATCH_CARBON_CONTENT_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getRofBurnsBiocharStoredCarbon = (project) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_ROF_BURNS_BIOCHAR_STORED_CARBON + `/${project}`,
            config
        );
        dispatch({
            type: GET_ROF_BURNS_BIOCHAR_STORED_CARBON_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_ROF_BURNS_BIOCHAR_STORED_CARBON_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const postRofBatchCarbonContentCcik = (data, project) => async dispatch => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_ROF_BURNS_BATCH_CCIK + `/${project}`,
            data,
            config
        );
        dispatch({
            type: POST_ROF_BURNS_BATCH_CCIK_SUCCESS,
        });
        dispatch(setAlert(response.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_ROF_BURNS_BATCH_CCIK_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const postRofBurnsBiocharStoredCarbon = (data, project) => async dispatch => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_ROF_BURNS_BIOCHAR_STORED_CARBON + `/${project}`,
            data,
            config
        );
        dispatch({
            type: POST_ROF_BURNS_BIOCHAR_STORED_CARBON_SUCCESS
        });
        dispatch(setAlert(response.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_ROF_BURNS_BIOCHAR_STORED_CARBON_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const rofLabReportFileUpload =
    (data, project) => async dispatch => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_URL +
                API_POST_ROF_BURNS_LAB_REPORT_FILE +
                `/${project}`,
                data,
                configFiles
            );
            dispatch({
                type: POST_ROF_BURNS_LAB_REPORT_FILE_SUCCESS,
                // payload: data,
            });
            dispatch(setAlert(response.data.detail, 'success'));
        } catch (err) {
            dispatch(setAlert(err.response.detail, 'error'));
            dispatch({
                type: POST_ROF_BURNS_LAB_REPORT_FILE_FAIL,
                payload: err.response.data.detail,
            });
        }
    };

export const rofLabReportFiles =
    (projectId) => async dispatch => {
        try {
            const { data } = await axios.get(
                process.env.REACT_APP_API_URL +
                API_GET_ROF_BURNS_LAB_REPORT_FILES +
                `/${projectId}`,
                config
            );
            dispatch({
                type: GET_ROF_BURNS_LAB_REPORT_FILES_SUCCESS,
                payload: data,
            });
            dispatch(setAlert(data.detail, 'success'));
        } catch (err) {
            dispatch(setAlert(err.response.detail, 'error'));
            dispatch({
                type: GET_ROF_BURNS_LAB_REPORT_FILES_FAIL,
                payload: err.response.data.detail,
            });
        }
    };


export const postLogKiln = (data, project) => async dispatch => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_BASE_LOG_KILN + `/${project}`,
            data,
            config
        );
        dispatch({
            type: POST_BASE_LOG_KILN_SUCCESS
        });
        dispatch(setAlert(response.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_BASE_LOG_KILN_FAIL,
            payload: err.response.data.detail,
        });
    }
};


export const getLogKilns = (project) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_BASE_LOG_KILNS + `/${project}`,
            config
        );
        dispatch({
            type: GET_BASE_LOG_KILNS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_BASE_LOG_KILNS_FAIL,
            payload: err.response.data.detail,
        });
    }
};


export const getProjectBatches = (project) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_PROJECT_BATCHES + `/${project}`,
            config
        );
        dispatch({
            type: GET_PROJECT_BATCHES_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_PROJECT_BATCHES_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const downloadBatchReport = (projectId, batchId) => async dispatch => {
    try {
        const response = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_DOWNLOAD_BATCH_REPORT + `/${projectId}/${batchId}`,
            configBlob
        );
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', 'batch_volumes.pdf'); // Set the file name
        document.body.appendChild(link);
        link.click();
        dispatch({
            type: GET_DOWNLOAD_BATCH_REPORT_SUCCESS,
        });
        dispatch(setAlert(response.data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_DOWNLOAD_BATCH_REPORT_FAIL,
            payload: err.response.data.detail,
        });
    }
};