export function Clock() {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.75 10.5C18.75 7.375 17.0703 4.52344 14.375 2.96094C11.6406 1.35938 8.32031 1.35938 5.625 2.96094C2.89062 4.52344 1.25 7.375 1.25 10.5C1.25 13.6641 2.89062 16.5156 5.625 18.0781C8.32031 19.6797 11.6406 19.6797 14.375 18.0781C17.0703 16.5156 18.75 13.6641 18.75 10.5ZM0 10.5C0 6.94531 1.875 3.66406 5 1.86719C8.08594 0.0703125 11.875 0.0703125 15 1.86719C18.0859 3.66406 20 6.94531 20 10.5C20 14.0938 18.0859 17.375 15 19.1719C11.875 20.9688 8.08594 20.9688 5 19.1719C1.875 17.375 0 14.0938 0 10.5ZM9.375 4.875C9.375 4.5625 9.64844 4.25 10 4.25C10.3125 4.25 10.625 4.5625 10.625 4.875V10.1875L14.0625 12.4922C14.375 12.6875 14.4531 13.0781 14.2578 13.3516C14.0625 13.6641 13.6719 13.7422 13.3984 13.5469L9.64844 11.0469C9.45312 10.9297 9.375 10.7344 9.375 10.5V4.875Z'
        fill='#DA9DE3'
      />
    </svg>
  );
}
