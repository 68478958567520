import { setAlert } from '../../alert';

import {
    GET_PACKAGING_MATERIALS_DATA_SUCCESS,
    GET_PACKAGING_MATERIALS_DATA_FAIL,
    POST_PACKAGING_MATERIALS_DATA_SUCCESS,
    POST_PACKAGING_MATERIALS_DATA_FAIL,
    GET_PACKAGING_MATERIALS_DATA_TOTALS_SUCCESS,
    GET_PACKAGING_MATERIALS_DATA_TOTALS_FAIL,
} from '../types/04-indirect-ghg-emissions-from-products-used-by-an-organisation';

import {
    API_GET_PACKAGING_MATERIALS,
    API_POST_PACKAGING_MATERIALS,
    API_GET_PACKAGING_MATERIALS_TOTALS,
} from '../constants/04-indirect-ghg-emissions-from-products-used-by-an-organisation';

import axios from 'axios';

const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    },
    // withCredentials: true,
};


export const postPackagingMaterialsData = (formData, year, company, group, facility) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_PACKAGING_MATERIALS + `/${year}/${company}/${group}/${facility}`,
            formData,
            config
        );
        dispatch({
            type: POST_PACKAGING_MATERIALS_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_PACKAGING_MATERIALS_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getPackagingMaterialsData = (year, facility) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_PACKAGING_MATERIALS + `/${year}/${facility}`,
            config
        );
        dispatch({
            type: GET_PACKAGING_MATERIALS_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_PACKAGING_MATERIALS_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getPackagingMaterialsDataTotals = (year, facility) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_PACKAGING_MATERIALS_TOTALS + `/${year}/${facility}`,
            config
        );
        dispatch({
            type: GET_PACKAGING_MATERIALS_DATA_TOTALS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_PACKAGING_MATERIALS_DATA_TOTALS_FAIL,
            payload: err.response.data.detail,
        });
    }
};