import { useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';

export default function F003AddFirebreak({ data, setData, setFormId }) {
  useEffect(() => {
    setFormId('F003');
  }, [setFormId]);

  const {
    nameOfFirebreak,
    firebreak_map,
    typeOfFirebreak,
    date,
    nextMaintenanceDate,
    FPP,
  } = data;

  return (
    <>
      <Typography variant='h5' sx={{ mb: 2 }}>
        Add Firebreak
      </Typography>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Name
      </Typography>
      <TextField
        label='Name'
        type='text'
        name='nameOfFirebreak'
        value={nameOfFirebreak}
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Please upload Firebreak map file
      </Typography>
      <TextField
        name='firebreak_map'
        id='doc'
        type='file'
        value={firebreak_map}
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Type of firebreak
      </Typography>
      <FormControl fullWidth>
        <InputLabel id='demo-simple-select-label'>
          Select type of firebreak
        </InputLabel>
        <Select
          name='typeOfFirebreak'
          label='Type of firebreak'
          value={typeOfFirebreak}
          onChange={setData}
        >
          <MenuItem value='Property perimeter'>Property perimeter</MenuItem>
          <MenuItem value='Internal Conservation Area'>
            Internal Conservation Area
          </MenuItem>
          <MenuItem value='Tracer belt'>Tracer belt</MenuItem>
        </Select>
      </FormControl>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Select date of last maintenance
      </Typography>
      <TextField
        name='date'
        type='date'
        value={date}
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Has the next maintenance been scheduled?
      </Typography>
      <RadioGroup row name='maintenanceScheduled' onChange={setData}>
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='maintenanceScheduled'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='maintenanceScheduled'
        />
      </RadioGroup>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        If yes, please select date
      </Typography>
      <TextField
        name='nextMaintenanceDate'
        type='date'
        value={nextMaintenanceDate}
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Is this firebreak shared with a neighbour?
      </Typography>
      <RadioGroup row name='fireBreaksShared' onChange={setData}>
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='fireBreaksShared'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='fireBreaksShared'
        />
      </RadioGroup>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Do you have a defined Fixed Point Photography (FPP) from where you can
        clearly see this firebreak?
      </Typography>
      <RadioGroup row name='fppDefined' onChange={setData}>
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='fppDefined'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='fppDefined'
        />
      </RadioGroup>
      <TextField
        name='FPP'
        label='If yes, please name the FPP to something you can easily remember'
        value={FPP}
        onChange={setData}
        fullWidth
      />
    </>
  );
}
