import React from 'react'
import { TableContainer, Table, TableHead, TableCell, TableRow, Paper, TableBody } from '@mui/material';

const ConversionFactors = () => {
    return (
        <>
            <h2>Conversion Factors</h2>
            <TableContainer key={0} component={Paper} sx={{ height: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                Conversion Factor Description
                            </TableCell>
                            <TableCell align="left">
                                Conversion
                            </TableCell>
                            <TableCell align="left">
                                Value (Conversion Factor)
                            </TableCell>
                            <TableCell align="left">
                                Unit
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">Gallon to Liter</TableCell>
                            <TableCell align="left">galToL</TableCell>
                            <TableCell align="left">3.785412534</TableCell>
                            <TableCell align="left">L/gal</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Cubic yard to Cubic Meter</TableCell>
                            <TableCell align="left">yrd3Tom3</TableCell>
                            <TableCell align="left">0.76455522</TableCell>
                            <TableCell align="left">m3/yrd3</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Miles to km</TableCell>
                            <TableCell align="left">milesTokm</TableCell>
                            <TableCell align="left">1.609344498</TableCell>
                            <TableCell align="left">km/mile</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Inch to cm</TableCell>
                            <TableCell align="left">inchTocm</TableCell>
                            <TableCell align="left">2.539998628</TableCell>
                            <TableCell align="left">cm/inch</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Standard cubic feet to cubic meter</TableCell>
                            <TableCell align="left">scfTom3</TableCell>
                            <TableCell align="left">0.0283168</TableCell>
                            <TableCell align="left">m3/scf</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Cubic inch to cubic meter</TableCell>
                            <TableCell align="left">Inch3Tom3</TableCell>
                            <TableCell align="left">0.00001639</TableCell>
                            <TableCell align="left">m3/inch3</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ConversionFactors