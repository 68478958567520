import { useEffect } from 'react';
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

export default function F019FirefightingEquipment({
  data,
  setData,
  setFormId,
}) {
  useEffect(() => {
    setFormId('F019');
  }, [setFormId]);

  const {
    farmSize,
    bakkieSakkie,
    bakkie_sakkie_doc,
    waterTanker1000,
    water_tanker_1000_doc,
    waterTanker2000,
    water_tanker_2000_doc,
    rakeHoles,
    rake_holes_doc,
    beaters,
    beaters_doc,
    knapsacks,
    knapsacks_doc,
    dripTorch,
    drip_torch_doc,
    cellphone,
    cellphone_doc,
    handHeld,
    handheld_doc,
    mobileRadio,
    mobile_radio_doc,
    firstAidKit,
    first_aid_kit_doc,
    firefighters,
    firefighters_doc,
    crewLeaders,
    crew_leaders_doc,
  } = data;

  return (
    <>
      <Typography variant='h5' sx={{ mb: 2 }}>
        Firefighting equipment checklist
      </Typography>
      <FormControl
        sx={{
          mt: 1,
        }}
        fullWidth
      >
        <InputLabel id='demo-simple-select-label'>
          Select the size of your farm
        </InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          label='Select the size of your farm'
          name='farmSize'
          value={farmSize}
          onChange={setData}
        >
          <MenuItem value={'< 10ha'}>{'< 10ha'}</MenuItem>
          <MenuItem value={'26 - 100ha'}>{'26 - 100ha'}</MenuItem>
          <MenuItem values={'101 - 500ha'}>{'101 - 500ha'}</MenuItem>
          <MenuItem value={'501 - 1000ha'}>{'501 - 1000ha'}</MenuItem>
          <MenuItem value={'1001 - 4000ha'}>{'1001 - 4000ha'}</MenuItem>
          <MenuItem value={'4001 - 10000ha'}>{'4001 - 10000ha'}</MenuItem>
          <MenuItem value={'> 10000ha'}>{'> 10000ha'}</MenuItem>
          <MenuItem value={'Cane Grower < 25ha'}>
            {'Cane Grower < 25ha'}
          </MenuItem>
          <MenuItem value={'Cane Grower > 26ha'}>
            {'Cane Grower > 26ha'}
          </MenuItem>
        </Select>
      </FormControl>
      <Divider sx={{ mt: 2 }} />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Specify the number of the following items/staff on your farm, even if it
        is 0. If they are present, please upload pictures or select the right
        collaborators
      </Typography>
      <Divider sx={{ mt: 2 }} />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        “Bakkie Sakkie” min 200L or tractor/trailer
      </Typography>
      <TextField
        name='bakkieSakkie'
        label='“Bakkie Sakkie” min 200L or tractor/trailer'
        type='text'
        value={bakkieSakkie}
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Upload a picture
      </Typography>
      <TextField
        name='bakkie_sakkie_doc'
        value={bakkie_sakkie_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Water tanker min 1000L
      </Typography>
      <TextField
        name='waterTanker1000'
        label='Water tanker min 1000L'
        type='text'
        value={waterTanker1000}
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Upload a picture
      </Typography>
      <TextField
        name='water_tanker_1000_doc'
        value={water_tanker_1000_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Water tanker min 2000L
      </Typography>
      <TextField
        name='waterTanker2000'
        label='Water tanker min 2000L'
        type='text'
        value={waterTanker2000}
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Upload a picture
      </Typography>
      <TextField
        name='water_tanker_2000_doc'
        value={water_tanker_2000_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Rake holes
      </Typography>
      <TextField
        name='rakeHoles'
        label='Rake holes'
        value={rakeHoles}
        type='text'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Upload a picture
      </Typography>
      <TextField
        name='rake_holdes_doc'
        value={rake_holes_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Beaters
      </Typography>
      <TextField
        name='beaters'
        label='Beaters'
        type='text'
        value={beaters}
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Upload a picture
      </Typography>
      <TextField
        name='beaters_doc'
        value={beaters_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Knapsacks min 15L
      </Typography>
      <TextField
        name='knapsacks'
        label='Knapsacks min 15L'
        type='text'
        value={knapsacks}
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Upload a picture
      </Typography>
      <TextField
        name='knapsacks_doc'
        value={knapsacks_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Drip torch
      </Typography>
      <TextField
        name='dripTorch'
        label='Drip torch'
        value={dripTorch}
        type='text'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Upload a picture
      </Typography>
      <TextField
        name='drip_torch_doc'
        value={drip_torch_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Cellphone
      </Typography>
      <TextField
        name='cellphone'
        label='Cellphone'
        value={cellphone}
        type='text'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Upload a picture
      </Typography>
      <TextField
        name='cellphone_doc'
        value={cellphone_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Hand held radio
      </Typography>
      <TextField
        name='handHeld'
        label='Hand held radio'
        value={handHeld}
        type='text'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Upload a picture
      </Typography>
      <TextField
        name='handheld_doc'
        value={handheld_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Mobile radio
      </Typography>
      <TextField
        name='mobileRadio'
        label='Mobile radio'
        value={mobileRadio}
        type='text'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Upload a picture
      </Typography>
      <TextField
        name='mobile_radio_doc'
        value={mobile_radio_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        First aid kit
      </Typography>
      <TextField
        name='firstAidKit'
        label='First aid kit'
        value={firstAidKit}
        type='text'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Upload a picture
      </Typography>
      <TextField
        name='first_aid_kit_doc'
        value={first_aid_kit_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Firefighters
      </Typography>
      <TextField
        name='firefighters'
        label='Firefighters'
        value={firefighters}
        type='text'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Upload a picture
      </Typography>
      <TextField
        name='firefighters_doc'
        value={firefighters_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Crewleaders
      </Typography>
      <TextField
        name='crewLeaders'
        label='Crewleaders'
        value={crewLeaders}
        type='text'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Upload a picture
      </Typography>
      <TextField
        name='crew_leaders_doc'
        value={crew_leaders_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
    </>
  );
}
