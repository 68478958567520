import { useEffect } from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

export default function F018AccessChecklist({ data, setData, setFormId }) {
  useEffect(() => {
    setFormId('F018');
  }, [setFormId]);

  const {
    roads_doc,
    signage_doc,
    gate_keys_doc,
    disable_electric_fences_doc,
    turning_circles_for_firetrucks_doc,
  } = data;

  return (
    <>
      <Typography variant='h5' sx={{ mb: 2 }}>
        Access checklist
      </Typography>
      <Typography variant='h5'>
        Do you have the following elements enabling easy access to firefighters
        in place in your property?
      </Typography>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Roads
      </Typography>
      <RadioGroup
        row
        aria-labelledby='demo-radio-buttons-group-label'
        name='roads'
        onChange={setData}
      >
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='roads'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='roads'
        />
      </RadioGroup>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        If Yes, Upload document
      </Typography>
      <TextField
        name='roads_doc'
        value={roads_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Signage
      </Typography>
      <RadioGroup
        row
        aria-labelledby='demo-radio-buttons-group-label'
        name='signage'
        onChange={setData}
      >
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='signage'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='signage'
        />
      </RadioGroup>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        If Yes, Upload document
      </Typography>
      <TextField
        name='signage_doc'
        value={signage_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Gate keys/codes
      </Typography>
      <RadioGroup
        row
        aria-labelledby='demo-radio-buttons-group-label'
        name='gateKeys'
        onChange={setData}
      >
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='gateKeys'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='gateKeys'
        />
      </RadioGroup>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        If Yes, Upload document
      </Typography>
      <TextField
        name='gate_keys_doc'
        value={gate_keys_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Disabling of electric fences and gates
      </Typography>
      <RadioGroup
        row
        aria-labelledby='demo-radio-buttons-group-label'
        name='disableElectricFences'
        onChange={setData}
      >
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='disableElectricFences'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='disableElectricFences'
        />
      </RadioGroup>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        If Yes, Upload document
      </Typography>
      <TextField
        name='disable_electric_fences_doc'
        value={disable_electric_fences_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Turning circles for firetrucks
      </Typography>
      <RadioGroup
        row
        aria-labelledby='demo-radio-buttons-group-label'
        name='turningCirclesForFiretrucks'
        onChange={setData}
      >
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='turningCirclesForFiretrucks'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='turningCirclesForFiretrucks'
        />
      </RadioGroup>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        If Yes, Upload document
      </Typography>
      <TextField
        name='turning_circles_for_firetrucks_doc'
        value={turning_circles_for_firetrucks_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
    </>
  );
}
