import { useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import F002GeneralQuestions from './types/F002GeneralQuestions';
import F003AddFirebreak from './types/F003AddFirebreak';
import F006PlanNewFirebreak from './types/F006PlanNewFirebreak';
import F011ScheduleMaintenance from './types/F011ScheduleMaintenance';

export default function Firebreaks({
  dataF002,
  dataF003,
  dataF006,
  dataF011,
  setData,
  setFormId,
  next,
  active,
  totalItems,
}) {
  const items = [
    {
      id: 0,
      name: 'General Questions',
    },
    {
      id: 1,
      name: 'Add Firebreak',
    },
    {
      id: 2,
      name: 'Plan New Firebreak',
    },
    {
      id: 3,
      name: 'Schedule Maintenance',
    },
  ];

  useEffect(() => {
    totalItems(items.length);
  }, [items.length, totalItems]);

  return (
    <Grid container>
      <Grid xs={3} sx={{ borderRight: '1px solid', p: 2 }}>
        {items.map(item => (
          <Button
            fullWidth
            key={item.id}
            onClick={() => next(item.id)}
            variant={active === item.id ? 'outlined' : null}
          >
            {item.name}
          </Button>
        ))}
      </Grid>
      <Grid xs={9} sx={{ p: 2 }}>
        {active === 0 ? (
          <F002GeneralQuestions
            data={dataF002}
            setData={setData}
            setFormId={setFormId}
          />
        ) : active === 1 ? (
          <F003AddFirebreak
            data={dataF003}
            setData={e => setData(e)}
            setFormId={setFormId}
          />
        ) : active === 2 ? (
          <F006PlanNewFirebreak
            data={dataF006}
            setData={e => setData(e)}
            setFormId={setFormId}
          />
        ) : active === 3 ? (
          <F011ScheduleMaintenance
            data={dataF011}
            setData={e => setData(e)}
            setFormId={setFormId}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}
