import { Typography, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const [counter, setCounter] = useState(3);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setCounter((prevCounter) => {
                if (prevCounter <= 1) {
                    clearInterval(interval);
                    navigate('/');
                    return 0; // Immediately navigate when counter hits 0
                }
                return prevCounter - 1;
            });
        }, 1000);

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [navigate]); // Only re-run the effect if navigate changes

    return (
        <Grid container sx={{ width: '100%', height: '100vh', background: "linear-gradient(to bottom, #D7CBFF, #212746)" }} justifyContent="center" alignItems="center" flexDirection={'column'}>
            <Typography variant="h4" sx={{ color: 'lightgrey' }}>Requested page not found</Typography><br />
            <Typography variant="h4" sx={{ color: 'lightgrey' }}>{counter <= 0 ? <></> : `You will be redirected in ${counter} seconds`}</Typography><br />
        </Grid>
    );
};

export default NotFound;