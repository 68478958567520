import { Box, Grid, Typography } from '@mui/material';
import Logo from '../../../assets/images/IkhalaLogo.svg';
import Ios from './svgs/ios'
import Android from './svgs/android'


const Footer = () => {
  return (
    <Grid container spacing={2} className='footer'>
      <Grid
        item
        lg={12}
        className='footer-section-one'
        sx={{
          width: '100%',
          flexDirection: { xs: 'column', sm: 'column', md: 'row' },
        }}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Box
          display={'flex'}
          sx={{
            flexDirection: { xs: 'column', sm: 'row', md: 'row' },
            width: '100%',
            justifyContent: { sm: 'space-between' },
          }}
        >
          <Box sx={{ ml: { xs: '20%', lg: '168px' }, display: 'flex', alignItems: 'center' }}>
            <Ios />
            <Android />


          </Box>
          <Box sx={{ mr: { lg: '168px' }, ml: { xs: '20%' } }}>
            <Typography sx={{ fontSize: '15px' }} visibility={'hidden'}>
              Placeholder
            </Typography>
            <Typography sx={{ fontSize: '13px', color: '#838282' }}>
              Project Development: wihan@ikhala.tech
              <br />
              General Enquiries: admin@ikhala.tech
              <br />
              Phone: 071 859 6656
            </Typography>
          </Box>
        </Box>
      </Grid>
      <hr style={{ width: '100%', margin: 'auto 6rem' }}></hr>
      <Grid item lg={12} className='footer-section-two'>
        <Box
          sx={{
            width: '100%',
            flexDirection: { xs: 'column', sm: 'column', md: 'row' },
          }}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <img
            src={Logo}
            width={80}
            height={80}
            style={{ padding: '0', margin: '0', opacity: '0.5' }}
            alt='ikhala-logo'
          />
          <Typography className='footer-brand'>
            <b>Ikhala {new Date().getFullYear()}</b>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;
