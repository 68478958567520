import { useParams } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import Home from './Home';
import MapTool from './MapTool';
import EcosystemAccount from './EcosystemAccount';
import Tokens from './Tokens';
import Contracts from './Contracts';
import Wallet from './Wallet';
import Support from './Support';
import Apply from '../sections/programmes/Apply';
import MyProgrammes from '../sections/programmes/MyProgrammes';
import ResourcesPage from './Resources';
import ProjectQuickLink from './ProjectQuickLink';
import CarbonFootprintingNew from '../sections/carbonfootprintingrework/CarbonFootprintingNew';
import CarRppb from '../sections/tigercatburns/CarRppb';
import GlobalVariables from '../sections/tigercatburns/GlobalVariables';
import Users from '../sections/tigercatburns/Users';
import Operations from '../sections/tigercatburns/Operations';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MainLayout({ userType, userData }) {
  const { page, projectId } = useParams();

  return (
    <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
      <DrawerHeader />
      {page === 'map-tool' ? (
        <MapTool />
      ) : page === 'programs' ? (
        <MyProgrammes />
      ) : page === 'apply' ? (
        <Apply />
      ) : page === 'ecosystem-account' ? (
        <EcosystemAccount />
      ) : page === 'tokens' ? (
        <Tokens />
      ) : page === 'contracts' ? (
        <Contracts />
      ) : page === 'wallet' ? (
        <Wallet />
      ) : page === 'support-&-faqs' ? (
        <Support />
      ) : page === 'carbon-footprinting' ? (
        <CarbonFootprintingNew />
      ) : page === 'resources' ? (
        <ResourcesPage />
      ) : page === undefined && projectId ? (
        <ProjectQuickLink />
      ) : page === 'car-reporting' ? (
        <CarRppb userData={userData} />
      ) : page === 'global-variables' ? (
        <GlobalVariables userData={userData} />
      ) : page === 'users' ? (
        <Users />
      ) : page === 'operations' ? (
        <Operations />
      ) : (
        <Home userType={userType} />
      )}
    </Box>
  );
}
