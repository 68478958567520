import React from "react";
import { Grid, TextField, Typography } from "@mui/material";

const fields = [
  { id: 'input-labor-costs', labelFunc: (currency) => `General Labor Costs per day (${currency})`, state: 'laborCosts', setState: 'setLaborCosts'},
  { id: 'input-burnSupervisor-costs', labelFunc: (currency) => `Burn Supervisor Costs per day (${currency})`, state: 'burnSupervisorCosts', setState: 'setBurnSupervisorCosts'},
  { id: 'input-capital-costs', labelFunc: (currency) => `Capital Cost per kiln (${currency})`, state: 'capitalCosts', setState: 'setCapitalCosts'},
  { id: 'input-other-costs', labelFunc: (currency) => `Other Costs per day (${currency})`, state: 'otherCosts', setState: 'setOtherCosts'},
];

const Step3Form = (props) => {
  const handleChange = (setter) => ({ target: { value } }) => {
    setter(value);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          OPEX and CAPEX Information
        </Typography>
      </Grid>
      {fields.map(({id, labelFunc, state, setState}) => (
        <Grid item xs={12} sm={6} key={id}>
          <TextField
            required
            fullWidth
            id={id}
            label={labelFunc(props.currency)}
            variant="outlined"
            value={props[state]}
            onChange={handleChange(props[setState])}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Step3Form;
