import {
    POST_DRY_ICE_DATA_SUCCESS,
    POST_DRY_ICE_DATA_FAIL,
    GET_DRY_ICE_DATA_SUCCESS,
    GET_DRY_ICE_DATA_FAIL,
    GET_DRY_ICE_DATA_TOTALS_SUCCESS,
    GET_DRY_ICE_DATA_TOTALS_FAIL
} from '../../../actions/carbonfootprint-rework/types/01-direct-ghg-emissions-and-removals-types';


const initialState = {
    loading: true,
    dryIceData: [],
    dryIceDataTotals: []
};

// PETROL REDUCERS
export default function
    directGhgEmissionsAndRemovalsForDryIce(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        // STATIONARY PETROL
        case POST_DRY_ICE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_DRY_ICE_DATA_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_DRY_ICE_DATA_SUCCESS:
            return {
                ...state,
                dryIceData: payload,
                loading: false,
            };
        case GET_DRY_ICE_DATA_FAIL:
            return {
                ...state,
                dryIceData: payload,
                loading: false
            }
        case GET_DRY_ICE_DATA_TOTALS_SUCCESS:
            return {
                ...state,
                dryIceDataTotals: payload,
                loading: false,
            };
        case GET_DRY_ICE_DATA_TOTALS_FAIL:
            return {
                ...state,
                dryIceDataTotals: payload,
                loading: false
            }

        default:
            return state;
    }
}


