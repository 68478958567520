import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, CircularProgress, Tabs, Tab, Paper } from '@mui/material';
import { getPlotList, createPlotList } from '../../../../../../actions/carbonfootprint';
import AddItem from '../../../../carbonfootprinting/ItemAdd';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function CultivationPlotList() {
  const dispatch = useDispatch();

  const group = localStorage.getItem('group')
  const year = localStorage.getItem('year')

  const { loading, plotlist } = useSelector(state => state.carbonfootprint.plotlist);

  useEffect(() => {
    dispatch(getPlotList(group, year));
  }, [dispatch, group, year]);


  const columns = [
    { field: '_id', headerName: 'ID' },
    { field: 'block_name', headerName: 'Block Name', flex: 1 },
    {
      field: 'official_land_num',
      headerName: 'Official Land Number',
      flex: 1,
    },
    { field: 'hectares', headerName: 'Hectares' },
    { field: 'primary_crop', headerName: 'Primary Crop' },
  ];

  const formFields = [
    { name: 'blockName', label: 'Block Name', type: 'text' },
    { name: 'officialLandNumber', label: 'Official Land Number', type: 'text' },
    {
      name: 'hectares',
      label: 'Hectares',
      type: 'text'
    },
    {
      name: 'primaryCrop',
      label: 'Primary Crop',
      type: 'text'
    }
  ]

  const initialState = formFields.reduce((obj, field) => {
    obj[field.name] = '';
    return obj;
  }, {});

  const handleFormSubmit = data => {
    dispatch(createPlotList(data, group, year))
  };

  const [curTab, setCurTab] = useState(0);
  // console.log(plotlist)
  return (
    <Box style={{ width: '100%' }}>
      <Tabs
        value={curTab}
        onChange={() => setCurTab(0)}
      >
        <Tab label='Add Plotlist' />
      </Tabs>
      <AddItem
        formFields={formFields}
        initialState={initialState}
        onFormSubmit={handleFormSubmit}
        items={[]}
        addTitle='Add New Plotlist'
      // setValue={setValue}
      />
      {!loading ? (
        <Box style={{ width: '100%', height: '100%' }}>
          {/* <DataGrid
            rows={plotlist?.plotlist?.length > 0 ? plotlist.plotlist : []}
            getRowId={row => row._id}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            componen
            ts={{ Toolbar: GridToolbar }}
          /> */}
          <TableContainer component={Paper} sx={{ height: 'auto', }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.field} align="center">
                      {column.headerName}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {plotlist && plotlist?.length > 0 ? plotlist?.map((row) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {columns.map((column) => (
                      <TableCell key={column.field} align="center">
                        {row[column.field]}
                      </TableCell>
                    ))}
                  </TableRow>
                )) : null}

              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}
