import {
  Box,
  Paper,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import TokenIcon from '@mui/icons-material/Token';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Tokens() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Paper sx={{ p: 5, minHeight: '25em' }} elevation={5}>
          <Box sx={{ textAlign: 'center' }}>
            <TokenIcon sx={{ fontSize: '5em' }} />
            <Typography variant='h3' component='h3'>
              Programme Tokens
            </Typography>
          </Box>
          <Box sx={{ mt: 5 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography>Hedge</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  - 100m pilot hedge token <br />
                  - 1km hedge token
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel2a-content'
                id='panel2a-header'
              >
                <Typography>CBR</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  - 100m pilot hedge token <br />
                  - 1km hedge token
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper sx={{ p: 5, minHeight: '25em' }} elevation={5}>
          <Box sx={{ textAlign: 'center' }}>
            <TokenIcon sx={{ fontSize: '5em' }} />
            <Typography variant='h3' component='h3'>
              Claim Tokens
            </Typography>
          </Box>
          <Box sx={{ mt: 5 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography variant='h6'>
                  Green Tokens <br />
                  <Typography>(New Growth Sequestration)</Typography>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                  >
                    <Typography>Hedge</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      - 12 Green Tokens from 100m hedge <br />
                      - 120 GT's from 1km hedge
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel2a-content'
                    id='panel2a-header'
                  >
                    <Typography>Mob Grazing</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      - 120 GT's from 200 hectare Mob Grazing
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
