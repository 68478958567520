import React, { useEffect, useState } from 'react';
import { Grid, Button, TableContainer, Table, TableHead, TableCell, TableRow, Paper, TableBody, Typography, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getBatchVolumes, getBatchBulkDensity, getVolAndBdAverage, postVolAndBdAverage, postBatchCarbonContent, getBatchCarbonContent, postBiocharStoredCarbon, getBiocharStoredCarbon, getBiocharMass } from '../../../../../../actions/conservation-burns/conservation-burn-actions';

const CbDataTables = ({ cbItems, active, next, projectKey }) => {
    const [volAndBdAveragesState, setVolAndBdAveragesState] = useState([]);
    const [batchCarbonContentState, setBatchCarbonContentState] = useState([]);
    const [biocharStoredCarbonState, setBiocharStoredCarbonState] = useState([]);

    const batchData = useSelector(state => state.conservationBurnsReducer.batchVolumes.batchVolumes);
    const bulkDensity = useSelector(state => state.conservationBurnsReducer.bulkDensity.bulkDensities);
    const volAndBdAverages = useSelector(state => state.conservationBurnsReducer.volAndBdAverage.volAndBdAverages);
    const biocharMass = useSelector(state => state.conservationBurnsReducer.biocharMass.biocharMass);
    const batchCarbonContent = useSelector(state => state.conservationBurnsReducer.batchCarbonContent.batchCarbonContent);
    const biocharStoredCarbon = useSelector(state => state.conservationBurnsReducer.biocharStoredCarbon.biocharStoredCarbon);
    const loading = useSelector(state => state.conservationBurnsReducer.loading);
    const dispatch = useDispatch();

    useEffect(() => {
        if (active === 0)
            dispatch(getBatchVolumes(projectKey));
        else if (active === 1)
            dispatch(getBatchBulkDensity(projectKey));
        else if (active === 2)
            dispatch(getVolAndBdAverage(projectKey));
        else if (active === 3)
            dispatch(getBiocharMass(projectKey));
        else if (active === 4)
            dispatch(getBatchCarbonContent(projectKey));
        else if (active === 5)
            dispatch(getBiocharStoredCarbon(projectKey));
    }, [dispatch, active, projectKey]);

    useEffect(() => {
        if (active === 2) {
            setVolAndBdAveragesState(Array.isArray(volAndBdAverages) ? [...volAndBdAverages] : []);
        }
        else if (active === 4) {
            setBatchCarbonContentState(Array.isArray(batchCarbonContent) ? [...batchCarbonContent] : []);
        }
        else if (active === 5) {
            setBiocharStoredCarbonState(Array.isArray(biocharStoredCarbon) ? [...biocharStoredCarbon] : []);
        }
    }, [volAndBdAverages, batchCarbonContent, biocharStoredCarbon, active]);

    const prdebOptions = [{ id: 0, value: 0.65 }]

    // VOL & BD AVERAGE

    const handleAirCorrrectionFactorChange = (event, id) => {
        setVolAndBdAveragesState((prevState) => {
            return prevState.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        airCorrectionFactor: parseFloat(event.target.value)
                    }
                }
                return item;
            })
        })
    }

    const handleCharCorrrectionFactorChange = (event, id) => {
        setVolAndBdAveragesState((prevState) => {
            return prevState.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        charCorrectionFactor: parseFloat(event.target.value)
                    }
                }
                return item;
            })
        })
    }

    const handleVolAndBdAverageSubmission = () => {
        dispatch(postVolAndBdAverage(projectKey, volAndBdAveragesState)).then(() => {
            dispatch(getVolAndBdAverage(projectKey));
        });
    }

    // BATCH CARBON CONTENT

    const handleCcikChange = (event, id) => {
        setBatchCarbonContentState((prevState) => {
            return prevState.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        ccik: parseFloat(event.target.value)
                    }
                }
                return item;
            })
        })
    }

    const handleBatchCarbonContentSubmission = () => {
        dispatch(postBatchCarbonContent(projectKey, batchCarbonContentState)).then(() => {
            dispatch(getBatchCarbonContent(projectKey));
        });
    }

    // BIOCHAR STORED CARBON

    const handlePrdebOptionsChange = (event, id) => {
        setBiocharStoredCarbonState((prevState) => {
            return prevState.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        prdeb: parseFloat(event.target.value)
                    }
                }
                return item;
            })
        })
    }

    const handleBiocharStoredCarbonSubmission = () => {
        dispatch(postBiocharStoredCarbon(biocharStoredCarbonState)).then(() => {
            dispatch(getBiocharStoredCarbon(projectKey));
        });
    }

    return (
        <>
            <Grid item xs={2} sx={{ borderRight: '1px solid', p: 2 }}>
                {cbItems.map(item => (
                    <Button
                        fullWidth
                        key={item.id}
                        onClick={() => next(item.id)}
                        variant={active === item.id ? 'outlined' : null}
                    >
                        {item.name}
                    </Button>
                ))}
            </Grid>
            {active === 0 && (
                <Grid item xs={10} sx={{ p: 2 }}>
                    <TableContainer key={0} component={Paper} sx={{ height: 'auto' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        ProjectId
                                    </TableCell>
                                    <TableCell align="left">
                                        BatchId
                                    </TableCell>
                                    <TableCell align="left">
                                        CBId
                                    </TableCell>
                                    <TableCell align="left">
                                        Bucket Volume
                                    </TableCell>
                                    <TableCell align="left">
                                        Partially Burnt Volume
                                    </TableCell>
                                    <TableCell align="left">
                                        Unit
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {batchData?.map((bd, key) => {
                                    return (
                                        <TableRow key={key}>
                                            <TableCell align="left">{bd.projectId}</TableCell>
                                            <TableCell align="left">{bd.batchId}</TableCell>
                                            <TableCell align="left">{bd.cbId}</TableCell>
                                            <TableCell align="left">{bd.bucketVolume.toFixed(3)}</TableCell>
                                            <TableCell align="left">{bd.partiallyBurntVolume.toFixed(3)}</TableCell>
                                            <TableCell align="left">{bd.unit}</TableCell>
                                        </TableRow>
                                    )
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
            {active === 1 && (
                <Grid item xs={10} sx={{ p: 2 }}>
                    <TableContainer key={1} component={Paper} sx={{ height: 'auto' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">ProjectId</TableCell>
                                    <TableCell align="left">BatchId</TableCell>
                                    <TableCell align="left">CBId</TableCell>
                                    <TableCell align="left">Bucket Size (gal)</TableCell>
                                    <TableCell align="left">Empty Bucket Weight (lbs)</TableCell>
                                    <TableCell align="left">Hot Coals Bucket Weight (lbs)</TableCell>
                                    <TableCell align="left">Bulk Density</TableCell>
                                    <TableCell align="left">Unit</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bulkDensity?.map((bd, key) => {
                                    return (
                                        <TableRow key={key}>
                                            <TableCell align="left">{bd.projectId}</TableCell>
                                            <TableCell align="left">{bd.batchId}</TableCell>
                                            <TableCell align="left">{bd.cbId}</TableCell>
                                            <TableCell align="left">{bd.bucketSize}</TableCell>
                                            <TableCell align="left">{bd.emptyBucketWeight}</TableCell>
                                            <TableCell align="left">{bd.hotCoalsWeight}</TableCell>
                                            <TableCell align="left">{bd.bulkDensity.toFixed(3)}</TableCell>
                                            <TableCell align="left">{bd.unit}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
            {active === 2 && (
                <Grid item xs={10} sx={{ p: 2 }}>
                    <TableContainer key={2} component={Paper} sx={{ height: 'auto' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">ProjectId</TableCell>
                                    <TableCell align="left">BatchId</TableCell>
                                    <TableCell align="left">CBId</TableCell>
                                    <TableCell align="left">Bucket Volume Average (yrd3)</TableCell>
                                    <TableCell align="left">Partially Burnt Average (ft3)</TableCell>
                                    <TableCell align="left">Air Correction Factor</TableCell>
                                    <TableCell align="left">Char Correction Factor</TableCell>
                                    <TableCell align="left">Total Volume Average (yrd3)</TableCell>
                                    <TableCell align="left">Bulk Density Average (lbs/yrd3)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? <Typography>Loading...</Typography> :
                                    volAndBdAveragesState?.map((vbda, key) => {
                                        return (
                                            <TableRow key={key}>
                                                <TableCell align="left">{vbda.projectId}</TableCell>
                                                <TableCell align="left">{vbda.batchId}</TableCell>
                                                <TableCell align="left">{vbda.cbId}</TableCell>
                                                <TableCell align="left">{vbda.averageBucketVolume.toFixed(3)}</TableCell>
                                                <TableCell align="left">{vbda.averagePartiallyBurntVolume.toFixed(3)}</TableCell>
                                                <TableCell align="left">
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        placeholder="Enter value"
                                                        type="number"
                                                        fullWidth
                                                        value={vbda?.airCorrectionFactor ? vbda.airCorrectionFactor : null}
                                                        onChange={(e) => handleAirCorrrectionFactorChange(e, vbda.id)}
                                                    />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        placeholder="Enter value"
                                                        type="number"
                                                        fullWidth
                                                        value={vbda?.charCorrectionFactor ? vbda.charCorrectionFactor : null}
                                                        onChange={(e) => handleCharCorrrectionFactorChange(e, vbda.id)}
                                                    />
                                                </TableCell>
                                                <TableCell align="left">{vbda.totalVolumeAverage === null ? <Typography sx={{ color: 'darkorange' }}>Correction Factors not defined</Typography> : vbda.totalVolumeAverage.toFixed(3)}</TableCell>
                                                <TableCell align="left">{vbda.averageBulkDensity.toFixed(3)}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button variant="contained" sx={{ m: 2 }} onClick={handleVolAndBdAverageSubmission}>Submit Correction Factors</Button>
                </Grid>
            )}
            {active === 3 && (
                <Grid item xs={10} sx={{ p: 2 }}>
                    <TableContainer key={3} component={Paper} sx={{ height: 'auto' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">ProjectId</TableCell>
                                    <TableCell align="left">BatchId</TableCell>
                                    <TableCell align="left">CBId</TableCell>
                                    <TableCell align="left">Total Volume Average</TableCell>
                                    <TableCell align="left">Bulk Density Average</TableCell>
                                    <TableCell align="left">Biochar Pile Count</TableCell>
                                    <TableCell align="left">Biochar Mass</TableCell>
                                    <TableCell align="left">Unit</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? <Typography>Loading...</Typography> :
                                    biocharMass?.map((bm, key) => {
                                        return (
                                            <TableRow key={key}>
                                                <TableCell align="left">{bm.projectId}</TableCell>
                                                <TableCell align="left">{bm.batchId}</TableCell>
                                                <TableCell align="left">{bm.cbId}</TableCell>
                                                <TableCell align="left">{bm.totalVolumeAverage === null ? <Typography sx={{ color: 'darkorange' }}>Undetermined</Typography> : bm.totalVolumeAverage.toFixed(3)}</TableCell>
                                                <TableCell align="left">{bm.averageBulkDensity.toFixed(3)}</TableCell>
                                                <TableCell align="left">{bm.biocharPileCount}</TableCell>
                                                <TableCell align="left">{bm.biocharMass === null ? <Typography sx={{ color: 'darkorange' }}>Undetermined</Typography> : bm.biocharMass.toFixed(3)}</TableCell>
                                                <TableCell align="left">{bm.unit}</TableCell>
                                                {/* <TableCell align="left">
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        placeholder="Enter value"
                                                        type="number"
                                                        fullWidth
                                                        value={bm.airCorrectionFactor}
                                                        onChange={(e) => handleAirCorrrectionFactorChange(e, bm.id)}
                                                    />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        placeholder="Enter value"
                                                        type="number"
                                                        fullWidth
                                                        value={bm.charCorrectionFactor}
                                                        onChange={(e) => handleCharCorrrectionFactorChange(e, bm.id)}
                                                    />
                                                </TableCell>
                                                <TableCell align="left">{bm.totalVolumeAverage === null ? <Typography sx={{ color: 'darkorange' }}>Correction Factors not defined</Typography> : bm.totalVolumeAverage}</TableCell>
                                                <TableCell align="left">{bm.averageBulkDensity}</TableCell> */}
                                            </TableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
            {active === 4 && (
                <Grid item xs={10} sx={{ p: 2 }}>
                    <TableContainer key={4} component={Paper} sx={{ height: 'auto' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">ProjectId</TableCell>
                                    <TableCell align="left">BatchId</TableCell>
                                    <TableCell align="left">CBId</TableCell>
                                    <TableCell align="left">CCi,k (%)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? <Typography>Loading...</Typography> :
                                    batchCarbonContentState?.map((bcc, key) => {
                                        return (
                                            <TableRow key={key}>
                                                <TableCell>
                                                    {bcc.projectId}
                                                </TableCell>
                                                <TableCell>
                                                    {bcc.batchId}
                                                </TableCell>
                                                <TableCell>
                                                    {bcc.cbId}
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        placeholder="Enter value"
                                                        type="number"
                                                        value={bcc?.ccik ? bcc.ccik : null}
                                                        onChange={(e) => handleCcikChange(e, bcc.id)}
                                                    />
                                                </TableCell>
                                            </TableRow>)
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button variant="contained" sx={{ m: 2 }} onClick={handleBatchCarbonContentSubmission}>Submit Batch Carbon Content</Button>
                </Grid>
            )}
            {active === 5 && (
                <Grid item xs={10} sx={{ p: 2 }}>
                    <TableContainer key={5} component={Paper} sx={{ height: 'auto' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">ProjectId</TableCell>
                                    <TableCell align="left">BatchId</TableCell>
                                    <TableCell align="left">CBId</TableCell>
                                    <TableCell align="left">Biochar Mass (kg)</TableCell>
                                    <TableCell align="left">CCi,k</TableCell>
                                    <TableCell align="left">BSC (kg CO2e)</TableCell>
                                    <TableCell align="left">PRde,b</TableCell>
                                    <TableCell align="left">BSC (100y permanence, kg CO2e)</TableCell>
                                    <TableCell align="left">Unit</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? <Typography>Loading...</Typography> :
                                    biocharStoredCarbonState?.map((bcc, key) => {
                                        return (
                                            <TableRow key={key}>
                                                <TableCell>
                                                    {bcc.projectId}
                                                </TableCell>
                                                <TableCell>
                                                    {bcc.batchId}
                                                </TableCell>
                                                <TableCell>
                                                    {bcc.cbId}
                                                </TableCell>
                                                <TableCell>
                                                    {bcc?.biocharMass?.toFixed(3)}
                                                </TableCell>
                                                <TableCell>
                                                    {bcc.ccik === null ? <Typography sx={{ color: 'darkorange' }}>Undetermined</Typography> : bcc.ccik.toFixed(3)}
                                                </TableCell>
                                                <TableCell>
                                                    {bcc.bsc === null ? <Typography sx={{ color: 'darkorange' }}>Undetermined</Typography> : bcc.bsc.toFixed(3)}
                                                </TableCell>
                                                <TableCell>
                                                    <FormControl
                                                        sx={{
                                                            width: '175px'
                                                        }}
                                                    >
                                                        <InputLabel sx={{ background: 'white', pl: 1, pr: 1 }}>
                                                            Select PRde,b
                                                        </InputLabel>
                                                        <Select
                                                            name='prdebOptions'
                                                            // value={data?.prde ? data.prde : null}
                                                            value={bcc?.prdeb ? bcc.prdeb : null}
                                                            onChange={(e) => handlePrdebOptionsChange(e, bcc.id)}
                                                        // disabled={data.ccik ? false : true}
                                                        >
                                                            {prdebOptions.map(option => {
                                                                return (
                                                                    <MenuItem key={option.id} value={option.value}>
                                                                        {option.value}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell>
                                                    {bcc.bscPermanence === null ? <Typography sx={{ color: 'darkorange' }}>Undetermined</Typography> : bcc.bscPermanence.toFixed(3)}
                                                </TableCell>
                                                <TableCell>
                                                    {bcc.unit}
                                                </TableCell>
                                            </TableRow>)
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button variant="contained" sx={{ m: 2 }} onClick={handleBiocharStoredCarbonSubmission}>Submit Biochar Stored Carbon</Button>
                </Grid>
            )}
        </>
    )
}

export default CbDataTables;