import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell, Checkbox, TextField, Divider } from '@mui/material';
import { getCarRppbProjects, getCompanyUserCarRppbs } from '../../../../actions/tigercat-burns/tigercat-base-actions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import AddCallerDialog from '../dialogs/operations/AddCallerDialog';
import { addBaseCaller, getBaseCallers, addCallSubmission, getCallSubmissionLog } from '../../../../actions/tigercat-burns/tigercat-actions';
import moment from 'moment'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

export const DailyCallLog = () => {

    const dispatch = useDispatch()
    const [activeCarRppb, setActiveCarRppb] = useState(null);
    const [activeCarRppbProject, setActiveCarRppbProject] = useState(null);

    const carRppbs = useSelector(state => state.tigercatBurnsBaseReducer.carRppbs.data)
    const carRppbProjects = useSelector(state => state.tigercatBurnsBaseReducer.carRppbProjects.data)
    const { userData } = useSelector(state => state.auth);
    const baseCallers = useSelector(state => state.tigercatBurnsReducer.baseCallers).data
    const callSubmissionLog = useSelector(state => state.tigercatBurnsReducer.callSubmissionLogs).data

    const userCompanyId = userData?.tigercatBurnsUser?.companyId;
    const [hide, setHide] = useState(true);
    const [hideProjects, setHideProjects] = useState(true);
    const [entity, setEntity] = useState('');
    const [contact, setContact] = useState('');
    const [openCallerModal, setOpenCallerModal] = useState(false);

    const [callers, setCallers] = useState([])
    const [addCallerFormErrors, setAddCallerFormErrors] = useState([]);


    useEffect(() => {
        if (userCompanyId)
            dispatch(getCompanyUserCarRppbs(userCompanyId))
    }, [dispatch, userCompanyId])

    useEffect(() => {
        if (activeCarRppbProject) {
            dispatch(getBaseCallers(activeCarRppbProject.id))
            dispatch(getCallSubmissionLog(activeCarRppbProject.id))

        }
    }, [dispatch, activeCarRppbProject])

    useEffect(() => {
        if (baseCallers && callSubmissionLog) {
            const today = moment().format('YYYY-MM-DD');
            setCallers(baseCallers.reduce((acc, curr) => {
                if (!callSubmissionLog.find(c => c.date === today && c.id === curr.id)) {
                    acc.push({ ...curr, called: false, note: '' });
                }
                return acc;
            }, []));
        }
    }, [baseCallers, callSubmissionLog]);

    const handleCalledChange = (event, id) => {
        setCallers((prevCallers) => {
            return prevCallers.map(caller =>
                caller.id === id ? { ...caller, called: event.target.checked } : caller
            );
        });
    }

    const handleNoteChange = (event, id) => {
        setCallers((prevCallers) => {
            return prevCallers.map(caller =>
                caller.id === id ? { ...caller, note: event.target.value } : caller
            );
        });
    }

    const handleSetActiveCarRppb = (carRppb) => {
        setActiveCarRppb(carRppb)
        dispatch(getCarRppbProjects(carRppb.id))
    }

    const handleAddNewCaller = () => {
        let errors = [];
        let payload = { entity, contact };
        for (let val in payload) {
            if (!payload[val]) {
                errors.push(`Please specify ${val.slice(0, 1).toUpperCase() + val.slice(1)}`);
            }
        }
        if (errors.length > 0) {
            setAddCallerFormErrors(errors);
            return;
        }
        // dispatch(createCompanyCarRppb(userCompanyId))
        dispatch(addBaseCaller(activeCarRppbProject.id, { entity: entity, contact: contact })).then(() => {
            setEntity('')
            setContact('')
            setOpenCallerModal(false)
        }).then(() => {
            dispatch(getBaseCallers(activeCarRppbProject.id))
        })
    }

    const handleClearFormErrors = (err) => {
        setAddCallerFormErrors(addCallerFormErrors.filter(e => e !== err))
    }

    const handleAddCallSubmission = () => {
        const today = moment().format('DD-MM-YYYY'); // Format the date as needed
        let payload = callers.filter(c => c.called).map(c => {
            return {
                id: c.id,
                entity: c.entity,
                number: c.number,
                note: c.note,
                date: today
            }
        });
        dispatch(addCallSubmission(activeCarRppbProject.id, payload)).then(() => {
            dispatch(getCallSubmissionLog(activeCarRppbProject.id));
        });
    }

    return (
        <Grid>
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => setHide(!hide)}
                >
                    <Typography>{hide ? "Hide" : "Expand"} CAR RPPBs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid xs={12} display="flex" alignContent={'space-between'} flexWrap={'wrap'} mb={'1rem'}>
                        {carRppbs?.map((carRppb, index) => {
                            return (
                                <Button
                                    sx={{ mr: 2 }}
                                    key={index}
                                    variant={carRppb.id === activeCarRppb?.id ? 'contained' : 'outlined'}
                                    color='primary'
                                    onClick={() => handleSetActiveCarRppb(carRppb)}>
                                    {carRppb.reportingEntityName}
                                </Button>
                            )
                        })}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {
                activeCarRppb &&
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={() => setHideProjects(!hideProjects)}
                    >
                        <Typography>{hideProjects ? "Hide" : "Expand"} Projects</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            <Typography variant='h5' component='h4' sx={{ mb: 3 }}>
                                CAR RPPB Projects
                            </Typography>
                            <Grid xs={12} display="flex" alignContent={'space-between'} flexWrap={'wrap'} mb={'1rem'}>
                                {carRppbProjects?.map((project, index) => {
                                    return (

                                        <Box className="biochar-project" key={index}
                                            sx={activeCarRppbProject?.id === project.id && { background: 'linear-gradient(to bottom right, #535988, #313551)', color: '#FFF', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                                            onClick={() => {
                                                setActiveCarRppbProject(project)
                                            }}>
                                            <Typography sx={{ fontSize: '18px' }}>{activeCarRppbProject?.id === project.id ? <BookmarkOutlinedIcon sx={{ mb: '-7px' }} /> : <BookmarkBorderOutlinedIcon sx={{ mb: '-7px' }} />} {project.id}</Typography>
                                            <Typography sx={project.projectName ? { fontSize: '14px' } : { visibility: 'hidden' }} mt={3}><strong>Project Name:</strong> {project.projectName}</Typography>
                                            <Typography sx={{ fontSize: '14px' }}><strong>Date Created:</strong> {project.createdAt.split('T')[0]}</Typography>
                                        </Box>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
            {activeCarRppbProject &&
                <>
                    <Button
                        variant='outlined'
                        size='medium'
                        sx={{ mt: 3, mb: 3, height: '3.4em', width: '15.5rem' }}
                        onClick={() => setOpenCallerModal(true)}
                    ><AddIcon></AddIcon>Add New Caller</Button>
                    <TableContainer component={Paper} sx={{ height: 'auto' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        Entity
                                    </TableCell>
                                    <TableCell align="left">
                                        Number
                                    </TableCell>
                                    <TableCell align="left">
                                        Called
                                    </TableCell>
                                    <TableCell align="left">
                                        Note
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {callers?.map((c, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell align="left">
                                                {c.entity}
                                            </TableCell>
                                            <TableCell align="left">
                                                {c.number}
                                            </TableCell>
                                            <TableCell align="left">
                                                <Checkbox
                                                    checked={c.called}
                                                    onChange={e => handleCalledChange(e, c.id)}
                                                    inputProps={{ 'aria-label': 'Called checkbox' }}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    disabled={!c.called}
                                                    name='entity'
                                                    value={c.note}
                                                    onChange={e => handleNoteChange(e, c.id)}
                                                    fullWidth
                                                    sx={{ input: { cursor: !c.called ? 'not-allowed' : 'pointer' } }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* {loading ? <CircularProgress sx={{ position: 'absolute', left: '125px', top: '12px' }}></CircularProgress> : */}
                    <Button variant='contained' sx={{ height: 'auto', mt: 2 }} onClick={handleAddCallSubmission}>Add Entry</Button>
                    <Typography variant='h5' component='h4' sx={{ m: 2.5 }}>
                        Call Submission Log
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <TableContainer component={Paper} sx={{ height: 'auto' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        Date
                                    </TableCell>
                                    <TableCell align="left">
                                        Entity
                                    </TableCell>
                                    <TableCell align="left">
                                        Number
                                    </TableCell>
                                    <TableCell align="left">
                                        Called
                                    </TableCell>
                                    <TableCell align="left">
                                        Note
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {callSubmissionLog?.map((csl, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell align="left">
                                                <Box sx={{ display: 'flex' }}>
                                                    {moment().format('YYYY-MM-DD') === csl.date ? <CalendarTodayIcon sx={{ color: 'green', ml: 1 }} /> : null}
                                                    <b>
                                                        {moment(csl.date).format('DD-MM-YYYY')}
                                                    </b>
                                                </Box>
                                            </TableCell>
                                            <TableCell align="left">
                                                {csl.entity}
                                            </TableCell>
                                            <TableCell align="left">
                                                {csl.number}
                                            </TableCell>
                                            <TableCell align="left">
                                                <Checkbox
                                                    disabled
                                                    checked={csl.called}
                                                    onChange={() => null}
                                                    inputProps={{ 'aria-label': 'Called checkbox' }}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                {csl.note}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }
            <AddCallerDialog
                entity={entity}
                setEntity={setEntity}
                setContact={setContact}
                contact={contact}
                openCallerModal={openCallerModal}
                setOpenCallerModal={setOpenCallerModal}
                handleAddNewCaller={handleAddNewCaller}
                formErrors={addCallerFormErrors}
                handleClearFormErrors={handleClearFormErrors}
            />
        </Grid>
    )
}