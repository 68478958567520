import { Grid, Typography, Paper, Divider, Button } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SecurityIcon from '@mui/icons-material/Security';

export default function Wallet() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Paper sx={{ textAlign: 'center' }} elevation={3}>
          <AttachMoneyIcon sx={{ fontSize: '10em' }} />
          <Typography variant='h5'>Available Funds</Typography>
          <Divider />
          <Typography variant='h2'>
            <strong>$ 1,000.00</strong>
          </Typography>
          <Divider />
          <Button variant='contained' size='large' sx={{ my: 2 }}>
            Withdraw Funds
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper sx={{ textAlign: 'center' }} elevation={3}>
          <SecurityIcon sx={{ fontSize: '10em' }} />
          <Typography variant='h5'>Restrcited Funds</Typography>
          <Divider />
          <Typography variant='h2'>
            <strong>$ 2,500.00</strong>
          </Typography>
          <Divider />
          <Button variant='contained' size='large' sx={{ my: 2 }}>
            View Payment Schedule
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}
