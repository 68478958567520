import { useEffect, useState } from 'react';
import {
    Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell, Tabs, Tab, Select, MenuItem, FormControl, InputLabel, Button, TextField
} from '@mui/material';
import { getFlightsAndAccommodationData, getFlightsAndAccommodationDataTotals, postFlightsAndAccommodationData } from '../../../../../actions/carbonfootprint-rework/03-indirect-ghg-emissions-from-transportation/01-flights-and-accommodation-actions'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { directGhgEnmissionsAndRemovalsFileUpload } from '../../../../../actions/carbonfootprint-rework/01-direct-ghg-emissions-and-removals/00-file-upload';
import { geHighestId } from '../../../../../actions/carbonfootprint-rework/01-direct-ghg-emissions-and-removals/01-diesel-actions';
import FlightsAndAccommodationTotalTable from './FlightsAndAccommodationTotalTable';

const FlightsAndAccommodationDataCapturing = () => {
    const dispatch = useDispatch()

    const flightsAndAccommodationData = useSelector((state) => state.flightsAndAccommodationReducer.flightsAndAccommodationData?.data)
    const flightsAndAccommodationDataTotals = useSelector((state) => state.flightsAndAccommodationReducer.flightsAndAccommodationDataTotals?.data)
    let highestId = useSelector((state) => state.directGhgEmissionsAndRemovalsForDiesel.highestId.max_id)

    const year = localStorage.getItem('year')
    const facility = localStorage.getItem('facility')
    const group = localStorage.getItem('group')
    const company = localStorage.getItem('company')

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Totals']
    const [month, setMonth] = useState(0);
    const [assetRows, setAssetRows] = useState({ ...flightsAndAccommodationData })

    useEffect(() => {
        dispatch(geHighestId('flights-and-accommodation'))
        dispatch(getFlightsAndAccommodationData(year, facility))
        dispatch(getFlightsAndAccommodationDataTotals(year, facility))
    }, [dispatch, year, facility])

    useEffect(() => {
        if (flightsAndAccommodationData && flightsAndAccommodationData[month]?.length > 0) setAssetRows({ ...flightsAndAccommodationData })
        else setAssetRows({ 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [], 11: [] })
    }, [flightsAndAccommodationData, dispatch, month])

    const handleMonthChange = (event, newValue) => {
        setMonth(newValue);
        if (newValue === 12) {
            dispatch(getFlightsAndAccommodationDataTotals(year, facility))
        } else {
            dispatch(getFlightsAndAccommodationData(year, facility))
            dispatch(geHighestId('flights-and-accommodation'))
        }
    };

    const handleAddAsset = async () => {
        await dispatch(geHighestId('flights-and-accommodation')).then(() => {
            setAssetRows((prevRows) => {
                let highestIdInAssetRows = 0;
                for (let key in assetRows) {
                    let sublist = assetRows[key];
                    for (let item of sublist) {
                        highestIdInAssetRows = Math.max(highestId, item.id);
                    }
                }
                if (highestIdInAssetRows >= highestId) {
                    highestId = ++highestIdInAssetRows;
                } else {
                    highestId = ++highestId
                }
                const prevRowsMonth = [...prevRows[month], { id: highestId, flightType: '', emissions: 'kg CO2', quantity: '', numberOfTravellers: '', hotelStays: '', file: '' }]
                prevRows[month] = prevRowsMonth
                return { ...prevRows }
            })
        })
    }

    const flightTypes = [
        { id: 0, value: 'International One Way' },
        { id: 1, value: 'International Return' },
        { id: 2, value: 'Domestic One Way' },
        { id: 3, value: 'Domestic Return' },
        { id: 4, value: 'N/A' }
    ];

    const handleFlightTypeSelection = (e, month, id) => {
        setAssetRows((prevAssets) => {
            const assetToEdit = prevAssets[month].find((data) => data.id === id)
            assetToEdit['flightType'] = e.target.value
            const filteredMonth = prevAssets[month].filter((d) => d.id !== id)
            prevAssets[month] = [...filteredMonth, assetToEdit].sort((a, b) => a.id - b.id)
            return { ...prevAssets }
        })
    }

    // const handlEmissionSelection = (e, month, id) => {
    //     setAssetRows((prevUnits) => {
    //         const emissionToEdit = prevUnits[month].find((data) => data.id === id)
    //         emissionToEdit['emissions'] = e.target.value
    //         const filteredMonth = prevUnits[month].filter((d) => d.id !== id)
    //         prevUnits[month] = [...filteredMonth, emissionToEdit].sort((a, b) => a.id - b.id)
    //         return { ...prevUnits }
    //     })
    // }

    const handleNumberOfTravellersSelection = (e, month, id) => {
        setAssetRows((prevUnits) => {
            const emissionToEdit = prevUnits[month].find((data) => data.id === id)
            emissionToEdit['numberOfTravellers'] = e.target.value
            const filteredMonth = prevUnits[month].filter((d) => d.id !== id)
            prevUnits[month] = [...filteredMonth, emissionToEdit].sort((a, b) => a.id - b.id)
            return { ...prevUnits }
        })
    }

    const handleHotelStaysSelection = (e, month, id) => {
        setAssetRows((prevUnits) => {
            const emissionToEdit = prevUnits[month].find((data) => data.id === id)
            emissionToEdit['hotelStays'] = e.target.value
            const filteredMonth = prevUnits[month].filter((d) => d.id !== id)
            prevUnits[month] = [...filteredMonth, emissionToEdit].sort((a, b) => a.id - b.id)
            return { ...prevUnits }
        })
    }

    const handleInputChange = (e, month, id) => {
        setAssetRows((prevRows) => {
            const quantityToEdit = prevRows[month].find((data) => data.id === id)
            quantityToEdit['quantity'] = e.target.value
            const filteredMonth = prevRows[month].filter((d) => d.id !== id)
            prevRows[month] = [...filteredMonth, quantityToEdit].sort((a, b) => a.id - b.id)
            return { ...prevRows }
        })
    }

    const handleFileUpload = (e, month, id) => {
        setAssetRows((prevRows) => {
            const fileToEdit = prevRows[month].find((data) => data.id === id)
            fileToEdit['file'] = e.target.files[0]
            const filteredMonth = prevRows[month].filter((d) => d.id !== id)
            prevRows[month] = [...filteredMonth, fileToEdit].sort((a, b) => a.id - b.id)
            return { ...prevRows }
        })
    }

    const handleFlightsAndAccommodationDataSubmission = () => {
        const removeEmptyMonthValues = (obj) => {
            for (let key in obj) {
                obj[key] = obj[key].filter(item => item.asset !== '' && item.quantity !== '');
                if (obj[key].length === 0) {
                    delete obj[key];
                }
            }
            return obj;
        }
        const clonedAssetRows = removeEmptyMonthValues({ ...assetRows })

        let formData = new FormData();

        Object.keys(clonedAssetRows).forEach((key) => {
            clonedAssetRows[key].forEach(item => {
                if (item.file?.name) {
                    formData.append(item.id, item.file);
                }
            });
        });
        // console.log(clonedAssetRows)
        dispatch(postFlightsAndAccommodationData(clonedAssetRows, year, company, group, facility)).then(() => {
            dispatch(directGhgEnmissionsAndRemovalsFileUpload(formData, 'flights-and-accommodation'))
        })
    }

    return (
        <>
            <Tabs
                value={month}
                onChange={handleMonthChange}
                sx={{ width: '100%', float: 'left' }}
                textColor='inherit'
                indicatorColor='secondary'
            >
                {months.map((m, i) => {
                    return (
                        <Tab key={i} label={m} sx={{ minWidth: '25px' }} />
                    )
                })}
            </Tabs>
            {month === 12 ? <FlightsAndAccommodationTotalTable flightsAndAccommodationDataTotals={flightsAndAccommodationDataTotals} /> :
                <>
                    <TableContainer component={Paper} sx={{ height: 'auto' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        Flight Type
                                    </TableCell>
                                    {/* <TableCell align="left">
                                        Emissions
                                    </TableCell> */}
                                    <TableCell align="left">
                                        Hotel Stays
                                    </TableCell>
                                    <TableCell align="left">
                                        Number of Travellers
                                    </TableCell>
                                    <TableCell align="left">
                                        Value (kg CO2)
                                    </TableCell>
                                    <TableCell align="left">
                                        Soure Document
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {assetRows[month]?.map((r, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell align="left">
                                                <FormControl sx={{ width: '200px' }}>
                                                    <InputLabel id="asset">{'Select Type'}</InputLabel>
                                                    <Select
                                                        required={true}
                                                        name="Type"
                                                        value={!!r.flightType ? r.flightType : ''}
                                                        onChange={(e) => handleFlightTypeSelection(e, month, r.id)}
                                                        label='Type'
                                                    >
                                                        {flightTypes.map(item => {
                                                            return (
                                                                <MenuItem key={item.id} value={item.value}>
                                                                    {item.value}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>

                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormControl sx={{ width: '200px' }}>
                                                    <InputLabel id="hotel-stays">{'Hotel Stays'}</InputLabel>
                                                    <Select
                                                        required={true}
                                                        name="hotel-stays"
                                                        value={!!r.hotelStays.toString() ? r.hotelStays : ''}
                                                        onChange={(e) => handleHotelStaysSelection(e, month, r.id)}
                                                        label='Amount'
                                                    >
                                                        {['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map((item, i) => {
                                                            return (
                                                                <MenuItem key={i} value={item}>
                                                                    {item}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            {/* <TableCell align="left">
                                                <FormControl sx={{ width: '200px' }}>
                                                    <InputLabel id="emissions">{'kg CO2'}</InputLabel>
                                                    <Select
                                                        disabled={true}
                                                        required={true}
                                                        name="emissions"
                                                        value={'kg CO2'}
                                                        onChange={(e) => handlEmissionSelection(e, month, r.id)}
                                                        label='kg CO2'
                                                    >
                                                        {['kg CO2'].map((item, i) => {
                                                            return (
                                                                <MenuItem key={i} value={item}>
                                                                    {item}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </TableCell> */}
                                            <TableCell align="left">
                                                <FormControl sx={{ width: '200px' }}>
                                                    <InputLabel id="travellers">{'Travellers'}</InputLabel>
                                                    <Select
                                                        required={true}
                                                        name="travellers"
                                                        value={!!r.numberOfTravellers ? r.numberOfTravellers : ''}
                                                        onChange={(e) => handleNumberOfTravellersSelection(e, month, r.id)}
                                                        label='Amount'
                                                    >
                                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, i) => {
                                                            return (
                                                                <MenuItem key={i} value={item}>
                                                                    {item}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    required={true}
                                                    fullWidth
                                                    name={'quantity'}
                                                    value={!!r.quantity ? r.quantity : ''}
                                                    onChange={(e) => handleInputChange(e, month, r.id)}
                                                    type="text"
                                                />
                                            </TableCell>{r.quanity}



                                            <TableCell align="left">
                                                {r?.file ? <>
                                                    <TextField
                                                        disabled
                                                        name={'Source document'}
                                                        value={!!r?.file?.name ? r.file.name : !!r?.file ? r.file : ''}
                                                        type='text'
                                                    />
                                                    <input
                                                        style={{ display: 'none' }}
                                                        name={'Source document'}
                                                        onChange={(e) => handleFileUpload(e, month, r.id)}
                                                        type='file'
                                                    />
                                                </> : <TextField
                                                    name={'Source document'}
                                                    // value={!!r?.file?.name ? r.file.name : !!r?.file ? r.file : ''}
                                                    onChange={(e) => handleFileUpload(e, month, r.id)}
                                                    type='file'
                                                />}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button variant="contained" sx={{ m: 2, }} onClick={handleAddAsset}>Add entry</Button>
                    <Button variant="contained" sx={{ m: 2, }} onClick={handleFlightsAndAccommodationDataSubmission}>Submit data</Button>
                </>}
        </>
    )
}

export default FlightsAndAccommodationDataCapturing;