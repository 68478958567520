import React from "react";
import { Grid, Typography } from "@mui/material";

const formatNumber = (number) => number.toFixed(2);

const Step4Form = ({
  kilnVolume,
  biocharYieldLevel,
  numberOfKilns,
  laborCosts,
  burnSupervisorCosts,
  otherCosts,
  measurementSystem,
  currency,
  bulkDensity,
  capitalCosts,
}) => {
  const currencySymbols = {
    "R-ZAR": "R",
    "$-USD": "$",
    "$-AUS": "$",
  };

  const currencySymbol = currencySymbols[currency];
  const measurementUnit = measurementSystem === "Metric" ? "m³" : "yd³";

  const totalBiocharProduced =
    kilnVolume * (biocharYieldLevel / 100) * numberOfKilns;
  const totalDailyCost =
    parseFloat(laborCosts) +
    parseFloat(burnSupervisorCosts) +
    parseFloat(otherCosts);
  const costOfDailyBiocharProduction = totalDailyCost / totalBiocharProduced;

  const estimatedBiocharMass =
    parseFloat(bulkDensity) * parseFloat(totalBiocharProduced);
  const estimatedCO2RemovalAmount = estimatedBiocharMass * 2;
  const totalKilnCapex = parseFloat(numberOfKilns) * parseFloat(capitalCosts);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Results
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Total biochar produced: {formatNumber(totalBiocharProduced)} {measurementUnit}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Total daily cost: {currencySymbol}{formatNumber(totalDailyCost)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Cost of daily biochar production: {currencySymbol}{formatNumber(costOfDailyBiocharProduction)}/{measurementUnit}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Total CAPEX for kilns: {currencySymbol}{formatNumber(totalKilnCapex)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Estimated biochar mass: {formatNumber(estimatedBiocharMass)} {measurementSystem === "Metric" ? "kg" : "lb"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Estimated CO2 Removal Amount: {formatNumber(estimatedCO2RemovalAmount)} {measurementSystem === "Metric" ? "kg" : "lb"} CO2
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Step4Form;
