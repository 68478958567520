import { useState, useEffect } from 'react';
import {
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Box,
  Tooltip,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import tooltips from '../../../../../../store/tooltips';
import InfoIcon from '@mui/icons-material/Info';

export default function BF004BiocharCharacteristics({
  data,
  setData,
  setFormId,
  handleLoopData,
  feedstockSourceTypes,
}) {
  useEffect(() => {
    setFormId('BF004');
  }, [setFormId]);

  const initState = [
    {
      batchNumber: '',
      cContent: '',
      density: '',
      feedstockType: '',
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const [active, setActive] = useState('');
  const [loopData, setLoopData] = useState(initState);

  const { labTestSubmit, labName, lab_results, labTestId } = data;
  const { batchNumber, cContent, density, feedstockType } = loopData;

  const handleLoop = () => {
    setLoopData(s => {
      return [...s, initState[0]];
    });
  };

  const handleChange = (e, i) => {
    setLoopData(s => {
      const newArr = s.slice();
      newArr[i][e.target.name] = e.target.value;
      return newArr;
    });
    handleLoopData({ ...data, loopData });
  };

  return (
    <>
      <Typography variant='h5' sx={{ mb: 2 }}>
        Biochar Characteristics
      </Typography>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Will you be submitting lab tests for this project?
        <Tooltip
          title={
            <h2 style={{ color: 'lightgreen', }}>
              {tooltips.biocharCharacteristicsQ1}
            </h2>
          }
        >
          {<InfoIcon sx={{ width: 15 }} />}
        </Tooltip>
      </Typography>
      <RadioGroup
        row
        name='labTestSubmit'
        onChange={e => {
          setData(e);
        }}
        value={labTestSubmit}
      >
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='labTestSubmit'
          onClick={() => setActive('yes')}
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='labTestSubmit'
          onClick={() => setActive('no')}
        />
      </RadioGroup>
      {data.labTestSubmit === 'yes' && (
        <>
          <Typography variant='h6' component='p' sx={{ mt: 2 }}>
            Lab Name
            <Tooltip
              title={
                <h2 style={{ color: 'lightgreen' }}>
                  {tooltips.biocharCharacteristicsQ1Yes}
                </h2>
              }
            >
              {<InfoIcon sx={{ width: 15 }} />}
            </Tooltip>
          </Typography>
          <TextField
            name='labName'
            label='Lab Name'
            value={labName}
            onChange={e => setData(e)}
            fullWidth
          />
          <Typography variant='h6' component='p' sx={{ mt: 2 }}>
            Lab Test ID
            <Tooltip
              title={
                <h2 style={{ color: 'lightgreen' }}>
                  {tooltips.biocharCharacteristicsQ2Yes}
                </h2>
              }
            >
              {<InfoIcon sx={{ width: 15 }} />}
            </Tooltip>
          </Typography>
          <TextField
            name='labTestId'
            label='Lab Test ID'
            value={labTestId}
            onChange={setData}
            fullWidth
          />
          <Typography variant='h6' component='p' sx={{ mt: 2 }}>
            Please upload lab results
            <Tooltip
              title={
                <h2 style={{ color: 'lightgreen' }}>
                  {tooltips.biocharCharacteristicsQ3Yes}
                </h2>
              }
            >
              {<InfoIcon sx={{ width: 15 }} />}
            </Tooltip>
          </Typography>
          <TextField
            name='lab_results'
            type='file'
            value={lab_results}
            onChange={setData}
            fullWidth
          />
        </>
      )}
      {loopData?.map((x, i) => (
        <Box key={i}>
          <Divider sx={{ my: 2 }} />
          <Typography variant='h6' component='p' sx={{ mt: 2 }}>
            Batch Number
            {data.labTestSubmit === 'yes' ? (
              <Tooltip
                title={
                  <h2 style={{ color: 'lightgreen' }}>
                    {tooltips.biocharCharacteristicsQ4Yes}
                  </h2>
                }
              >
                {<InfoIcon sx={{ width: 15 }} />}
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <h2 style={{ color: 'lightgreen' }}>
                    {tooltips.biocharCharacteristicsQ1No}
                  </h2>
                }
              >
                {<InfoIcon sx={{ width: 15 }} />}
              </Tooltip>
            )}
          </Typography>
          <TextField
            id={i}
            name='batchNumber'
            label='Batch Number'
            value={batchNumber}
            onChange={e => {
              handleChange(e, i);
              setData(e);
            }}
            fullWidth
          />
          {data.labTestSubmit === 'no' && (
            <>
              {' '}
              <Typography variant='h6' component='p' sx={{ mt: 2 }}>
                Please confirm feedstock type used
              </Typography>
              <FormControl
                sx={{
                  mt: 1,
                }}
                fullWidth
              >
                <InputLabel sx={{ background: 'white', pl: 1, pr: 1 }}>
                  Please confirm feedstock type used
                </InputLabel>
                <Select
                  name='feedstockType'
                  value={feedstockType}
                  onChange={e => handleChange(e, i)}
                >
                  {feedstockSourceTypes.map(item => {
                    return (
                      <MenuItem key={item.id} value={item.name}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </>
          )}
          <Typography variant='h6' component='p' sx={{ mt: 2 }}>
            Organic Carbon Content
            <Tooltip
              title={
                <h2 style={{ color: 'lightgreen' }}>
                  {tooltips.biocharCharacteristicsQ5Yes}
                </h2>
              }
            >
              {<InfoIcon sx={{ width: 15 }} />}
            </Tooltip>
          </Typography>
          <TextField
            name='acContent'
            label='Organic Carbon Content'
            value={cContent}
            onChange={e => {
              handleChange(e, i);
            }}
            fullWidth
          />
          <Typography variant='h6' component='p' sx={{ mt: 2 }}>
            Bulk Density
          </Typography>
          <TextField
            name='density'
            label='Bulk Density'
            value={density}
            onChange={e => handleChange(e, i)}
            fullWidth
          />
          <Divider sx={{ my: 2 }} />
        </Box>
      ))}
      <IconButton size='large' onClick={handleLoop}>
        <Typography>Submit and add another batch</Typography>
        <AddCircleOutlineIcon sx={{ fontSize: 50 }} />
      </IconButton>
    </>
  );
}
