import { Grid, Typography, CircularProgress, Alert } from '@mui/material';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getNotes } from '../../../../../../actions/forms';

export default function FieldNotes({ projectId }) {
  const dispatch = useDispatch();
  const { loading, error, notes } = useSelector(state => state.postFormData);

  useEffect(() => {
    dispatch(getNotes(projectId));
  }, [dispatch, projectId]);

  return loading ? (
    <CircularProgress />
  ) : error ? (
    <Typography>{error}</Typography>
  ) : !notes.length ? (
    <Alert severity='info'>No notes submitted for this project</Alert>
  ) : (
    notes?.map(note => {
      return (
        <Grid
          container
          sx={{ p: 2, bgcolor: '#eee' }}
          key={note?._id}
          marginBottom={2}
        >
          <Grid item xs={6}>
            <Typography>
              <strong>Note Submitted on:</strong> {note?.created_at.split('T')[0]}
            </Typography>
            <Typography>
              <strong>Subject:</strong> {note?.subject}
            </Typography>
            <Typography>
              <strong>Tag:</strong> {note?.tag}
            </Typography>
            <Typography>
              <strong>Notes:</strong> {note?.notes}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            {note?.file !== null ? (
              <img width={'50%'} src={note?.file} alt={note?.subject} />
            ) : (
              <Typography>No Photo Submitted</Typography>
            )}
          </Grid>
        </Grid>
      );
    })
  );
}
