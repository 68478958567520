import { Alert, Box, Button, Divider, Grid, Typography, Tabs, Tab } from '@mui/material';
import React, { useState } from 'react';

export default function WineryCatOneSelection({ setSelection, setWineryCategory }) {

    const facilityCatOne = ['Plot List', 'Fertilizer List', 'Agrochemical List', 'Asset List']

    const [currentTab, setCurrentTab] = useState(0)
    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const renderComponent = () => {
        switch (currentTab) {
            case 0: {
                return (
                    <Box marginTop={2} sx={{ clear: 'left' }}>
                        <Typography variant='h5'>Category</Typography>
                        <Divider />
                        <Alert severity='info'>Please select a category</Alert>
                        {facilityCatOne.map((c, i) => {
                            return (
                                <Button
                                    key={i}
                                    variant='contained'
                                    size='large'
                                    sx={{ width: '35%', display: 'block', mb: 2.5 }}
                                    onClick={() => {
                                        setWineryCategory(c)
                                        setSelection({
                                            id: 5,
                                            title: c
                                        })
                                        localStorage.setItem('wineryCatOne', c)
                                    }}
                                >
                                    {c}
                                </Button>
                            )
                        })
                        }
                    </Box>
                )
            }
            case 1: {
                return (
                    <Box marginTop={2} sx={{ clear: 'left', display: 'flex', flexDirection: 'column', width: '200px' }}>
                        <Button variant="contained" sx={{ mt: 2 }} onClick={() => null}>Asset Register</Button>
                        <Button variant="contained" sx={{ mt: 2 }} onClick={() => null}>Plotlist</Button>
                    </Box>
                )
            }
            case 2: {
                return (
                    <Box marginTop={2} sx={{ clear: 'left' }}>
                        {/* <OverviewTable category="Direct GHG emissions and removals" data={catOneOverview} /> */}
                    </Box>
                )
            }
            default: {
                return null
            }
        }
    }

    return (
        <Grid item xs={12}>
            <Tabs
                value={currentTab}
                onChange={handleTabChange}
                style={{ marginBottom: 10 }}
                textColor='inherit'
                indicatorColor='secondary'
                className='theTabs'
                sx={{
                    float: 'left',
                }}
            >
                <Tab label='Data Capturing' />
                <Tab label='Data Input' />
                <Tab label='Overview' disabled={true} />
            </Tabs>
            {renderComponent()}
        </Grid>
    );
}
