import { Box, Grid, Paper, Typography } from '@mui/material';
import { Phone } from '../svgs/Phone';
import { Clock } from '../svgs/Clock';
import { Envelope } from '../svgs/Envelope';

const Contact = () => {
  const contactCards = [
    {
      icon: <Phone />,
      heading: 'Telephone',
      content: '+27 71 859 6656',
      subText: '',
    },
    {
      icon: <Envelope />,
      heading: 'By E-Mail',
      headingLineTwo: '',
      content: 'admin@ikhala.tech',
    },
    {
      icon: <Clock />,
      heading: 'Operating hours',
      headingLineTwo: '',
      content: '8:00 - 20:00 (SAST)',
    },
  ];

  return (
    <Grid container className='contact'>
      <Box
        className='contact__content'
        display={'flex'}
        sx={{
          width: '100%',
          flexDirection: { xs: 'column', md: 'row' },
          gap: '25px'
        }}
      >
        <Paper
          sx={{
            // mr: '25px',
            minWidth: { xs: '90%', md: '205px' },
            // mb: { xs: '25px', lg: '0px' },
            flex: 1
          }}
          square
          elevation={0}
          className='contact__paper contact__paper__info contact__paper__info--contact'
        >
          <Typography
            sx={{
              fontSize: '36px',
              fontWeight: '600',
              fontStyle: 'normal',
              lineHeight: '44px',
              letterSpacing: '-0.72px',
              // color: '#DA9DE3',

            }}
            className="contact__heading"
          >
            Contact us for
          </Typography>
          <Typography>
            <ul>
              <li style={{ listStyle: 'none', marginBottom: '10px' }}>- Access to the biochar CHARR App</li>
              <li style={{ listStyle: 'none', marginBottom: '10px' }}>- Product Carbon Footprinting</li>
              <li style={{ listStyle: 'none', marginBottom: '10px' }}>- To develop a custom policy</li>
            </ul>
          </Typography>
          {contactCards.map((c, i) => {
            return (
              <Box key={i} sx={{ mb: '1.5em', background: 'white', ml: '50px' }}>
                {/* <Typography className='contact__paper__info__icon'>
                  </Typography> */}
                <Typography className='contact__paper__info__heading'>
                  {c.icon}{" "}
                  {c.heading}
                  {c.headingLineTwo ? <br /> : null}
                  {c.headingLineTwo}
                </Typography>

                <Typography className='contact__paper__info__content'>
                  {c.content}
                </Typography>
                {c.subText ? (
                  <Typography className='contact__paper__info__sub-text'>
                    {c.subText}
                  </Typography>
                ) : null}
              </Box>)
          })}
        </Paper>

        {/* <Paper
          sx={{
            minWidth: { xs: '90%', md: '205px' },
            flex: 1
          }}
          square
          elevation={0}
          className='contact__paper contact__paper__info'
        >
          
        </Paper> */}

        <Paper
          sx={{

            flex: 3,
            // mb: { xs: '25px', lg: '0px' }
          }}
          square
          elevation={0}
          className='contact__paper contact__paper__info'
        >
          At Ikhala, we are dedicated to providing a thoughtful and inclusive Digital-MMRV solution to aid in the fight against climate change. Our platform is designed with a selection of 'programs' that assist projects from start to finish, ensuring their efforts in making a positive impact are thoroughly monitored, measured, and reported on. <br /><br />

          Our flagship initiative, the Biochar Program, is managed through the CHARR Mobile App. This app simplifies the biochar production data collection process, offering an automated approach to calculating carbon removals and reports. It's a tool that empowers small woodland owners, land managers, and contractors to demonstrate their active role in climate action, particularly through biochar production from "vegetation and fuel load management activities."<br /><br />

          What truly distinguishes Ikhala is our platform's flexibility. We offer the ability for projects to customize their MMRV policy to align with existing standards or to establish new ones. Ikhala aims to streamline project lifecycle management, serving as a unified repository for all project-related data. Our focus is on simplifying the reporting process and ensuring the collection of precise data necessary to validate a project's impact.<br /><br />

          We're here to support your climate action initiatives, offering a platform that blends functionality with adaptability, all while keeping your project's unique needs at the forefront..

        </Paper>
      </Box>
    </Grid>
  );
};

export default Contact;
