import React, { useState } from "react";
import Steps from "./StepForm";
import {
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";

// const style = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: "80%",
//     bgcolor: "background.paper",
//     boxShadow: 24,
//     p: 4,
// };

const BiocharUnitEconCalculator = ({ handleClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [currency, setCurrency] = useState("R-ZAR");
  const [kilnVolume, setKilnVolume] = useState("");
  const [biocharYieldLevel, setBiocharYieldLevel] = useState("");
  const [numberOfKilns, setNumberOfKilns] = useState("");
  const [laborCosts, setLaborCosts] = useState("0");
  const [burnSupervisorCosts, setBurnSupervisorCosts] = useState("0");
  const [otherCosts, setOtherCosts] = useState("0");
  const [capitalCosts, setCapitalCosts] = useState("0");
  const [measurementSystem, setMeasurementSystem] = useState("Metric - m3");
  const [bulkDensity, setBulkDensity] = useState("0");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 3 }} align="right">
        Biochar Unit Economics Calculator
      </Typography>
      <Steps
        activeStep={activeStep}
        currency={currency}
        kilnVolume={kilnVolume}
        biocharYieldLevel={biocharYieldLevel}
        numberOfKilns={numberOfKilns}
        laborCosts={laborCosts}
        burnSupervisorCosts={burnSupervisorCosts}
        otherCosts={otherCosts}
        measurementSystem={measurementSystem}
        setCurrency={setCurrency}
        setMeasurementSystem={setMeasurementSystem}
        setKilnVolume={setKilnVolume}
        setBiocharYieldLevel={setBiocharYieldLevel}
        setNumberOfKilns={setNumberOfKilns}
        setLaborCosts={setLaborCosts}
        setBurnSupervisorCosts={setBurnSupervisorCosts}
        setOtherCosts={setOtherCosts}
        capitalCosts={capitalCosts}
        setCapitalCosts={setCapitalCosts}
        bulkDensity={bulkDensity}
        setBulkDensity={setBulkDensity}
      />
      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {activeStep > 0 && (
            <Button variant="outlined" color="primary" onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
          )}
          {activeStep < 3 ? (
            <Button variant="contained" color="primary" onClick={handleNext}>
              Next Step
            </Button>
          ) : null}
          <Button variant="contained" onClick={handleClose} sx={{ ml: 1 }}>
            Close
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};

export default BiocharUnitEconCalculator;
