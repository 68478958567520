export const POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_SUCCESS = 'POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_SUCCESS';
export const POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_FAIL = 'POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_FAIL';

export const GET_TIGERCAT_BURNS_GET_COMPANY_USER_CAR_RPPBS_SUCCESS = 'GET_TIGERCAT_BURNS_GET_COMPANY_USER_CAR_RPPBS_SUCCESS';
export const GET_TIGERCAT_BURNS_GET_COMPANY_USER_CAR_RPPBS_FAIL = 'GET_TIGERCAT_BURNS_GET_COMPANY_USER_CAR_RPPBS_FAIL';

export const POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_PROJECT_SUCCESS = 'POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_PROJECT_SUCCESS';
export const POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_PROJECT_FAIL = 'POST_TIGERCAT_BURNS_CREATE_CAR_RPPB_PROJECT_FAIL';

export const GET_TIGERCAT_BURNS_GET_CAR_RPPB_PROJECTS_SUCCESS = 'GET_TIGERCAT_BURNS_GET_CAR_RPPB_PROJECTS_SUCCESS';
export const GET_TIGERCAT_BURNS_GET_CAR_RPPB_PROJECTS_FAIL = 'GET_TIGERCAT_BURNS_GET_CAR_RPPB_PROJECTS_FAIL';

export const GET_TIGERCAT_BURNS_GET_USERS_SUCCESS = 'GET_TIGERCAT_BURNS_GET_USERS_SUCCESS';
export const GET_TIGERCAT_BURNS_GET_USERS_FAIL = 'GET_TIGERCAT_BURNS_GET_USERS_FAIL';

// Action Types
export const POST_TIGERCAT_BURNS_CREATE_BIOCHAR_BINS_SUCCESS = 'POST_TIGERCAT_BURNS_CREATE_BIOCHAR_BINS_SUCCESS';
export const POST_TIGERCAT_BURNS_CREATE_BIOCHAR_BINS_FAIL = 'POST_TIGERCAT_BURNS_CREATE_BIOCHAR_BINS_FAIL';

export const GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_BINS_SUCCESS = 'GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_BINS_SUCCESS';
export const GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_BINS_FAIL = 'GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_BINS_FAIL';

export const POST_TIGERCAT_BURNS_CREATE_BIOCHAR_EQUIPMENT_SUCCESS = 'POST_TIGERCAT_BURNS_CREATE_BIOCHAR_EQUIPMENT_SUCCESS';
export const POST_TIGERCAT_BURNS_CREATE_BIOCHAR_EQUIPMENT_FAIL = 'POST_TIGERCAT_BURNS_CREATE_BIOCHAR_EQUIPMENT_FAIL';

export const GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_EQUIPMENT_SUCCESS = 'GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_EQUIPMENT_SUCCESS';
export const GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_EQUIPMENT_FAIL = 'GET_TIGERCAT_BURNS_GET_USER_BIOCHAR_EQUIPMENT_FAIL';

export const GET_TIGERCAT_BURNS_GET_PROJECTS_FOR_USER_SUCCESS = 'GET_TIGERCAT_BURNS_GET_PROJECTS_FOR_USER_SUCCESS';
export const GET_TIGERCAT_BURNS_GET_PROJECTS_FOR_USER_FAIL = 'GET_TIGERCAT_BURNS_GET_PROJECTS_FOR_USER_FAIL';

export const PATCH_TIGERCAT_BURNS_ASSIGN_ASSET_TO_PROJECT_SUCCESS = 'PATCH_TIGERCAT_BURNS_ASSIGN_ASSET_TO_PROJECT_SUCCESS';
export const PATCH_TIGERCAT_BURNS_ASSIGN_ASSET_TO_PROJECT_FAIL = 'PATCH_TIGERCAT_BURNS_ASSIGN_ASSET_TO_PROJECT_FAIL';

export const PATCH_TIGERCAT_BURNS_UNASSIGN_ASSET_FROM_PROJECT_SUCCESS = 'PATCH_TIGERCAT_BURNS_UNASSIGN_ASSET_FROM_PROJECT_SUCCESS';
export const PATCH_TIGERCAT_BURNS_UNASSIGN_ASSET_FROM_PROJECT_FAIL = 'PATCH_TIGERCAT_BURNS_UNASSIGN_ASSET_FROM_PROJECT_FAIL';

export const GET_TIGERCAT_BURNS_GET_ALL_PROJECTS_WITH_ASSIGNED_ASSETS_SUCCESS = 'GET_TIGERCAT_BURNS_GET_ALL_PROJECTS_WITH_ASSIGNED_ASSETS_SUCCESS';
export const GET_TIGERCAT_BURNS_GET_ALL_PROJECTS_WITH_ASSIGNED_ASSETS_FAIL = 'GET_TIGERCAT_BURNS_GET_ALL_PROJECTS_WITH_ASSIGNED_ASSETS_FAIL';