const Ios = () => {
    return (
        <svg width="121" height="30" viewBox="0 0 121 30" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <g id="Mobile app store badge">
                <g id="Group 266">
                    <g id="App Store">
                        <path d="M83.6244 13.1492V15.4329H82.1143V16.9306H83.6244V22.0194C83.6244 23.757 84.4537 24.4521 86.54 24.4521C86.9066 24.4521 87.2558 24.4107 87.5613 24.361V22.8799C87.2995 22.9048 87.1336 22.9213 86.8455 22.9213C85.9115 22.9213 85.5012 22.5076 85.5012 21.5643V16.9306H87.5613V15.4329H85.5012V13.1492H83.6244Z" fill="#7A7B7A" />
                        <path d="M92.8761 24.5762C95.652 24.5762 97.3542 22.8137 97.3542 19.8929C97.3542 16.9885 95.6432 15.2178 92.8761 15.2178C90.1002 15.2178 88.3892 16.9885 88.3892 19.8929C88.3892 22.8137 90.0914 24.5762 92.8761 24.5762ZM92.8761 22.9958C91.2437 22.9958 90.3271 21.8622 90.3271 19.8929C90.3271 17.9401 91.2437 16.7982 92.8761 16.7982C94.4997 16.7982 95.425 17.9401 95.425 19.8929C95.425 21.8539 94.4997 22.9958 92.8761 22.9958Z" fill="#7A7B7A" />
                        <path d="M98.8105 24.4024H100.687V19.082C100.687 17.816 101.691 16.9637 103.114 16.9637C103.446 16.9637 104.004 17.0216 104.162 17.0713V15.3171C103.961 15.2675 103.603 15.2426 103.324 15.2426C102.084 15.2426 101.028 15.888 100.757 16.7734H100.617V15.3916H98.8105V24.4024Z" fill="#7A7B7A" />
                        <path d="M108.822 16.732C110.21 16.732 111.117 17.6505 111.161 19.0654H106.36C106.465 17.6588 107.434 16.732 108.822 16.732ZM111.152 21.9697C110.803 22.6731 110.026 23.062 108.891 23.062C107.39 23.062 106.421 22.0608 106.36 20.4803V20.3811H113.073V19.7605C113.073 16.9306 111.475 15.2178 108.83 15.2178C106.15 15.2178 104.448 17.0465 104.448 19.926C104.448 22.8055 106.116 24.5762 108.839 24.5762C111.013 24.5762 112.532 23.5833 112.959 21.9697H111.152Z" fill="#7A7B7A" />
                        <path d="M71.3168 21.0749C71.4615 23.2875 73.4067 24.7026 76.302 24.7026C79.3963 24.7026 81.3325 23.2189 81.3325 20.8519C81.3325 18.9908 80.2286 17.9617 77.5506 17.3699L76.112 17.0355C74.411 16.6581 73.7234 16.1521 73.7234 15.2688C73.7234 14.1539 74.791 13.4249 76.3925 13.4249C77.9125 13.4249 78.962 14.1367 79.152 15.2774H81.1244C81.0067 13.1933 79.0706 11.7268 76.4196 11.7268C73.5696 11.7268 71.6696 13.1933 71.6696 15.3974C71.6696 17.2156 72.7463 18.2962 75.1077 18.8193L76.7906 19.2052C78.5186 19.5912 79.2786 20.1572 79.2786 21.1006C79.2786 22.1983 78.0844 22.9959 76.4558 22.9959C74.7096 22.9959 73.4972 22.2498 73.3253 21.0749H71.3168Z" fill="#7A7B7A" />
                        <path d="M51.8752 15.2426C50.5832 15.2426 49.4659 15.8549 48.8897 16.881H48.7501V15.3916H46.9431V27.3978H48.8199V23.0371H48.9683C49.4659 23.9887 50.5396 24.5514 51.8926 24.5514C54.2932 24.5514 55.8208 22.7558 55.8208 19.8929C55.8208 17.0299 54.2932 15.2426 51.8752 15.2426ZM51.3427 22.9544C49.7714 22.9544 48.785 21.7794 48.785 19.9011C48.785 18.0146 49.7714 16.8396 51.3514 16.8396C52.9401 16.8396 53.8916 17.9897 53.8916 19.8929C53.8916 21.8043 52.9401 22.9544 51.3427 22.9544Z" fill="#7A7B7A" />
                        <path d="M62.388 15.2426C61.096 15.2426 59.9787 15.8549 59.4026 16.881H59.2629V15.3916H57.4559V27.3978H59.3327V23.0371H59.4811C59.9787 23.9887 61.0524 24.5514 62.4054 24.5514C64.806 24.5514 66.3336 22.7558 66.3336 19.8929C66.3336 17.0299 64.806 15.2426 62.388 15.2426ZM61.8555 22.9544C60.2842 22.9544 59.2978 21.7794 59.2978 19.9011C59.2978 18.0146 60.2842 16.8396 61.8642 16.8396C63.4529 16.8396 64.4044 17.9897 64.4044 19.8929C64.4044 21.8043 63.4529 22.9544 61.8555 22.9544Z" fill="#7A7B7A" />
                        <path d="M43.5758 24.4024H45.7292L41.0154 12.027H38.8349L34.1211 24.4024H36.202L37.4054 21.1177H42.3816L43.5758 24.4024ZM39.8211 14.2739H39.9749L41.8658 19.5054H37.9211L39.8211 14.2739Z" fill="#7A7B7A" />
                    </g>
                    <g id="Download on the">
                        <path d="M35.3809 2.69145V8.66144H37.6548C39.5316 8.66144 40.6184 7.56508 40.6184 5.65783C40.6184 3.77953 39.5229 2.69145 37.6548 2.69145H35.3809ZM36.3585 3.53544H37.5457C38.8507 3.53544 39.6233 4.32151 39.6233 5.67024C39.6233 7.03966 38.8638 7.81745 37.5457 7.81745H36.3585V3.53544Z" fill="#7A7B7A" />
                        <path d="M43.9469 8.74832C45.3348 8.74832 46.1859 7.8671 46.1859 6.40666C46.1859 4.9545 45.3305 4.06914 43.9469 4.06914C42.5589 4.06914 41.7035 4.9545 41.7035 6.40666C41.7035 7.8671 42.5546 8.74832 43.9469 8.74832ZM43.9469 7.95812C43.1307 7.95812 42.6724 7.39132 42.6724 6.40666C42.6724 5.43028 43.1307 4.85935 43.9469 4.85935C44.7587 4.85935 45.2213 5.43028 45.2213 6.40666C45.2213 7.38718 44.7587 7.95812 43.9469 7.95812Z" fill="#7A7B7A" />
                        <path d="M53.4338 4.15602H52.4954L51.6487 7.59404H51.5745L50.5968 4.15602H49.6977L48.72 7.59404H48.6502L47.7991 4.15602H46.8476L48.157 8.66144H49.1216L50.0993 5.3434H50.1735L51.1555 8.66144H52.1288L53.4338 4.15602Z" fill="#7A7B7A" />
                        <path d="M54.5145 8.66144H55.4529V6.02604C55.4529 5.32271 55.8937 4.88417 56.5877 4.88417C57.2817 4.88417 57.6134 5.24411 57.6134 5.96812V8.66144H58.5518V5.74471C58.5518 4.67317 57.9669 4.06914 56.9063 4.06914C56.1905 4.06914 55.7191 4.37115 55.4878 4.87176H55.418V4.15602H54.5145V8.66144Z" fill="#7A7B7A" />
                        <path d="M60.0296 8.66144H60.968V2.39771H60.0296V8.66144Z" fill="#7A7B7A" />
                        <path d="M64.4973 8.74832C65.8852 8.74832 66.7363 7.8671 66.7363 6.40666C66.7363 4.9545 65.8809 4.06914 64.4973 4.06914C63.1093 4.06914 62.2539 4.9545 62.2539 6.40666C62.2539 7.8671 63.105 8.74832 64.4973 8.74832ZM64.4973 7.95812C63.6811 7.95812 63.2228 7.39132 63.2228 6.40666C63.2228 5.43028 63.6811 4.85935 64.4973 4.85935C65.3091 4.85935 65.7718 5.43028 65.7718 6.40666C65.7718 7.38718 65.3091 7.95812 64.4973 7.95812Z" fill="#7A7B7A" />
                        <path d="M69.5323 7.98708C69.0217 7.98708 68.6507 7.75126 68.6507 7.34581C68.6507 6.94864 68.9475 6.73764 69.6021 6.69627L70.7631 6.62593V7.00242C70.7631 7.56094 70.2394 7.98708 69.5323 7.98708ZM69.2923 8.73591C69.9164 8.73591 70.4358 8.4794 70.7195 8.02845H70.7937V8.66144H71.6972V5.58336C71.6972 4.6318 71.025 4.06914 69.8335 4.06914C68.7554 4.06914 67.9872 4.5656 67.8912 5.33926H68.7991C68.9038 5.0207 69.2661 4.83866 69.7898 4.83866C70.4314 4.83866 70.7631 5.10758 70.7631 5.58336V5.97225L69.4756 6.04259C68.3451 6.10878 67.7079 6.57629 67.7079 7.38718C67.7079 8.21049 68.3757 8.73591 69.2923 8.73591Z" fill="#7A7B7A" />
                        <path d="M74.8816 8.73591C75.5363 8.73591 76.0906 8.44217 76.3743 7.94984H76.4485V8.66144H77.3476V2.39771H76.4092V4.87176H76.3394C76.0819 4.37529 75.5319 4.08155 74.8816 4.08155C73.6813 4.08155 72.9088 4.98346 72.9088 6.40666C72.9088 7.834 73.6726 8.73591 74.8816 8.73591ZM75.1478 4.88003C75.9335 4.88003 76.4267 5.47165 76.4267 6.4108C76.4267 7.35408 75.9378 7.93743 75.1478 7.93743C74.3535 7.93743 73.8777 7.36236 73.8777 6.40666C73.8777 5.45924 74.3578 4.88003 75.1478 4.88003Z" fill="#7A7B7A" />
                        <path d="M83.4328 8.74832C84.8207 8.74832 85.6718 7.8671 85.6718 6.40666C85.6718 4.9545 84.8164 4.06914 83.4328 4.06914C82.0448 4.06914 81.1894 4.9545 81.1894 6.40666C81.1894 7.8671 82.0405 8.74832 83.4328 8.74832ZM83.4328 7.95812C82.6166 7.95812 82.1583 7.39132 82.1583 6.40666C82.1583 5.43028 82.6166 4.85935 83.4328 4.85935C84.2446 4.85935 84.7072 5.43028 84.7072 6.40666C84.7072 7.38718 84.2446 7.95812 83.4328 7.95812Z" fill="#7A7B7A" />
                        <path d="M86.914 8.66144H87.8524V6.02604C87.8524 5.32271 88.2932 4.88417 88.9872 4.88417C89.6812 4.88417 90.0129 5.24411 90.0129 5.96812V8.66144H90.9513V5.74471C90.9513 4.67317 90.3664 4.06914 89.3058 4.06914C88.59 4.06914 88.1186 4.37115 87.8873 4.87176H87.8175V4.15602H86.914V8.66144Z" fill="#7A7B7A" />
                        <path d="M95.2731 3.03484V4.17671H94.5181V4.92554H95.2731V7.46993C95.2731 8.33874 95.6878 8.68626 96.7309 8.68626C96.9142 8.68626 97.0888 8.66558 97.2416 8.64076V7.9002C97.1106 7.91261 97.0277 7.92088 96.8837 7.92088C96.4167 7.92088 96.2115 7.71402 96.2115 7.24238V4.92554H97.2416V4.17671H96.2115V3.03484H95.2731Z" fill="#7A7B7A" />
                        <path d="M98.5012 8.66144H99.4396V6.03018C99.4396 5.34754 99.8673 4.88831 100.636 4.88831C101.299 4.88831 101.652 5.25238 101.652 5.97225V8.66144H102.591V5.75298C102.591 4.68145 101.967 4.07328 100.954 4.07328C100.238 4.07328 99.7364 4.37529 99.5051 4.88003H99.4309V2.39771H98.5012V8.66144Z" fill="#7A7B7A" />
                        <path d="M105.976 4.82625C106.67 4.82625 107.124 5.28548 107.146 5.99294H104.745C104.798 5.28962 105.282 4.82625 105.976 4.82625ZM107.141 7.4451C106.967 7.79676 106.578 7.99121 106.011 7.99121C105.26 7.99121 104.776 7.49061 104.745 6.7004V6.65076H108.102V6.34047C108.102 4.92554 107.303 4.06914 105.98 4.06914C104.64 4.06914 103.789 4.98346 103.789 6.42321C103.789 7.86296 104.623 8.74832 105.985 8.74832C107.072 8.74832 107.831 8.25186 108.045 7.4451H107.141Z" fill="#7A7B7A" />
                    </g>
                    <g id="Apple logo">
                        <path d="M23.9283 14.2455C23.9511 12.5698 24.9004 10.9843 26.4065 10.1066C25.4564 8.82039 23.865 8.00489 22.2093 7.95579C20.4434 7.78009 18.7314 8.95743 17.8315 8.95743C16.9143 8.95743 15.5287 7.97323 14.0368 8.00233C12.0921 8.06188 10.2791 9.10993 9.33299 10.7215C7.29916 14.0593 8.81621 18.9647 10.7644 21.6628C11.7392 22.9839 12.8784 24.4597 14.369 24.4073C15.8277 24.35 16.3724 23.5256 18.1332 23.5256C19.8775 23.5256 20.3887 24.4073 21.9095 24.374C23.4747 24.35 24.4609 23.047 25.4014 21.7134C26.1018 20.772 26.6407 19.7316 26.9982 18.6307C25.1588 17.8932 23.9304 16.1386 23.9283 14.2455Z" fill="#7A7B7A" />
                        <path d="M21.0556 6.18144C21.909 5.21032 22.3295 3.96212 22.2277 2.7019C20.9239 2.83171 19.7195 3.42239 18.8545 4.35624C18.0087 5.26867 17.5686 6.49491 17.6518 7.70696C18.9561 7.71969 20.2383 7.14502 21.0556 6.18144Z" fill="#7A7B7A" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Ios

