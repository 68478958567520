import { useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';

export default function F005ManagementPlans({ data, setData, setFormId }) {
  useEffect(() => {
    setFormId('F005');
  }, [setFormId]);

  const { managementPlan, responsibleEntity, nameOfEntity, entity_doc } = data;

  return (
    <>
      <Typography variant='h5' sx={{ mb: 2 }}>
        Management plans
      </Typography>
      <FormControl
        sx={{
          mt: 1,
        }}
        fullWidth
      >
        <InputLabel id='demo-simple-select-label'>
          What type of management plan to you have in place?
        </InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          label='Type of management plan'
          name='managementPlan'
          value={managementPlan}
          onChange={setData}
        >
          <MenuItem value='EMP'>EMP - Environmental Management Plan</MenuItem>
          <MenuItem value='CMP'>CMP - Conservation Management Plan</MenuItem>
        </Select>
      </FormControl>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        What is the name of the entity responsible for the elaboration of this
        plan?
      </Typography>
      <TextField
        name='responsibleEntity'
        id='plan'
        label='What is the name of the entity responsible for the elaboration of this plan?'
        type='text'
        value={responsibleEntity}
        onChange={setData}
        fullWidth
      />

      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Is this the same entity you share responsibility with for implementation
        of MP?
      </Typography>
      <RadioGroup
        row
        aria-labelledby='demo-radio-buttons-group-label'
        name='sameEntity'
        onChange={setData}
      >
        <FormControlLabel
          value='yes'
          control={<Radio />}
          label='Yes'
          name='sameEntity'
        />
        <FormControlLabel
          value='no'
          control={<Radio />}
          label='No'
          name='sameEntity'
        />
      </RadioGroup>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        If no, what is the name of the entity you share responsibility with for
        implementation of MP?
      </Typography>
      <TextField
        name='nameOfEntity'
        id='responsibility'
        label='If no, what is the name of the entity you share responsibility with for implementation of MP?'
        type='text'
        value={nameOfEntity}
        onChange={setData}
        fullWidth
      />

      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Upload document
      </Typography>
      <TextField
        name='entity_doc'
        value={entity_doc}
        type='file'
        onChange={setData}
        fullWidth
      />
    </>
  );
}
